import React, { useEffect, useState } from "react";
import "./style.css";
import { useLocation, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import GooglePayButton from "@google-pay/button-react";
import ApplePayButton from "./ApplePay/ApplePayButton";
import { merchantIdentifier } from "./ApplePay/PaymentConf";
import { performPayment } from "./ApplePay/PaymentApi";
import "./lottery.css";
import { useHistory } from "react-router";
import CryptoJS from "crypto-js";
import styles from "./donation.module.css";
import FooterWeb from "./Footer/FooterWeb";
import { useStateValue } from "../stateProvider";
import Validator from "validator";
import { getProvinceData } from "./API/ProvinceAPI/ProvinceAPI";
import { getTaxInfoDataByEmail } from "./API/TaxInfoAPI/TaxInfoAPI";
import Loader from "./Spinner/Loader";
import logo from "../logo/BNAedit.png";
import {
  APIBase_URL,
  GLOBLE_PAY_Merchant_Id,
  GPAY_Merchant_Id,
} from "../config";
import { CARDARR, CARDICON } from "./constant";
import {
  stripeCardNumberValidation,
  stripeCardExpirValidation,
  textWithSpacesOnly,
  minLength,
  textAndSpacesOnly,
  texEmailOnly,
} from "./validations";

function Lottery(props) {
  const [{ privacy }, dispatch] = useStateValue();
  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [data, setData] = useState("");
  const [charitydata, setCharityData] = useState("");
  const [lotteryValues, setLotteryValues] = useState([]);
  const [fiftyLotteryValues, setFiftyLotteryValues] = useState([]);

  const [amount, setAmount] = useState([]);
  const [hidediv, sethidediv] = useState(false);
  const [currentAmount, setCurrentAmount] = useState("");
  const [clogo, setLogo] = useState("");
  const [mapAmount, setMapAmount] = useState("");
  const [mapMonthlyAmount, setMapMonthlyAmount] = useState("");
  const [fees, setFees] = useState("");
  const [isOhterAmountBox, setIsOhterAmountBox] = useState(true);
  const [isAmountBox, setIsAmountBox] = useState(true);
  const [isOtherform, setIsOtherform] = useState(false);
  const [otherAmount, setOtherAmount] = useState("");
  const [isprocessFee, setIsProcessFee] = useState(0);
  const [processFeeAmount, setProcessFeeAmount] = useState("");
  const [processFeeAmountPer, setProcessFeeAmountPer] = useState(0);

  //Language State
  const [selectDonationLan, setSelectDonationLan] = useState("");
  const [amountLan, setAmountLan] = useState("");
  const [otherLan, setOtherLan] = useState("");
  const [donationMesapplePayLan, setDonationMesapplePayLan] = useState("");
  const [donationMesgPayLan, setDonationMesgPayLan] = useState("");
  const [submitLan, setSubmitLan] = useState("");
  const [cancelLan, setCancelLan] = useState("");
  const [amountRequiredLan, setAmountRequiredLan] = useState("");
  const [backLan, setBackLan] = useState("");
  const [typeLan, setTypeLan] = useState("");
  const [englishAmountSign, setEnglishAmountSign] = useState("");
  const [frenchAmountSign, setFrenchAmountSign] = useState("");
  const [cardButtonLan, setCardButtonLan] = useState("");
  const [orLan, setOrLan] = useState("");
  const [cardPayButtonLan, setCardPayButtonLan] = useState("");
  const [cardNumberLan, setCardNumberLan] = useState("");
  const [cardHolderLan, setCardHolderLan] = useState("");
  const [cardExpiryMonthLan, setCardExpiryMonthLan] = useState("");
  const [cardExpiryYearLan, setCardExpiryYearLan] = useState("");
  const [cardCvnLan, setCardCvnLan] = useState("");
  const [requuireMessLan, setRequuireMessLan] = useState("");
  const [cardInvalidMesLan, setCardInvalidMesLan] = useState("");
  const [onlyAlphabetLan, setOnlyAlphabetLan] = useState("");
  const [invaildDataFormatLan, setInvaildDataFormatLan] = useState("");
  const [cvnErrorMessLan, setCvnErrorMessLan] = useState("");
  const [creditCardPageMessLan, setCreditCardPageMess] = useState("");
  const [cardDetailsMessLan, setCardDetailsMessLan] = useState("");
  const [firstNameLan, setfirstNameLan] = useState("");
  const [lastNameLan, setlastNameLan] = useState("");
  const [addressLan, setaddressLan] = useState("");
  const [cityLan, setcityLan] = useState("");
  const [postalCodeLan, setPostalCodeLan] = useState("");
  const [provinceLan, setprovinceLan] = useState("");
  const [emailLan, setemailLan] = useState("");

  const [emailInvalidLan, setemailInvalidLan] = useState("");

  const [selectLotteryLan, setselectLotteryLan] = useState("");
  const [lotteryNameLan, setlotteryNameLan] = useState("");
  const [fifftyLotteryNameLan, setfifftyLotteryNameLan] = useState("");
  const [lotteryTermConditionLan, setlotteryTermConditionLan] = useState("");
  const [ruleRegulationLan, setruleRegulationLan] = useState("");
  const [errorMesLotteryLan, seterrorMesLotteryLan] = useState("");
  const [nextButtonLan, setnextButtonLan] = useState("");

  const [thankYouMesAgainLan, setthankYouMesAgainLan] = useState("");
  const [taxRecieptInfoLan, settaxRecieptInfoLan] = useState("");

  const [isLottery, setIsLottery] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [numTime, setNumTime] = useState("");

  const [cardSection, setCardSection] = useState(false);
  const [payButton, setPaybutton] = useState(true);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardExpiryMonth, setCardExpiryMonth] = useState("");
  const [cardExpiryYear, setCardExpiryYear] = useState("");
  const [cardCvn, setCardCvn] = useState("");
  const [cardType, setCardType] = useState();
  const [error, setError] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [provinceList, setProvinceList] = useState("");
  const [address, setAddress] = useState("");

  const [thankYouInfo, setthankYouInfo] = useState(false);
  const [donationPageInfo, setdonationPageInfo] = useState(true);
  const [formData, setFormData] = useState("");
  const [isLoder, setisLoder] = useState(false);
  const [isEighteen, setIsEighteen] = useState(false);
  const [errorAmountMes, setErrorAmountMes] = useState("");
  const [lastTicketNumber, setLastTicketNumber] = useState(null);
  const [color, setColor] = useState("#03989e");
  const [fontColor, setFontColor] = useState('#000000');
  const [btnFontColor, setBtnFontColor] = useState('#FFFFFF');
  const [spinner, setSpinner] = useState(true);
  // const [rulesRegUrl, setRulesRegUrl] =useState(null);
  var donationId1 = useLocation();
  var dataObj = donationId1.pathname;
  const str = dataObj;
  const data1 = str.substring(str.lastIndexOf("lottery/") + 8, str.length);

  const bytes = CryptoJS.AES.decrypt(data1, "secret key 123");
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  //console.log(originalText, "hhh");
  const originalStr = originalText.split(",");

  const donationID = originalStr[0];
  const retailerID = originalStr[1];
  const locationID = originalStr[2];
  const taxInfo = originalStr[3];
  const qrCodeID =
    typeof originalStr[4] === "undefined"
      ? donationID === "6238a251af6c492314c85755"
        ? "6238a3bcaf6c492314c85776"
        : "62336d2bf091241e1ca13871"
      : originalStr[4];
  //const qrCodeID = originalStr[4]

  const processingFee = (event) => {
    if (event.target.checked) {
      setFees(event.target.value);
    } else {
      setFees("0");
    }
  };

  const handleClick = () => {
    let result = isLottery
      ? lotteryValues.reduce(function (acc, obj) {
          return acc + Number(obj.amount);
        }, 0.00)
      : fiftyLotteryValues.reduce(function (acc, obj) {
          return acc + Number(obj.amount);
        }, 0.00); 
    if (result === 0) { 
      setErrorAmountMes(errorMesLotteryLan);
      setTimeout(() => {
        setErrorAmountMes("");
      }, 4000);
    } else {
      let totalAmount = parseFloat(result).toFixed(2);
      // console.log('Current Amount value',totalAmount);
      setthankYouInfo(true);
      //setProcessFeeAmountPer(processFee);
      setCurrentAmount(totalAmount);
      sethidediv(true);
      setIsOhterAmountBox(false);
      setdonationPageInfo(false);
    }
  };
  
  const donationInfoPageOpem = () => {
    setdonationPageInfo(true);
    setthankYouInfo(false);
  };

  const cancelThankYou = () => {
    sethidediv(false);
    setCurrentAmount("");
    setIsOtherform(false);
    setIsAmountBox(true);
    setIsOhterAmountBox(true);
    setthankYouInfo(false);
    setdonationPageInfo(true);
    setProcessFeeAmountPer(0);
  };
  const cardSectionOpen = () => {
    setCardSection(true);
    setPaybutton(false);
  };
  const cancelCardPayment = () => {
    setCardSection(false);
    setPaybutton(true);
  };

  const onClick = (
    amount,
    charityName,
    clogo,
    dataObj,
    donationID,
    qrCodeID,
    locationID,
    taxinfo
  ) => {
    performPayment(
      amount,
      charityName,
      clogo,
      dataObj,
      donationID,
      qrCodeID,
      locationID,
      taxinfo
    )
      .then((Status) => {
        console.log(Status, true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    async function fetchData() {
      const res = await axios
        .get(`${APIBase_URL}charity/lottery/${donationID}/${locationID}`)
        .then((resp) => {
          //console.log(resp.data,"fetched")
          const ab = resp.data;
          const monthlyAm = ab.monthlyDonationAmount.map((val1) =>
              val1?.lottery.map((val2) =>
              val1?.lotteryType === 1
                ? setLotteryValues((p) => [
                    ...p,
                    {
                      lotteryName: val2.lotteryName,
                      count: 0,
                      amount: 0,
                      actualPrice: parseFloat(val2.amount).toFixed(2),
                      lotteryType: "Lottery",
                      ticketNo:[]
                    },
                  ]) : setFiftyLotteryValues((m) => [
                    ...m,
                    {
                      lotteryName: val2.lotteryName,
                      count: 0,
                      amount: 0,
                      actualPrice: parseFloat(val2.amount).toFixed(2),
                      lotteryType: "50/50 Lottery",
                      ticketNo:[]
                    },
                  ])
            )
          );

          dispatch({
            type: "LANGUAGE",
            data: `${ab.languageData.poweredBy}`,
          });
          dispatch({
            type: "PRIVACY",
            data: `${ab.languageData.privacyPolicy}`,
          });
          setCharityData(ab);
          if(ab.color !=='' || ab.color !==null || ab.color !==undefined){
            setColor(ab.color);
            localStorage.setItem("color", ab.color);
          }
          if(ab.fontColor !=='' || ab.fontColor !==null || ab.fontColor !==undefined){
            setFontColor(ab.fontColor);
            setBtnFontColor(ab.fontColor);
            localStorage.setItem("fontColor", ab.fontColor);
          }

          ab.fiftyTicket==="Yes" && ab.lotteryTicket==="No"?setIsLottery(false):setIsLottery(true);
          setSelectDonationLan(ab.languageData.selectDonation);
          setAmountLan(ab.languageData.amount);
          setOtherLan(ab.languageData.other);
          setDonationMesapplePayLan(ab.languageData.lotteryMesapplePay);
          setDonationMesgPayLan(ab.languageData.lotteryMesgPay);
          setSubmitLan(ab.languageData.submit);
          setCancelLan(ab.languageData.cancel);
          setAmountRequiredLan(ab.languageData.amountRequired);
          setBackLan(ab.languageData.back);
          setTypeLan(ab.languageData.type);
          setCardButtonLan(ab.languageData.cardButton);
          setOrLan(ab.languageData.or);
          setCardPayButtonLan(ab.languageData.cardPayButtonLottery);
          setCardNumberLan(ab.languageData.cardNumber);
          setCardHolderLan(ab.languageData.cardHolder);
          setCardExpiryMonthLan(ab.languageData.cardExpiryMonth);
          setCardExpiryYearLan(ab.languageData.cardExpiryYear);
          setCardCvnLan(ab.languageData.cardCvn);
          setRequuireMessLan(ab.languageData.requuireMess);
          setCardInvalidMesLan(ab.languageData.cardInvalidMes);
          setOnlyAlphabetLan(ab.languageData.onlyAlphabet);
          setInvaildDataFormatLan(ab.languageData.invaildDataFormat);
          setCvnErrorMessLan(ab.languageData.cvnErrorMess);
          setCreditCardPageMess(ab.languageData.creditCardPageMess);
          setCardDetailsMessLan(ab.languageData.cardDetailsMess);
          setfirstNameLan(ab.languageData.firstName);
          setlastNameLan(ab.languageData.lastName);
          setaddressLan(ab.languageData.address);
          setcityLan(ab.languageData.city);
          setPostalCodeLan(ab.languageData.postalCode);
          setprovinceLan(ab.languageData.province);
          setemailLan(ab.languageData.email);
          setemailInvalidLan(ab.languageData.emailInvalid);
          setthankYouMesAgainLan(ab.languageData.thankYouMesAgain);
          settaxRecieptInfoLan(ab.languageData.taxRecieptInfo);
          setselectLotteryLan(ab.languageData.selectLottery)
          setlotteryNameLan(ab.languageData.lotteryName)
          setfifftyLotteryNameLan(ab.languageData.fifftyLotteryName)
          setlotteryTermConditionLan(ab.languageData.lotteryTermCondition)
          setruleRegulationLan(ab.languageData.ruleRegulation)
          seterrorMesLotteryLan(ab.languageData.errorMesLottery)
          setnextButtonLan(ab.languageData.nextButton)
          if (ab.languageData.type == "English") {
            setEnglishAmountSign("$");
          } else {
            setFrenchAmountSign("$");
          }
          setLogo(ab["charityLogo"]);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setSpinner(false);
        });
    }
    async function fetchQRStatus(){
      await axios
         .get(`${APIBase_URL}qrCode/status/${qrCodeID}`)
         .then((resp) => {
           const status = resp.data;
           // console.log(status, "Print status data ....../////")
           if(status === false){
             fetchData()
         } else if(status === true)
         {
            //setSpinner(false);
          // localStorage.setItem("clogo", clogo);
           localStorage.setItem("dataInvalid", 'Lottery');
           history.push({ pathname: "/invalidQr" });

         }
         })
         .catch((error) => {
           console.log(error, "error");
           setSpinner(false);
         });
   }

    fetchQRStatus()
    getProvince();
    //getTransaction();
    // getOnPaymentAuthorized();
  }, []);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const showDonationAmount = () => {
    // console.log("go to showDonationAmount");
    setthankYouInfo(false);
    setdonationPageInfo(true);
    setCardSection(false);
    setPaybutton(true);

    sethidediv(false);
    setCurrentAmount("");
    setIsOtherform(false);
    setIsAmountBox(true);
    setIsOhterAmountBox(true);
  };

  const goToCharity = () => {
    // console.log("go to charity");
    // history.back(-1);
  };

  let check = mapAmount.length;
  let check2 = mapMonthlyAmount.length;
  // console.log(check, "chkk");
  const dis = {
    display: "inline-block",
  };

  localStorage.setItem(
    "languageData",
    JSON.stringify(charitydata.languageData)
  );
  localStorage.setItem(
    "taxReceiptMes",
    charitydata.sendGridLotteryTemplateId && charitydata.lotteryTemplateName && charitydata.createLotteryTicket === "Yes" && (charitydata.charityType==="Lottery" || charitydata.charityType==="Both" ) ?2:0
  );
  localStorage.setItem(
    "lotterySucessfulMsg",'Lottery'
  );
  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
        return card;
    }
    return "";
  }

  const handleInputData = (e) => {
    setCardNumber(e.target.value);
  };
  const handleInputData2 = (e) => {
    setCardHolderName(e.target.value);
  };

  const handleInputData4 = (e) => {
    setCardCvn(e.target.value);
  };

  const handleMonthData = (e) => {
    setCardExpiryMonth(e.target.value);
  };
  const handleYearData = (e) => {
    setCardExpiryYear(e.target.value);
  };

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case "card":
        setCardType(findDebitCardType(value));
        errorText = stripeCardNumberValidation(value, cardInvalidMesLan);
        setError({ ...error, cardError: errorText });
        break;
      case "cardHodler":
        errorText =
          value === ""
            ? requuireMessLan
            : textWithSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, cardHodlerError: errorText });
        break;
      case "expiry":
        errorText =
          value === ""
            ? requuireMessLan
            : stripeCardExpirValidation(value, invaildDataFormatLan);
        setError({ ...error, expiryError: errorText });
        break;
      case "securityCode":
        errorText =
          value === "" ? requuireMessLan : minLength(3)(value, cvnErrorMessLan);
        setError({ ...error, securityCodeError: errorText });
        break;
      case "expiryMonth":
        errorText =
          value === ""
            ? requuireMessLan
            : textAndSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, expiryMonthError: errorText });
        break;
      case "expiryYear":
        errorText =
          value === ""
            ? requuireMessLan
            : textAndSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, expiryYearError: errorText });
        break;
      default:
        break;
    }
  };
  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };
  const checkErrorEmailBeforeSave = (e) => {
    let errorText12 =
      e === "" ? requuireMessLan : texEmailOnly(e, emailInvalidLan);
    if (!errorText12) {
      getTaxInfoDataByEmail(e).then((resp) => {
        setFirstName(resp.firstName);
        setLastName(resp.lastName);
        setCity(resp.city);
        setProvince(resp.province);
        setPostalCode(resp.postalCode);
        setAddress(resp.address);
        let newData = {
          firstName: resp.firstName,
          lastName: resp.lastName,
          city: resp.city,
          province: resp.province,
          postalCode: postalCodeFormat(resp.postalCode),
          address: resp.address,
        };

        setFormData(newData);
      });
    }
  };
  const checkErrorBeforeSave = () => {
    let errorValue = {};
    let isError = false;
    let errorText1 = stripeCardNumberValidation(cardNumber, cardInvalidMesLan);
    let errorText2 =
      cardHolderName === ""
        ? requuireMessLan
        : textWithSpacesOnly(cardHolderName, onlyAlphabetLan);
    let errorText4 =
      cardCvn === "" ? requuireMessLan : minLength(3)(cardCvn, cvnErrorMessLan);
    let errorText5 =
      cardExpiryMonth === ""
        ? requuireMessLan
        : textAndSpacesOnly(cardExpiryMonth, onlyAlphabetLan);
    let errorText6 =
      cardExpiryYear === ""
        ? requuireMessLan
        : textAndSpacesOnly(cardExpiryYear, onlyAlphabetLan);
    if (errorText1) {
      errorValue = {
        ...errorValue,
        cardError: cardNumber ? errorText1 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText2) {
      errorValue = {
        ...errorValue,
        cardHodlerError: cardHolderName ? errorText2 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText4) {
      errorValue = {
        ...errorValue,
        securityCodeError: cardCvn ? errorText4 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText5) {
      errorValue = {
        ...errorValue,
        expiryMonthError: cardExpiryMonth ? errorText5 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText6) {
      errorValue = {
        ...errorValue,
        expiryYearError: cardExpiryYear ? errorText5 : requuireMessLan,
      };
      isError = true;
    }

    setError(errorValue);
    return isError;
  };
  const handleSave = (e) => {
    let errorCheck = checkErrorBeforeSave();
    let lottery = isLottery? lotteryValues.filter((element) => element.amount > 0):fiftyLotteryValues.filter((element) => element.amount > 0)
    if (!errorCheck) {
      setisLoder(true);
      let cardNumberText = CryptoJS.AES.encrypt(
        cardNumber,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      let cardCvnText = CryptoJS.AES.encrypt(
        cardCvn,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      let cardTypeText = CryptoJS.AES.encrypt(
        cardType,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      const data = {
        amount: parseFloat(currentAmount).toFixed(2),
        charityName: charitydata.charityName,
        charityID: donationID,
        retailerID: retailerID,
        locationID: locationID,
        qrCodeID: qrCodeID,
        cardNumber: cardNumberText,
        cardHolderName: cardHolderName,
        expMonth: cardExpiryMonth,
        expYear: cardExpiryYear.slice(-2),
        cvn: cardCvnText,
        cardType: cardTypeText,
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        email: email,
        type: isLottery === true? "Lottery":"FiftyLottery",
        status: isLottery === true? "Lottery":"FiftyLottery",
        lottery: lottery,
        lastTicketNumber:lastTicketNumber,
        locationStatus:charitydata.locationStatus,
      };
      // console.log(data, charitydata,"Data display of charity for location status//")
      axios
        .post(`${APIBase_URL}creditCard`, data)
        .then((resp) => {
          setisLoder(false);
          let result1 = JSON.stringify(resp.data.result);
          let orderID = JSON.stringify(resp.data.orderid);

          let result = result1.toString();
          var arrayWithEventIds = result.replace(/["]+/g, "");
          let arrnew = arrayWithEventIds.replace(/\[|\]/g, "");

          let orderID1 = orderID.toString();
          var arrayWithEventIds1 = orderID1.replace(/["]+/g, "");
          let newOrderID = arrayWithEventIds1.replace(/\[|\]/g, "");
          // console.log(arrnew, "arrnew");
          let a = "00";
          if (arrnew === a) {
            localStorage.setItem("am", currentAmount);
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            localStorage.setItem("orderID", newOrderID);
            localStorage.setItem(
              "paymentRequest",
              JSON.stringify(resp.data.tokenData)
            );
            localStorage.setItem("qrCodeID", qrCodeID);
            history.push({ pathname: "/thankyou" });
          } else {
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            history.push({ pathname: "/failed" });
          }
        })
        .catch((err) => {
          setisLoder(false);
          console.log(err, "error");
        });
      //setCardType("");
    }
  };

  const handleSaveMonthly = (e) => {
    let errorCheck = checkErrorBeforeSave();
    if (!errorCheck) {
      setisLoder(true);
      const data = {
        amount: parseFloat(currentAmount).toFixed(2),
        charityName: charitydata.charityName,
        charityID: donationID,
        retailerID: retailerID,
        locationID: locationID,
        qrCodeID: qrCodeID,
        cardNumber: cardNumber,
        cardHolderName: cardHolderName,
        expMonth: cardExpiryMonth,
        expYear: cardExpiryYear.slice(-2),
        cvn: cardCvn,
        cardType: cardType,
        startDate: startDate,
        numTime: numTime,
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        email: email,
      };
      axios
        .post(`${APIBase_URL}createCustomerAndPay`, data)
        .then((resp) => {
          setisLoder(false);
          let result1 = JSON.stringify(resp.data.result);
          let orderID = JSON.stringify(resp.data.orderid);

          let result = result1.toString();
          var arrayWithEventIds = result.replace(/["]+/g, "");
          let arrnew = arrayWithEventIds.replace(/\[|\]/g, "");

          let orderID1 = orderID ? orderID.toString() : "";
          var arrayWithEventIds1 = orderID1.replace(/["]+/g, "");
          let newOrderID = arrayWithEventIds1.replace(/\[|\]/g, "");
          // console.log(arrnew, "arrnew");
          let a = "00";
          if (arrnew === a) {
            //alert(qrCodeID)
            localStorage.setItem("am", currentAmount);
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            localStorage.setItem("orderID", newOrderID);
            localStorage.setItem(
              "paymentRequest",
              JSON.stringify(resp.data.tokenData)
            );
            localStorage.setItem("qrCodeID", qrCodeID);
            history.push({ pathname: "/thankyou" });
          } else {
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            history.push({ pathname: "/failed" });
          }
        })
        .catch((err) => {
          setisLoder(false);
          console.log(err, "error");
        });
      //setCardType("");
    }
  };
  const handlePayType = (e) => {
    setIsLottery(true);
  };
  const handlePayType2 = (e) => {
    setIsLottery(false);
  };
  const getProvince = async () => {
    getProvinceData().then((resp) => {
      setProvinceList(resp);
    });
  };


  const handleEighteen = (e) => {
    e.target.checked === true
      ? setIsEighteen(e.target.value)
      : setIsEighteen(false);
  };

  const monthList = [];

  for (let i = 1; i <= 12; i++) {
    monthList.push(
      <option className="dropdown-item" value={i}>
        {i}
      </option>
    );
  }

  const monthListCArd = [];

  for (let i = 1; i <= 12; i++) {
    monthListCArd.push(
      <option className="dropdown-item" value={("0" + i).slice(-2)}>
        {("0" + i).slice(-2)}
      </option>
    );
  }

  const yearList = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i <= currentYear + 10; i++) {
    yearList.push(
      <option className="dropdown-item" value={i}>
        {i}
      </option>
    );
  }

  function postalCodeFormat(string) {
    if (string) {
      let foo = string.split(" ").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,3}", "g")).join(" ");
      }
      return foo;
    } else {
      return "";
    }
  }

  const getRandomIds = (characters, numbers, charitydata, lastTicketNumber, total) => {
    const ticketNoArray =[];
    if(lastTicketNumber !== null){
      ticketNoArray.push(lastTicketNumber);      
    }else{
      ticketNoArray.push(charitydata.lastTicketNumber);
    }

    for(let i=1; i<= total - 1; i++){ 
      let newTicketDigitVal = Number(numbers) + i;
      let newTicket = characters + newTicketDigitVal;
      let fNewTicketDigitVal = Number(numbers)+i+1;
      let fNewTicket = characters + fNewTicketDigitVal;
      ticketNoArray.push(newTicket);
      setLastTicketNumber(fNewTicket)
    }
    if(total ===1){
      let fNewTicketDigitVal = Number(numbers)+total+1;
      let fNewTicket = characters + fNewTicketDigitVal;
      setLastTicketNumber(fNewTicket)
    }
   
   
   return ticketNoArray;
  }

  let handleAddLotteryChange = (i, e) => {

    let newFormValues = [...lotteryValues]
    let actualPrice = newFormValues[i]["actualPrice"]
    newFormValues[i][e.target.name] = parseFloat(e.target.value * actualPrice).toFixed(2)
    // console.log(e.target.value,"lottery product amount value////", newFormValues[i]["count"])    
    newFormValues[i]["count"] = +e.target.value;

    if(charitydata.lastTicketNumber){
      let startingTicketNumberVl =''
      let numbers =0
      let characters=''

      if(lastTicketNumber !== null && lastTicketNumber !=='' && lastTicketNumber !== undefined){
         
          startingTicketNumberVl = lastTicketNumber;
      }else{
        
        startingTicketNumberVl = charitydata.lastTicketNumber;
      }
      if(startingTicketNumberVl!=='' && startingTicketNumberVl!== null && startingTicketNumberVl!==undefined){
       
        // matching all alphabetic characters
        if(startingTicketNumberVl.match(/[a-z]+/gi)){
          characters = startingTicketNumberVl.match(/[a-z]+/gi).join("");
        }
        // matching all numbers
        if(startingTicketNumberVl.match(/\d+/g)){
           numbers = startingTicketNumberVl.match(/\d+/g).join("");
        }

        const Ids = getRandomIds(characters, numbers, charitydata, lastTicketNumber, e.target.value);
        // console.log(newFormValues,'Ticket Array///////////////////////')
        newFormValues[i]["ticketNo"] = Ids
      }
      setLotteryValues(newFormValues);
    }else{
      // console.log(newFormValues,"Dispaly updated amount data ///////")
      setLotteryValues(newFormValues);
    }
  };

   
  let handleAddFiftyLotteryChange = (i, e) => {
    let newFormValues = [...fiftyLotteryValues]
    let actualPrice = newFormValues[i]["actualPrice"]
    newFormValues[i][e.target.name] = parseFloat(e.target.value * actualPrice).toFixed(2)
    newFormValues[i]["count"] = +e.target.value
    if(charitydata.lastTicketNumber){
      let startingTicketNumberVl =''
      let characters=''
      let numbers=0

      if(lastTicketNumber !== null && lastTicketNumber !=='' && lastTicketNumber !== undefined){
         
          startingTicketNumberVl = lastTicketNumber;
      }else{
       
        startingTicketNumberVl = charitydata.lastTicketNumber;
      }
      if(startingTicketNumberVl!=='' && startingTicketNumberVl!== null && startingTicketNumberVl!==undefined){
        // matching all alphabetic characters
        if(startingTicketNumberVl.match(/[a-zA-Z]+/gi)){
          characters = startingTicketNumberVl.match(/[a-zA-z]+/gi).join("");
        }
        // matching all numbers
        if(startingTicketNumberVl.match(/\d+/g)){
           numbers = startingTicketNumberVl.match(/\d+/g).join("");
        }

        const Ids = getRandomIds(characters, numbers, charitydata, lastTicketNumber, e.target.value);
        newFormValues[i]["ticketNo"] = Ids
      }
      setFiftyLotteryValues(newFormValues);
    }else{
      setFiftyLotteryValues(newFormValues);
    }
    
  };

  return (
    <>
    {spinner === true? (
      <div className="container-fluid"><Loader /></div>):(
    <div className="container-fluid" style={{ color : "#000000", fontWeight: 600, backgroundColor: color?color:"#03989e", backgroundImage: color?color:"#03989e"}}>
      <div className="card-custom2 "  style={{ color : "#000000", fontWeight: 600, backgroundColor: color?color:"#03989e", backgroundImage: color?color:"#03989e"}}>
        <div className="inner-card"  style={{ color : "#000000", fontWeight: 600, backgroundColor: color?color:"#03989e", backgroundImage: color?color:"#03989e"}}>
          <div>
            <div className="logo donationLogo_Box">
              <span className="logo-circle charity_logo">
                <img src={`/uploads/${charitydata.charityLogo}`} alt="logo" />
              </span>
            </div>
          </div>
          <div className="custom-card custom-for-all"  style={{ color : "#000000", fontWeight: 600}}>
            <div className="headingTop mt-1 mb-4">
              <h2 hidden={hidediv}>{selectLotteryLan}</h2>
            </div>
            {(charitydata.lotteryTicket === "Yes" &&
            charitydata.fiftyTicket === "Yes") ? (
              <div
                className="headingTop mt-1"
                hidden={hidediv}
                style={{ textAlign: "center", color : "#000000", fontWeight: 600 }}
              >
                <div className="form-group1 "  style={{ color : "#000000", fontWeight: 600 }}>
                  <input
                    type="radio"
                    id="css"
                    name={`fav_language1`}
                    value={true}
                    checked={isLottery == true}
                    onChange={handlePayType}
                  />
                  <label>&nbsp;{lotteryNameLan}&nbsp;&nbsp;</label>
                  <input
                    type="radio"
                    id="html"
                    name={`fav_language1`}
                    value={false}
                    onChange={handlePayType2}
                  />
                  <label>&nbsp;{fifftyLotteryNameLan}</label>&nbsp;
                </div>
              </div>
            ) : (charitydata.lotteryTicket === "Yes" &&
            charitydata.fiftyTicket !== "Yes") ? (
              <div
                className="headingTop mt-1"
                hidden={hidediv}
                style={{ textAlign: "center", color : "#000000", fontWeight: 600 }}
              >
                <div className="form-group1">
                  <input
                    type="radio"
                    id="css"
                    name={`fav_language1`}
                    value={true}
                    checked={isLottery == true}
                    onChange={handlePayType}
                    readOnly="true"
                  />
                  <label>&nbsp;{lotteryNameLan}&nbsp;&nbsp;</label>
                </div>
              </div>
             
            ):(charitydata.lotteryTicket !== "Yes" &&
            charitydata.fiftyTicket === "Yes") ? (
              <div
                className="headingTop mt-1"
                hidden={hidediv}
                style={{ textAlign: "center", color : "#000000", fontWeight: 600 }}
              >
                <div className="form-group1">
                <input
                    type="radio"
                    id="html"
                    name={`fav_language1`}
                    value={false}
                    checked={isLottery == false}
                    onChange={handlePayType2}
                    readOnly="true"

                  />
                  <label>&nbsp;{fifftyLotteryNameLan}</label>&nbsp;
                </div>
              </div>
             
            ):''}
            <div className="custombtns lotteryFormBox mt-3">
              <small className="text-danger">{errorAmountMes}</small>
              {currentAmount === "" && isLottery === true && lotteryValues ? (
                <>
                  {lotteryValues.map((element, index) => (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group custom-formGroup">
                            <label  style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color,borderRadius: "5px"}} >{`${element.lotteryName}-${englishAmountSign}${parseFloat(element.actualPrice).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}${frenchAmountSign}`}</label>
                          </div>
                        </div>
                        <div className="col-2 p-0">
                          <div class="form-group custom-formGroup">
                            <input
                              type="text"
                              class="form-control"
                              name="amount"
                              autocomplete="off"
                              maxLength={2}
                              onKeyPress={function (event) {
                                var regex = new RegExp("^[0-9]");
                                var key = String.fromCharCode(
                                  !event.charCode ? event.which : event.charCode
                                );
                                if (!regex.test(key)) {
                                  event.preventDefault();
                                  return false;
                                }
                              }}
                              onChange={(e) => handleAddLotteryChange(index, e)}
                              value={element.count}
                              // value= {lotteryValues[index] ? lotteryValues[index].count : element.count}
                            />                           
                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-group custom-formGroup" >
                            <label  style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color,borderRadius: "5px"}} >{`${englishAmountSign}${parseFloat(element.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}${frenchAmountSign}`}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  {isEighteen ? (
                    <div className="nextBTNBox text-center mt-3">
                      <button className="btn" onClick={() => handleClick()}  style={{ color : btnFontColor,background: color, backgroundImage: color,boxShadow: "0px 1px 5px #0000003d"}}>
                      {nextButtonLan}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {/* {console.log("fsd",currentAmount,isLottery,fiftyLotteryValues,"Data Display values /////////////=====>>>>>")} */}
              {currentAmount === "" &&
              isLottery === false &&
              fiftyLotteryValues ? (
                <>
                  {fiftyLotteryValues.map((element, index) => (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <div class="form-group custom-formGroup">
                            <label style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color,borderRadius: "5px"}}>{`${element.lotteryName}-${englishAmountSign}${parseFloat(element.actualPrice).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}${frenchAmountSign}`}</label>
                          </div>
                        </div>
                        <div className="col-2 p-0" >
                          <div class="form-group custom-formGroup">
                            <input
                              type="text"
                              class="form-control"
                              name="amount"
                              autocomplete="off"
                              maxLength={2}
                              onKeyPress={function (event) {
                                var regex = new RegExp("^[0-9]");
                                var key = String.fromCharCode(
                                  !event.charCode ? event.which : event.charCode
                                );
                                if (!regex.test(key)) {
                                  event.preventDefault();
                                  return false;
                                }
                              }}
                              onChange={(e) =>
                                handleAddFiftyLotteryChange(index, e)
                              }
                              value={element.count}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-group custom-formGroup">
                            <label style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color,borderRadius: "5px"}}>{`${englishAmountSign}${parseFloat(element.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}${frenchAmountSign}`}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  {isEighteen ? (
                    <div className="nextBTNBox text-center mt-3" style={{ color : btnFontColor}}>
                      <button className="btn" onClick={() => handleClick()}  style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color ,boxShadow: "0px 1px 5px #0000003d"}}>
                        Next
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {/* {"For Thank you support page"} */}
              {thankYouInfo && donationPageInfo === false ? (
                <>
                  <div
                    className="custom-modal-head"
                    style={{ marginBottom: "25px", color : "#000000", fontWeight: 6 }}
                  >
                    <h4>{`${thankYouMesAgainLan}`}</h4>
                    <h6>{`${taxRecieptInfoLan}`}</h6>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${emailLan}`}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.email && "invalid"}`}
                        //value={firstName}
                        name="email"
                        required
                        {...register("email", {
                          required: `${requuireMessLan}`,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        onChange={(e) => {
                          checkErrorEmailBeforeSave(e.target.value);
                          setEmail(e.target.value);
                        }}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${firstNameLan}`}</label>
                      <input
                        type="text"
                        maxLength={50}
                        className={`form-control ${
                          errors.firstName && "invalid"
                        }`}
                        value={firstName}
                        name="firstName"
                        required
                        {...register("firstName", {
                          required: `${requuireMessLan}`,
                        })}
                        onChange={(e) =>
                          setFirstName(Validator.ltrim(e.target.value))
                        }
                      />
                      {errors.firstName && (
                        <small className="text-danger">
                          {errors.firstName.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${lastNameLan}`}</label>
                      <input
                        type="text"
                        maxLength={50}
                        className={`form-control ${
                          errors.lastName && "invalid"
                        }`}
                        name="lastName"
                        value={lastName}
                        required
                        {...register("lastName", {
                          required: `${requuireMessLan}`,
                        })}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {errors.lastName && (
                        <small className="text-danger">
                          {errors.lastName.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${addressLan}`}</label>
                      <input
                        type="text"
                        maxLength={55}
                        className={`form-control ${
                          errors.address && "invalid"
                        }`}
                        name="address"
                        value={address}
                        required
                        {...register("address", {
                          required: `${requuireMessLan}`,
                        })}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      {errors.address && (
                        <small className="text-danger">
                          {errors.address.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${cityLan}`}</label>
                      <input
                        type="text"
                        maxLength={50}
                        className={`form-control ${errors.city && "invalid"}`}
                        name="city"
                        value={city}
                        required
                        {...register("city", {
                          required: `${requuireMessLan}`,
                        })}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {errors.city && (
                        <small className="text-danger">
                          {errors.city.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${provinceLan}`}</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.province && "invalid"
                        }`}
                        name="province"
                        list="browsers"
                        value={province}
                        required
                        {...register("province", {
                          required: `${requuireMessLan}`,
                        })}
                        onClick={(e) => (e.target.value = "")}
                        onMouseLeave={(e) =>
                          (e.target.value = province ? province : "")
                        }
                        onChange={(e) => setProvince(e.target.value)}
                      />
                      <datalist id="browsers">
                        {provinceList &&
                          provinceList.map((list, id) =>
                            list.province.map((res) => {
                              return <option value={res} />;
                            })
                          )}
                      </datalist>
                      {errors.province && (
                        <small className="text-danger">
                          {errors.province.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="left-side">{`${postalCodeLan}`}</label>
                      <input
                        type="text"
                        onKeyPress={function (event) {
                          var regex = new RegExp("^[a-zA-Z0-9]+$");
                          var key = String.fromCharCode(
                            !event.charCode ? event.which : event.charCode
                          );
                          if (!regex.test(key)) {
                            event.preventDefault();
                            return false;
                          }
                        }}
                        className={`form-control ${
                          errors.postalCode && "invalid"
                        }`}
                        maxLength="7"
                        minLength="7"
                        name="postalCode"
                        value={postalCode ? postalCodeFormat(postalCode) : ""}
                        required
                        {...register("postalCode", {
                          required: `${requuireMessLan}`,
                        })}
                        onChange={(e) =>
                          setPostalCode(postalCodeFormat(e.target.value))
                        }
                      />
                      {errors.postalCode && (
                        <small className="text-danger">
                          {errors.postalCode.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary btnOther"
                        onClick={handleSubmit(donationInfoPageOpem)}
                        style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                      >
                        {`${submitLan}`}
                      </button>
                      <button
                        className="btn btn-primary btnOther"
                        onClick={() => cancelThankYou()}
                        style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                      >
                        {cancelLan}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <p class="Spacing-custom">
                      <a
                        href="https://www.bnasmartpayment.com/privacy-policy"
                        target="_blank"
                        style={{color:'#000000'}}
                      >{`${
                        privacy
                          ? privacy
                          : privacy.data
                          ? privacy.data
                          : "Privacy Policy"
                      }`}</a>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* deepak work */}
              {currentAmount !== "" && donationPageInfo === true ? (
                <div className="custombtns">
                  <div className="text-center d-justify-wrap">
                    {cardSection ? (
                      <>
                        <div className="headingTop mt-1" style={{ color : "#000000", fontWeight: 600  }}>
                          <h2>{cardDetailsMessLan}</h2>
                          <p></p>
                        </div>
                        <div
                          className={
                            isLoder ? "col-md-12 div-block" : "col-md-12"
                          }
                          style={{ color : "#000000", fontWeight: 600  }}
                        >
                          <div className="form-group">
                            <input
                              className="form-control pr-5"
                              type="tel"
                              name="card"
                              required
                              value={cardNumber}
                              placeholder={`${cardNumberLan}*`}
                              maxLength="16"
                              onKeyPress={function (event) {
                                var regex = new RegExp("^[0-9]");
                                var key = String.fromCharCode(
                                  !event.charCode ? event.which : event.charCode
                                );
                                if (!regex.test(key)) {
                                  event.preventDefault();
                                  return false;
                                }
                              }}
                              onChange={handleInputData}
                              onBlur={handleBlur}
                            />
                            {(!error || !error.cardError) &&
                              CARDARR.includes(cardType) && (
                                <img
                                  style={{
                                    position: "absolute",
                                    right: "13px",
                                    top: "3px",
                                  }}
                                  src={CARDICON[cardType]}
                                  alt="card"
                                  width="50px"
                                  height="33px"
                                />
                              )}
                            {error &&
                              error.cardError &&
                              error.cardError.length > 1 && (
                                <small className="text-danger">
                                  {error.cardError}
                                </small>
                              )}
                          </div>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="cardHodler"
                              required
                              placeholder={`${cardHolderLan}*`}
                              value={cardHolderName}
                              onChange={handleInputData2}
                              onBlur={handleBlur}
                            />
                            {error &&
                              error.cardHodlerError &&
                              error.cardHodlerError.length > 1 && (
                                <small className="text-danger">
                                  {error.cardHodlerError}
                                </small>
                              )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  onChange={handleMonthData}
                                  className="form-control"
                                >
                                  <option className="dropdown-item">{`${cardExpiryMonthLan}*`}</option>
                                  {monthListCArd}
                                </select>

                                {error &&
                                  error.expiryMonthError &&
                                  error.expiryMonthError.length > 1 && (
                                    <small className="text-danger">
                                      {error.expiryMonthError}
                                    </small>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  onChange={handleYearData}
                                  className="form-control"
                                >
                                  <option className="dropdown-item">{`${cardExpiryYearLan}*`}</option>
                                  {yearList}
                                </select>

                                {error &&
                                  error.expiryYearError &&
                                  error.expiryYearError.length > 1 && (
                                    <small className="text-danger">
                                      {error.expiryYearError}
                                    </small>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="password"
                              pattern="[0-9]*"
                              inputmode="numeric"
                              name="securityCode"
                              required
                              placeholder={`${cardCvnLan}*`}
                              value={cardCvn}
                              onKeyPress={function (event) {
                                var regex = new RegExp("^[0-9]");
                                var key = String.fromCharCode(
                                  !event.charCode ? event.which : event.charCode
                                );
                                if (!regex.test(key)) {
                                  event.preventDefault();
                                  return false;
                                }
                              }}
                              onChange={handleInputData4}
                              onBlur={handleBlur}
                            />
                            {error &&
                              error.securityCodeError &&
                              error.securityCodeError.length > 1 && (
                                <small className="text-danger">
                                  {error.securityCodeError}
                                </small>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="">
                            <button
                              className="btn btn-primary btnCard"
                              onClick={
                                handleSave
                              }
                              style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                            >
                              {`${cardPayButtonLan}`}
                            </button>
                            <button
                              className="btn btn-primary btnCard"
                              onClick={() => cancelCardPayment()}
                              style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                            >
                              {cancelLan}
                            </button>
                          </div>
                        </div>

                        {isLoder ? (
                          <div className="div-image">
                            <svg
                              style={{
                                margin: "auto",
                                background: "rgba(90, 90, 90, 0.5)",
                                display: "block",
                                backgroundColor: "transparent",
                              }}
                              width="200px"
                              height="200px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g transform="translate(80,50)">
                                <g transform="rotate(0)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="1"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.875s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.875s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(71.21320343559643,71.21320343559643)">
                                <g transform="rotate(45)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.875"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.75s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.75s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(50,80)">
                                <g transform="rotate(90)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.75"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.625s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.625s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(28.786796564403577,71.21320343559643)">
                                <g transform="rotate(135)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.625"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.5s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.5s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(20,50.00000000000001)">
                                <g transform="rotate(180)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.5"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.375s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.375s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(28.78679656440357,28.786796564403577)">
                                <g transform="rotate(225)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.375"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.25s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.25s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(49.99999999999999,20)">
                                <g transform="rotate(270)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.25"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.125s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="-0.125s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                              <g transform="translate(71.21320343559643,28.78679656440357)">
                                <g transform="rotate(315)">
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#03989e"
                                    fill-opacity="0.125"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="0s"
                                      values="1.5 1.5;1 1"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                    ></animateTransform>
                                    <animate
                                      attributeName="fill-opacity"
                                      keyTimes="0;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      values="1;0"
                                      begin="0s"
                                    ></animate>
                                  </circle>
                                </g>
                              </g>
                            </svg>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {/* {"For onetime donation amount"} */}
                    {payButton ? (
                      <div
                        className={`button-section-1`}
                        style={{
                          padding: "12px 8px",
                          margin: "0px",
                          width: check === 3 && "100%",
                        }}
                      >
                        <div
                          className={`boxElement_btn btnWidth donation_wid__X-UYZ`}
                          style={{ width: check === 3 && "100%" ,color: btnFontColor, backgroundColor: color }}
                        >
                          <span
                            className="btn11"
                            style={{
                              width: " 100%",
                              fontSize: "25px",
                              fontWeight: " bold",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "10px",
                              color: btnFontColor,
                              backgroundColor: color,
                            }}
                          >
                            {`${englishAmountSign}${parseFloat(currentAmount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}${frenchAmountSign}`}
                          </span>
                        </div>

                        {currentAmount === "" ? (
                          ""
                        ) : (
                          <div className="selectedCharityWithAmount" style={{color:"#000000", fontWeight: 600}} >
                            <span>
                              {navigator.userAgent.match(
                                /Mac OS| iPad |iPhone |iPod/i
                              )
                                ? isLottery
                                  ? donationMesapplePayLan
                                  : creditCardPageMessLan
                                : isLottery
                                ? donationMesgPayLan
                                : creditCardPageMessLan}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {charitydata &&
                payButton == true &&
                donationPageInfo &&
                (function(){
                if (Object.values(charitydata).map(item => item === charitydata["contactName"])) {
                    if (
                      currentAmount !== "" &&
                      navigator.userAgent.match(
                        /Mac OS X| iPad |iPhone |iPod/i
                      ) &&
                      payButton == true
                    ) {
                      return (
                        <>
                          <div
                            style={{ textAlign: "center" }}
                            className="App mt-btn-top"
                          >
                            <ApplePayButton
                              merchantIdentifier={merchantIdentifier}
                              language={typeLan === "French" ? "fr" : ""}
                              onClick={() =>
                                onClick(
                                  currentAmount,
                                  charitydata.charityName,
                                  clogo,
                                  dataObj,
                                  donationID,
                                  qrCodeID,
                                  locationID,
                                  {
                                    firstName: firstName,
                                    lastName: lastName,
                                    address: address,
                                    city: city,
                                    province: province,
                                    postalCode: postalCode,
                                    email: email,
                                    type: isLottery === true? "Lottery":"FiftyLottery",
                                    status: isLottery === true? "Lottery":"FiftyLottery",
                                    lottery: isLottery? lotteryValues.filter((element) => element.amount > 0):fiftyLotteryValues.filter((element) => element.amount > 0),
                                  }
                                )
                              }
                            />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                            className="App end-btn-cstm"

                          >
                            <div className="pad-15">{orLan}</div>
                            <button
                              className="btn btn-primary btnCard"
                              onClick={cardSectionOpen}
                              style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                            >
                              {cardButtonLan}
                            </button>
                          </div>
                        </>
                      );
                    } else {
                      //if (isLottery == true) {
                      return currentAmount === "" || payButton == false ? (
                        ""
                      ) : (
                        <>
                          <div
                            style={{ textAlign: "center" }}
                            className="App mt-btn-top"
                           
                          >
                            <GooglePayButton
                              environment="PRODUCTION"
                              paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                  {
                                    type: "CARD",
                                    // ['CARD', 'TOKENIZED_CARD'],
                                    parameters: {
                                      allowedAuthMethods: [
                                        "PAN_ONLY",
                                        "CRYPTOGRAM_3DS",
                                      ],
                                      allowedCardNetworks: [
                                        "MASTERCARD",
                                        "VISA",
                                      ],
                                    },
                                    tokenizationSpecification: {
                                      type: "PAYMENT_GATEWAY",
                                      parameters: {
                                        gateway: "globalpayments",
                                        gatewayMerchantId:
                                          GLOBLE_PAY_Merchant_Id,
                                      },
                                    },
                                  },
                                ],
                                merchantInfo: {
                                  merchantId: GPAY_Merchant_Id,
                                  merchantName: "",
                                },
                                transactionInfo: {
                                  totalPriceStatus: "FINAL",
                                  totalPriceLabel: "Total",
                                  totalPrice: String(currentAmount),
                                  currencyCode: "CAD",
                                  // countryCode: data.charity.country,
                                },

                                shippingAddressRequired: true,
                                callbackIntents: [
                                  "SHIPPING_ADDRESS",
                                  "PAYMENT_AUTHORIZATION",
                                ],
                              }}
                              onLoadPaymentData={(paymentRequest) => {
                                try {
                                  const data = {
                                    amount: currentAmount,
                                    charityName: charitydata.charityName,
                                    charityID: donationID,
                                    retailerID: retailerID,
                                    locationID: locationID,
                                    paymentRequest: paymentRequest,
                                    qrCodeID: qrCodeID,
                                    firstName: firstName,
                                    lastName: lastName,
                                    address: address,
                                    city: city,
                                    province: province,
                                    postalCode: postalCode,
                                    email: email,
                                    type: isLottery === true? "Lottery":"FiftyLottery",
                                    status: isLottery === true? "Lottery":"FiftyLottery",
                                    lottery: isLottery? lotteryValues.filter((element) => element.amount > 0):fiftyLotteryValues.filter((element) => element.amount > 0),
                                    lastTicketNumber:lastTicketNumber,
                                    locationStatus:charitydata.locationStatus,
                                  };
                                  axios
                                    .post(`${APIBase_URL}gPayDebit`, data)
                                    .then((resp) => {
                                      let result1 = JSON.stringify(
                                        resp.data.result
                                      );
                                      let orderID = JSON.stringify(
                                        resp.data.orderid
                                      );
                                      let result = result1.toString();
                                      var arrayWithEventIds = result.replace(
                                        /["]+/g,
                                        ""
                                      );
                                      let arrnew = arrayWithEventIds.replace(
                                        /\[|\]/g,
                                        ""
                                      );

                                      let orderID1 = orderID.toString();
                                      var arrayWithEventIds1 = orderID1.replace(
                                        /["]+/g,
                                        ""
                                      );
                                      let newOrderID =
                                        arrayWithEventIds1.replace(
                                          /\[|\]/g,
                                          ""
                                        );
                                      let a = "00";
                                      if (arrnew === a) {
                                        //alert(qrCodeID)
                                        localStorage.setItem(
                                          "am",
                                          currentAmount
                                        );
                                        localStorage.setItem("clogo", clogo);
                                        localStorage.setItem(
                                          "dataObj",
                                          dataObj
                                        );
                                        localStorage.setItem(
                                          "orderID",
                                          newOrderID
                                        );
                                        localStorage.setItem(
                                          "paymentRequest",
                                          JSON.stringify(resp.data.tokenData)
                                        );
                                        localStorage.setItem(
                                          "qrCodeID",
                                          qrCodeID
                                        );
                                        history.push({
                                          pathname: "/thankyou",
                                        });
                                      } else {
                                        localStorage.setItem("clogo", clogo);
                                        localStorage.setItem(
                                          "dataObj",
                                          dataObj
                                        );
                                        history.push({ pathname: "/failed" });
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err, "error");
                                    });
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              onPaymentAuthorized={(paymentData) => {
                                return {
                                  transactionState: "SUCCESS",
                                };
                              }}
                              onPaymentDataChanged={(paymentData) => {
                                return {};
                              }}
                              existingPaymentMethodRequired="false"
                              buttonColor="black"
                              buttonType="buy"
                              buttonLocale={typeLan === "French" ? "fr" : "en"}
                            />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                            className="App end-btn-cstm"
                          >
                            <div className="pad-15"  style={{color:"#000000" }} >{orLan}</div>
                            <button
                              className="btn btn-primary btnCard"
                              onClick={cardSectionOpen}
                              style={{ color : fontColor, fontWeight: 600, background: color, backgroundImage: color}}
                            >
                              {cardButtonLan}
                            </button>
                          </div>
                        </>
                      );
                      //}
                    }
                  }
                })()
              }
            </div>
            {(charitydata.lotteryTicket === "Yes" ||
            charitydata.fiftyTicket === "Yes")  ? (
            <div
              className="headingTop mt-1"
              hidden={hidediv}
              style={{ textAlign: "center",color:"#000000" }}
            >
              <div className="form-group1">
                <p style={{ textAlign: "justify", color:"#000000" }}>
                  <input
                    type="checkbox"
                    value={true}
                    onClick={(e) => handleEighteen(e)}
                  />
                  &nbsp;
                  {lotteryTermConditionLan}
                  &nbsp;
                </p>
              </div>
            </div>
            ):""
                }
            {currentAmount === "" && (charitydata.rulesRegUrl !== null && charitydata.rulesRegUrl!== undefined && charitydata.rulesRegUrl !== '')? (
              <div className="col-md-12">
                <p class="Spacing-custom">
                  <a
                    href={charitydata.rulesRegUrl}
                    target="_blank"
                    style={{color:'#000000'}}
                  >{ruleRegulationLan}</a>
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {color == "#ffffff" ? 
         <>
         <footer
           className="text-center text-lg-start text-dark footer-fixed-bottom"
          
         >
           <div
             className="text-center p-3"
             style={{
               color:"#000000",
               backgroundColor:"#ffffff",
             }}
   
           >
             Powered by&nbsp;
             <img src={logo} style={{width:'30px'}} alt="" />
           </div>
         </footer>
       </> : <FooterWeb />}
      </div>
      <section className="col-md-12"  style={{ color : "#000000", fontWeight: 600, background: color, backgroundImage: color}}>
        <span
          onClick={hidediv ? () => showDonationAmount() : () => goToCharity()}
          style={{ cursor: "pointer" , color:"#000000",fontWeight: 600, background: color, backgroundImage: color }}
          className="link-next pl-3"
        >
          {hidediv && <span  style={{ cursor: "pointer" , color:"#000000",fontWeight: 600, background: color, backgroundImage: color }}>{`${backLan}`}</span>}
        </span>
      </section>
    </div>
        )}
        </>
  );
}

export default withRouter(Lottery);
