import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
// import dateFormat from require('dateformat');
import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getTaxInfoData } from "../API/TaxInfoAPI/TaxInfoAPI";
import Footer from "../Footer/Footer";
import PrintComponent from "./DonationDetailsPrint";
import jwt_decode from "jwt-decode";

const DonationDetail = ({ side }) => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); //moment().toDate()
  const [endDate, setEndtDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [disableDates, setDisableDates] = useState(new Date());
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';

  const charityId = JSON.parse(decodedData?.charityId);
  const [charityName, setCharityName] = useState([]);

  useEffect(() => {
    getDonator();
    getCharity();
  }, []);

  const getDonator = async () => {
    getTaxInfoData()
      .then((resp) => {
        // console.log(resp,"*******")
        setDonatorList(resp);
        // console.log(resp,"Display donator list data //////////////")
        setFilterData(resp);
      })
      .catch((err) => {
      });
  };
  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let charityName = [];
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
          // if(data._id === charityId){
            if(charityId.includes(data._id)){
            charityName.push(data.charityName)
            
          }
        });
        setCharityList(arr);
        setCharityName(charityName);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };

  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setDonatorList([]);
    if (selectedCharity === "All Charities") return setDonatorList(filterData);
    let filter = filterData.map((data, i) => {
      return data.charityID.map((d) => {
        if (d.charityName === selectedCharity) {
          setDonatorList((p) => [...p, data]);
        } else {
          <div>"no Record Found"</div>;
        }
      });
    });
  };

  // console.log(donatorList, "filterData");

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);
    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };

  const columns = [
    {
      id: "name",
      displayName: "Donor Name",
    },
    {
      id: "address",
      displayName: "Address",
    },
    {
      id: "city",
      displayName: "City",
    },
    {
      id: "province",
      displayName: "Province",
    },
    {
      id: "postalCode",
      displayName: "Postal Code",
    },
    // {
    //   id: "country",
    //   displayName: "Country",
    // },
    {
      id: "orderID",
      displayName: "OrderID",
    },
    {
      id: "taxReceipt",
      displayName: "Tax Receipt",
    },
    {
      id: "last4digit",
      displayName: "Card Type",
    },
    {
      id: "amount",
      displayName: "Amount",
    },
    {
      id: "date",
      displayName: "Date",
    },
    {
      id: "emailAddress",
      displayName: "Email Address",
    },
  ];

  const datas = donatorList.map((a) =>
    a.firstName
      ? {
          name: `${a.firstName} ${a.lastName}`,
          address: a.address.replace(/,/g, " "),
          city: a?.city.replace(/,/g, " "),
          province: a.province.replace(/,/g, " "),
          postalCode: a.postalCode.replace(/,/g, " "),
          // country: `${a.address}${a.province}${a.postalCode}`,
          last4digit: a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a.orderID?.slice(0, 6),
          taxReceipt: a.qrCodeID.map(a => a.taxInfo),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a.created_at.slice(0, 10),
          emailAddress: a.email,
        }
      : {
          name: "",
          address: "",
          city: "",
          province: "",
          postalCode: "",
          // country: "",
          last4digit: a?.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a?.orderID?.slice(0, 6),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a.created_at.slice(0, 10),
          emailAddress: "",
        }
  );
  //  let created_at = moment(val).format("MM-DD-YYYY")
  // console.log(datas, "datasdatasdatas");

  const handlePrint = () => {
    
    window.print();

    // aba

    // $("#myDiv").printElement();

    // var divToPrint=document.getElementsByClassName("home-content").innerHTML;
    // let newWin= window.open("");
    // newWin.document.write(divToPrint);
    // newWin.print();
    // newWin.close();
  };

  const columns1 = [
    {
      name: "Donor Name",
      selector: (row) => row.donorName,
      // sortable: true,
      width: "190px",
    },
    {
      name: "Address",
      selector: (row) => row.address,
      // sortable: true,
      // width: "104px"
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      width: "180px",
    },
    {
      name: "Tax Receipt",
      selector: (row) => row.taxReceipt ,
      width: "150px",
      center:true
    },
    {
      name: "Card Type",
      selector: (row) => row?.last4digit == "AmEx" ? "Amex" : row?.last4digit,
      width: "140px",
      center:true
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "140px",
      right: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      width: "150px",
      // textAlign: "right"
    },
    {
      name: "Email Address",
      selector: (row) => row.emailAddress,
      sortable: true,
      width: "300px",
    },
  ];

  const data1 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator && donator.address?donator.address:''}, ${donator && donator.city? donator.city:''}, ${donator && donator.province? donator.province: ''}, ${donator && donator.postalCode? donator.postalCode: ''}`
      : "",
    orderID: donator.orderID.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `$${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    date: moment(donator.created_at.slice(0, 10)).format("MM-DD-YYYY"),
    emailAddress: donator.email,
    taxReceipt: donator.qrCodeID.map(a => a.taxInfo),
  }));

  const data5 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator && donator.address?donator.address:''}, ${donator && donator.city? donator.city:''}, ${donator && donator.province? donator.province: ''}, ${donator && donator.postalCode? donator.postalCode: ''}`
      : "",
    orderID: donator.orderID.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    date: moment(donator.created_at.slice(0, 10)).format("MM-DD-YYYY"),
    emailAddress: donator.email,
    taxReceipt: donator.qrCodeID.taxInfo,
  }));
 

  // const handleSort = () =>{
  //   // const sortedActivities = activities.slice().sort((a, b) => b.date - a.date)
  //   const check = donatorList.slice().sort((a, b) => b.created_at - a.created_at)
  //   console.log(check,"-----")
  // }
  return (
    <>
      <section className="home-section">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Donation Details {typeof(charityId) !=='undefined' && charityId.length===1 ? `for ${charityName}`:''}</h2>

            <div className="row">
              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-lefttop">
                <div className="tophead">
                {typeof(charityId) ==='undefined' || charityId.length===0 || charityId.length>1?
                  <div className="charityMain">
                    <h5>
                      <b>CHARITY</b>
                    </h5>
                    <div>
                      <select onChange={handleChange} className="selectBox">
                        <option className="dropdown-item">All Charities</option>
                        {typeof(charityId) ==='undefined'|| charityId.length===0?
                        charityList
                          ? charityList.map((data) => (
                              <option
                                className="dropdown-item"
                                 value={data.charityName}
                              >
                                {data.charityName}
                              </option>
                            ))
                          : null
                        :  charityName
                          ? charityName.map((data) => (
                            
                              <option
                                className="dropdown-item"
                                 value={data}
                              >
                                {data}
                              </option>
                            ))
                          : null}
                      </select>
                      
                    </div>
                  </div>
                  :''}
                  <div className="donationAction d-flex">
                  <div className="">
                    <CsvDownloader
                      filename="Donation Details"
                      extension=".csv"
                      separator=","
                      // wrapColumnChar="'"
                      columns={columns}
                      datas={datas}
                      text="DOWNLOAD"
                    >
                      <button className="btn btn-primary text-nowrap maxWidnone">Download .CSV</button>
                    </CsvDownloader>
                  </div>

                  <div className="">
                    {/* <button
                      className="btn btn-primary custm-print"
                      onClick={handlePrint}
                    >
                      Print
                    </button> */}
                   
                    <PrintComponent data={data1}/>
                  </div>
                  </div>

                </div>
              </div>

              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-righttop">
                <form>
                  <div className="customdate">
                    {/* <h5>
                    <b>REPORTING PERIOD : </b>&nbsp;
                 </h5> */}

                    <div>
                      <label>
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label>
                        <b>To</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <div>
                {/* {console.log(data1,"DataTable data should be displayed....")} */}
                <DataTable
                  id="aba"
                  columns={columns1}
                  data={data1}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                />
              </div>
              {/* <table className="table table-striped mt-3" >
                <thead className="thead-light">
                  <tr>
                    <th>Donor Name</th>
                    <th>Address</th>
                    <th style = {{textAlign: "center"}}>Order ID</th>
                    <th style = {{textAlign: "center"}}>Amount</th>  
                    <th>Date &nbsp;<i className="fa fa-sort" aria-hidden="true" onClick = {handleSort}></i></th>  
                    <th>Email Address</th>
                  </tr>
                </thead>
                <tbody>
                  {donatorList
                    ? donatorList.map((donator) => {

                      let text1 = donator.orderID
                      let orderid = text1?.slice(0, 6);

                      let text = donator.created_at;
                      let val = text.slice(0, 10);
                      let created_at = moment(val).format("MM-DD-YYYY")
                      // let created_at = created_at1.sort((a, b) => a.valueOf() - b.valueOf())
                       
                        return (
                          <tr key={donator._id}>
                            <td>{donator.firstName} {donator.lastName}</td>
                            {donator.address ?
                            <td>{donator.address}, {donator.city}, {donator.province}, {donator.postalCode}</td>:<td></td>}
                            <td style = {{textAlign: "right"}}>{ orderid}</td>
                            {donator.amount.includes(".")?
                              <td style = {{textAlign: "right"}}>{`$${donator.amount}`}</td>
                              :<td style = {{textAlign: "right"}}>{`$${donator.amount}.00`}</td>}
                            <td>{created_at}</td>
                            
                            <td>{donator.email}</td>
                          </tr>
                        );
                      })
                    : `${"No Record Found"}`}
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
      {/* </div> */}
    </>
  );
};

export default DonationDetail;
