
import { React, useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import logo from "../../logo/BNALOGOv2.png";
import FooterWeb from "../Footer/FooterWeb";
// import logo from './logo/BNALOGOv2.png'
// import { API_URL } from "./config";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { APIBase_URL } from "../../config";
import Validator from 'validator';
import ForgotPassword from "./ForgotPassword"
import { useStateValue } from "../../stateProvider";
import Forgot_pass from "./Forgot_pass"

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, dispatch] = useStateValue();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loginStatus, setLoginStatus] = useState(false);
  const [errorMessage, setSuccessMessage] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const [updatePassword, setUpadtePassword] = useState("");
  const [msg, setMsg] = useState("");
  

  const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");
  const donationId = useParams(updatePassword);
  // console.log(donationId, "donation");

 
  const currentUser = localStorage.getItem("token");
  if (currentUser && currentUser.length > 10) {

    return <Redirect to="/dashboard" />;
  } 
  

  const login = async (event) => {
    event.preventDefault();
    // if(email||password) return false
    const data = {
      email: email,
      password: password,
    };
    await axios
      .post(`${APIBase_URL}admin/login`, data)
      .then((res) => {
        
        const token = res.data.token;
        
        if(res.data.auth){
          // debugger;
          // console.log(res.data,'resData');
          dispatch({
              type: 'ADD_TO_USER',
              data: res.data?.admin[0],
          })
          localStorage.clear();
          localStorage.setItem("token", token);
          localStorage.setItem("data", JSON.stringify(res.data));
         
          setLoginStatus(true);
          setSuccessMessage(false);
       
        }
        else if(!res.data.auth) {
          // console.log("not loged in");
          setLoginStatus(false);
          setSuccessMessage(true);
          localStorage.clear();
          
        } else {
          // console.log(res.data);
        
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return false;
  };
  if (loginStatus) {
    return <Redirect to="/dashboard" />;
  } else {
    <div>"Email or password is incorrect."</div>;
  }

  const forgotPassword = () => {
    if(passwordModal==false){
      setPasswordModal(true);
    }else{
      setPasswordModal(false);
      setTimeout(function () {
        setPasswordModal(true);

         }, 300);
    }
    

       };

  const emailHandleChange = (e) => {
    setUpadtePassword(Validator.trim(e.target.value));
    setForgetPasswordEmail(Validator.trim(e.target.value));
  };

  

  const sendEmail1 = async () => {
    
    if (
      !updatePassword.match(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      )
    ) {
      errors.Email.message =
        "Please enter valid email";
         return;
    }

    var ciphertext = CryptoJS.AES.encrypt(
      updatePassword,
      "secret key 123"
    ).toString();
    // console.log(ciphertext, "ciphertext");

    const passwordResetlink= APIBase_URL+"#/forgotpassword/"+ciphertext;

    // console.log("password Reseet link ",passwordResetlink);

    const mailText= "<p>Hi,</br>You requested to reset your password.</br> Please, click the link below to reset your passowrd.</p></br>"
    const UrlLink= mailText+`<a href=${passwordResetlink}>${passwordResetlink}</a>`;


    const data = {
      from: "bnasmartpay21@gmail.com",
      to: updatePassword,
      subject: "BNA Smart Payment",
      html:UrlLink,
      link:passwordResetlink
    };
    // console.log(data, "dataaa");

    await axios
      .post(`${APIBase_URL}mail`, data)
      .then((result) => {
        // console.log(result, "result");
        setMsg(`Change Password Link has been sent to your email`);
        setTimeout(() => {
          setMsg("");
        }, 2000);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
    setPasswordModal(false);
    reset();
  };

  return (
    <>
      <div className="card-custom" style={{ backgroundColor : "#03989e" }}>
        <div className="inner-card" >
          <form className="LoginForm custom-for-all cstmLog p-3">
            <h6 style={{ color: "green" }}>{msg}</h6>
            <div className="text-center loginlogo">
              <img src={logo} alt="" />
            </div>
            <h2 className="mt-3 mb-3 text-center">Scan2Give</h2>
            <h2 className="mt-3 mb-3 text-center">Login</h2>
            <div className="Logincontainer">
              <label for="email">
                <b>Email</b>
              </label>
              <input
                style={{ borderRadius: "50px", outline: "none" }}
                type="text"
                placeholder="Enter Email"
                name="email"
                required
                // {...register("Email",
                // 	{
                // 		required: "Email is required",
                // 		pattern: {
                // 			value: /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                // 			message: "Invalid email address",
                // 		}
                // 	})}
                // onKeyUp={()=>{
                // 	trigger("Email");
                // }}
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* {errors.Email && (<small className="text-danger" >{errors.Email.message}</small>)} */}
            </div>

            <div className="Logincontainer">
              <label for="psw">
                <b>Password</b>
              </label>
              <input
                style={{ borderRadius: "50px", outline: "none" }}
                type="password"
                placeholder="Enter Password"
                name="psw"
                required
                // {...register("Password",
                // 	{
                // 		required: "Password is required"
                // 	})}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* {errors.Password && (<small className="text-danger" >{errors.Password.message}</small>)} */}

              <button
                style={{
                  borderRadius: "50px",
                  outline: "none",
                  background: "linear-gradient(to left, #32d295, #31acdf)",
                }}
                className="themeColor loginSub text-center"
                type="submit"
                onClick={(e) => login(e)}
              >
                Login
              </button>
              <label>
                <input type="checkbox" name="remember" /> Remember me
              </label>
              <span className="psw" onClick={forgotPassword}>
                Forgot Password?
              </span>
              <div className="topWrapper new-display-class d-flex mb-2 text-danger">
                {errorMessage ? <h6>Invalid Email or Password </h6> : null}
              </div>
            </div>
          </form>
        </div>

        <div className="topWrapper d-flex mb-5">
          {passwordModal ? (
            
                <ForgotPassword   />
              // <Forgot_pass />
               
          ) : null}
        </div>

        <FooterWeb />
      </div>
    </>
  );
};

export default Login;

