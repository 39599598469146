import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const  Spinner = () =>{
  return (
    <div className="text-center">
      <BeatLoader  color="#ffffff" size={20} />
     
    </div>
  );
};

export default Spinner;