import axios from 'axios';
import { APIBase_URL } from "../../../config";

// this.fetch(`${API_URL}getCharity`, (response) => {

export const getCharityData = async() => {
   const result1 = await fetch(`${APIBase_URL}charity`)
   const result = await result1.json()
    return result

}
export const addCharityData = async(formData) => {
    // console.log(formData,"----")
   const result = await axios.post(`${APIBase_URL}charity`,formData ,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
  //  console.log(result)
    return result

}
export const importCsvCharityData = async (formData) => {
    const result = await axios.post(`${APIBase_URL}charity/importCsv`,formData ,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return result
    
  };
// export const updateCharityData = async(formData,updateID) => {
//     console.log(formData,updateID,"----")
//    const result1 = await fetch(`${APIBase_URL}charity/${updateID}`,formData,{
// 			method: 'PATCH',
// 			headers: {
// 				"Content-Type": "multipart/form-data",
// 			}
// 		})
//         const result = result1.json()
//         console.log(result)
//          return result

// }


export const updateCharityData = async(formData,updateID) => {
    // console.log(formData.getAll("UpdateLogo"),"----aaaaa")
   const result1 = await axios.patch(`${APIBase_URL}charity/${updateID}`,formData,{
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
        // const result = result1.json()
        // console.log(result)
         return result1

}

export const deleteCharityData = async(data,id) => {
    // console.log(data,id,"----")
   const result1 = await fetch(`${APIBase_URL}charity/delete/${id}`,{
			method: 'PATCH',
			headers: {
				'Accept': 'application/json',
				'Content-Type':'application/json'
			},
			body:JSON.stringify(data) 
		})
        const result = await result1.json()
        // console.log(result)
         return result

}

export const getStartingTicketNumberData = async(startingTicketNumber) => {
  // console.log(startingTicketNumber,"charity API");
  const result1 = await fetch(`${APIBase_URL}charity/lotteryNumber/${startingTicketNumber}`)
  const result = await result1.json()
  // console.log(result)
   return result

}