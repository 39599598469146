import { React } from "react";

const PathError = () => {
  return (
    <>
      <div className="pathError">
        <h2>404</h2>
        <h2>Page Not Found</h2>
        <h2>Please Check URL</h2>
      </div>
    </>
  );
};

export default PathError;
