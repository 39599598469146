import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getTaxInfoMonthlyData } from "../API/TaxInfoAPI/TaxInfoAPI";
import Footer from "../Footer/Footer";
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import axios from "axios";
import { APIBase_URL } from "../../config";
import TableLoader from "../Spinner/TableLoader"

const RecurringTransactionDetail = ({ side }) => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndtDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [disableDates, setDisableDates] = useState(new Date());
  const currentUserData = localStorage ? localStorage.getItem("token") : "";
  var decodedData = currentUserData ? jwt_decode(currentUserData) : "";
  const charityId = decodedData?.charityId;
  const [viewTransactionDetails, setViewTransactionDetails] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState([]);
  const [optionType, setOptionType] = useState([]);
  const [isCancel, setIsCancel] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState("");
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getDonator();
  }, []);

  const getDonator = async () => {
    getTaxInfoMonthlyData()
      .then((resp) => {
        setDonatorList(resp);
        setFilterData(resp);
      })
      .catch((err) => {});
  };

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  useEffect(()=>{
    const timeout = setTimeout(() => {
      // setRows(data1);
      setPending(false);
    },1000);
    return () => clearTimeout(timeout);
  })

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);

    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };

  const columns1 = [
    {
      name: "Date and Time",
      selector: (row) => row.created_at,
      width: "300px",
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      width: "300px",
    },
    // {
    //   name: "Payment Method",
    //   selector: (row) => row.mobileType,
    // },
    {
      name: "Card Brand",
      selector: (row) => row.last4digit,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      //sortable: true,
      center: true,
    },
    {
      name: "Account",
      selector: (row) => row.account,
      sortable: true,
      center: true,
    },
    {
      name: "No. of Payments",
      selector: (row) => row.recurringNo,
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: (row) => row._id,
      cell: (row) => (
        <>
           <button
          type="button"
          data-dismiss="modal"
          className="btn btn-primary"
          onClick={() => viewTransaction(row._id)}
          disabled={donatorList.filter((donator) => donator._id === row._id)[0] != undefined ? false : true}
        >
          Details
        </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const data1 = donatorList.filter(vl => vl.paymentType === "Monthly").map((donator) => ({
    // console.log(donator,"donator")
    created_at: donator.created_at
      ? moment(donator.created_at).format("LLLL")
      : "",
    orderID: donator.orderID,
    mobileType: donator.orderDetails[0]?.mobileType,
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g, ""),
    cardHolderName:donator.orderDetails[0]?.cardHolderName,
    amount: `${parseFloat(donator.amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    account: donator.orderDetails[0]?.MID?donator.orderDetails[0]?.MID:"",
    recurringNo: donator.recurringNo,
    status: donator.status
      ? donator.status === "Cancel"
        ? "Cancelled"
        : "Active"
      : "Active",
    _id: donator._id,
  }));

  // useEffect(() => {
  //   reset(formData);
  // }, [formData]);

  const viewTransaction = (id) => {
    reset({ ActionSelect: '' });
    const result = donatorList.filter((donator) => donator._id === id);
    let newData = {
      ActionSelect:result[0].status !== "undefined"?result[0].status !=='Donation'?result[0].status:'':'',
    };
    setFormData(newData);
    result[0].status = result[0].status !== "undefined"?result[0].status !=='Donation'?result[0].status:'':''
    setOptionType(result[0].status);
    setShowTransactionDetails(result);
    setViewTransactionDetails(!viewTransactionDetails); 
    setIsCancel(typeof(result[0].recurringCancelDate) != "undefined"?result[0].recurringCancelDate:"")
  };
  const transactionUpdate = () => {
    let apiType = "saveRecurringTransCancel"
    const data = {
      orderId:showTransactionDetails[0].orderID
    };
    axios
      .post(`${APIBase_URL}transaction/${apiType}`, data)
      .then((resp) => { 
        if(resp.data.status === false){ 
          setErrorMsg(resp.data.message)  
          setTimeout(() => {
            setErrorMsg("");
          }, 4000); 
        }else{ 
          setMsg("Recurring has been successfully cancelled.")
          setTimeout(() => {
            setMsg("");
          }, 4000);
          getDonator();
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleTypeChange = (e) => {
    const value = e.target.value;
    setOptionType(value);
  };

  const closePopup = (e) => {
    setShowTransactionDetails([]);
    setOptionType('');
    setViewTransactionDetails(false)
    setIsCancel("")
  };

  return (
    <>
      <section className="home-section lotteryPage">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Recurring Transactions</h2>

            <div className="row">
              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-lefttop">
                <form>
                  <div className="tophead customdate2">
                    <div>
                      <label className="from_to_width">
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label className="from_to_width">
                        <b>To</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <div className="mt-1">
                <DataTable
                  id="aba"
                  columns={columns1}
                  data={data1}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                  progressPending={pending}
                  progressComponent={<TableLoader/>}
                />
              </div>
            </div>
            {viewTransactionDetails ? (
              <div className="ModalBackground">
                <div className="modalContainer modelLocationContainer">
                  <div className="modalHeader mb-5">
                    <h3>Transaction Details</h3>
                  </div>
                  <button
                    className="closeBtn"
                    onClick={closePopup}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                  <h6 style={{ color: "red" }}>{errorMsg}</h6>
                  <h6 style={{ color: "green" }}>{msg}</h6>
                  <div className="container">
                  { isCancel === '' ?
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <form>              
                          <select
                            className="form-control"
                            {...register("ActionSelect", {
                              required: "Option is required",
                            })}
                            onChange={handleTypeChange}
                            id="transaction-id"
                          >
                            <option value="" disabled >Action</option>
                            <option className="dropdown-item" value="Cancel" selected={optionType === "Cancel"}>Cancel</option>
                          </select>
                          {errors.ActionSelect && (
                            <small className="text-danger">
                              {errors.ActionSelect.message}
                            </small>
                          )}
                          </form>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <button
                            className="btn btn-primary"
                            style={{ margin:"0px" }}
                            onClick={handleSubmit(transactionUpdate)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    :""
                  }
                  </div>

                  <div className="table-responsive location-custom-height">
                    <table className="table table-striped mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Frequency</th>
                          {/* <th>Card Brand</th> */}
                          {/* <th>Cardholder Name</th> */}
                          <th>{showTransactionDetails.map((trnaction) => (
                            trnaction.orderDetails[0]?.mobileType === 'Card Pay' ? "Cardholder Name" : "Name"
                          ))}</th>
                          <th>Card Number</th>
                          <th>Amount</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showTransactionDetails.length
                          ? showTransactionDetails.map((trnaction) => (
                              <tr id={trnaction._id} key={trnaction._id}>
                               
                                <td>{trnaction.orderID}</td>
                                <td>{trnaction.orderDetails[0]?.mobileType === "Card Pay Monthly"?"Monthly":trnaction.orderDetails[0]?.mobileType}</td>
                                {/* <td>
                                  {trnaction.orderDetails[0]?.last4digit.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  )}
                                </td> */}
                                {/* <td>{`${trnaction.firstName} ${trnaction.lastName}`}</td> */}
                                <td>{!!trnaction?.orderDetails[0]?.cardHolderName  && trnaction?.orderDetails[0]?.cardHolderName ? trnaction?.orderDetails[0]?.cardHolderName : (trnaction?.firstName !== undefined ? trnaction?.firstName : "") + " " + (trnaction?.lastName !== undefined ? trnaction?.lastName : "") }</td>
                                <td>{trnaction.orderDetails[0]?.last4digit}</td>
                                <td>{`${parseFloat(
                                  trnaction.amount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}</td>
                                {/* <td>
                                  {trnaction.orderDetails[0]?.MID?trnaction.orderDetails[0]?.MID:""}
                                  
                                </td> */}
                                 <td>
                                  {trnaction.created_at
                                    ? moment(trnaction.created_at).format(
                                        "LL"
                                      )
                                    : ""}
                                </td>
                                <td>
                                  {trnaction.created_at
                                    ? moment(trnaction.created_at).add('days', trnaction.recurringNo).format(
                                        "LL"
                                      )
                                    : ""}
                                </td>
                              </tr>
                            ))
                          : `No Location found`}
                      </tbody>
                    </table>
                  </div>
                  {showTransactionDetails[0].recurringCancelDate?
                  <div className="table-responsive location-custom-height">
                    <table className="table table-striped mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>Cancelled Date</th>
                          <th>Order ID</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showTransactionDetails.length
                          ? showTransactionDetails.map((trnaction) => (
                              <tr id={trnaction._id} key={trnaction._id}>
                                <td>
                                  {trnaction.recurringCancelDate
                                    ? moment(trnaction.recurringCancelDate).format(
                                        "LLLL"
                                      )
                                    : ""}
                                </td>
                                <td>{trnaction.orderID}</td>
                                <td>{`Cancelled`}</td>
                                <td>{`${parseFloat(
                                  trnaction.amount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}</td>
                                <td>
                                {trnaction.orderDetails[0]?.MID?trnaction.orderDetails[0]?.MID:""}
                                </td>
                              </tr>
                            ))
                          : `No Location found`}
                      </tbody>
                    </table>
                  </div>
                  :""
                  }
                  <div
                    className="modalFooter text-right"
                    style={{ marginTop: "18px", padding: "0" }}
                  >
                    <button
                      className="btn btn-primary"
                      style={{ float: "right", marginTop: "10px" }}
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
    </>
  );
};

export default RecurringTransactionDetail;
