import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import {
  getAllTransList,
  getAllTransListLottery,
} from "../API/TaxInfoAPI/TaxInfoAPI";
import TableLoader from "../Spinner/TableLoader";
import Footer from "../Footer/Footer";
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import axios from "axios";
import { APIBase_URL } from "../../config";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const TransactionDetail = ({ side }) => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndtDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [disableDates, setDisableDates] = useState(new Date());
  const currentUserData = localStorage ? localStorage.getItem("token") : "";
  var decodedData = currentUserData ? jwt_decode(currentUserData) : "";
  const charityId = decodedData?.charityId;
  const [viewTransactionDetails, setViewTransactionDetails] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState([]);
  const [iSHide, setIsHide] = useState("");
  const [optionType, setOptionType] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState("");
  const [value, setValue] = useState(0);
  const [pending, setPending] = useState(true);
  const [voidStatus, setVoidStatus] = useState(false);
  const [rows, setRows] = useState([]);
  const [detailsCheck, setDetailsCheck] = useState(false);

  const { userType, charityDetails } = JSON.parse(localStorage.getItem("data"))
    .admin[0];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getDonator(0);
  }, []);

  const getDonator = async (val) => {
    if (val === 0) {
      getAllTransList()
        .then((resp) => {
          setDonatorList(resp);
          setFilterData(resp);
        })
        .catch((err) => {});
    } else if (val === 1) {
      getAllTransListLottery()
        .then((resp) => {
          setDonatorList(resp);
          setFilterData(resp);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
      // reset(formData);
  }, [formData]);

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);

    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };

  const details = JSON.parse(localStorage.getItem("data"));

  const columns1 = [
    {
      name: "Date and Time",
      selector: (row) => row.created_at,
      width: "300px",
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      width: "300px",
    },
    {
      name: "Payment Method",
      selector: (row) => row.mobileType,
    },
    {
      name: "Card Brand",
      selector: (row) => row.last4digit,
    },
    {
      name: "TXN Type",
      selector: (row) =>
        row.status === "FiftyLottery" ? "50/50 Lottery" : row.status,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      //sortable: true,
      center: true,
    },
    {
      name: "Account",
      selector: (row) => row.account,
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      selector: (row) => row._id,
      cell: (row) => (
        <>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-primary"
            onClick={() => viewTransaction(row._id)}
            disabled={donatorList.filter((donator) => donator._id === row._id)[0] != undefined ? false : true}
          >
            Details
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const data1 = donatorList?.map((donator) => {
    const donate = donator?.orderDetails[0]?.response;
    return {
      created_at: donator?.created_at
        ? moment(donator?.created_at).format("LLLL")
        : "",
      orderID: donator?.orderID,
      mobileType: donator?.orderDetails[0]?.mobileType,
      cardHolderName: donator?.orderDetails[0]?.cardHolderName,
      last4digit: donator?.orderDetails[0]?.last4digit.replace(
        /[^a-zA-Z]/g,
        ""
      ),
      amount: `${parseFloat(donator.amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      account: donate != undefined ? JSON.parse(donate)?.account : "",
      recurringNo: donator?.recurringNo,
      status: donator?.status
        ? donator.status === "Refund"
          ? "Refunded"
          : donator.status
        : "Donation",
      _id: donator._id,
    };
  });

  const viewTransaction = (id) => {
    reset({ ActionSelect: '' });
    setDetailsCheck(true)
    setVoidStatus(false)  
    const result = donatorList.filter((donator) => donator._id === id);
    let newData = {
      ActionSelect:
        result[0]?.status !== "undefined"
          ? result[0]?.status !== "Donation"
            ? result[0]?.status
            : ""
          : "",
    };
    setFormData(newData);
    result[0].status = result[0]?.status !== "undefined"
        ? result[0]?.status !== "Donation"
          ? result[0]?.status
          : ""
        : "";

    setOptionType(result[0].status);
    setShowTransactionDetails(result);
    let currentStartDayDate = moment().startOf("day").format("LLLL");
    let currentEndDayDate = moment().endOf("day").format("LLLL");
    let transactionCreateAt = moment(result[0].created_at).format("LLLL");
    let currStartDate = new Date(currentStartDayDate);
    let curEndDate = new Date(currentEndDayDate);
    let transactionCreateDate = new Date(transactionCreateAt);
    if (
      currStartDate <= transactionCreateDate &&
      transactionCreateDate < curEndDate
    ) {
      setVoidStatus(true);
    } else {
      setVoidStatus(false);
    }
    setViewTransactionDetails(!viewTransactionDetails);
    setIsHide(
      typeof result[0].refundedDate != "undefined"
        ? result[0].refundedDate
        : typeof result[0].voidDate != "undefined"
        ? result[0].voidDate
        : ""
    );
  };
  const transactionUpdate = () => {
    let apiType = optionType == "Void" ? "saveTransVoid" : "saveTransRefund";
    const data = {
      orderId: showTransactionDetails[0].orderID,
    };
    axios
      .post(`${APIBase_URL}transaction/${apiType}`, data)
      .then((resp) => {
        if (resp.data.status === false) {
          if (
            resp.data.message.response.message[0] ===
            "You may only refund up to 115% of the original amount."
          ) {
            setErrorMsg("Transaction is already Refunded.");
          } else {
            setErrorMsg(resp.data.message.response.message[0]);
          }
          setTimeout(() => {
            setErrorMsg("");
          }, 4000);
        } else {
          if (optionType === "Refund") {
            setMsg("Transaction Refunded");
            getDonator(value);
          } else {
            setMsg(resp.data.message.message[0]);
          }
          getDonator(value);
          setTimeout(() => {
            setMsg("");
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleTypeChange = (e) => {
    const value = e.target.value;
    setOptionType(value);
  };

  const closePopup = (e) => {
    setShowTransactionDetails([]);
    setOptionType("");
    setViewTransactionDetails(false);
    setIsHide("");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(data1);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  });
  // const setTabVal = (val) =>{

  //     getDonator();
  // };

  // useEffect(() => {
  //   if(detailsCheck){
  //     // console.log("check that", document.getElementById("mySelect").length);
  //   let selectobject = document.getElementById("mySelect");
  //   for (let i=0; i < selectobject.length; i++) {
  //       if (selectobject.options[i].value === ''){
  //         selectobject.remove(i);
  //       }
  //     }
  //     setDetailsCheck(false)
  //   }
  // },[detailsCheck])

  return (
    <>
      <section className="home-section lotteryPage">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Transactions</h2>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <Tabs
                  value={value}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    getDonator(newValue);
                  }}
                >
                  {/* <Tab label="Donations" /> */}
                  {/* <Tab label="Lottery" /> */}

                  {(userType === "CHARITYUSER" &&
                    (donatorList[0]?.charityID[0]?.charityType === "Both" ||
                      donatorList[0]?.charityID[0]?.charityType ===
                        "Donations" ||
                      donatorList[0]?.locationID[0]?.charityType === "Both" ||
                      donatorList[0]?.locationID[0]?.charityType ===
                        "Donations")) ||
                  details.admin[0]?.charityDetails[0]?.charityType === "Both" ||
                  details.admin[0]?.charityDetails[0]?.charityType ===
                    "Donations" ? (
                    <Tab label="Donations" />
                  ) : (
                    ""
                  )}

                  {userType === "ADMIN" || userType === "BNAUSER" ? (
                    <Tab label="Donations" />
                  ) : (
                    ""
                  )}

                  {(userType === "CHARITYUSER" &&
                    (donatorList[0]?.charityID[0]?.charityType === "Both" ||
                      donatorList[0]?.charityID[0]?.charityType === "Lottery" ||
                      donatorList[0]?.locationID[0]?.charityType === "Both" ||
                      donatorList[0]?.locationID[0]?.charityType ===
                        "Lottery")) ||
                  details.admin[0]?.charityDetails[0]?.charityType === "Both" ||
                  details.admin[0]?.charityDetails[0]?.charityType ===
                    "Lottery" ? (
                    <Tab label="Lottery" />
                  ) : (
                    ""
                  )}

                  {userType === "ADMIN" || userType === "BNAUSER" ? (
                    <Tab label="Lottery" />
                  ) : (
                    ""
                  )}

                  {/* <Tab label="Disabled TAB!" disabled />
                  <Tab label="Active Tab Three" /> */}
                </Tabs>
              </div>
              <div
                className="col-xl-6 col-lg-6 col-sm-12 col-md-6 ui-lefttop"
                style={{ "margin-top": "5px" }}
              >
                <form style={{ float: "right" }}>
                  <div className="tophead customdate2">
                    <div>
                      <label className="from_to_width_2">
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label className="from_to_width_2">
                        <b>To</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <div className="mt-1">
                <DataTable
                  id="aba"
                  columns={columns1}
                  data={rows}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                  progressPending={pending}
                  progressComponent={<TableLoader />}
                />
              </div>
            </div>
            {viewTransactionDetails && showTransactionDetails ? (
              <div className="ModalBackground">
                <div className="modalContainer modelLocationContainer modalcontainercstm">
                  <div className="modalHeader mb-5">
                    <h3>Transaction Details</h3>
                  </div>
                  <button className="closeBtn" onClick={closePopup}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                  <h6 style={{ color: "red" }}>{errorMsg}</h6>
                  <h6 style={{ color: "green" }}>{msg}</h6>
                  <div className="container">
                    {iSHide === "" ? (
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <form>
                              <select
                                id="transaction-id"
                                className="form-control"
                                {...register("ActionSelect", {
                                  required: "Option is required",
                                })}
                                onChange={handleTypeChange}
                              >
                                <option value="">Action</option>
                                {voidStatus === true ? (
                                  <option
                                    className="dropdown-item"
                                    value="Void"
                                    selected={optionType === "Void"}
                                  >
                                    Void
                                  </option>
                                ) : (
                                  <option
                                    className="dropdown-item"
                                    value="Refund"
                                    selected={optionType === "Refund"}
                                  >
                                    Refund
                                  </option>
                                )}
                              </select>
                              {errors.ActionSelect && (
                                <small className="text-danger">
                                  {errors.ActionSelect.message}
                                </small>
                              )}
                            </form>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              className="btn btn-primary"
                              style={{ margin: "0px" }}
                              onClick={handleSubmit(transactionUpdate)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="table-responsive location-custom-height">
                    <table className="table table-striped mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>Date and Time</th>
                          <th>Order ID</th>
                          <th>Payment Method</th>
                          {/* <th>Card Brand</th> */}
                          {/* <th>Cardholder Name</th> */}
                          <th>
                            {showTransactionDetails.map((trnaction) =>
                              trnaction.orderDetails[0]?.mobileType ===
                              "Card Pay"
                                ? "Cardholder Name"
                                : "Name"
                            )}
                          </th>
                          <th>Card Number</th>
                          <th>Amount</th>
                          <th>Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showTransactionDetails.length
                          ? showTransactionDetails.map((trnaction) => (
                              <tr id={trnaction._id} key={trnaction._id}>
                                <td>
                                  {trnaction.created_at
                                    ? moment(trnaction.created_at).format(
                                        "LLLL"
                                      )
                                    : ""}
                                </td>
                                <td>{trnaction.orderID}</td>
                                <td>{trnaction.orderDetails[0]?.mobileType}</td>
                                {/* <td>
                                  {trnaction.orderDetails[0]?.last4digit.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  )}
                                </td> */}
                                <td>
                                  {!!trnaction?.orderDetails[0]
                                    ?.cardHolderName &&
                                  trnaction?.orderDetails[0]?.cardHolderName
                                    ? trnaction?.orderDetails[0]?.cardHolderName
                                    : (trnaction?.firstName !== undefined
                                        ? trnaction?.firstName
                                        : "") +
                                      " " +
                                      (trnaction?.lastName !== undefined
                                        ? trnaction?.lastName
                                        : "")}
                                </td>
                                {/* <td>{`${trnaction.orderDetails[0].cardHolderName!==undefined?trnaction.orderDetails[0].cardHolderName:trnaction.firstName!==undefined?trnaction.firstName:''} ${trnaction.orderDetails[0].cardHolderName!==undefined?trnaction.orderDetails[0].cardHolderName:trnaction.lastName!==undefined?trnaction.lastName:''}`}</td> */}
                                <td>{trnaction.orderDetails[0]?.last4digit}</td>
                                <td>{`${parseFloat(
                                  trnaction.amount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}</td>
                                <td>
                                  {
                                    JSON.parse(
                                      trnaction.orderDetails[0].response
                                    ).account[0]
                                  }
                                </td>
                              </tr>
                            ))
                          : `No Location found`}
                      </tbody>
                    </table>
                  </div>
                  {showTransactionDetails[0].refundedDate ? (
                    <div className="table-responsive location-custom-height">
                      <table className="table table-striped mt-3">
                        <thead className="thead-light">
                          <tr>
                            <th>Refunded Date</th>
                            <th>Order ID</th>
                            <th>TXN Type</th>
                            <th>Amount</th>
                            <th>Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showTransactionDetails.length
                            ? showTransactionDetails.map((trnaction) => (
                                <tr id={trnaction._id} key={trnaction._id}>
                                  <td>
                                    {trnaction.refundedDate
                                      ? moment(trnaction.refundedDate).format(
                                          "LLLL"
                                        )
                                      : ""}
                                  </td>
                                  <td>{`${trnaction.orderID}`}</td>
                                  <td>{`Rebate`}</td>
                                  <td>{`${parseFloat(
                                    trnaction.amount
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}</td>
                                  <td>
                                    {
                                      JSON.parse(
                                        trnaction.orderDetails[0].response
                                      ).account[0]
                                    }
                                  </td>
                                </tr>
                              ))
                            : `No Location found`}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                  {showTransactionDetails[0].voidDate ? (
                    <div className="table-responsive location-custom-height">
                      <table className="table table-striped mt-3">
                        <thead className="thead-light">
                          <tr>
                            <th>Void Date</th>
                            <th>Order ID</th>
                            <th>TXN Type</th>
                            {/* <th>Cardholder Name</th>
                          <th>Card Number</th> */}
                            <th>Amount</th>
                            <th>Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showTransactionDetails.length
                            ? showTransactionDetails.map((trnaction) => (
                                <tr id={trnaction._id} key={trnaction._id}>
                                  <td>
                                    {trnaction.voidDate
                                      ? moment(trnaction.voidDate).format(
                                          "LLLL"
                                        )
                                      : ""}
                                  </td>
                                  <td>{trnaction.orderID}</td>
                                  <td>{`Void`}</td>
                                  <td>{`${parseFloat(
                                    trnaction.amount
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}</td>
                                  <td>
                                    {
                                      JSON.parse(
                                        trnaction.orderDetails[0].response
                                      ).account[0]
                                    }
                                  </td>
                                </tr>
                              ))
                            : `No Location found`}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="modalFooter text-right"
                    style={{ marginTop: "18px", padding: "0" }}
                  >
                    <button
                      className="btn btn-primary"
                      style={{ float: "right", marginTop: "10px" }}
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
    </>
  );
};

export default TransactionDetail;
