import { performApplePayPayment, isApplePayJsAvailable } from './ApplePayJsHandler';
import { performApplePayPayment as performApplePayPaymentPaymentRequest, isPaymentRequestAvailable } from './PaymentRequestHandler';
import { paymentRequestApi } from './PaymentConf';
// import logger from '../../logs/logger';

export { PaymentStatus } from './PaymentStatus';

// console.log(`Using ${paymentRequestApi ? "Payment Request": "Apple Pay JS"} API!`)

export const isApplePayAvailable = () =>  {
    try{
    return paymentRequestApi ? isPaymentRequestAvailable() : isApplePayJsAvailable()
    } catch (err) {
        // logger("error", `isApplePayAvailable: ${err}`);			
        console.log(err)
    }
}

// export const performPayment = () => {
//     try{
//     return paymentRequestApi ? performApplePayPaymentPaymentRequest() : performApplePayPayment(),
//         alert("alert 3")
// }catch (err) {
//     // logger("error", `performPayment: ${err}`);			
//     console.log(err)
//   }
// }

export const performPayment = (amount,charityName,clogo,dataObj, donationID,qrCodeID, locationID,taxinfo) => {
    // console.log(charityName,"recived charityName")
    return paymentRequestApi ?
        performApplePayPaymentPaymentRequest( amount, charityName,clogo,dataObj) : 
        performApplePayPayment( amount, charityName,clogo,dataObj,donationID,qrCodeID, locationID,taxinfo)
}