import { React, useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getRetailerData } from "../API/RetailerAPI/RetailerAPI";
import { getLocationData } from "../API/RetailerAPI/LocationAPI";
import { addQRCodeData } from "../API/QRCodeAPI/QRCodeAPI";

const QRCode = () => {
  // const [setModal, setModalState] = useState(false);
  const [charityList, setCharityList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [locationList, setLocationList] = useState("");
  const [cname, setCname] = useState("");
  const [charityName, setCharity] = useState("");
  const [retailer, setRetailer] = useState([]);
  const [location, setLocation] = useState(null);
  const [qr, setQr] = useState([]);
  const [showQr, setShowQr] = useState([]);
  const [saveID, setID] = useState("");
  const [uID, setUnique] = useState("");
  const [qrID, setUniqueQR] = useState("");

  const [charityID, setCharityID] = useState([]);
  const [retailerID, setRetailerID] = useState([]);
  const [locationID, setLocationID] = useState([]);
  const [showMsg, setmsg] = useState("");
  const [disableLocation, setDisableLocation] = useState("true");
  const [disableQR, setDisableQR] = useState("true");
  const [taxInfo, setTaxInfo] = useState({});
  const [getValue, setGetValue] = useState([]);
  // const [chkDisable, setChkDisable] = useState({});
  const [isCheckAllQR, setIsCheckAllQR] = useState(false);
  const [isCheckDonations, setIsCheckDonations] = useState([]);
  const [isCheckLottery, setIsCheckLottery] = useState([]);
  const [isCheckAllLottery, setIsCheckAllLottery] = useState(false);
  const [isCheckAllDonations, setIsCheckAllDonations] = useState(false);
  const [isCheckQR, setIsCheckQR] = useState([]);
  const [isCheckQRRadio, setIsCheckQRRadio] = useState([]);
  const [eventlocationID, setEventLocationID] = useState([]);
  const [retailerLotteryLocationData, setRetailerLotteryLocationData] = useState(null);
  const [retailerDonationLocationData, setRetailerDonationLocationData] = useState(null);
  const [charityType,setCharityType] =useState(null);
  const [createTaxReceipt,setCreateTaxReceipt] =useState(null);
  const[eventDonationLocationID,setEventDonationLocationID]=useState([]);
  const[eventLotteryLocationID,setEventLotteryLocationID]=useState([]);
  const[qrLocations,setQrLocations] = useState([]);

  useEffect(() => {
    getCharity();
    getRetailer();
    getLocation();
  }, []);

  const getCharity = async () => {
    getCharityData().then((resp) => {
      let arr = [];
      let filter = resp.map((data, i) => {
        if (data.isActive === false) {
          arr.push(data);
        }
      });

      setCharityList(arr);
    });
  };

  const getRetailer = async () => {
    getRetailerData().then((resp) => {
      let arr = [];
      const res1 = resp.retailer;
      let filter = res1.map((data, i) => {
        if (data.isActive === false) {
          arr.push(data);
        }
      });
      setRetailerList(arr);
    });
  };

  const getLocation = async () => {
    getLocationData().then((resp) => {
      let arr = [];

      let filter = resp.map((data, i) => {
        if (data.isActive === false) {
          arr.push(data);
        }
      });
      setLocationList(arr);

      // console.log(resp);
    });
  };
 
  useEffect(() => {}, [charityName]);

  const setCharityData = (name, id, charityType, createTaxReceipt) => {
    setUnique("");
    setUniqueQR("");
    setCharity(name);

    setID(id);
    setCharityType(charityType)
    setCreateTaxReceipt(createTaxReceipt);
    setRetailer([]);
    setQrLocations([]);
    setQr([]);
    setIsCheckAllLottery(false);
    setIsCheckAllDonations(false);
    setIsCheckDonations([]);
    setIsCheckLottery([]);
    setShowQr([]);
    setIsCheckQR([]);
     setIsCheckQRRadio([]);
    setEventLocationID([]);
    setIsCheckAllQR(false);
    setDisableQR(true);
    setRetailerDonationLocationData(null);
    setRetailerLotteryLocationData(null);
    setEventDonationLocationID([]);
    setEventLotteryLocationID([]);

    // if(saveID !== id){ 
    //   setRetailer([]);
    // }

    //
    var clist = document.getElementsByClassName("retailer-mapping");
    for (var i = 0; i < clist.length; ++i) {
      clist[i].checked = "";
    }

    document.getElementById("mapRetailerBtn").disabled = true;
  };

  const user = (id, name, charityType, createTaxReceipt) => {
    setCharityID(id);
    setCname(name);
    setCharityType(charityType);
    setCreateTaxReceipt(createTaxReceipt);
  };

  const mapRetailer = (event, loc) => {
    const { name, id } = event.target;

    var clist = document.getElementsByClassName("retailer-mapping");
    let btnStatus = false;
    for (var i = 0; i < clist.length; ++i) {
      if (clist[i].checked === true) {
        btnStatus = true;
      }
    }

    if (btnStatus === true) {
      document.getElementById("mapRetailerBtn").disabled = false;
    } else {
      document.getElementById("mapRetailerBtn").disabled = true;
    }

    if (event.target.checked) {
      setRetailer((prev) => [
        ...prev,
        { ["RetailerName"]: name, ["retailerid"]: id, ["location"]: loc },
      ]);

      document.getElementById("mapRetailerBtn").disabled = false;
    } else {
      let x = retailer.findIndex(function (o) {
        return o.retailerid === id;
      });
      retailer.splice(x, 1);
    }
  };

  const mapRetailer1 = () => { 
    if (!retailer.length) return false;
    //console.log(retailer,'ffffdfdf')
    setQrLocations([]);
    setQr([]);
    setIsCheckAllLottery(false);
    setIsCheckAllDonations(false);
    setIsCheckDonations([]);
    setIsCheckLottery([]);
    setShowQr([]);
    setIsCheckQR([]);
     setIsCheckQRRadio([]);
    setEventLocationID([]);
    setIsCheckAllQR(false);
    setDisableQR(true);
    setRetailerDonationLocationData(null);
    setRetailerLotteryLocationData(null);
    setEventDonationLocationID([]);
    setEventLotteryLocationID([]);

   // setRetailerDonationLocations([]);
    setLocation(null);
    retailer.map((retailer,id) => {
      retailer.location.map((val) => { 
        // if(typeof(val.type)=== undefined || val.type=== "" || val.type === null){
          setEventLocationID((p) => [...p, val._id]);
        // }else{
        //   setEventLocationID((p) => [...p, val._id+val.Type]);
        // }
        
      });
    });
    setLocation([...retailer]);
    let retailerData=[...retailer]
    let donationLocations =[];
    let lotteryLocations =[];
   
    retailerData.length && retailerData.map((retailerVal) => {

   
      //if user want to overwrite chariy on the basis of location
       //filter data of type both when location type value exit
        let locDataTypeBoth=retailerVal.location.filter((v1)=>(v1.charityType === "Both" ))
        if(locDataTypeBoth.length>0){
          locDataTypeBoth.map((bothVal) =>{
            donationLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName }, bothVal));
            lotteryLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName }, bothVal));
          })
        }
        //filter data of type donations when location type value exit
        let donationLocDataDonations=retailerVal.location.filter((v1)=>(v1.charityType === "Donations" ))
        if(donationLocDataDonations.length>0){
          donationLocDataDonations.map((donationVal) =>{
            donationLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName }, donationVal))
          })
        }
        //filter data of type lottery
        let lotteryLoc = retailerVal.location.filter((v1)=>(v1.charityType === "Lottery"))
        if(lotteryLoc.length>0){
          lotteryLoc.map((lotteryVal) =>{
            lotteryLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName },lotteryVal))
          })
        }
        let LocDataForDefaultCharity=retailerVal.location.filter((v1)=>(v1.charityType === null ))
        if(LocDataForDefaultCharity.length>0){
          if(charityType==="Both"){
            LocDataForDefaultCharity.map((locDefaultVal) =>{
              donationLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName },locDefaultVal))
              lotteryLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName },locDefaultVal))
          })
          }else  if(charityType==="Lottery"){
            LocDataForDefaultCharity.map((locDefaultVal) =>{
              lotteryLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName },locDefaultVal))
            })
          }else  if(charityType==="Donations"){
            LocDataForDefaultCharity.map((locDefaultVal) =>{
            donationLocations.push(Object.assign({ retailerid:retailerVal.retailerid,RetailerName:retailerVal.RetailerName }, locDefaultVal))
            })
          }
        }
        
    });
    if(donationLocations.length>0){
      setRetailerDonationLocationData([...donationLocations]);
      donationLocations.map((val) => {
        setEventDonationLocationID((p) => 
        [...p, val._id+'Donations']);
      });
    } else{
      setRetailerDonationLocationData(null);
      setEventDonationLocationID([]);
    } 
     if(lotteryLocations.length>0){
      setRetailerLotteryLocationData([...lotteryLocations]);
      lotteryLocations.map((val) => {
        setEventLotteryLocationID((p) => [...p, val._id+'Lottery']);
      });
     }
      else{
        setRetailerLotteryLocationData(null);
        setEventLotteryLocationID([]);
      }
      
      setDisableLocation(!true);
    setUnique(saveID);
  };
  
  
  const handleSelectAllDonations = (e) => {
    setIsCheckAllDonations(!isCheckAllDonations);
    if (isCheckAllDonations) {
      setIsCheckDonations([]);
     // setQr([]);
    } else {
      setIsCheckDonations([...eventDonationLocationID]);
        retailerDonationLocationData.map((val) => {
          let taxVal= typeof(val.createTaxReceipt)=== undefined || val.createTaxReceipt === null || val.createTaxReceipt === '' ? val.createTaxReceipt: createTaxReceipt
          setQr((pre) => [
             ...pre,
            {
              ["name"]: val.RetailerName,
              ["location"]: `${val.address}, ${val.city}, ${val.province}, ${val.country}, ${val.postal_code}`,
              ["retailerid"]: val.retailerid,
              ["locationId"]: val._id,
              ["type"]: 'Donations',
              ["taxReceipt"]: (taxVal==='' || taxVal===0 || taxVal==='No' ||taxVal===null || taxVal=== undefined )? 'No': 'Yes',
            },
          ]);
        });
    }
  };
  const handleSelectAllLottery = (e) => {
    setIsCheckAllLottery(!isCheckAllLottery);
    if (isCheckAllLottery) {
      setIsCheckLottery([]);
      //setQr([]);
    } else {
      setIsCheckLottery([...eventLotteryLocationID]);
        retailerLotteryLocationData.map((val) => {
          setQr((pre) => [
            ...pre,
            {
              ["name"]: val.RetailerName,
              ["location"]: `${val.address}, ${val.city}, ${val.province}, ${val.country}, ${val.postal_code}`,
              ["retailerid"]: val.retailerid,
              ["locationId"]: val._id,
              ["type"]: 'Lottery',
              ["taxReceipt"]:'No',
            },
          ]);
        });
    }
  };
  const saveRetailerDonations = (event) => {
    const { name, value, checked, id } = event.target;
    const locationId = event.target.getAttribute("data-lid");
    const retailerId = event.target.getAttribute("data-id");
    const createTaxReceiptVal = event.target.getAttribute("data-createTaxReceipt");
    setIsCheckDonations([...isCheckDonations, id+'Donations']);
    if (!checked) {
      setIsCheckDonations(isCheckDonations.filter((item) => item !== id+'Donations'));
    }
    let length = retailerDonationLocationData.length;
   
    if (event.target.checked) {
      setQr((pre) => [
        ...pre,
        {
          ["name"]: name,
          ["location"]: value,
          ["retailerid"]: retailerId,
          ["locationId"]: locationId,
          ["type"]: 'Donations',
          ["taxReceipt"]: createTaxReceiptVal === 0 || createTaxReceiptVal === null || createTaxReceiptVal ==='' || createTaxReceiptVal === undefined || createTaxReceiptVal === "No" ? "No": "Yes",
        },
      ]);
       
      if (length !== isCheckDonations.length + 1) {
        setIsCheckAllDonations(false);
      } else if (length === isCheckDonations.length + 1) {
        setIsCheckAllDonations(true);
      }
    } else {
      if (length === isCheckDonations.length -1) {
        setIsCheckAllDonations(true);
      } else{
        setIsCheckAllDonations(false);
      }
      let x = qr.findIndex(function (o) {
        return o.retailerid === retailerId;
      });
      qr.splice(x, 1);
    }
  };
  const saveRetailerLottery = (event) => {
    const { name, value, checked, id } = event.target;
    const locationId = event.target.getAttribute("data-dlid");
    const retailerId = event.target.getAttribute("data-did");
    const createTaxReceiptVal = event.target.getAttribute("data-dcreateTaxReceipt");
    setIsCheckLottery([...isCheckLottery, id+'Lottery']);
    if (!checked) {
      setIsCheckLottery(isCheckLottery.filter((item) => item !== id+'Lottery'));
    }
    let length = retailerLotteryLocationData.length;
    // retailer.map((retailer) => {
    //   length = length + retailer.location.length;
    // });
    if (event.target.checked) {
      setQr((pre) => [
        ...pre,
        {
          ["name"]: name,
          ["location"]: value,
          ["retailerid"]: retailerId,
          ["locationId"]: locationId,
          ["type"]: 'Lottery',
          ['taxReceipt']: 'No',
        },
      ]);

      if (length !== isCheckLottery.length + 1) {
        setIsCheckAllLottery(false);
      } else if (length === isCheckLottery.length + 1) {
        setIsCheckAllLottery(true);
      }
    } else {
      if (length !== isCheckLottery.length - 1) {
        setIsCheckAllLottery(false);
      } else if (length === isCheckLottery.length - 1) {
        setIsCheckAllLottery(true);
      }
      let x = qr.findIndex(function (o) {
        return o.retailerid === retailerId;
      });
      qr.splice(x, 1);
    }
  };
  const mapLocation = () => {
    setQrLocations([]);
  
    if (!qr.length) return false;
    const allQR=[...qr];
    allQR.map((retailer, id) => {
      setQrLocations((p)=> ([...p, retailer.locationId+'_'+id+retailer.type]))
    });
    setShowQr([...qr]);
    setDisableQR(!true);
    setUniqueQR(saveID);
  };

  const storeRetailer = (e, id, location,type, taxReceipt) => {
    const { name, value, checked, idData } = e.target;
    setIsCheckQR([...isCheckQR, location+'_'+id+type]);
    if (!checked) {
      setIsCheckQR(isCheckQR.filter((item) => item !== location+'_'+id+type));
    }
    let length = showQr.length;
    
    if (!e.target.checked) {
      // setChkDisable((p) => ({ ...p, [`ab${id}`]: true }));
      let data = [...getValue];
      
      let filterDat = data.filter(
        ({ locationName , charityType }) => locationName !== location && charityType === type
      );

      document.getElementsByName(`ab${id}`).checked = false;
      setGetValue(filterDat);
      if (length !== isCheckQR.length - 1) {
        setIsCheckAllQR(false);
      } else if (length === isCheckQR.length - 1) {
        setIsCheckAllQR(true);
      }
    } else {
      if (length !== isCheckQR.length + 1) {
        setIsCheckAllQR(false);
      } else if (length === isCheckQR.length + 1) {
        setIsCheckAllQR(true);
      }
      // setChkDisable((p) => ({ ...p, [`ab${id}`]: false }));
    }
  };
  // console.log('resetCharity value ///////', resetCharity)
  const storeRetailer1 = (e, id, retailer, location, type, taxReceipt) => {
    // console.log('resetCharity value ///////', resetCharity)
    const a = retailer;
    const c = charityID;
    var b = location;
    const typeCharity = type;

    // const d = taxInfo?.[`ab${id}`] === "N" ? "N" : "Y";
    let vv = taxReceipt ===0 || taxReceipt === null || taxReceipt ==='' || taxReceipt ===undefined || taxReceipt === "No"? "No": "Yes";;

    let data = [...getValue];
    let filterDat = data.findIndex(({ locationName, type }) => locationName === b && type ===typeCharity);

    if (filterDat !== -1) {
      let data1 = replaceAt(data, filterDat, {
        ["charityName"]: c,
        ["retailerName"]: a,
        ["locationName"]: b,
        ["taxReceipt"]: vv,
        ["type"]: type,
      });
    
      setGetValue(data1);
      return;
    }
    setGetValue((p) => [
      ...p,
      {
        ["charityName"]: c,
        ["retailerName"]: a,
        ["locationName"]: b,
        ["taxReceipt"]: vv,
        ["type"]: type,

      },
    ]);
  };

  const storeRetailerSelect = (e, id, retailer, location,type) => {
    const a = retailer;
    const c = charityID;
    var b = location;
    // const taxReceiptVal =taxReceipt;
    // const d = taxInfo?.[`ab${id}`] === "N" ? "N" : "Y";
    let vv = e;

    let data = [...getValue];
    let filterDat = data.findIndex(({ locationName }) => locationName === b);

    if (filterDat !== -1) {
      let data1 = replaceAt(data, filterDat, {
        ["charityName"]: c,
        ["retailerName"]: a,
        ["locationName"]: b,
        ["taxReceipt"]: vv,
        ["type"]: type,
      });

      setGetValue(data1);
      return;
    }
    setGetValue((p) => [
      ...p,
      {
        ["charityName"]: c,
        ["retailerName"]: a,
        ["locationName"]: b,
        ["taxReceipt"]: vv,
        ["type"]: type,
  
      },
    ]);
  };

  function replaceAt(array, index, value) {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
  }

  const generateQR = () => {
    //console.log(getValue,"All selected QR's=======>>>>")
    setmsg(`You have Generated QR code for ${cname}`);
    setTimeout(() => {
      setmsg("");
    }, 4000);
      // console.log("Display Get Values Data /////////////",getValue);
    addQRCodeData(getValue)
      .then((res) => {})
      .catch((err) => {});
  };
  const handleSelectAllQR = (e) => {
    setIsCheckAllQR(!isCheckAllQR);
    if (isCheckAllQR) {
      setIsCheckQR([]);
      setIsCheckQRRadio([])
      showQr.map((retailer, id) => {
        // setChkDisable((p) => ({ ...p, [`ab${id}`]: true }));
        let data = [...getValue];
        
        let filterDat = data.filter(
          ({ locationName , charityType}) => locationName !== retailer.locationId && charityType === retailer.type
        );

        document.getElementsByName(`ab${id}`).checked = false;
        setGetValue([]);
        //storeRetailerSelect("Y",id,retailer.retailerid,retailer.locationId)
      });
    } else {
      setIsCheckQR([]);
     setIsCheckQR([...qrLocations]);

      setIsCheckQRRadio([...qrLocations])
      showQr.map((retailer, id) => {
        // setIsCheckQR((p) => ({ ...p, [`ab${id}`]: false }));
        // setChkDisable((p) => ([ ...p, retailer.locationId+'_'+id+retailer.type]));
        const valTaxReceipt = retailer.taxReceipt ===0 || retailer.taxReceipt === null || retailer.taxReceipt ==='' || retailer.taxReceipt ===undefined ||retailer.taxReceipt === "No"? "No": "Yes";
        storeRetailerSelect(valTaxReceipt,id,retailer.retailerid,retailer.locationId, retailer.type )
      });
    }
  };

  
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="retailerContainer p-3">
              <h2 className="pageTitle mb-4">QR CODE</h2>
              <div className="topWrapper new-display-class d-flex mb-2">
                <h6 style={{ color: "green" }}>{showMsg}</h6>
              </div>
              <div className="table-responsive custom-height">
                <table className="table table-striped mt-3">
                  <thead className="thead-light" style={{ zIndex: "2" }}>
                    <tr>
                      <th>Charity</th>
                      <th>Address</th>
                      <th>Type</th>
                      <th>Map Retailer/Event</th>
                      <th>Retailer/Event Location</th>
                      <th>Create QR Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {charityList &&
                      charityList.map((charity) => (
                        <tr
                          key={charity._id}
                          onChange={(e) => setCharityID(e.target.value)}
                        >
                          <td>{charity.charityName}</td>
                          <td>
                            {charity.address}, {charity.city},{" "}
                            {charity.province}, {charity.country},{" "}
                            {charity.postal_code}
                          </td>
                          <td>{charity?.charityType === "Both" ? "Both(Lottery, Donations)" : charity?.charityType}</td>
                          <td>
                            <button
                              onClick={() =>
                                setCharityData(charity.charityName, charity._id,charity.charityType, charity.createTaxReceipt)
                              }
                              type="button"
                              className="btn btn-primary btn_resp"
                              data-toggle="modal"
                              data-target="#exampleModal1"
                            >
                              Map Retailer/Event
                            </button>
                          </td>

                          <td>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              disabled={charity._id === uID ? false : true}
                            >
                              Map Location
                            </button>
                          </td>

                          <td>
                            <div className="btn-group"></div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal2"
                              disabled={charity._id === qrID ? false : true}
                              onClick={() =>
                                user(charity._id, charity.charityName, charity.charityType, charity.createTaxReceipt)
                              }
                            >
                              Generate QR
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>

      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Map Retailer/Event for {charityName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              className="modal-body"
              style={{ overflowY: "scroll", maxHeight: "25em" }}
            >
              {/* <div >
								<ul style={{ marginBottom: '0px' }} id="treeView" >
									<li style={{ width: '100%' }}>
										<input type="checkbox" />Select All
									</li>
								</ul>
							</div> */}
              {retailerList.length &&
                retailerList.map((retailer) => (
                  <ul id="treeView" key={retailer._id}>
                    <li>
                      <input
                        className="retailer-mapping"
                        onChange={(event) =>
                          mapRetailer(event, retailer.locations)
                        }
                        id={`${retailer._id}`}
                        name={retailer.RetailerName}
                        // value={retailer.locations}
                        type="checkbox"
                      />
                      {retailer.RetailerName}
                    </li>
                  </ul>
                ))}
              {/* <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  id="mapRetailerBtn"
                  className="btn btn-primary"
                  onClick={mapRetailer1}
                >
                  Map Retailer/Event
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div> */}
            </div>
            {/* <div
              className="modal-header"
              style={{ borderTop: "1px solid #dee2e6" }}
            > */}
              <div
                className="modal-footer p-0 pt-2 pb-2 justify-content-start"
                // style={{ borderTop: "0px solid #dee2e6" }}
              >

                <button
                  type="button"
                  data-dismiss="modal"
                  id="mapRetailerBtn"
                  className="btn btn-primary btn_resp"
                  onClick={() => mapRetailer1()}
                //   onClick={() =>
                //     { 
                     
                //     const confirmBox = window.confirm(
                //       "Are you sure you want to overwrite charities details on the basis of location of the selected retailer?"
                //     ) 
                //  // eslint-disable-next-line no-unused-expressions
                //  confirmBox === true? mapRetailer1(true) :  mapRetailer1(false)
                //   }
      
              
                // }
                >
                  Map Retailer/Event
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}

      <div
        className="modal "
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">

              <h5 className="modal-title" id="exampleModalLabel">
                Map Locations of {charityName} for below Retailers/Events
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              className="modal-body"
              style={{ overflowY: "scroll", maxHeight: "20em" }}
            >
              <div className="row">
              {retailerDonationLocationData!==null? 
               <div className ="col-md-6">
                  <div  style={{ marginLeft: "24px" }}>
                      <h6>Location For Donations : </h6>
                      <ul style={{ marginBottom: "0px" }} id="treeView">
                        <li style={{ width: "100%" }}>
                          <input
                            type="checkbox"
                            checked={isCheckAllDonations}
                            onChange={handleSelectAllDonations}
                            isChecked={isCheckAllDonations}
                          />
                          Select All
                        </li>
                      </ul>
                    </div>
                 {!!retailerDonationLocationData && retailerDonationLocationData.map((location) => (
                  <>    
                    <ul id="treeView">
                      <li style={{ display: "flex" }}>
                        {/* {retailer.RetailerName} */}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div>
                          <div>
                            <input
                              id={location._id}
                              onChange={saveRetailerDonations}
                              data-lid={location._id}
                              data-id={location.retailerid}
                              data-createTaxReceipt ={typeof(location.createTaxReceipt)=== undefined || location.createTaxReceipt === null || location.createTaxReceipt===''? createTaxReceipt : location.createTaxReceipt}
                              value={`${location.address}, ${location.city}, ${location.province}, ${location.country}, ${location.postal_code}`}
                              name={location.RetailerName}
                              type="checkbox"
                              checked={isCheckDonations.includes(location._id+'Donations')}
                            />
                            {location.RetailerName}
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            {location.address}, {location.city},{" "}
                            {location.province}, {location.country},{" "}
                            {location.postal_code}
                          </div>    
                        </div>
                      </li>
                    </ul>
                  </>
                   ))
                }
                </div>
                 :''
              } 
          
              {retailerLotteryLocationData!==null ?
                <div className ="col-md-6">
                  <div  style={{ marginLeft: "24px" }}>
                    <h6>Location For Lottery : </h6>
                    <ul style={{ marginBottom: "0px" }} id="treeView">
                      <li style={{ width: "100%" }}>
                        <input
                          type="checkbox"
                          checked={isCheckAllLottery}
                          onChange={handleSelectAllLottery}
                          isChecked={isCheckAllLottery}
                        />
                        Select All
                      </li>
                    </ul>
                  </div> 
                  {!!retailerLotteryLocationData && retailerLotteryLocationData.map((location) => (
                      <>    
                        <ul id="treeView">
                          <li style={{ display: "flex" }}>
                            {/* {retailer.RetailerName} */}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>
                              <div>
                                <input
                                  id={location._id}
                                  onChange={saveRetailerLottery}
                                  data-dlid={location._id}
                                  data-did={location.retailerid}
                                  data-dcreateTaxReceipt= {typeof(location.createTaxReceipt)=== undefined || location.createTaxReceipt === null || location.createTaxReceipt===''? createTaxReceipt : location.createTaxReceipt}
                                  value={`${location.address}, ${location.city}, ${location.province}, ${location.country}, ${location.postal_code}`}
                                  name={location.RetailerName}
                                  type="checkbox"
                                  checked={isCheckLottery.includes(location._id+'Lottery')}
                                />
                                {location.RetailerName}
                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                {location.address}, {location.city},{" "}
                                {location.province}, {location.country},{" "}
                                {location.postal_code}
                              </div>    
                            </div>
                          </li>
                        </ul>
                      </>
                  ))
                }
                 </div>
                 :''}
              
                 </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-primary"
                onClick={mapLocation}
              >
                Map Location
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Generate QR for {charityName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              className="modal-body"
              style={{ overflowY: "scroll", maxHeight: "20em" }}
            >
              {/* <div style={{ marginLeft: "91%", color: "black" }}>
                Tax Receipt
              </div> */}
              <div>
                <ul style={{ marginBottom: "0px" }} id="treeView">
                  <li style={{ width: "100%" }}>
                    <input
                      type="checkbox"
                      checked={isCheckAllQR}
                      onChange={handleSelectAllQR}
                      isChecked={isCheckAllQR}
                    />
                    Select All
                  </li>
                </ul>
              </div>
              {/* <div>
								<ul style={{ marginBottom: '0px' }} id="treeView" >
									<li style={{ width: '100%' }}>
										<input type="checkbox" />Select All</li>
								</ul>
							</div> */}
              {/* {console.log(showQr,"Display show Qr length ////////////////// ========>>>>>")} */}
              {showQr.length
                ? showQr.map((retailer, id) => (
                   
                    <ul id="treeView" key={id}>
                      {/* { console.log(retailer,  "Retailer Display Data//////////")} */}
                      <li style={{ display: "flex" }}>
                        {/* {retailer.name} &nbsp;&nbsp;&nbsp;&nbsp; */}
                        <input
                          onClick={(e) =>
                           [storeRetailer(e, id, retailer.locationId, retailer.type,retailer.taxReceipt),storeRetailer1(e,id,retailer.retailerid,retailer.locationId,retailer.type,retailer.taxReceipt)]
                          }
                          // id={retailer.retailerid}
                          // data-id={retailer.locationId}
                          type="checkbox"
                         
                          checked={isCheckQR.includes(retailer.locationId+'_'+id+retailer.type)}
                        />
                        {retailer.name}&nbsp;&nbsp;-&nbsp;&nbsp;
                        {/* {retailer.locationId} */}
                        {retailer.location}
                        &nbsp;Type: {retailer.type}
                      </li>
                      {/* <div style={{ marginLeft: "91%" }}>
                        <li style={{ marginTop: "-31px" }}>
                          <form>
                            <input
                              type="radio"
                              //checked={isCheckQRRadio.includes(retailer.locationId)}
                              id="html"
                              name={`fav_language${id}`}
                              value="Y"
                              disabled={
                                chkDisable[`ab${id}`] === false ? false : true
                              }
                              onClick={(e) =>
                                storeRetailer1(
                                  e,
                                  id,
                                  retailer.retailerid,
                                  retailer.locationId,
                                  retailer.type
                                )
                              }
                              
                            />
                            &nbsp;
                           // onChange={(e)=>setTaxInfo((p)=>({...p, [`ab${id}`] : e.target.value}))}/>&nbsp; 
                            <label for="html">Yes</label>&nbsp;&nbsp;
                            <input
                              type="radio"
                              //checked={isCheckQRRadio.includes(retailer.locationId)}
                              id="css"
                              name={`fav_language${id}`}
                              value="N"
                              disabled={
                                chkDisable[`ab${id}`] === false ? false : true
                              }
                              onClick={(e) =>
                                storeRetailer1(
                                  e,
                                  id,
                                  retailer.retailerid,
                                  retailer.locationId
                                )
                              }
                              
                            />
                            &nbsp;
                            <label for="css">No</label>
                          </form>
                        </li>
                      </div> */}
                    </ul>
                  ))
                : null}
            </div>
            <div className="modal-footer">
              {/* {console.log(getValue.length,getValue,"Print Get Values Data -------/////////")} */}
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={!getValue.length}
                onClick={generateQR}
              >
                Generate QR
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCode;
