import React, { Component } from "react";
import PropTypes from "prop-types";
import { isApplePayAvailable } from "./PaymentApi";
// import "./ApplePay.css";
import "../style.css";

const ApplePayButtonStatus = { UNKNOWN: 0, AVAILABLE: 1, NOT_AVAILABLE: 2 };

class ApplePayButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applePayButtonStatus: ApplePayButtonStatus.AVAILABLE,
    };
  }
  componentDidMount() {
    // Runs after the first render() lifecycle
    this.props.onClick()
  }
  UNSAFE_componentWillMount() { 
    isApplePayAvailable()
      .then((canMakePayments) => {
        // console.log(canMakePayments,"can make Payment data ////")
        this.setState({
          applePayButtonStatus: canMakePayments
            ? ApplePayButtonStatus.AVAILABLE
            : ApplePayButtonStatus.NOT_AVAILABLE,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          applePayButtonStatus: ApplePayButtonStatus.NOT_AVAILABLE,
        });
      });
      
  }
  
  render() {
    // console.log("data---->11111",this.props);
    let getButton = () => {
      switch (this.state.applePayButtonStatus) {
        case ApplePayButtonStatus.UNKNOWN:
          return (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 inner px-0">
                  {" "}
                  Apple Pay Not Supported...{" "}
                </div>
              </div>
            </div>
          );
        case ApplePayButtonStatus.AVAILABLE:
          return (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 inner px-0">
                  {this.props.language ? (
                    <button
                      lang={`${this.props.language}`}
                      className="apple-pay-button apple-pay-button-white"
                      // style={{
                      //   "-webkit-appearance": "-apple-pay-button",
                      //   "-apple-pay-button-type": "buy",
                      // }}
                      id="apple-pay"
                      onClick={this.props.onClick}
                    ></button>
                  ) : (
                    <button
                      lang={`${this.props.language}`}
                      className="apple-pay-button apple-pay-button-white"
                      id="apple-pay"
                      onClick={this.props.onClick}
                    ></button>
                  )}
                </div>
              </div>
            </div>
          );
        case ApplePayButtonStatus.NOT_AVAILABLE:
          return (
            <div className="text-muted text-center" id="apple-pay-activation">
              Apple Pay inactive on your device.
            </div>
          );
        default:
          return <div> Invalid status!!! </div>;
      }
    };
    return (
      <div className="row" id="apple-pay-row">
        {getButton()}
      </div>
    );
  }
}

ApplePayButton.propTypes = {
  merchantIdentifier: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ApplePayButton;
