import React, { useState, useEffect } from 'react';


const InvalidQr = (props) => {


const [dataInvalid,setDataInvalid]=useState(localStorage.getItem("dataInvalid"))

useEffect (() =>{
}, []);



  return (
    <>
       
     <div className="card-custom" style={{ color : '#000000', fontWeight: 600, backgroundColor: "#03989e", backgroundImage: "#03989e"}}>
        <div className="inner-card-invalidQr" style={{ color :'#000000',  backgroundColor:"#03989e", backgroundImage:"#03989e"}}>
          <div className="LoginForm custom-for-all" style={{ color :'#000000',  backgroundColor:"#03989e", backgroundImage: "#03989e"}}>
           
          <div className="logo donationLogo_Box">
              <span >
              <h1 ><b style={{ width: "100%", textAlign: "center", color:"#921e1e"}}>Invalid QR Code</b></h1>
              </span>
            </div>
            <div
              className="custom-card custom-for-all"
              style={{ marginTop: "10px" }}
            >
              <div
                className="mt-3 customHeadingTop"
                style={{ width: "100%", textAlign: "center" }}
              >
                
                <h2>
                {dataInvalid==='Lottery'?'Lottery Campaign is no longer active':'Charity Campaign is no longer active'}
                
                </h2>
              </div>

              
            </div>
           
          </div>
         
        </div>
        </div>
    </>
  );
};

export default InvalidQr;
