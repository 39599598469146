import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const  TableLoader = () =>{
  return (
    <div className="text-center">
      <ClipLoader  color="#03989e" size={20} />
    <span className="tableloaderText">Loading is in Progress...</span >
    </div>
  );
};

export default TableLoader;