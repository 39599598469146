import {
  merchantIdentifier,
  APPLE_PAY_VERSION_NUMBER,
  // validationurl
} from "./PaymentConf";

import axios from 'axios';
import { Redirect } from "react-router-dom";
// let history = useHistory();
// import history from "./History";
// import { createBrowserHistory } from 'history';
// import { history } from './History';
// import sendPaymentToken from "./SendData"
import { createBrowserHistory } from 'history';
import { APIBase_URL } from "../../config";
const history = createBrowserHistory();

const existsApplePayJsApi = () => {
  
  return new Promise((resolve, reject) => {
    try {
      const enabled =
        window.ApplePaySession && window.ApplePaySession.canMakePayments();
      resolve(enabled);
    } catch (err) {
      reject(err);
			// logger("error", `existsApplePayJsApi: ${err}`);			
    }
  });
};

export const isApplePayJsAvailable = () => {
	try{
  return existsApplePayJsApi()
	.then(() => {
    return window.ApplePaySession.canMakePaymentsWithActiveCard(
      merchantIdentifier
    );
  });
}catch (err) {
		// logger("error", `isApplePayJsAvailable: ${err}`);
		console.log(err)			
}
};


const getPaymentRequest = (amount,charityName,clogo,dataObj) => {
  return {
    currencyCode: 'CAD',
    countryCode: "CA",  
    total: {
      label: "",
      amount: amount,
    },
  
    supportedNetworks: ["masterCard", "visa", "amex"],
    merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
  };
};

const getOnValidateMerchant =  ( session,amount,charityName,clogo,dataObj) => {
  axios.get(`${APIBase_URL}getCertificate`)
  .then(response => {
    session.completeMerchantValidation(response.data.body)
  }).catch((err)=>{
  })
}


const getOnPaymentAuthorized = (session,amount, charityName,clogo,dataObj,donationID,qrCodeID,locationID,taxinfo) => {
  return (event) => {
  const promise = sendPaymentToken(event.payment.token,amount, charityName,clogo,dataObj,donationID,qrCodeID,locationID,taxinfo);
    promise.then((success) =>{  
      var status;
      if (success){       
        status = window.ApplePaySession.STATUS_SUCCESS;
      } else {
        status = window.ApplePaySession.STATUS_FAILURE;
      }
      session.completePayment(status); 
    }).catch((err)=>{
      console.log(err,"error")
    })
  }
}

  const sendPaymentToken =(paymentToken,amount, charityName,clogo,dataObj,donationID,qrCodeID,locationID,taxinfo) => { 
  try{
    return  new Promise (function(resolve, reject) {
      
      //console.log(paymentToken,"paymentToken");
      const value={
        charityName : charityName,
        amount:amount,
        paymentToken:paymentToken,
        donationID:donationID,
        qrCodeID:qrCodeID,  
        locationID:locationID,
        firstName: taxinfo.firstName,
        lastName: taxinfo.lastName,
        address: taxinfo.address,
        city: taxinfo.city,
        province: taxinfo.province,
        postalCode: taxinfo.postalCode,
        email: taxinfo.email,
        ...( taxinfo.type  != "undefined" && {type:  taxinfo.type }),
        ...(taxinfo.lottery != "undefined" && {lottery: taxinfo.lottery}),
      }
      // console.log(value,"valueeeee")
      axios.post(`${APIBase_URL}getDebit`,value)
      .then((resp)=>{
        let result1 = JSON.stringify(resp.data.result)
        let orderID = JSON.stringify(resp.data.orderid)
        let result = result1.toString();
        var arrayWithEventIds = result.replace(/["]+/g, '')
        let arrnew = arrayWithEventIds.replace(/\[|\]/g,'')
        // console.log(arrnew,"arrnew")

        let orderID1 = orderID.toString();
        var arrayWithEventIds1 = orderID1.replace(/["]+/g, '')
        let newOrderID = arrayWithEventIds1.replace(/\[|\]/g,'')

        // console.log(dataObj,"dataObj")
        // console.log(clogo,"clogo")
        if(arrnew==="00"){
          // alert("abc")
          // console.log("=========arrnew", arrnew, history)
         
          localStorage.setItem("am", amount)
          localStorage.setItem("clogo",clogo)
          localStorage.setItem("dataObj",dataObj)
          localStorage.setItem("orderID",newOrderID)
          localStorage.setItem("qrCodeID",qrCodeID)
          localStorage.setItem("paymentRequest", '')
          history.push({ pathname:'/#/thankyou' }) 
          window.location.reload();      
           
        }else{
          localStorage.setItem("clogo",clogo)
          localStorage.setItem("dataObj",dataObj)
          history.push({pathname:'/#/failed'})
          window.location.reload();
        }
      }).catch((err)=>{
        console.log(err,"err")
      })
      // document.write("Thank You for Donating " + "$"+amount + " for " + charityName + "<br><br>");
      // document.write(JSON.stringify(paymentToken ))
      // window.history.push({ pathname:'/thankyou' } )
      // detailes:JSON.stringify( data),logo:clogo
      const debug = true
      if ( debug === true )
      resolve(true);
      else
      reject();
    });
  } catch (err){
    console.log(err,"error")
  }
} 

export const performApplePayPayment = async (amount, charityName,clogo,dataObj,donationID,qrCodeID,locationID,taxinfo) => {
  return new Promise((resolve) => {        
    const session = new window.ApplePaySession(APPLE_PAY_VERSION_NUMBER, getPaymentRequest(amount,charityName,clogo,dataObj));
    session.onvalidatemerchant = getOnValidateMerchant(session,amount, charityName,clogo,dataObj);
    session.onpaymentauthorized =  getOnPaymentAuthorized(session,amount, charityName,clogo,dataObj,donationID,qrCodeID,locationID,taxinfo);


      // session.oncancel = getOnCancel(resolve, reject, session)
      session.begin();
      // return session;
      return resolve(true);
  })
}
// **********************