import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
// import dateFormat from require('dateformat');
import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getAllLotteryData } from "../API/TaxInfoAPI/TaxInfoAPI";
import Footer from "../Footer/Footer";
import PrintComponent from "../DonationDetail/DonationDetailsPrint";
import jwt_decode from "jwt-decode";

const LotteryDetail = ({ side }) => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); //moment().toDate()
  const [endDate, setEndtDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [disableDates, setDisableDates] = useState(new Date());
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = JSON.parse(decodedData?.charityId);
  const [charityName, setCharityName] = useState([]);
  const [viewLotteryDetails, setViewLotteryDetails] = useState(false);
  const [showLotteryDetails, setShowLotteryDetails] = useState([]);

  useEffect(() => {
    getDonator();
    getCharity();
  }, []);

  const getDonator = async () => {
    getAllLotteryData()
      .then((resp) => {
        setDonatorList(resp);
        setFilterData(resp);
      })
      .catch((err) => {
      });
  };
  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let charityName = []
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
          //if(data._id === charityId){
          if(charityId.includes(data._id)){
            charityName.push(data.charityName)
          }
        });

        setCharityList(arr);
        setCharityName(charityName);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };

  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setDonatorList([]);
    if (selectedCharity === "All Charities") return setDonatorList(filterData);
    let filter = filterData.map((data, i) => {
      return data.charityID.map((d) => {
        if (d.charityName === selectedCharity) {
          setDonatorList((p) => [...p, data]);
        } else {
          <div>"no Record Found"</div>;
        }
      });
    });
  };
  // const handleTypeChange = (e) => {
  //   const value = e.target.value;
  //   setOptionType(value);
  // };

  const closePopup = (e) => {
    setShowLotteryDetails([]);
    // setOptionType("");
    setViewLotteryDetails(false);
  };

  // console.log(donatorList, "filterData");

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);
    
    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };

  const columns = [
    {
      id: "name",
      displayName: "Donor Name",
    },
    {
      id: "address",
      displayName: "Address",
    },
    {
      id: "city",
      displayName: "City",
    },
    {
      id: "province",
      displayName: "Province",
    },
    {
      id: "postalCode",
      displayName: "Postal Code",
    },
    // {
    //   id: "country",
    //   displayName: "Country",
    // },
    {
      id: "orderID",
      displayName: "OrderID",
    },
    {
      id: "last4digit",
      displayName: "Card Type",
    },
    {
      id: "amount",
      displayName: "Amount",
    },
    {
      id: "date",
      displayName: "Date",
    },
    {
      id: "ticketNo",
      displayName: "ticketNo",
    },
    {
      id: "emailAddress",
      displayName: "Email Address",
    },
    // {
    //   id: "action",
    //  // displa
    // }
  ];

  // console.log(donatorList, "donatorList Data", typeof donatorList);

  const datas = donatorList.map((a) =>
    a.firstName
      ? {
          name: `${a.firstName} ${a.lastName}`,
          address: a.address.replace(/,/g, " "),
          city: a?.city.replace(/,/g, " "),
          province: a.province.replace(/,/g, " "),
          postalCode: a.postalCode.replace(/,/g, " "),
          // country: `${a.address}${a.province}${a.postalCode}`,
          last4digit: a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a.orderID?.slice(0, 6),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a.created_at.slice(0, 10),
          ticketNo:'"'+ String(`${a.orderDetails[0]?.lottery?.[0]?.ticketNo}`)+'"',
          emailAddress: a.email,
        }
      : {
          name: "",
          address: "",
          city: "",
          province: "",
          postalCode: "",
          // country: "",
          last4digit: a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a.orderID?.slice(0, 6),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a.created_at.slice(0, 10),
          ticketNo:'"'+ String((a.orderDetails[0]?.lottery?.[0]?.ticketNo))+'"',
          emailAddress: "",
        }
  );
  //  let created_at = moment(val).format("MM-DD-YYYY")
  // console.log(datas, "datasdatasdatas");

  const handlePrint = () => {
    
    window.print();

    // aba

    // $("#myDiv").printElement();

    // var divToPrint=document.getElementsByClassName("home-content").innerHTML;
    // let newWin= window.open("");
    // newWin.document.write(divToPrint);
    // newWin.print();
    // newWin.close();
  };

  const columns1 = [
    {
      name: "Donor Name",
      selector: (row) => row.donorName,
      // sortable: true,
      width: "190px",
    },
    {
      name: "Address",
      selector: (row) => row.address,
      // sortable: true,
      // width: "104px"
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "Tax Receipt",
    //   selector: (row) => row.taxReceipt,
    //   width: "150px",
    //   center:true
    // },
    {
      name: "Card Type",
      selector: (row) => row.last4digit,
      width: "140px",
      center:true
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "140px",
      right: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      width: "150px",
      // textAlign: "right"
    },
    {
      name: "TicketNo",
      selector: (row) => String(row.ticketNo),
      sortable: true,
      width: "150px",
      // textAlign: "right"
    },
    {
      name: "Email Address",
      selector: (row) => row.emailAddress,
      sortable: true,
      width: "300px",
    },
    {
      name: "Actions",
      selector: (row) => row._id,
      cell: (row) => (
        <>
        <button
          type="button"
          data-dismiss="modal"
          className="btn btn-primary"
          onClick={() => viewLottery(row._id)}
        >
          Details
        </button>
         
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const data1 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator?.address}, ${donator?.city}, ${donator?.province}, ${donator?.postalCode}`
      : "",
    orderID: donator.orderID.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `$${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    date: moment(donator.created_at.slice(0, 10)).format("MM-DD-YYYY"),
    ticketNo:String((donator.orderDetails[0]?.lottery?.[0]?.ticketNo)),
    emailAddress: donator.email,
    taxReceipt: donator.qrCodeID[0].taxInfo,
    _id:donator._id,
    type:donator.orderDetails[0]?.lottery?.[0]?.lotteryType,
  }));

  const viewLottery = (id) => {
    // console.log(id, 'Id')
    const result = donatorList.filter((donator) => donator._id === id);
    // console.log(result,'Result Data')
    setViewLotteryDetails(true)
    setShowLotteryDetails(result?.[0].orderDetails?.[0]?.lottery)

  };
  const data5 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator?.address}, ${donator?.city}, ${donator?.province}, ${donator?.postalCode}`
      : "",
    orderID: donator.orderID.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    date: moment(donator.created_at.slice(0, 10)).format("MM-DD-YYYY"),
    ticketNo:String((donator.orderDetails[0]?.lottery?.[0]?.ticketNo)),
    emailAddress: donator.email,
    taxReceipt: donator.qrCodeID[0].taxInfo,
  }));


  // const handleSort = () =>{
  //   // const sortedActivities = activities.slice().sort((a, b) => b.date - a.date)
  //   const check = donatorList.slice().sort((a, b) => b.created_at - a.created_at)
  //   console.log(check,"-----")
  // }
  // console.log(showLotteryDetails,"LotteryDetails")
    return (
    <>
    
      <section className="home-section lotteryPage">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Lottery Details {typeof(charityId) !=='undefined' && charityId.length===1 ? `for ${charityName}`:''}</h2>

            <div className="row">
              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-lefttop">
                <div className="tophead">
                {typeof(charityId) ==='undefined' || charityId.length===0|| charityId.length>1?
                  <div className="charityMain">
                    <h5>
                      <b>CHARITY</b>
                    </h5>
                    <div>
                      <select onChange={handleChange} className="selectBox">
                        <option className="dropdown-item">All Charities</option>
                        {typeof(charityId) ==='undefined' || charityId.length===0?
                        charityList
                          ? charityList.map((data) => (
                              <option
                                className="dropdown-item"
                                 value={data.charityName}
                              >
                                {data.charityName}
                              </option>
                            ))
                          : null
                        :  charityName
                          ? charityName.map((data) => (
                            
                              <option
                                className="dropdown-item"
                                 value={data}
                              >
                                {data}
                              </option>
                            ))
                          : null}
                      </select>
                      
                    </div>
                  </div>
                  :''}
                  <div className="donationAction d-flex">
                  <div>
                    <CsvDownloader
                      filename="Lottery Details"
                      extension=".csv"
                      separator=","
                      // wrapColumnChar="'"
                      columns={columns}
                      datas={datas}
                      text="DOWNLOAD"
                    >
                      <button className="btn btn-primary maxWidnone text-nowrap">Download .CSV</button>
                    </CsvDownloader>
                  </div>

                  <div>
                    {/* <button
                      className="btn btn-primary custm-print"
                      onClick={handlePrint}
                    >
                      Print
                    </button> */}
                   
                    <PrintComponent data={data5} pdfTitle = {'2'}/>
                  </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-righttop">
                <form>
                  <div className="customdate">
                    {/* <h5>
                    <b>REPORTING PERIOD : </b>&nbsp;
                 </h5> */}

                    <div>
                      <label>
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label>
                        <b>To</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <div>
                <DataTable
                  id="aba"
                  columns={columns1}
                  data={data1}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                />
              </div>
              {viewLotteryDetails && showLotteryDetails ?
                  <div className="ModalBackground">
                  <div className="modalContainer modelLocationContainer">
                    <div className="modalHeader mb-5">
                      <h3>Lottery Details </h3>
                    </div>
                    <button className="closeBtn" onClick={closePopup}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  <div className="table-responsive location-custom-height">
                    <table className="table table-striped mt-3">
                      <thead className="thead-light">
                        <tr>
                          <th>Lottery Description</th>
                          <th>Count</th>
                          <th>Amount</th>
                          <th>Actual Price</th>
                          <th>Lottery Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {console.log(showLotteryDetails,"Lottery Details popup to display values===============//////")} */}
                        {showLotteryDetails.length
                          ? showLotteryDetails.map((details) => (
                              <tr id={details._id} key={details._id}>
                                <td>{details.lotteryName}</td>
                                <td>{details.count}</td>
                                <td>{`${parseFloat(
                                  details.amount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}</td>
                                <td>
                                {`${parseFloat(
                                  details.actualPrice
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                                </td>
                                <td>
                                {details.lotteryType}
                                </td>
                              </tr>
                            )) :'No Lottery Data Found'
                              }
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="modalFooter text-right"
                    style={{ marginTop: "18px", padding: "0" }}
                  >
                    <button
                      className="btn btn-primary"
                      style={{ float: "right", marginTop: "10px" }}
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
                  :null
                  }
                  
            </div>
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
      {/* </div> */}
    </>
  );
};

export default LotteryDetail;
