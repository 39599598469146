import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const  Loader = () =>{
  return (
    <div className="text-center loaderTop">
      <ClipLoader  color="#03989e" size={30} />
    <span className="loaderText">Loading is in Progress...</span >
    </div>
  );
};

export default Loader;