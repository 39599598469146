import axios from "axios";
import { APIBase_URL } from "../../../config";

export const getQRCodeData = async () => {
  const result1 = await fetch(`${APIBase_URL}qrcode`);
  const result = await result1.json();
  return result;
};
export const getLotterQRCodeData = async () => {
  const result1 = await fetch(`${APIBase_URL}qrcode/getLotterCampaign`);
  const result = await result1.json();
  return result;
};
export const addQRCodeData = async (data) => {
  const result1 = await axios.post(`${APIBase_URL}qrcode`, data);
  const result = result1.json();
  // console.log(result);
  return result;
};


// export const getQRCodeStatusData = async (data, id) => {
//   const result1 = await fetch(`${APIBase_URL}qrcode/status/${id}`);
//   const result = result1.json();
//   return result;
// };

export const deleteQRCodeData = async (data, id) => {
  const result1 = await fetch(`${APIBase_URL}qrcode/delete/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await 
  result1.json();
  // console.log(result);
  return result;
};
