import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getTaxInfoMonthlyData } from "../API/TaxInfoAPI/TaxInfoAPI";
import Footer from "../Footer/Footer";
import PrintMonthlyComponent from "./DonationMonthlyDetailsPrint";
import jwt_decode from "jwt-decode";

const DonationDetail = ({ side }) => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndtDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [disableDates, setDisableDates] = useState(new Date());
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = JSON.parse(decodedData?.charityId);
  const [charityName, setCharityName] = useState([]);

  useEffect(() => {
    getDonator();
    getCharity();
  }, []);

  const getDonator = async () => {
    getTaxInfoMonthlyData()
      .then((resp) => {
        setDonatorList(resp);
        setFilterData(resp);
      })
      .catch((err) => {
      });
  };
  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let charityName = []
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
          // if(data._id === charityId){
          //   charityName = data.charityName
          // }
          
          if(charityId.includes(data._id)){
            charityName.push(data.charityName)
          }
        })
        
        setCharityList(arr);
        setCharityName(charityName);
      })
      .catch((err) => {
      });
  };

  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setDonatorList([]);
    if (selectedCharity === "All Charities") return setDonatorList(filterData);
    let filter = filterData.map((data, i) => {
      return data.charityID.map((d) => {
        if (d.charityName === selectedCharity) {
          setDonatorList((p) => [...p, data]);
        } else {
          <div>"no Record Found"</div>;
        }
      });
    });
  };

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);
    
    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.startDate;
      let created_at = text?.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };

  const columns = [
    {
      id: "name",
      displayName: "Donor Name",
    },
    {
      id: "address",
      displayName: "Address",
    },
    {
      id: "city",
      displayName: "City",
    },
    {
      id: "province",
      displayName: "Province",
    },
    {
      id: "postalCode",
      displayName: "Postal Code",
    },
    {
      id: "emailAddress",
      displayName: "Email",
    },
    {
      id: "orderID",
      displayName: "OrderID",
    },
    {
      id: "amount",
      displayName: "Amount",
    },
    {
      id: "startDate",
      displayName: "First Payment",
    },
    {
      id: "recurringNo",
      displayName: "No. of Payments",
    },
    {
      id: "nexttDate",
      displayName: "Final Payment",
    },
    
  ];

  const datas = donatorList.map((a) =>
    a.firstName
      ? {
          name: `${a.firstName} ${a.lastName}`,
          address: a.address.replace(/,/g, " "),
          city: a?.city.replace(/,/g, " "),
          province: a?.province.replace(/,/g, " "),
          postalCode: a?.postalCode.replace(/,/g, " "),
          last4digit: a?.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a?.orderID?.slice(0, 6),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a?.created_at?.slice(0, 10),
          startDate: moment(a?.startDate?.slice(0, 10)).format("MM-DD-YYYY"),
          nexttDate: a?.recurringNo!==1?moment(a.startDate).add(a.recurringNo - 1, 'M').format("MM-DD-YYYY"):'',
          recurringNo: a?.recurringNo,
          emailAddress: a?.email,
        }
      : {
          name: "",
          address: "",
          city: "",
          province: "",
          postalCode: "",
          last4digit: a?.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
          orderID: a?.orderID?.slice(0, 6),
          amount: `${
            a.amount.includes(".") ? `$${a.amount}` : `$${a.amount}.00`
          }`,
          date: a?.created_at?.slice(0, 10),
          startDate: moment(a?.startDate?.slice(0, 10)).format("MM-DD-YYYY"),
          nexttDate: a?.recurringNo!==1?moment(a?.startDate).add(a?.recurringNo - 1, 'M').format("MM-DD-YYYY"):'',
          recurringNo: a?.recurringNo,
          emailAddress: "",
        }
  );

  const handlePrint = () => {
    
    window.print();

  };

  const columns1 = [
    {
      name: "Donor Name",
      selector: (row) => row.donorName,
      width: "190px",
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Email Address",
      selector: (row) => row.emailAddress,
      sortable: true,
      width: "300px",
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      width: "140px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "140px",
    },
    {
      name: "First Payment",
      selector: (row) => row.startDate,
      //sortable: true,
      center:true
    },
    {
      name: "No. of Payments",
      selector: (row) => row.recurringNo,
      sortable: true,
      center:true
    },
    {
      name: "Final Payment",
      selector: (row) => row.nexttDate,
      //sortable: true,
      center:true
      //width: "140px",
    },
    
    
  ];

  const data1 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator && donator.address?donator.address:''}, ${donator && donator.city? donator.city:''}, ${donator && donator.province? donator.province: ''}, ${donator && donator.postalCode? donator.postalCode: ''}`
      : "",
    orderID: donator.orderID?.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `$${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    startDate: moment(donator.startDate?.slice(0, 10)).format("MM-DD-YYYY"),
    nexttDate: donator.recurringNo!==1?moment(donator.startDate).add(donator.recurringNo- 1, 'M').format("MM-DD-YYYY"):'',
    recurringNo: donator.recurringNo,
    emailAddress: donator.email,
    taxReceipt: donator.qrCodeID[0].taxInfo,
  }));

  const data5 = donatorList.map((donator) => ({
    donorName:
      donator.firstName && donator.lastName
        ? `${donator?.firstName} ${donator?.lastName}`
        : "",
    address: donator.address
      ? `${donator && donator.address?donator.address:''}, ${donator && donator.city? donator.city:''}, ${donator && donator.province? donator.province: ''}, ${donator && donator.postalCode? donator.postalCode: ''}`
      : "",
    orderID: donator.orderID?.slice(0, 6),
    last4digit: donator.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
    amount: `${parseFloat(donator.amount).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`,
    startDate: moment(donator.startDate?.slice(0, 10)).format("MM-DD-YYYY"),
    nexttDate: donator.recurringNo!==1?moment(donator.startDate).add(donator.recurringNo- 1, 'M').format("MM-DD-YYYY"):'',
    recurringNo: donator.recurringNo,
    emailAddress: donator.email,
  }));

  return (
    <>
      <section className="home-section">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Monthly Recurring Donations {typeof(charityId) !=='undefined' && charityId.length===1 ? `for ${charityName}`:''}</h2>

            <div className="row">
              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-lefttop">
                <div className="tophead">
                {typeof(charityId) ==='undefined' || charityId.length===0 || charityId.length>1?
                  <div className="charityMain">
                    <h5>
                      <b>CHARITY</b>
                    </h5>
                    <div>
                      <select onChange={handleChange} className="selectBox">
                        <option className="dropdown-item">All Charities</option>
                         {typeof(charityId) ==='undefined'|| charityId.length===0?
                        charityList
                          ? charityList.map((data) => (
                              <option
                                className="dropdown-item"
                                 value={data.charityName}
                              >
                                {data.charityName}
                              </option>
                            ))
                          : null
                        :  charityName
                          ? charityName.map((data) => (
                            
                              <option
                                className="dropdown-item"
                                 value={data}
                              >
                                {data}
                              </option>
                            ))
                          : null}
                      </select>
                      
                    </div>
                  </div>
                  :''}
                  <div className="donationAction d-flex">

                  <div>
                    <CsvDownloader
                      filename="Recurring Donations"
                      extension=".csv"
                      separator=","
                      // wrapColumnChar="'"
                      columns={columns}
                      datas={datas}
                      text="DOWNLOAD"
                    >
                      <button className="btn btn-primary maxWidnone text-nowrap">Download .CSV</button>
                    </CsvDownloader>
                  </div>

                  <div>
                   
                    <PrintMonthlyComponent data={data1}/>
                  </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 ui-righttop">
                <form>
                  <div className="customdate">
                    
                    <div>
                      <label>
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label>
                        <b>To</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <div>
                <DataTable
                  id="aba"
                  columns={columns1}
                  data={data1}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                />
              </div>
              
            </div>
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
      {/* </div> */}
    </>
  );
};

export default DonationDetail;
