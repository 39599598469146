import { React, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import VerifyEmail from "./components/ForgotPassword/verifyEmail";
import Dashboard from "./components/Dashboard/Dashboard";
import Retailer from "./components/Retailer/Retailer";
import Charity from "./components/Charity/Charity";
import Demo1 from "./components/donation";
import Demo2 from "./components/Lottery";
// import AddCharity from './Charity/addCharity';
import User from "./components/User/User";
import Qrcode from "./components/Qrcode/Qrcode";
import CharityCampaigns from "./components/CharityCampaigns/CharityCampaigns";
import LotteryCampaigns from "./components/CharityCampaigns/LotteryCampaigns";
import DonationDetail from "./components/DonationDetail/DonationDetail";
import LotteryDetail from "./components/Lottery/LotteryDetail";
import DonationSummary from "./components/DonationSummary/DonationSummary";
import DonationMonthlyDetail from "./components/DonationDetail/DonationMonthlyDetail";
import TransactionDetail from "./components/DonationDetail/TransactionDetails";
import RecurringTransactionDetail from "./components/DonationDetail/recurringTransationDetails";
import Lottery from "./components/Lottery/Lottery";
import Profile from "./components/Profile/Profile";
import ChangePassword from "./components/ChangePassword/ChangePassword";
// import Demo from './demo';
import ThankYou from "./components/ThankYou/ThankYou";
import Failed from "./components/Failed/Failed";
import NoReports from "./components/NoReports/NoReports"
import InvalidQr from "./components/Failed/InvalidQr"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import PathError from "./PathError";
import history from "./components/ApplePay/History";
import { Redirect } from "react-router-dom";
import {PrivateRoute, AdminRoute} from "./privateRoute";
import { useStateValue } from "./stateProvider";
import { APIBase_URL } from "./config";
import { useHistory } from "react-router";
import {useIdleSession} from  "./hooks/useIdleSession"


function App() {
  const [{ userProfile }, dispatch] = useStateValue();
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const getUserID = decodedData?.adminid;
  let history = useHistory();
  
  useEffect(() => {
   getUserByID();
  }, []);
  
  const getUserByID = async () => {
    if(getUserID){
      const res = await fetch(`${APIBase_URL}admin/${getUserID}`);
      res.json().then((res) => {
        dispatch({
          type: "ADD_TO_USER",
          data: res,
        });
      });
    }
  };

  const handleLogout = () => {
    // console.log("logout");
  };

  const IDLE_CONFIG = {
    threshold: 50000,
    timeout: 80000, 
    action: handleLogout 
  };

  const { open, idleTime, handleUserActivity } = useIdleSession(IDLE_CONFIG);

    return (
      <>
         {idleTime/1000 > 630 ? (localStorage.clear() & history.push('/') & window.location.reload()): ""}
        <HashRouter>
          <Switch>
            <Route path="/" exact component={Login} />,
            <Route path="/forgotpassword/" component={ForgotPassword} />,
            <Route path="/verifyemail/" component={VerifyEmail} />,
            <PrivateRoute path="/dashboard" component={Dashboard} />
              <AdminRoute path="/retailer" component={Retailer} />,
              <AdminRoute path="/charity" component={Charity} />,
              <Route path="/donation/" component={Demo1} />,
              <Route path="/lottery/" component={Demo2} />,
              <AdminRoute path="/user" component={User} />,
              <AdminRoute path="/qrcode" component={Qrcode} />,
              <AdminRoute path="/charityCampaigns" component={CharityCampaigns} />,
              <AdminRoute path="/lotterCampaigns" component={LotteryCampaigns} />,
              <PrivateRoute path="/transactionDetails">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <TransactionDetail />
                  </div>
                </>
              </PrivateRoute>,
              <PrivateRoute path="/recurringTransactionDetails">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <RecurringTransactionDetail />
                  </div>
                </>
              </PrivateRoute>,
              <AdminRoute path="/lotteryList" component={Lottery} />,
              <PrivateRoute path="/donationDetail">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <DonationDetail />
                  </div>
                </>
              </PrivateRoute>,
              <PrivateRoute path="/lotteryDetail">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <LotteryDetail />
                  </div>
                </>
              </PrivateRoute>,

              <PrivateRoute path="/donationMonthlyDetail">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <DonationMonthlyDetail />
                  </div>
                </>
              </PrivateRoute>,

              <PrivateRoute path="/donationSummary">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <DonationSummary />
                  </div>
                </>
              </PrivateRoute>,

              <PrivateRoute path="/reports">
                <>
                  <Header />
                  <div className="Dashboard d-flex">
                    <Sidebar />
                    <NoReports />
                  </div>
                </>
              </PrivateRoute>,

              <PrivateRoute path="/profile" component={Profile} />,

              <PrivateRoute path="/changePassword" component={ChangePassword} />,
              
              <Route path="/failed" component={Failed} />,
              <Route path="/invalidQr" component={InvalidQr} />,
              <Route
              key={window.location.pathname}
              path="/thankyou"
              component={ThankYou}
            />
  
            <Route path="/*" component={PathError} />
          </Switch>
        </HashRouter>
      </>
    );
  
}

export default App;
