import axios from 'axios';
import { APIBase_URL } from "../../../config";

// this.fetch(`${API_URL}getCharity`, (response) => {

export const getAddData = async() => {
   const result1 = await fetch(`${APIBase_URL}lottery`)
   const result = result1.json()
//    console.log(result)
    return result

}
export const addLotterData = async(data) => {
    // console.log(data,"----")
   const result = await axios.post(`${APIBase_URL}lottery`,data)
//    console.log(result)
    return result

}
export const updateLotterData = async(formData,updateID) => {

   const result1 = await axios.patch(`${APIBase_URL}lottery/${updateID}`,formData)
   return result1

}