import React, { useState, useEffect } from "react";
import "../style.css";
import FooterWeb from "../Footer/FooterWeb";
import logo2 from "../../logo/BNAedit.png";

const ThankYou = () => {
  const logo = localStorage.getItem("clogo"); 
  const lan = localStorage.getItem("languageData")
  const taxMes = localStorage.getItem("taxReceiptMes")

  // const taxMesLottery = localStorage.getItem("taxReceiptMesLottery")
  const lotteryMes =localStorage.getItem("lotterySucessfulMsg")
  const languageData = JSON.parse(lan)
  const [donationSuccessfullLan, setdonationSuccessfullLan] = useState(languageData.donationSuccessfull)
  const [lotterySuccessfullLan, setlotterySuccessfullLan] = useState(languageData.lotterySuccessful)
  const [thankYouLan, setthankYouLan] = useState(languageData.thankYou)
  const [poweredByLan, setpoweredByLan] = useState(languageData.poweredBy);
  const [taxReceiptMesDonation,setTaxReceiptMesDonation ] = useState(languageData.taxReceiptMes);
  const [taxReceiptMesLotteryTicket,setTaxReceiptMesLotteryTicket]=useState(languageData.taxReceiptMesLottery);
  const [colorVal,setColorval]=useState(localStorage.getItem("color"))
  const [fontColorVal,setFontColorVal]=useState(localStorage.getItem("fontColor"))
  //const [btnFontColor, setBtnFontColor] = useState(localStorage.getItem("fontColor"))
  if(colorVal == undefined || colorVal == null || colorVal == ''){
    setColorval('#03989e')
  }
  if(fontColorVal === undefined || fontColorVal === null || fontColorVal === ''){
    setFontColorVal('#000000')
   // setBtnFontColor('#FFFFFF')
  }
  //const [texReceiptMes, settexReceiptMesLan] = useState(languageData.type==='English'?'Your Tax Receipt will be sent':'Votre reçu fiscal sera envoyé');


  return (
    <>
      {/* <div className="card-custom"style={{ color : fontColorVal?fontColorVal:'#000000', fontWeight: 600, backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
        <div className="inner-card" style={{ color :fontColorVal?fontColorVal:'#000000',  backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
          <div className="LoginForm custom-for-all" style={{ color :fontColorVal?fontColorVal:'#000000',  backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}> */}
      <div className="card-custom"style={{ color : '#000000', fontWeight: 600, backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
        <div className="inner-card" style={{ color :'#000000',  backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
          <div className="LoginForm custom-for-all" style={{ color :'#000000',  backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
            {/* <div className="logoMain"> */}
            <div className="logo donationLogo_Box">
              <span className="logo-circle charity_logo">
                <img src={`/uploads/${logo}`} alt="logo" />
              </span>
            </div>
            {/* </div> */}
            <div
              className="custom-card custom-for-all"
              style={{ marginTop: "10px" }}
            >
              <div
                className="mt-3 customHeadingTop"
                style={{ width: "100%", textAlign: "center" }}
              >
                <h2>
                  <b>{`${lotteryMes ==='Lottery'? lotterySuccessfullLan : donationSuccessfullLan}`}</b>
                  {/* <b>{`${lotterySuccessfullLan}`}</b> */}
                </h2>
              </div>

              <div className="headingTop my-5">
                <div className="SuccessIcon" sstyle={{  backgroundColor: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
                  <svg
                    id="thumbs-up"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M0,0H24V24H0Z"
                      fill="none"
                      opacity="0.87"
                    />
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M13.12,2.06,7.58,7.6A2,2,0,0,0,7,9.01V19a2.006,2.006,0,0,0,2,2h9a2.014,2.014,0,0,0,1.84-1.21l3.26-7.61A3,3,0,0,0,20.34,8H14.69l.95-4.58a1.508,1.508,0,0,0-.41-1.37,1.488,1.488,0,0,0-2.11.01ZM3,21a2.006,2.006,0,0,0,2-2V11a2,2,0,0,0-4,0v8A2.006,2.006,0,0,0,3,21Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className="thank-you-text">
                  <h2>{`${thankYouLan}`}</h2>
                  <h6>{`${(taxMes==='1' && lotteryMes ==='Donations')?taxReceiptMesDonation:(taxMes==='2' && lotteryMes ==='Lottery')?taxReceiptMesLotteryTicket:''}`}</h6>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <footer
          className="text-center text-lg-start text-dark customFooter_Box" 
        >
          <div
            className="text-center"
            style={{
              color: "#000000"
            }}
          >
           Powered by&nbsp;
            <img src={logo2} style={{width:'30px'}} alt="" />
          </div>
        </footer>
      </div>
    </>
  );
};
export default ThankYou;
