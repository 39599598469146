import { React, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FooterWeb from "../Footer/FooterWeb";
import logo from "../../logo/BNALOGOv2.png";
import { useParams, NavLink, useLocation, useHistory  } from "react-router-dom";
import { axios } from "axios";
import CryptoJS from "crypto-js";
import { APIBase_URL } from "../../config";
import { useFormik } from "formik";

const VerifyEmail = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [msg, setMsg] = useState("");
  const [err, setErr] = useState("");
  const [status, setStatus] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const [isExpired, setIsExpired] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const donationId = useLocation();
  const data = donationId.pathname;


  const str = data;

  const data2 = str.substring(
    str.lastIndexOf("verifyemail/") + 12,
    str.length
  );

  // console.log(data2, "forgot error");

  let parts = data.split("/");

  const token = parts.pop() || parts.pop();

  let removeToken = "/" + token;
  const data1 = data2.replace(removeToken, "");

  const bytes = CryptoJS.AES.decrypt(data1, "secret key 123");
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  // console.log(originalText, "kkkkk22");
  const history = useHistory();

  useEffect(() => {
    getEmail();
  }, []);

  const getEmail = async () => {

    if (!originalText) {
      setIsExpired(true);
      return;
    }
    const res = await fetch(`${APIBase_URL}admin`);
    res.json().then((res) => {
      res.map((val) => {
        if (val.email == originalText) {
          // console.log("hellllo");
          let minutesToAdd = 120;
          let currentDate = new Date();

          let tokenTime = new Date(val.lastPasswordReset);
          let futureDate = new Date(tokenTime.getTime() + minutesToAdd * 60000);

          if (currentDate > futureDate) {
            // console.log(currentDate,"URL Expired1",futureDate, val.lastPasswordReset);
            setIsExpired(true);
            return;
          }

          if (val.passwordResetToken != token) {
            // console.log(val.passwordResetToken,"URL Expired2", token);
            setIsExpired(true);
            return;
          }
          // console.log("set true");
          setShowForm(true);
          return setEmailValue(val.email);
        }
      });
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.psw) {
      errors.psw = "Conform password is required";
    }

    if (values.psw != values.password) {
      errors.psw = "Password Not Matched";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 characters or greater";
    } else if (
      !values.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      errors.password =
        "Password must have number a lowercase a uppercase letter and special characters";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      psw: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      updatePassword(values);
      resetForm();
    },
  });

  const updatePassword = async (values) => {
    let newPswrd = values.password;
    let confirmPswrd = values.psw;

    //let filter =
    // emailValue.length && emailValue.filter((data) => data === originalText);
    // console.log(filter, "filter");
    if (originalText && newPswrd && confirmPswrd) {
      const data = {
        password: newPswrd,
        ConfirmPassword: confirmPswrd,
      };
      // console.log(data, "dataaa");
      await fetch(`${APIBase_URL}admin/${originalText}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          // console.log(result, "result");
          reset();
          setStatus(`Password has been Changed`);
          
          setTimeout(() => {
            setStatus("");
            history.push("/");
          }, 2000);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      if (!originalText) {
        // alert("Email Not Found");
      } else {
        setMsg(`Password Not Matched`);
        setTimeout(() => {
          setMsg("");
        }, 2000);
      }
    }
  };

  return (
    <>
      {isExpired === true ? (
        <div className="card-custom">
          <div className="inner-card"></div>
          <h1> Link has Expired </h1>
        </div>
      ) : (
        <div className="card-custom">
          <div className="inner-card">
            {showForm === false ? (
              ""
            ) : (
              <form
                className="LoginForm custom-for-all p-3"
                onSubmit={formik.handleSubmit}
              >
                <h6 style={{ color: "green" }}>{status}</h6>
                <div className="text-center loginlogo">
                  <img src={logo} alt="" />
                </div>

                <h2 className="mt-3 mb-3 text-center">Set Password</h2>

                <div className="Logincontainer">
                  <label>
                    <b>New Password</b>
                  </label>
                  <input
                    style={{ borderRadius: "50px", outline: "none" }}
                    type="password"
                    placeholder="Enter New Password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    required
                  />

                  {formik.touched.password && formik.errors.password ? (
                    <div
                      style={{ bottom: "-22px" }}
                      className="custom-error text-danger"
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
                <small style={{ color: "red" }}> {err} </small>

                <div
                  className={`Logincontainer ${
                    errors.confirmPassword && "invalid"
                  }`}
                >
                  <label for="psw">
                    <b>Confirm Password</b>
                  </label>
                  <input
                    style={{ borderRadius: "50px", outline: "none" }}
                    type="password"
                    placeholder="Confirm Password"
                    name="psw"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.psw}
                  />
                  {formik.touched.psw && formik.errors.psw ? (
                    <div
                      style={{ bottom: "-22px" }}
                      className="custom-error text-danger"
                    >
                      {formik.errors.psw}
                    </div>
                  ) : null}

                  <button
                    style={{
                      borderRadius: "50px",
                      outline: "none",
                      background: "linear-gradient(to left, #32d295, #31acdf)",
                    }}
                    className="themeColor loginSub text-center"
                    type="submit"
                  >
                    Update Password
                  </button>
                </div>
                <div className="text-right Logincontainer pt-0">
                  <NavLink to="/">
                    <i aria-hidden="true"></i>
                    <b>
                      <span className="links_name">Login</span>
                    </b>
                  </NavLink>
                </div>
              </form>
            )}
          </div>
          <FooterWeb />
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
