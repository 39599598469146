import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import CryptoJS from "crypto-js"

const Failed = (props) => {
const [data, setData] = useState({})
var history = useHistory();	

const clogo = localStorage.getItem("clogo")

const logo = clogo
// console.log(logo,"ddddd")
const dataObject = localStorage.getItem("dataObj")
// console.log(dataObject,"ccccccc")


const str = dataObject
// const data1 = str.substring(str.lastIndexOf("donation/") +9,str.length)
// console.log(data1,"dataqqq")
// const bytes = CryptoJS.AES.decrypt(data1,'secret key 123');
// const originalText = bytes.toString(CryptoJS.enc.Utf8);
// console.log(originalText,"originalText111")
// const originalStr = originalText.split(',')
// const donationID = originalStr[0]
// const retailerID = originalStr[1]
// const locationID = originalStr[2]
// console.log(originalStr)
// console.log(donationID, retailerID, locationID,"donationid")

const lan = localStorage.getItem("languageData")
const languageData = JSON.parse(lan)
const [errorMessOne, seterrorMessOne] = useState(languageData.errorMessOne)
const [errorMessTwo, seterrorMessTwo] = useState(languageData.errorMessTwo)
const [errorMessThree, seterrorMessThree] = useState(languageData.errorMessThree)
const [donationAgain, setdonationAgain] = useState(languageData.donationAgain)
const [payAgain, setPayAgain] = useState(languageData.payAgain)
const [lotteryErrMess2, setLotteryErrMess2]=useState(languageData.lotteryErrMess2)
const lotteryMes =localStorage.getItem("lotterySucessfulMsg")
const [colorVal,setColorval]=useState(localStorage.getItem("color"))
const [fontColorVal,setFontColorVal]=useState(localStorage.getItem("fontColor"))
const [btnFontColor, setBtnFontColor] = useState(localStorage.getItem("fontColor"))
if(colorVal == undefined || colorVal == null || colorVal == ''){
  setColorval('#03989e')
}
if(fontColorVal === undefined || fontColorVal === null || fontColorVal === ''){
  setFontColorVal('#000000')
  setBtnFontColor('#FFFFFF')
}
useEffect (() =>{
}, []);

const selctButton = () => {
  
	history.push(`${dataObject}`);
}

  return (
    <>
      <div className="mainWrapper min_heightcstm" style={{ color : fontColorVal?fontColorVal:'#000000',  background: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
        <div className="container-fluid" style={{ color :  fontColorVal?fontColorVal:'#000000',  background: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
          <div className="row" style={{ color :  fontColorVal?fontColorVal:'#000000',  background: colorVal, backgroundImage: colorVal}}>
            <div className="col-md-6 offset-md-3" style={{ color :  fontColorVal?fontColorVal:'#000000',  background: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
              <div className="row" style={{ color :  fontColorVal?fontColorVal:'#000000',  background:   colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
                <div className="col-md-6 offset-md-3" style={{ color :  fontColorVal?fontColorVal:'#000000',  background: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e"}}>
                  {/* <div class="logoMain"> */}
                  <div className="logo">
                    <span className="logo-circle charity_logo">
                      <img src={`/uploads/${logo}`} alt="logo" />
                    </span>
                  </div>
                  
                  <div className="custom-card" style={{ marginTop: "60px",width: "100%"}}>
                    <div className="headingTop my-5">
											<div className="mt-3" style={{textAlign: "center",color:'red'}}>
												<h4 >{`${errorMessOne}`}</h4>
												<h4 >{`${lotteryMes ==='Lottery'? lotteryErrMess2 : errorMessTwo}`}</h4>
												<h4 >{`${errorMessThree}`}</h4>
                        <p></p>
                      </div>
                      <div className="button-main-cus">
												<button className="btn btn-primary" onClick={selctButton} style={{ color : btnFontColor?btnFontColor:'#FFFFFF',  fontWeight: 600,background: colorVal?colorVal:"#03989e", backgroundImage: colorVal?colorVal:"#03989e", borderColor:colorVal?colorVal:"#03989e"}}>
													<b>{`${lotteryMes ==='Lottery'? payAgain : donationAgain}`}</b>
												</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Failed;
