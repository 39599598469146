import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Footer from "../Footer/Footer";
// import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getTaxInfoData } from "../API/TaxInfoAPI/TaxInfoAPI";
import PrintComponent from "./DonationSummeryPrint";
import jwt_decode from "jwt-decode";

const DonationSummary = () => {
  const [donatorList, setDonatorList] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [filterData, setFilterData] = useState([]);
  const [totalDonation, setTotalDonation] = useState("");
  const currentUserData = localStorage ? localStorage.getItem("token") : "";
  var decodedData = currentUserData ? jwt_decode(currentUserData) : "";
  const charityId = JSON.parse(decodedData?.charityId);
  const [charityName, setCharityName] = useState([]);
  const [locationDataDisplay,setLocationDataDisplay]=useState([]);
  const [showPrintPopup,setShowPrintPopup] = useState(false);
  const [printRetailer,setPrintRetailer] = useState(false);
  const [printRetailerAmount,setPrintRetailerAmount] =useState(false);
  const [endDate, setEndtDate] = useState(new Date());
  const [disableDates, setDisableDates] = useState(new Date());

  const  locationDataDisplayforDonorCSV =[]
  const locDataPrint = donatorList.map((a) => {
    return a.retailerID.map((ab) => {
      return a.locationID.map((abcc) => {
        locationDataDisplayforDonorCSV.push({
            _id: abcc._id,
            orderDetails:{
              orderID:a.orderDetails[0]?.orderID?.slice(0, 6),
              email: a.email,
              donorName: typeof(a?.firstName)==="undefined"?"":  typeof(a?.lastName)==="undefined"? `${a.firstName}`:`${a.firstName} ${a.lastName}`,
              address: a.address
              ? `${a?.address}, ${a?.city}, ${a?.province}, ${a?.postalCode}`
              : "",
              donorAddress:a.address,
              date:moment(a.created_at.slice(0, 10)).format("MM-DD-YYYY"),
              cardType:a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
              donorCity : a.city,
              donorProvince : a.province,
              donorPostalCode : a.postalCode,
              amount: a.orderDetails[0]?.amount,
            }
        });
      });
    });
  }); 
   
  useEffect(() => {
    getDonator();
    getCharity();
   
  }, []);

 
  const getDonator = async () => {
    getTaxInfoData()
      .then((resp) => {
        setDonatorList(resp);
        setFilterData(resp);
        const d = resp.map((aa) => aa.created_at);
        const date = moment(d);
        const amount1 = resp
          .map((aa) => aa.amount)
          .reduce(function (prev, curr) {
            return (Number(prev) || 0) + (Number(curr) || 0);
          });
        const amount = amount1.toFixed(2);
        setTotalDonation(amount);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };

  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let charityName = [];
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
          if (charityId.includes(data._id)) {
            charityName.push(data.charityName);
          }
        });

        setCharityList(arr);
        setCharityName(charityName);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };

 
  
  const av = donatorList
    .map((a) => a.amount)
    .reduce(function (prev, curr) {
      return (Number(prev) || 0) + (Number(curr) || 0);
    }, 0);

  useEffect(() => {
    setTotalDonation(av.toFixed(2));
  }, [av]);

   const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndtDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };
  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndtDate(dateObj2);
    
    if (startDate > dateObj2) {
      return;
    }

    setDonatorList([]);
    let filter = filterData.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        setDonatorList((p) => [...p, data]);
      } else {
        // console.log("not matched");
      }
    });
  };
 

  let abc = [];
  let locationArray = [];
  let arr1 = donatorList.map((a) => {
    return a.retailerID.map((ab) => {
      return a.locationID.map((abcc) => {
        abc.push({
          retailer: ab.RetailerName,
          location: {
            _id: abcc._id,
            address: abcc.address,
            city: abcc.city,
            country: abcc.country,
            postal_code: abcc.postal_code,
            province: abcc.province,
            storeID: abcc.storeID,
          },
          amount: a.amount,
        });
      });
    });
  });

  let DonationsData = abc.reduce((acc, obj) => {
    let existItem = acc.find((item) => item.location._id === obj.location._id);
    if (existItem) {
      existItem.amount = Number(existItem.amount) + Number(obj.amount);
      return acc;
    }
    acc.push(obj);
    return acc;
  }, []);

  // const columns = [
  //   {
  //     id: "retailer",
  //     displayName: "Retailer/Event",
  //   },
  
  //   {
  //     id: "retailerAmount",
  //     displayName: "Retailer Amount",
  //   },
  //     {
  //     id: "locationAddress",
  //     displayName: "Location Address",
  //   },
  //   {
  //     id: "locationCity",
  //     displayName: "Location City",
  //   },
  //   {
  //     id: "storeID",
  //     displayName: "Store ID",
  //   },
    
  //   {
  //     id: "locationProvince",
  //     displayName: "Province",
  //   },
  //   {
  //     id: "locationPostalCode",
  //     displayName: "Postal Code",
  //   },
  //   {
  //     id: "locationCountry",
  //     displayName: "Country",
  //   },
  //   {
  //     id: "locationAmount",
  //     displayName: "Location Amount",
  //   },
  //   {
  //     id:"donorName",
  //     displayName: "Donor Name",
  //   },
  //   {
  //     id:"donorAddress",
  //     displayName: "Address",
  //   },
  //   {
  //     id:"donorCity",
  //     displayName: "Donor City",
  //   },
  //    {
  //     id:"donorProvince",
  //     displayName: "Donor Province",
  //   },
  //   {
  //     id:"donorPostalCode",
  //     displayName: "Donor PostalCode",
  //   },
  //   {
  //     id:"orderID",
  //     displayName: "Order ID",
     
  //   },
  //   {
  //     id:"cardType",
  //     displayName: "Card Type",
      
  //   },
  //   {
  //     id:"donateAmount",
  //     displayName: "Amount",
      
  //   },
  //   {
  //     id:"date",
  //     displayName: "Date",
    
  //   },
  //   {
  //     id: "email",
  //     displayName: "Email",
    
  //   },
  // ];

  const arr = [];
  const datas = DonationsData.map((val) => ({
    name: val.retailer,
    address: `${val.location.address}`,
    city: `${val.location.city}`,
    province: `${val.location.province}`,
    postal_code: `${val.location.postal_code}`,
    country: `${val.location.country}`,
    amount: `$${val.amount}.00`,
  }));

  const columnsReport = [
    {
      name: "Retailer/Event",
      sortable: true,
      selector: (row) => row.retailer,
    },
    {
      name: "Total Collected",
      selector: (row) =>
       `$${parseFloat(row.amount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      center: true,
      sortable: true,
      filterable: true,
    },
  ];
  const columnsExpandTable = [
    {
      name: "Location",
      selector: (row) => row?.storeID ? `${row.address}, ${row.city}, ${row.province}, ${row.postal_code}, ${row.country}, Store #${row.storeID}`: `${row.address}, ${row.city}, ${row.province}, ${row.postal_code}, ${row.country}` ,
    },
   
    {
      name: "Amount",
      selector: (row) => `$${parseFloat(row.amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    },
  ];


  const DonationsDataRetailer = DonationsData.reduce((acc, obj) => {
  
    let existItm = acc.find((item) => item.retailer === obj.retailer);
    if (existItm) {
      existItm.amount = Number(existItm.amount) + Number(obj.amount);
    }else{
      acc.push({...obj});  
    }

    return acc;
  }, []);
 const setLocData =DonationsData.filter(
      (vl) => vl.retailer === DonationsDataRetailer?.[0]?.retailer
    ).map((val) => val);
setLocData.map((v1) => {
      const filterDataForOrderDetailss = locationDataDisplayforDonorCSV.filter(
        (v2) => v2._id === v1.location._id
      ).map((val) => val.orderDetails);
      v1.orderDetails = filterDataForOrderDetailss
    });
   const printDataLoc = [...setLocData];
    
// console.log(printDataLoc,"Display Loc Data here......./////") 

  
  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setPrintRetailer(false);
    setPrintRetailerAmount(false);
    setDonatorList([]);
    setEndtDate(new Date());
    setStartDate(new Date());
    setDisableDates(new Date());
    if (selectedCharity === "All Charities") {return setDonatorList(filterData)};
    let filter = filterData.map((data, i) => {
       data.charityID.map((d) => {
        if (d.charityName === selectedCharity) {
          setDonatorList((p) => [...p, data]);
        } else {
          <div>"no Record Found"</div>;
        }
      });

      
    });
  //   console.log(DonationsDataRetailer,"Donation Data Retailer Print Dtat");
  //   if(DonationsDataRetailer.length>0){
  //     const selectedRetailer = DonationsDataRetailer?.[0].retailer ;
  //     setPrintRetailer(selectedRetailer);
  //     console.log(selectedRetailer,'selected Retailer Data');
  //     if(selectedRetailer){
  //       const filterData = DonationsData.filter(
  //         (vl) => vl.retailer === selectedRetailer
  //       ).map((val) => val);
  //       filterData.map((v1) => {
  //         const filterDataForOrderDetails = locationDataDisplayforDonorCSV.filter(
  //           (v2) => v2._id === v1.location._id
  //         ).map((val) => val.orderDetails);
  //         v1.orderDetails = filterDataForOrderDetails
  //       })
  //       setLocationDataDisplay([...filterData])
  //     }
  // }
}

  

  const handleChangePrint = (e) => {
    const selectedRetailer = e.target.value ;
    setEndtDate(new Date());
    setStartDate(new Date());
    setDisableDates(new Date());
    
    if(selectedRetailer && selectedRetailer!=="Select Retailer/Event"){
      setPrintRetailer(selectedRetailer);
      const filterData = DonationsData.filter(
        (vl) => vl.retailer === selectedRetailer
      ).map((val) => val);
      const rtAmount=filterData?.[0].amount;
      setPrintRetailerAmount(rtAmount);
      filterData.map((v1) => {
        const filterDataForOrderDetails = locationDataDisplayforDonorCSV.filter(
          (v2) => v2._id === v1.location._id
        ).map((val) => val.orderDetails);
        v1.orderDetails = filterDataForOrderDetails
      })
        setLocationDataDisplay([...filterData])
    }else{
      setPrintRetailer(false);
      setPrintRetailerAmount(false);
    }
  }

  //*********To export data in CSV**********
  // const CSVData =[];
  // const csvprintData = DonationsDataRetailer.map((a) => {
  //   let filterlocCSv= locationDataDisplay.filter(
  //     (vl) => vl.retailer === a.retailer
  //   ).map((val) => val);

  //     filterlocCSv.length > 0?
  //       filterlocCSv.map((v1) => {
  //         v1?.orderDetails?.length>0 ?
  //         v1.orderDetails.map((v2) => {
  //           CSVData.push({
  //             retailer: a.retailer,
  //             retailerAmount:`$${parseFloat(a.amount).toLocaleString("en-US", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}`,
  //             locationAddress: v1.location.address,
  //             locationCity: v1.location.city,
  //             storeID: v1.location.storeID,
  //             locationProvince: v1.location.province,
  //             locationPostalCode: v1.location.postal_code,
  //             locationCountry: v1.location.country,
  //             locationAmount: `$${parseFloat(v1.amount).toLocaleString("en-US", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}`,
  //             donorName : v2.donorName,
  //             donorAddress : v2.donorAddress,
  //             donorCity : v2.donorCity,
  //             donorProvince : v2.donorProvince,
  //             donorPostalCode : v2.donorPostalCode,
  //             orderID : v2.orderID,
  //             cardType : v2.cardType,
  //             donateAmount :`$${parseFloat(v2.amount).toLocaleString("en-US", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}` ,
  //             date : v2.date,
  //             email :  v2.email
  //           })
  //         })
  //         :
  //         CSVData.push({
  //           retailer: a.retailer,
  //           retailerAmount:`$${parseFloat(a.amount).toLocaleString("en-US", {
  //             minimumFractionDigits: 2,
  //             maximumFractionDigits: 2,
  //           })}` ,
  //           locationAddress: v1.location.address,
  //           locationCity: v1.location.city,
  //           storeID: v1.location.storeID,
  //           locationProvince: v1.location.provience,
  //           locationPostalCode: v1.location.postal_code,
  //           locationCountry: v1.location.country,
  //           locationAmount:`$${parseFloat(v1.amount).toLocaleString("en-US", {
  //             minimumFractionDigits: 2,
  //             maximumFractionDigits: 2,
  //           })}`,
            
  //         })

        
  //       })
  //     :
  //     CSVData.push({
  //       retailer: a.retailer,
  //       retailerAmount: a.amount,
  
  //     })
  // });
  const printSummary = () => {
    DonationsDataRetailer.length > 1 ?  setShowPrintPopup(true) : setShowPrintPopup(false)
  };
  const closePopup = (e) => {
    setPrintRetailer(false);
    setPrintRetailerAmount(false);
    setShowPrintPopup(false);
  };
  const ExpandedComponent = ({ data }) => {
    const filterData = DonationsData.filter(
      (vl) => vl.retailer === data.retailer
    ).map((val) => val);
    const ExpandDataDisplay = filterData.map((val, key) => {
      return {
        amount: val.amount,
        ...val.location,
      };
      
    })

  //    const onExpandRowLocationChange = async (rowStatus, rowData) => {
      
  //     let locationDataDisplayforDonor =[]
  //     donatorList.map((a) => {
  //       return a.retailerID.map((ab) => {
  //         return a.locationID.map((abcc) => {
  //           locationDataDisplayforDonor.push({
  //               _id: abcc._id,
  //               orderDetails:{
  //                 orderID:a.orderDetails[0]?.orderID?.slice(0, 6),
  //                 email: a.email,
  //                 name: `${a.firstName} ${a.lastName}`,
  //                 date:moment(a.created_at.slice(0, 10)).format("MM-DD-YYYY"),
  //                 cardType:a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
  //                 address: a.address
  //                 ? `${a?.address}, ${a?.city}, ${a?.province}, ${a?.postalCode}`
  //                 : "",
  //                 amount: a.orderDetails[0]?.amount,
  //               }
  //           });
  //         });
  //       });
  //     }); 
  //     if(rowStatus){
  //       // let donorDataDisplay = []
  //       const filterDataForLocationDisplay = locationDataDisplayforDonor.filter(
  //         (vl) => vl._id === rowData._id
  //       ).map((val) => val);
  //      //donorDataDisplay.push(...filterDataForLocationDisplay)
  //       // console.log(donorDataDisplay,"Print Donor Data Display ----->>>>>>///////--->>>")

  //       // setPrintDonorData([...printDonorData, ...filterDataForLocationDisplay])
  
  //      console.log(printDonorData, "check the value8 filterDataForLocationDisplay",filterDataForLocationDisplay )
  //     //   console.log("check the value8 filterDataForLocationDisplay",data)
  //     }else{
  //       const expandComponentLocData = locationDataDisplayforDonor.filter(
  //       (vl) => vl._id === rowData._id
  //       ).map((val) => val);
  //       console.log(expandComponentLocData,"locationData Display for retailer///////--->>>", locationDataDisplayforDonor)
  //         let relData= expandComponentLocData.map(i=>i._id)
  //         let printDataDonor =printDonorData.filter(item=>relData.indexOf(item._id))
  //         // setPrintDonorData([...printDataDonor])
  //   }
  // }

 
    return (
      <DataTable
        columns={columnsExpandTable}
        data={ExpandDataDisplay}
        expandableRows
        expandableRowsComponent={ExpandedComponentForLocations}
        // onRowExpandToggled={(bool, row) => onExpandRowLocationChange(bool, row)}
        striped
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="450px"
      />
    );
  };

  const columnsExpandLocationTable = [

    {
      name: "Donor Name",
      selector: (row) => row.orderDetails.name,
    },
    {
      name: "Address",
      sortable: true,
      selector: (row) => row.orderDetails.address,
    },
    {
      name: "Order ID",
      selector: (row) => row.orderDetails.orderID,
    },
    {
      name: "Card Type",
      selector: (row) => row.orderDetails.cardType,
    },
   
    {
      name: "Amount",
      selector: (row) => `$${parseFloat(row.orderDetails.amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      
    },
    {
      name: "Date",
      selector: (row) => row.orderDetails.date,
    },
    {
      name: "Email",
      selector: (row) => row.orderDetails.email,
    },
  ];
  const ExpandedComponentForLocations = ({ data }) => {
    let locationData =[]
    donatorList.map((a) => {
      return a.retailerID.map((ab) => {
        return a.locationID.map((abcc) => {
          locationData.push({
              _id: abcc._id,
              orderDetails:{
                orderID:a.orderDetails[0]?.orderID?.slice(0, 6),
                email: a.email,
                name: typeof(a?.firstName)==="undefined"?"":  typeof(a?.lastName)==="undefined"? `${a.firstName}`:`${a.firstName} ${a.lastName}`,
                date:moment(a.created_at.slice(0, 10)).format("MM-DD-YYYY"),
                cardType:a.orderDetails[0]?.last4digit.replace(/[^a-zA-Z]/g,""),
                address: a.address
                ? `${a?.address}, ${a?.city}, ${a?.province}, ${a?.postalCode}`
                : "",
                amount: a.orderDetails[0]?.amount,
              }
          });
        });
      });
    });
    const filterDataForLocation = locationData.filter(
      (vl) => vl._id === data._id
    ).map((val) => val);
    return (
      
      <DataTable 
        columns={columnsExpandLocationTable}
        data={filterDataForLocation}
        striped
        highlightOnHover
        fixedHeader
        pagination
        paginationRowsPerPageOptions={[10,8,4,2]}
        fixedHeaderScrollHeight="400px"
      />
    );
  };

  return (
    <>
      <section className="home-section" id="printarea">
        <div className="home-content">
          <div className="retailerContainer donationSummary p-3">
            <h2 className="pageTitle mb-4">
              Donation Summary{" "}
              {typeof charityId !== "undefined" && charityId.length === 1
                ? `for ${charityName}`
                : ""}
            </h2>
            <div className="row">
              <div className="col-xl-5 col-lg-12 ui-left-col">
                <div className="tophead">
                  {typeof charityId === "undefined" ||
                  charityId.length === 0 ||
                  charityId.length > 1 ? (
                    <div className="charityMain">
                      <h5>
                        <b>CHARITY</b>
                      </h5>
                      <div>
                        <select onChange={handleChange} className="selectBox">
                          <option className="dropdown-item">
                            All Charities
                          </option>
                          {typeof charityId === "undefined" ||
                          charityId.length === 0
                            ? charityList
                              ? charityList.map((data) => (
                                  <option
                                    className="dropdown-item"
                                    value={data.charityName}
                                  >
                                    {data.charityName}
                                  </option>
                                ))
                              : null
                            : charityName
                            ? charityName.map((data) => (
                                <option className="dropdown-item" value={data}>
                                  {data}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="flmob">
                    <CsvDownloader
                      filename="Donation Summary"
                      extension=".csv"
                      separator=","
                      columns={columns}
                      datas={CSVData}
                      text="DOWNLOAD"
                    >
                      <button className="btn btn-primary">Download .CSV</button>
                    </CsvDownloader>
                  </div> */}

                  <div className="flmob">
                  {/* {console.log(DonationsDataRetailer,"kkkeeewqeeqweqeqwqw")} */}
                  {DonationsDataRetailer.length >1 ? 
                    <button
                      className="btn btn-primary custm-print"
                      // onClick={print}
                      data-dismiss="modal"
                      onClick={printSummary}
                    >
                      Print
                    </button>
                    :
                    <PrintComponent
                      data={DonationsDataRetailer}
                      retailerName ={printRetailer!==false ?printRetailer:DonationsDataRetailer?.[0]?.retailer}
                      totalDonation={totalDonation} 
                      printLocationData ={printDataLoc}
                    /> 
                  }
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-12 text-center ui-center-col">
                <h5>
                  <b>
                    TOTAL DONATIONS: $
                    {parseFloat(totalDonation).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </h5>
              </div>

              <div className="col-xl-4 col-lg-12 ui-right-col">
                <form>
                  <div className="customdate">
                    {/* <h5>
                    <b>REPORTING PERIOD : </b>&nbsp;
                 </h5> */}

                    <div>
                      <label>
                        <b>From</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        onChangeCapture={handleFirstCalender}
                        value={startDate}
                      ></input>
                      <label>
                        <b>TO</b>
                      </label>
                      <input
                        type="date"
                        name="celander"
                        required
                        className="mobmarg"
                        onChangeCapture={handleSecondCalender}
                        value={endDate}
                        min={disableDates}
                      ></input>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="expandDataTable">
              {/* {setPrintCsvData(DonationsDataRetailer)} */}
              <DataTable
                columns={columnsReport}
                data={DonationsDataRetailer}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                // onRowExpandToggled={(bool, row) => onExpandRowChange(bool, row)}
                // onRowClicked={(row) => console.log(row, "click working")}
                striped
                highlightOnHover
                //actions={actionsMemo}
                // fixedHeader
                // fixedHeaderScrollHeight="550px"
              />
            </div>
            {showPrintPopup  ?
                  <div className="ModalBackground donationSummaryModal">
                  <div className="modalContainer modelRetailerEventContainer" id="modelRetailerEventContainer1">
                    <div className="modalHeader mb-5">
                      <h3>Retailer/Event Donation Summary Print</h3>
                    </div>
                    <button className="closeBtn" onClick={closePopup}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                   <div className="col-md-12 col-sm-12">
                   <h6>Please select any one retailer/event to print donation summary report :</h6>
                   {typeof charityId === "undefined" ||
                  charityId.length === 0 ||
                  charityId.length > 1 ? (
                    <div className="charityMain">
                     
                        <b>Retailer/Event</b>
                      <div>
                      
                        <select onChange ={handleChangePrint} className="selectBox scrollable">
                          <option className="dropdown-item" >
                            Select Retailer/Event
                          </option>
                          {
                          DonationsDataRetailer.length  >1
                            &&  DonationsDataRetailer
                              ? DonationsDataRetailer.map((data) => (
                                  <option
                                    className="dropdown-item"
                                    value={data.retailer}
                                  >
                                    {data.retailer}
                                  </option>
                                ))
                               
                            : null}
                        </select>
                        
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                    
                  </div> 
                  <div
                    className="modalFooter "
                    style={{ marginTop: "18px", padding: "0" }}
                  >
                    <div className="col-sm-12 text-right">
                     
                      {printRetailer!== false?
                      <PrintComponent
                        data={DonationsDataRetailer}
                        retailerName ={printRetailer}
                        totalDonation={printRetailerAmount!== false ? printRetailerAmount : 0}
                        printLocationData ={locationDataDisplay}
                      /> 
                      :""}
                      <button
                        className="btn btn-primary"
                        style={{ float: "right", marginTop: "10px", padding: "4px 20px" }}
                        onClick={closePopup}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                  :null
                  }
                  
          </div>
        </div>
        <br></br>
        <Footer />
      </section>
    </>
  );
};

export default DonationSummary;