import axios from 'axios';
import { APIBase_URL } from "../../../config";

// this.fetch(`${API_URL}getCharity`, (response) => {

export const getProvinceData = async() => {
   const result1 = await fetch(`${APIBase_URL}province`)
   const result = await result1.json()
//    await console.log(result,"------")
    return result

}