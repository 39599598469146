import { React, useState, useEffect } from "react";
import logo from "../../logo/BNAedit.png";

const Footer = (prop) => {
  return (
    <>
      <footer
        className="text-center text-lg-start text-dark footer-fixed-bottom"
       
      >
        <div
          className="text-center p-3"
          style={{
            backgroundColor:"#e9e9e9",
          }}

        >
          Powered by&nbsp;
          <img src={logo} style={{width:'30px'}} alt="" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
