import { React, useState, useEffect } from "react";
import logo from "../../logo/BNAedit.png";
import { useStateValue } from "../../stateProvider";

const FooterWeb = (prop) => {
  const [ {language,privacy}, dispatch] = useStateValue();
  return (
    <>
      <footer
        className="text-center text-lg-start text-dark customFooter_Box" 
      >
        {/* <div
          className="text-center"
          style={{
            color: "#FFF",
          }}
        >
          <p><a href="https://www.bnasmartpayment.com/privacy-policy" target="_blank" style={{color:"#fff"}}> {`${privacy?privacy:prop.data?prop.data:'Privacy Policy'}`}</a></p>
         
        </div> */}
        <div
          className="text-center"
          style={{
            color: "#FFF",
            // backgroundColor: "red"
          }}
        >
         {/* {console.log(language,"language",prop)} */}
         <span className="footer-data"> {`${language?language:prop.data?prop.data:'Powered by'}`}&nbsp;</span>
          <img src={logo} style={{width:'30px'}} alt="" />
        </div>
      </footer>
    </>
  );
};

export default FooterWeb;
