import {
    useLocation,
    Switch,
    Route,
    useHistory,
    Redirect,
  } from "react-router-dom";
  import jwt_decode from "jwt-decode";
   
  export const PrivateRoute = (props) => {
    return localStorage.getItem("token") ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/",
          //   state: { from: location }
        }}
      />
    );
  };



  export const AdminRoute = (props) => {
    const currentUserData = localStorage?localStorage.getItem("token"):'';
    var decodedData = currentUserData?jwt_decode(currentUserData):'';
    const getUserType = decodedData?.userType;
    return localStorage.getItem("token") && (getUserType ==='ADMIN' || getUserType ==='BNAUSER' ) ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/donationDetail",
          //   state: { from: location }
        }}
      />
    );
  };