import moment from "moment";

export function stripeCardNumberValidation(cardNumber,cardInvalidMesLan) {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) {
      if (cardNumber) {
        return cardNumber &&
          /^[1-6]{1}[0-9]{14,15}$/i.test(
            cardNumber.replace(/[^\d]/g, "").trim()
          )
          ? ""
          : cardInvalidMesLan;
      }
    }
  }
  return cardInvalidMesLan;
}

export const stripeCardExpirValidation = (value,invaildDataFormatLan) => { 
  if (value) {
    if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
      let today = new Date();
      let CurrentDate = new Date(`${today.getFullYear()}`,(today.getMonth() + 1),new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate())
      let visaValue = value.split("/");
      let visaDate = new Date(`20${visaValue[1]}`, visaValue[0], 0);   
      return moment(CurrentDate) < moment(visaDate)
        ? undefined
        : invaildDataFormatLan;
    } else {
      return invaildDataFormatLan;
    }
  }
};

export const textWithSpacesOnly = (value,onlyAlphabetLan) => {
  if (value) {
    if (/^[a-zA-Z ]*$/i.test(value)) {
      return undefined;
    } else {
      return onlyAlphabetLan;
    }
  } else {
    return undefined;
  }
};

export const texEmailOnly = (value,onlyAlphabetLan) => {
  if (value) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) { 
      return undefined;
    } else {
      return onlyAlphabetLan;
    }
  } else {
    return undefined;
  }
};

export const textAndSpacesOnly = (value,onlyAlphabetLan) => {
  if (value) {
    // if (/^[a-zA-Z ]*$/i.test(value)) {
    //   return undefined;
    // } else {
    //   return onlyAlphabetLan;
    // }
  } else {
    return undefined;
  }
};

export const minLength = (min) => (value,cvnErrorMessLan) =>
  value && value.length < min ? cvnErrorMessLan : undefined;
