import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { APIBase_URL } from "../../config";

const PasswordForm = () => {
  const currentUser = localStorage.getItem("token");
  var token = currentUser;
  var decoded = jwt_decode(token);
  const userID = decoded.adminid;

  const [status, setStatus] = useState("");

  const validate = (values) => {
    const errors = {};
    if (!values.pwd) {
      errors.pwd = "Conform password is required";
    }

    if (values.pwd != values.password) {
      errors.pwd = "Password Not Matched";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 characters or greater";
    } else if (
      !values.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      errors.password =
        "Password must have number a lowercase a uppercase letter and special characters";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      pwd: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      updatePassword(values);
      resetForm();
    },
  });

  const updatePassword = async (values) => {
    let newPswrd = values.password;
    let confirmPswrd = values.pwd;

    let filter = userID;
    if (filter && newPswrd && confirmPswrd) {
      const data = {
        password: newPswrd,
        ConfirmPassword: confirmPswrd,
      };

      await fetch(`${APIBase_URL}admin/changePassword/${userID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          setStatus(`Password has been Changed`);
          setTimeout(() => {
            setStatus("");
          }, 2000);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };
  return (
    <>
      <h2 className="pageTitle mb-4">Change Password</h2>
      <h6 style={{ color: "green" }}>{status}</h6>
      <form onSubmit={formik.handleSubmit}>
        <label htmlFor="firstName"></label>

        <div className="col-xl-3 col-lg-6 col-md-12 col-12 Logincontainer">
          <label>
            <b>New Password</b>
          </label>

          <input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            style={{ borderRadius: "50px", outline: "none" }}
          />
          {formik.touched.password && formik.errors.password ? (
            <div
              style={{ bottom: "-22px" }}
              className="custom-error text-danger"
            >
              {formik.errors.password}
            </div>
          ) : null}
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 col-12 Logincontainer">
          <label>
            <b>Confirm Password</b>
          </label>
          <input
            id="pwd"
            name="pwd"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.pwd}
            style={{ borderRadius: "50px", outline: "none" }}
          />

          {formik.touched.pwd && formik.errors.pwd ? (
            <div
              style={{ bottom: "-22px" }}
              className="custom-error text-danger"
            >
              {formik.errors.pwd}
            </div>
          ) : null}
        </div>
        <div className="col-xl-3 col-lg-8 col-md-12 col-12 py-3 text-sm-right">
          <button
            style={{
              borderRadius: "50px",
              outline: "none",
              padding: "5px 15px",
              maxWidth: "unset",
              margin: "0",
              height: "46px"
            }}
            className="btn btn-primary text-nowrap updatBtn"
            type="submit"
          >
            Update Password
          </button>

        </div>
      </form>
    </>
  );
};

export default PasswordForm;
