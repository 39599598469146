import { React, useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
// import dateFormat from require('dateformat');
import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import { getTaxInfoData } from "../API/TaxInfoAPI/TaxInfoAPI";
import Footer from "../Footer/Footer";


const DonationDetail = ({ side }) => {
  return (
    <>
      <section className="home-section">
        <div className="home-content">
          <div className="retailerContainer p-3">
            <h2 className="pageTitle mb-4">Reports</h2>

            <div className="row">
              <div className="col-xl-12 ui-lefttop">
              <div>
                <DataTable
                  id="aba"
                //   columns={columns1}
                //   data={data1}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </section>
      {/* </div> */}
    </>
  );
};

export default DonationDetail;
