import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import moment from "moment";

export default function PrintComponent(props) {
  let componentRef = useRef();
  return (
    <>
      <div id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint
            data={props.data}
            pdfTitle={props.pdfTitle}
            ref={(el) => (componentRef = el)}
          />
        </div>
      </div>
    </>
  );
}

// component to be printed
class ComponentToPrint extends React.Component {
  render() {
    let donatorList = this.props.data;
    return (
      <div>
        <h2 style={{ color: "green" }}>{this.props.pdfTitle =='2' ? "Lottery Details" :"Donation Details"}</h2>
        <table className="table table-striped mt-3">
          <thead className="thead-light">
            <tr>
              <th>Donor Name</th>
              <th>Address</th>
              <th>Order ID</th>
              <th>Tax Receipt</th>
              <th>Card Type</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Email Address</th>
            </tr>
          </thead>
          <tbody>
            {donatorList
              ? donatorList?.map((donator) => {
                  let text1 = donator.orderID;
                  let orderid = text1?.slice(0, 6);

                  let text = donator.created_at;
                  let val = text?.slice(0, 10);
                  let created_at = moment(val).format("MM-DD-YYYY");

                  return (
                    <tr>
                      <td>
                        {donator.donorName}
                      </td>
                      {donator.address ? (
                        <td>
                          {donator.address}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td style={{ textAlign: "right" }}>{orderid}</td>
                      <td style={{ textAlign: "center" }}>
                        {donator.taxReceipt}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {donator.last4digit}
                      </td>
                      {donator.amount.includes(".") ? (
                        <td
                          style={{ textAlign: "right" }}
                        >{`${donator.amount}`}</td>
                      ) : (
                        <td
                          style={{ textAlign: "right" }}
                        >{`${donator.amount}.00`}</td>
                      )}
                      <td>{created_at}</td>

                      <td>{donator.emailAddress}</td>
                    </tr>
                  );
                })
              : `${"No Record Found"}`}
          </tbody>
        </table>
      </div>
    );
  }
}
