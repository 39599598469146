import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import { duplicateMID } from './Message/Message'
import TableLoader from "../Spinner/TableLoader";
import {
  getCharityData,
  addCharityData,
  updateCharityData,
  deleteCharityData,
  importCsvCharityData,
  // getStartingTicketNumberData,
} from "../API/CharityAPI/CharityAPI";
import { getProvinceData } from "../API/ProvinceAPI/ProvinceAPI";
import { addAmountData, updateAmountData } from "../API/CharityAPI/AmountAPI";
import Validator from "validator";
import { APIBase_URL } from "../../config";
import Swal from 'sweetalert2'

// this.fetch(`${API_URL}getCharity`, (response) => {

const Charity = () => {
  const [setModal, setModalState] = useState(false);
  const [setUpdateModal, setUpdateModalState] = useState(false);
  const [donationModal, setDonationModal] = useState(false);
  const [updateDonationModal, setUpdateDonationModal] = useState(false);
  const [updateID, setUpdateID] = useState(false);
  const [charityList, setCharityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [pending, setPending] = useState(true);
  const [charityName, setCharityName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [color, setColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [country, setCountry] = useState("Canada");
  const [MID, setMID] = useState("");
  const [charityLogo, setCharityLogo] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [ext, setExt] = useState("");
  const [unitSuite, setUnitSuite] = useState("");
  const [language, setLanguage] = useState("English");
  const [donationFrequency, setDonationFrequency] = useState("OneTime");
  // const [donation_amount, setDonation_amount] = useState([]);
  const [createTaxReceipt, setCreateTaxReceipt] = useState("0");
  const [taxReceiptEligible, setTaxReceiptEligible] = useState("0");
  const [processFee, setProcessFee] = useState("0");
  const [processFeeAmount, setProcessFeeAmount] = useState("");
  const [lotteryTicket, setLotteryTicket] = useState("No");
  const [fiftyTicket, setFiftyTicket] = useState("No");
  const [createLotteryTicket, setCreateLotteryTicket] = useState("No");
  const [name1, setName1] = useState({});
  const [nameMonthly, setNameMonthly] = useState({});
  const [msg, setMsg] = useState("");
  const [msgAmount, setMsgAmount] = useState("");
  const [msgMonthlyAmount, setMsgMonthlyAmount] = useState("");
  const [cName, setCName] = useState("");
  const [addSecond, setAddSecond] = useState(false);
  // const [imageUploadType, setImageUploadType] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [updateCharityName, setUpdateCharityName] = useState("");
  const [updateContactName, setUpdateContactName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [updateAddress, setUpdateAddress] = useState("");
  const [updateProvince, setUpdateProvince] = useState("");
  const [updateCity, setUpdateCity] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updateMID, setUpdateMID] = useState("");
  const [updateCharityLogo, setUpdateCharityLogo] = useState("");
  const [updatePostal_Code, setUpdatePostal_Code] = useState("");
  const [updateExt, setUpdateExt] = useState("");
  const [updateUnitSuite, setUpdateUnitSuite] = useState("");
  const [updateDonation_amount, setUpdateDonation_amount] = useState([]);
  const [updateMonthlyDonation_amount, setUpdateMonthlyDonation_amount] =
    useState([]);
  const [updateAmount1, setUpdateAmount1] = useState("");
  const [updateAmount2, setUpdateAmount2] = useState("");
  const [updateAmount3, setUpdateAmount3] = useState("");
  const [updateAmount4, setUpdateAmount4] = useState("");
  const [updateAmount5, setUpdateAmount5] = useState("");
  const [updateAmount6, setUpdateAmount6] = useState("");
  const [updateAmount7, setUpdateAmount7] = useState("");
  const [updateAmount8, setUpdateAmount8] = useState("");
  const [updateLanguage, setUpdateLanguage] = useState("");
  const [updateColor, setUpdateColor] = useState("");
  const [updateFontColor, setUpdateFontColor] = useState("");
  const [updateDonationFrequency, setUpdateDonationFrequency] = useState("");
  const [updateMonthlyAmount1, setUpdateMonthlyAmount1] = useState("");
  const [updateMonthlyAmount2, setUpdateMonthlyAmount2] = useState("");
  const [updateMonthlyAmount3, setUpdateMonthlyAmount3] = useState("");
  const [updateMonthlyAmount4, setUpdateMonthlyAmount4] = useState("");
  const [updateMonthlyAmount5, setUpdateMonthlyAmount5] = useState("");
  const [updateMonthlyAmount6, setUpdateMonthlyAmount6] = useState("");
  const [updateMonthlyAmount7, setUpdateMonthlyAmount7] = useState("");

  const [updateCreateTaxReceipt, setUpdateCreateTaxReceipt] = useState("0");
  const [updateTaxReceiptEligible, setUpdateTaxReceiptEligible] = useState("0");
  const [updateProcessFee, setUpdateProcessFee] = useState("0");
  const [updateProcessFeeAmount, setUpdateProcessFeeAmount] = useState();
  const [updatelotteryTicket, setUpdateLotteryTicket] = useState("No");
  const [updatefiftyTicket, setUpdateFiftyTicket] = useState("No");
  const [updatecreateLotteryTicket, setUpdateCreateLotteryTicket] =
    useState("No");
  const [updateSecond, setUpdateSecond] = useState(false);

  const [abc, setAbc] = useState("");
  const [isUpload, setIsUpload] = useState("");
  const [isAddUpload, setIsAddUpload] = useState("");
  const [updateName1, setUpdateName1] = useState({});
  const [updateMonthlyName, setUpdateMonthlyName] = useState({});

  //===================================================================//
  const [addDonationAmount, setAddDonationAmount] = useState([]);
  const [getDonationAmount, setGetDonationAmount] = useState([]);
  const [donationAmount, setDonationAmount] = useState("");
  const [saveamount, setamount] = useState([]);
  const [saveMonthlyAmount, setMonthlyamount] = useState([]);
  const [saveUpdateAmount, setUpdateAmount] = useState([]);
  const [saveUpdateMonthlyAmount, setUpdateMonthlyAmount] = useState([]);
  const [amountID, setAmountID] = useState();
  const [updateAmountID, setUpdateAmountID] = useState();
  const [AddAmountValue, setAmountValue] = useState();
  const [msg2, setUpdateMsg] = useState("");
  const [formData, setFormData] = useState("");
  const [importExcelModel, setImportExcelModal] = useState(false);
  const [fileNamemsg, setFileNamemsg] = useState("");
  const [file, setFile] = useState();
  const [msgUpdateMonthlyAmount, setUpdateMonthlyAmountMsg] = useState("");

  const [isDonationMonthly, setisDonationMonthly] = useState(false);
  const [updateIsDonationMonthly, setupdateIsDonationMonthly] = useState(false);
  const [charityType, setCharityType] = useState("Donations");
  const [updateCharityType, setUpdateCharityType] = useState("Donations");
  const [error, setError] = useState({});
  const [startingTicketNumber, setStartingTicketNumber] = useState("");
  const [updateStartingTicketNumber, setUpdateStartingTicketNumber] =
    useState("");
  const [sendGridTemplateId, setSendGridTemplateId] = useState("");
  const [sendGridLotteryTemplateId, setSendGridLotteryTemplateId] =
    useState("");
  const [templateName, setTemplateName] = useState(null);
  const [lotteryTemplateName, setlotteryTemplateName] = useState(null);
  const [updateSendGridTemplateId, setUpdateSendGridTemplateId] =
    useState(null);
  const [updateSendGridLotteryTemplateId, setUpdateSendGridLotteryTemplateId] =
    useState(null);
  const [updateTemplateName, setUpdateTemplateName] = useState(null);
  const [updateLotteryTemplateName, setUpdateLotteryTemplateName] =
    useState(null);
  const [lotteryEmailSendGridId, setLotteryEmailSendGridId] = useState("");
  const [donationEmailSendGridId, setDonationEmailSendGridId] = useState("");
  const [updateLotteryEmailSendGridId, setUpdateLotteryEmailSendGridId] =
    useState("");
  const [updateDonationEmailSendGridId, setUpdateDonationEmailSendGridId] =
    useState("");
  const [stringOne, setStringOne] = useState("");
  const [stringTwo, setStringTwo] = useState("");
  const [updateStringOne, setUpdateStringOne] = useState("");
  const [updateStringTwo, setUpdateStringTwo] = useState("");
  const [lotteryEmailEdit, setLotteryEmailEdit] = useState(false);
  const [donationEmailEdit, setDonationEmailEdit] = useState(false);
  const [updateLotteryEmailEdit, setUpdateLotteryEmailEdit] = useState(false);
  const [updateDonationEmailEdit, setUpdateDonationEmailEdit] = useState(false);
  const [rulesRegUrl, setRulesRegUrl] = useState("");
  const [updateRulesRegUrl, setUpdateRulesRegUrl] = useState("");

  //====================================================================//

  useEffect(() => {
    getCharity();
    getProvince();
  }, []);

  const getCharity = async () => {
    getCharityData().then((resp) => {
      let arr = [];
      let filter = resp.map((data, i) => {
        if (data.isActive === false) {
          arr.push(data);
        }
      });

      setCharityList(arr);

      setUpdateCharityName(resp[0]?.charityName);
      setUpdateContactName(resp[0]?.contactName);
      setUpdateEmail(resp[0]?.email);
      setUpdatePhone(resp[0]?.phone);
      setUpdateAddress(resp[0]?.address);
      setUpdateProvince(resp[0]?.province);
      setUpdateCity(resp[0]?.city);
      setUpdateCountry(resp[0]?.country);
      setUpdateMID(resp[0]?.MID);
      setUpdateCharityLogo(resp[0]?.charityLogo);
      setUpdatePostal_Code(resp[0]?.postal_code);
      setUpdateDonation_amount(resp[0]?.donation_amount[0]?.amount);
      setUpdateMonthlyDonation_amount(resp[0]?.monthlyDonationAmount);
      setUpdateID(resp[0]?._id);
      setUpdateExt(resp[0]?.ext);
      setUpdateUnitSuite(resp[0]?.unitSuite);
      setUpdateCharityType(resp[0]?.charityType);
      setUpdateLanguage(resp[0]?.language);
      setUpdateDonationFrequency(resp[0]?.donationFrequency);
      setUpdateColor(resp[0]?.color);
      if (resp[0]?.startingTicketNumber) {
        let whole_string = resp[0]?.startingTicketNumber;
        let split_string = whole_string.split(/(\d+)/);
        setUpdateStringOne(split_string[0]);
        setUpdateStringTwo(split_string[1]);
      }
      setUpdateStartingTicketNumber(resp[0]?.startingTicketNumber);
      setUpdateFontColor(resp[0]?.fontColor);
      setUpdateLotteryTemplateName(resp[0]?.lotteryTemplateName);
      setUpdateSendGridLotteryTemplateId(resp[0]?.sendGridLotteryTemplateId);
      setUpdateSendGridTemplateId(resp[0]?.sendGridTemplateId);
      setUpdateTemplateName(resp[0]?.templateName);
      setUpdateDonationEmailSendGridId(resp[0]?.donationEmailSendGridId);
      setUpdateLotteryEmailSendGridId(resp[0]?.lotteryEmailSendGridIdlFrom);
      setUpdateRulesRegUrl(resp[0]?.rulesRegUrl);
    });
  };

  const getProvince = async () => {
    getProvinceData().then((resp) => {
      setProvinceList(resp);
      // console.log(resp, ">>>>>>");
    });
  };

  const openModal = async () => {
    setModalState(!setModal);
    setUpdateCharityName("");
    setUpdateContactName("");
    setUpdateEmail("");
    setUpdatePhone("");
    setUpdateAddress("");
    setUpdateProvince("");
    setUpdateCity("");
    setUpdateCountry("");
    // setUpdateMID(""),
    setUpdateCharityLogo("");
    // setUpdatePostal_Code("");
    setUpdateDonation_amount("");
    setUpdateExt("");
    setUpdateUnitSuite("");
    setUpdateLanguage("");
    setUpdateColor("");
    setUpdateFontColor("");
    setUpdateLotteryTemplateName("");
    setUpdateSendGridLotteryTemplateId("");
    setUpdateSendGridTemplateId("");
    setUpdateTemplateName("");
    setUpdateDonationEmailSendGridId("");
    setUpdateRulesRegUrl("");
    setUpdateLotteryEmailSendGridId("");
    // setamount([]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // setRows(data1);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  });

  const handleFile = (event) => {
    let img = event.target.files[0];
    setCharityLogo(img);
    setIsAddUpload(event.target.files);
  };

  const handleMID = (event) => {
    const mid = Validator.ltrim(event.target.value);
    setMID(mid);
    // let midData = charityList.map((val) => val.MID);
    // let chk = midData.includes(mid);
    // if (chk) {
    //   setMsg(` Duplicate MID `);
    //   setTimeout(() => {
    //     setMsg("");
    //   }, 2000);
    // } else {
    //   setMID(mid);
    // }
  };
  const openAddSecondForm = async () => {
    //setImageUploadType('add');
    let errorCheckforImage = checkValidationFormatBeforeSave("add");
    if (errorCheckforImage === true) {
      setAddSecond(true);
    } else {
      setAddSecond(false);
    }
  };
  const closeAddSecondForm = async () => {
    setAddSecond(false);
  };
  const addCharity = async () => {
    let startingNumber = "";
    if (
      stringOne !== "" &&
      stringOne !== null &&
      typeof stringOne !== undefined
    ) {
      startingNumber = stringOne + stringTwo;
    } else if (
      stringOne === "" ||
      stringOne === null ||
      typeof stringOne === undefined
    ) {
      startingNumber = stringTwo;
    } else if (
      stringTwo === "" ||
      stringTwo === null ||
      typeof stringTwo === undefined
    ) {
      startingNumber = stringOne;
    }

    setStartingTicketNumber(startingNumber);
    let errorCheckFoTicketNumber = await checkValidDataFields("add");
    if (errorCheckFoTicketNumber) {
      const formData = new FormData();
      formData.append("charityName", charityName);
      formData.append("contactName", contactName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("province", province);
      formData.append("city", city);
      formData.append("color", color);
      formData.append("fontColor", fontColor);
      formData.append("country", country);
      formData.append("postal_code", postal_code);
      formData.append("MID", MID);
      formData.append("charityLogo", charityLogo, charityLogo.name);
      formData.append("language", language);
      formData.append("donationFrequency", donationFrequency);
      formData.append("charityType", charityType);
      //formData.append("donation_amount", amountID);
      saveamount.map((currElement, index) => {
        formData.append("donation_amount[]", currElement);
      });
      formData.append("createTaxReceipt", createTaxReceipt);
      formData.append("taxReceiptEligible", taxReceiptEligible);
      formData.append("processFee", processFee);
      formData.append(
        "processFeeAmount",
        processFee === "1" ? parseFloat(processFeeAmount.replace(/,/g, "")) : ""
      );
      formData.append("lotteryTicket", lotteryTicket);
      formData.append("fiftyTicket", fiftyTicket);
      formData.append("createLotteryTicket", createLotteryTicket);
      formData.append("startingTicketNumber", startingTicketNumber);
      formData.append("lotteryTemplateName", "lotteryTicket");
      formData.append("sendGridLotteryTemplateId", sendGridLotteryTemplateId);
      formData.append("sendGridTemplateId", sendGridTemplateId);
      formData.append("templateName", "taxReciept");
      formData.append("donationEmailSendGridId", donationEmailSendGridId);
      formData.append("lotteryEmailSendGridId", lotteryEmailSendGridId);
      if (donationFrequency == "Monthly") {
        saveMonthlyAmount.map((currElement, index) => {
          formData.append("monthlyDonationAmount[]", currElement);
        });
      } else {
        formData.append("monthlyDonationAmount[]", "");
      }
      formData.append("ext", ext);
      formData.append("unitSuite", unitSuite);
      formData.append("rulesRegUrl", rulesRegUrl);
      addCharityData(formData)
        .then((res) => {
          // localStorage.setItem("User", JSON.stringify(res.data));
          setMsg(`Charity Successfully Added `);
          setTimeout(() => {
            setMsg("");
          }, 4000);
          getCharity();
        })
        .catch((err) => {
          // console.log(err);
        });
      setModalState(false);
      setamount([]);
      setName1({});
      setisDonationMonthly(false);
      setCharityName("");
      setContactName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setProvince("");
      setCity("");
      setColor("");
      setFontColor("");
      setCountry("");
      setMID("");
      setCharityLogo("");
      setPostal_Code("");
      setExt("");
      setUnitSuite("");
      setLanguage("English");
      setDonationFrequency("OneTime");
      setAddSecond(false);
      setCreateTaxReceipt("0");
      setTaxReceiptEligible("0");
      setProcessFee("0");
      setProcessFeeAmount("");
      setIsAddUpload("");
      setCharityType("Donations");
      setLotteryTicket("No");
      setFiftyTicket("No");
      setCreateLotteryTicket("No");
      setStartingTicketNumber("");
      setStringOne("");
      setStringTwo("");
      setTemplateName("");
      setSendGridTemplateId("");
      setSendGridLotteryTemplateId("");
      setlotteryTemplateName("");
      setDonationEmailSendGridId("");
      setLotteryEmailSendGridId("");
      setRulesRegUrl("");
      reset();
    }
  };

  //Amount

  const openAmountModal = () => {
    // setUpdateDonation_amount([])
    // setamount([]);
    setDonationModal(!donationModal);
  };
  const addAmount = async () => {
    const data = {
      amount: saveamount,
    };
    addAmountData(data)
      .then((res) => {
        setAmountID(res.data._id);
      })
      .catch((err) => {
        // console.log(err);
      });

    setDonationModal(false);
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);
  const selectCharity = async (id, name) => {
    setCName(name);
    // console.log(id, "id");
    setUpdateCharityName("");
    setUpdateContactName("");
    setUpdateEmail("");
    setUpdatePhone("");
    setUpdateAddress("");
    setUpdateProvince("");
    setUpdateCity("");
    setUpdateCountry("");
    setUpdateCharityLogo("");
    setUpdatePostal_Code("");
    setUpdateMID("");
    setUpdateDonation_amount("");
    setUpdateMonthlyDonation_amount("");
    setUpdateExt("");
    setUpdateColor("");
    setUpdateFontColor("");
    setUpdateRulesRegUrl("");
    setUpdateModalState(!setUpdateModal);
    let filter = await charityList.filter((data) => data._id === id);
    let newData = {
      updateCharityName: filter[0].charityName,
      updateContactName: filter[0].contactName,
      updateEmail: filter[0].email,
      updatePhone: normalizeInput(filter[0].phone),
      updateAddress: filter[0].address,
      updateProvince: filter[0].province,
      updateCity: filter[0].city,
      updateCountry: filter[0].country,
      updateMID: filter[0].MID,
      updatePostalCode: postalCodeFormat(filter[0].postal_code),
    };

    setFormData(newData);
    setUpdateCharityName(filter[0].charityName);
    setUpdateContactName(filter[0].contactName);
    setUpdateEmail(filter[0].email);
    setUpdatePhone(normalizeInput(filter[0].phone));
    setUpdateAddress(filter[0].address);
    setUpdateProvince(filter[0].province);
    setUpdateCity(filter[0].city);
    setUpdateCountry(filter[0].country);
    setUpdateMID(filter[0].MID);
    setUpdateCharityLogo(filter[0].charityLogo);
    setUpdatePostal_Code(postalCodeFormat(filter[0].postal_code));
    setUpdateMID(filter[0].MID);
    setUpdateCharityType(filter[0].charityType);
    setUpdateColor(filter[0].color);
    if (filter[0]?.startingTicketNumber) {
      let whole_string1 = filter[0]?.startingTicketNumber;
      let split_string1 = whole_string1.split(/(\d+)/);
      setUpdateStringOne(split_string1[0]);
      setUpdateStringTwo(split_string1[1]);
    }
    setUpdateStartingTicketNumber(filter[0]?.startingTicketNumber);
    setUpdateFontColor(filter[0].fontColor);
    setUpdateLotteryTemplateName(filter[0]?.lotteryTemplateName);
    setUpdateSendGridLotteryTemplateId(filter[0]?.sendGridLotteryTemplateId);
    setUpdateSendGridTemplateId(filter[0]?.sendGridTemplateId);
    setUpdateTemplateName(filter[0]?.templateName);
    setUpdateDonationEmailSendGridId(filter[0]?.donationEmailSendGridId);
    setUpdateRulesRegUrl(filter[0]?.rulesRegUrl);
    setUpdateLotteryEmailSendGridId(filter[0]?.lotteryEmailSendGridId);
    setUpdateDonationFrequency(
      typeof filter[0].donationFrequency != "undefined"
        ? filter[0].donationFrequency
        : "OneTime"
    );
    setUpdateLotteryTemplateName(filter[0]?.lotteryTemplateName);
    setUpdateSendGridLotteryTemplateId(filter[0]?.sendGridLotteryTemplateId);
    if (filter[0].donationFrequency == "Monthly") {
      setupdateIsDonationMonthly(true);
    }
    setUpdateLanguage(
      typeof filter[0].language != "undefined" ? filter[0].language : "English"
    );
    setUpdateDonation_amount(filter[0].donation_amount[0].amount);
    setUpdateMonthlyDonation_amount(filter[0].monthlyDonationAmount);
    setUpdateAmount1(
      filter[0].donation_amount[0].amount[0] !== "undefined" &&
        filter[0].donation_amount[0].amount[0] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[0])
        : ""
    );
    setUpdateAmount2(
      filter[0].donation_amount[0].amount[1] !== "undefined" &&
        filter[0].donation_amount[0].amount[1] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[1])
        : ""
    );
    setUpdateAmount3(
      filter[0].donation_amount[0].amount[2] !== "undefined" &&
        filter[0].donation_amount[0].amount[2] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[2])
        : ""
    );
    setUpdateAmount4(
      filter[0].donation_amount[0].amount[3] !== "undefined" &&
        filter[0].donation_amount[0].amount[3] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[3])
        : ""
    );
    setUpdateAmount5(
      filter[0].donation_amount[0].amount[4] !== "undefined" &&
        filter[0].donation_amount[0].amount[4] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[4])
        : ""
    );
    setUpdateAmount6(
      filter[0].donation_amount[0].amount[5] !== "undefined" &&
        filter[0].donation_amount[0].amount[5] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[5])
        : ""
    );
    setUpdateAmount7(
      filter[0].donation_amount[0].amount[6] !== "undefined" &&
        filter[0].donation_amount[0].amount[6] !== ""
        ? currencyFormat2(filter[0].donation_amount[0].amount[6])
        : ""
    );
    // setUpdateAmount8(
    //   filter[0].donation_amount[0].amount[7] !== "undefined"
    //     ? currencyFormat2(filter[0].donation_amount[0].amount[7])
    //     : ""
    // );
    let amount = filter[0].donation_amount[0].amount;

    var amountData = {};
    amount.forEach(function (e, i) {
      amountData[`name${i + 1}`] = e[0];
    });
    setUpdateAmount(amount);
    setUpdateName1(amountData);
    setUpdateID(filter[0]._id);
    setUpdateAmountID(filter[0].donation_amount[0]._id);
    setUpdateExt(filter[0].ext);
    setUpdateUnitSuite(filter[0].unitSuite);
    if (filter[0].monthlyDonationAmount !== null) {
      setUpdateMonthlyAmount1(
        filter[0].monthlyDonationAmount[0] !== "undefined" &&
          filter[0].monthlyDonationAmount[0] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[0])
          : ""
      );
      setUpdateMonthlyAmount2(
        filter[0].monthlyDonationAmount[1] !== "undefined" &&
          filter[0].monthlyDonationAmount[1] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[1])
          : ""
      );
      setUpdateMonthlyAmount3(
        filter[0].monthlyDonationAmount[2] !== "undefined" &&
          filter[0].monthlyDonationAmount[2] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[2])
          : ""
      );
      setUpdateMonthlyAmount4(
        filter[0].monthlyDonationAmount[3] !== "undefined" &&
          filter[0].monthlyDonationAmount[3] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[3])
          : ""
      );
      setUpdateMonthlyAmount5(
        filter[0].monthlyDonationAmount[4] !== "undefined" &&
          filter[0].monthlyDonationAmount[4] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[4])
          : ""
      );
      setUpdateMonthlyAmount6(
        filter[0].monthlyDonationAmount[5] !== "undefined" &&
          filter[0].monthlyDonationAmount[5] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[5])
          : ""
      );
      setUpdateMonthlyAmount7(
        filter[0].monthlyDonationAmount[6] !== "undefined" &&
          filter[0].monthlyDonationAmount[6] !== ""
          ? currencyFormat2(filter[0].monthlyDonationAmount[6])
          : ""
      );
    } else {
      setUpdateMonthlyAmount1("");
      setUpdateMonthlyAmount2("");
      setUpdateMonthlyAmount3("");
      setUpdateMonthlyAmount4("");
      setUpdateMonthlyAmount5("");
      setUpdateMonthlyAmount6("");
      setUpdateMonthlyAmount7("");
    }

    let amountMonthly = filter[0].monthlyDonationAmount;
    if (amountMonthly !== null) {
      var amountMonthlyData = {};
      amountMonthly.forEach(function (e, i) {
        amountMonthlyData[`namemonthly${i + 1}`] = e[0];
      });
      setUpdateMonthlyAmount(amountMonthly);
      setUpdateMonthlyName(amountMonthlyData);
    }

    setUpdateCreateTaxReceipt(`${filter[0].createTaxReceipt}`);
    setUpdateTaxReceiptEligible(`${filter[0].taxReceiptEligible}`);
    setUpdateProcessFee(`${filter[0].processFee}`);
    setUpdateProcessFeeAmount(
      filter[0].processFeeAmount !== "undefined"
        ? filter[0].processFeeAmount
        : ""
    );
    setUpdateLotteryTicket(`${filter[0].lotteryTicket}`);
    setUpdateFiftyTicket(`${filter[0].fiftyTicket}`);
    setUpdateCreateLotteryTicket(`${filter[0].createLotteryTicket}`);
  };

  // {console.log(fiftyTicket,"fiftyTicket",lotteryTicket,"update",updatefiftyTicket, "updatelotteryTicket",updatelotteryTicket)}

  const onDonationAmountDelete = (index) => {
    let updated = addDonationAmount.filter((data, id) => {
      return index != id;
    });
    setAddDonationAmount(updated);
  };

  const updateHandleFile = (event) => {
    let img = event.target.files[0];
    setAbc(img);
    setIsUpload(event.target.files);
  };

  const openUpdateSecondForm = async () => {
    //setImageUploadType('upload');
    let errorCheckforImage = checkValidationFormatBeforeSave("upload");
    if (errorCheckforImage === true) {
      setUpdateSecond(true);
    } else {
      setUpdateSecond(false);
    }
  };
  const closeUpdateSecondForm = async () => {
    setUpdateSecond(false);
  };

  const deleteLogo = () => {
    //////
    setIsAddUpload("");
    setCharityLogo("");
  };

  const updateDeleteLogo = () => {
    //////
    setUpdateCharityLogo("");
    setIsUpload("");
    setAbc("");
  };

  const updateCharity = async () => {
    let updateStartingNumber = "";
    if (
      updateStringOne !== "" &&
      updateStringOne !== null &&
      typeof updateStringOne !== undefined
    ) {
      updateStartingNumber = updateStringOne + updateStringTwo;
    } else if (
      updateStringOne === "" ||
      updateStringOne === null ||
      typeof updateStringOne === undefined
    ) {
      updateStartingNumber = updateStringTwo;
    } else if (
      updateStringTwo === "" ||
      updateStringTwo === null ||
      typeof updateStringTwo === undefined
    ) {
      updateStartingNumber = updateStringOne;
    }

    setUpdateStartingTicketNumber(updateStartingNumber);
    let errorCheckFoTicketNumber = await checkValidDataFields("update");
    if (errorCheckFoTicketNumber) {
      const formData = new FormData();
      formData.append("charityName", updateCharityName);
      formData.append("contactName", updateContactName);
      formData.append("email", updateEmail);
      formData.append("phone", updatePhone);
      formData.append("address", updateAddress);
      formData.append("province", updateProvince);
      formData.append("city", updateCity);
      formData.append("country", updateCountry);
      formData.append("postal_code", updatePostal_Code);
      formData.append("MID", updateMID);
      formData.append("ext", updateExt);
      formData.append("language", updateLanguage);
      formData.append("color", updateColor);
      formData.append("fontColor", updateFontColor);
      formData.append("unitSuite", updateUnitSuite);
      formData.append("donationFrequency", updateDonationFrequency);
      saveUpdateAmount.map((currElement, index) => {
        formData.append("donation_amount[]", currElement);
      });
      formData.append("createTaxReceipt", updateCreateTaxReceipt);
      formData.append("taxReceiptEligible", updateTaxReceiptEligible);
      formData.append("processFee", updateProcessFee);
      formData.append(
        "processFeeAmount",
        updateProcessFee === "1"
          ? parseFloat(updateProcessFeeAmount.replace(/,/g, ""))
          : ""
      );
      formData.append("lotteryTicket", updatelotteryTicket);
      formData.append("fiftyTicket", updatefiftyTicket);
      formData.append("charityType", updateCharityType);
      formData.append("createLotteryTicket", updatecreateLotteryTicket);
      formData.append("startingTicketNumber", updateStartingNumber);
      formData.append("lotteryTemplateName", "lotteryTicket");
      formData.append(
        "sendGridLotteryTemplateId",
        updateSendGridLotteryTemplateId
      );
      formData.append("sendGridTemplateId", updateSendGridTemplateId);
      formData.append("templateName", "taxReciept");
      formData.append("donationEmailSendGridId", updateDonationEmailSendGridId);
      formData.append("lotteryEmailSendGridId", updateLotteryEmailSendGridId);
      formData.append("rulesRegUrl", updateRulesRegUrl);

      if (updateDonationFrequency == "Monthly") {
        saveUpdateMonthlyAmount.map((currElement, index) => {
          formData.append("monthlyDonationAmount[]", currElement);
        });
      } else {
        formData.append("monthlyDonationAmount[]", "");
      }

      if (abc) {
        formData.append("charityLogo", abc, abc.name);
      }

      //console.log(formData.getAll("UpdateLogo"), "formData");

      updateCharityData(formData, updateID).then((res) => {
        setMsg(`${cName} Successfully Updated `);
        setTimeout(() => {
          setMsg("");
        }, 4000);
        setUpdateModalState(false);
        setAbc("");
        setIsUpload("");
        getCharity();
        setUpdateAmount([]);
        setUpdateName1({});
        setUpdateAmount1("");
        setUpdateAmount2("");
        setUpdateAmount3("");
        setUpdateAmount4("");
        setUpdateAmount5("");
        setUpdateAmount6("");
        setUpdateAmount7("");
        setUpdateAmount8("");
        setUpdateMonthlyAmount([]);
        setUpdateMonthlyName({});
        setUpdateMonthlyAmount1("");
        setUpdateMonthlyAmount2("");
        setUpdateMonthlyAmount3("");
        setUpdateMonthlyAmount4("");
        setUpdateMonthlyAmount5("");
        setUpdateMonthlyAmount6("");
        setUpdateMonthlyAmount7("");
        setFormData("");
        setUpdateSecond(false);
        setUpdateColor("");
        setUpdateFontColor("");
        setUpdateCreateTaxReceipt("0");
        setUpdateTaxReceiptEligible("0");
        setUpdateProcessFee("0");
        setUpdateProcessFeeAmount("");
        setUpdateLotteryTicket("No");
        setUpdateFiftyTicket("No");
        setUpdateCreateLotteryTicket("No");
        setUpdateCharityType("Donations");
        setUpdateLotteryTemplateName("");
        setUpdateSendGridLotteryTemplateId("");
        setUpdateSendGridTemplateId("");
        setUpdateTemplateName("");
        setUpdateRulesRegUrl("");
      });
    }
  };

  // update Amount

  const updateAmountValue = () => {
    setUpdateDonationModal(!updateDonationModal);
  };

  const updateAmount = async () => {
    const data = {
      amount: updateDonation_amount,
    };
    // console.log(data, "amountdata");
    // console.log(updateAmountID, "amountID");
    updateAmountData(data, updateAmountID).then((res) => {
      // console.log(res);
      setUpdateDonationModal(false);
    });
  };

  const deleteCharity = async (id) => {
    const data = {
      isActive: true,
    };
    deleteCharityData(data, id).then((res) => {
      getCharity();
      setMsg(`Charity Successfully Deleted `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
    });
  };

  ///Validate Data Fields before addition or updation of charity
  const checkValidDataFields = async (formType) => {
    setError({});
    let errorValueData = {};
    let errorText = "";
    let startTicketNo = "";
    let taxReceipt = "";
    //let donationTempName= "";
    let donationSendGridTempId = "";
    let lotteryTempName = "";
    let lotterySendGridTempId = "";
    let createLotteryTicketVal = "";
    let donationEmailSendGridIdValue = "";
    let lotteryEmailSendGridIdValue = "";
    let rulesRegUrlValue = "";
    let charityTypeValue = "";
    if (formType === "add") {
      //  startTicketNo=startingTicketNumber;
      taxReceipt = createTaxReceipt;
      //donationTempName= templateName;
      donationSendGridTempId = sendGridTemplateId;
      //  lotteryTempName= lotteryTemplateName;
      lotterySendGridTempId = sendGridLotteryTemplateId;
      createLotteryTicketVal = createLotteryTicket;
      lotteryEmailSendGridIdValue = lotteryEmailSendGridId;
      donationEmailSendGridIdValue = donationEmailSendGridId;
      rulesRegUrlValue = rulesRegUrl;
      charityTypeValue = charityType;
    } else if (formType === "update") {
      // startTicketNo=updateStartingTicketNumber;
      taxReceipt = updateCreateTaxReceipt;

      donationSendGridTempId = updateSendGridTemplateId;

      lotterySendGridTempId = updateSendGridLotteryTemplateId;
      createLotteryTicketVal = updatecreateLotteryTicket;
      lotteryEmailSendGridIdValue = updateLotteryEmailSendGridId;
      donationEmailSendGridIdValue = updateDonationEmailSendGridId;
      rulesRegUrlValue = updateRulesRegUrl;
      charityTypeValue = updateCharityType;
    }

    if (taxReceipt === "1") {
      if (
        donationSendGridTempId === "" ||
        donationSendGridTempId === null ||
        donationSendGridTempId === undefined
      ) {
        errorText = "Donation SendGrid Template Id is required.";
        errorValueData = {
          ...errorValueData,
          SendGridTemplateIdVal: errorText,
        };
        setError(errorValueData);
      }
      if (
        donationEmailSendGridIdValue === "" ||
        donationEmailSendGridIdValue === null ||
        donationEmailSendGridIdValue === undefined
      ) {
        errorText = "Donation Email SendGrid Id is required.";
        errorValueData = {
          ...errorValueData,
          donationEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          donationEmailSendGridIdValue
        )
      ) {
        errorText = "Invalid email address.";
        errorValueData = {
          ...errorValueData,
          donationEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      }
    }
    if (charityTypeValue === "Lottery" || charityTypeValue === "Both") {
     if(fiftyTicket === "Yes" || lotteryTicket === "Yes" || updatefiftyTicket === "Yes" || updatelotteryTicket === "Yes"){
        if (
          rulesRegUrlValue === "" ||
          rulesRegUrlValue === undefined ||
          rulesRegUrlValue === null
        ) {
          errorText = "Rules and Regulations URL is required.";
          errorValueData = {
            ...errorValueData,
            rulesRegUrlVal: errorText,
          };
          setError(errorValueData);
        }
      }
    }
    if (createLotteryTicketVal === "Yes") {
      if (
        lotterySendGridTempId === "" ||
        lotterySendGridTempId === null ||
        lotterySendGridTempId === undefined
      ) {
        errorText = "Lottery SendGrid Template Id is required.";
        errorValueData = {
          ...errorValueData,
          SendGridLotteryTemplateIdVal: errorText,
        };
        setError(errorValueData);
      }

      if (
        lotteryEmailSendGridIdValue === "" ||
        lotteryEmailSendGridIdValue === null ||
        lotteryEmailSendGridIdValue === undefined
      ) {
        errorText = "Lottery Email SendGrid Id is required.";
        errorValueData = {
          ...errorValueData,
          lotteryEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          lotteryEmailSendGridIdValue
        )
      ) {
        errorText = "Invalid email address.";
        errorValueData = {
          ...errorValueData,
          lotteryEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      }
    }
    // console.log(errorValueData, "shdgskdgsdherrorValueData//////////");
    if (
      Object.keys(errorValueData).length === 0 &&
      errorValueData.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  };
  const saveHandleChange = (e) => {
    setDonationAmount("");
    const val = e.target.value;
    if (val == 0 || null) {
      setMsg(`You Can Not Add 0 Amount! `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
    } else {
      setDonationAmount(val);
    }
  };

  const handleDonationAmount = () => {
    if (donationAmount == "") {
      setMsg(`Amount Value should be 1 `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
    } else {
      let amnt = [...saveamount];
      let chk = amnt.length && amnt.includes(donationAmount);
      if (chk) {
        setMsg(`You Can Not Add the Same Amount Again! `);
        setTimeout(() => {
          setMsg("");
        }, 4000);
      } else {
        setamount((pre) => [...pre, donationAmount]);
      }
      setDonationAmount("");
    }
  };

  const clearAmount = () => {
    setamount([]);
    // console.log("amount clear");
  };
  const clearCharityData = () => {
    setamount([]);
    setMonthlyamount([]);
    setName1({});
    setNameMonthly({});
    setModalState(false);
    setAddSecond(false);
    setCharityType("Donations");
    setTemplateName("");
    setSendGridTemplateId("");
    setlotteryTemplateName("");
    setSendGridLotteryTemplateId("");
    setDonationEmailSendGridId("");
    setLotteryEmailSendGridId("");
    setRulesRegUrl("");
  };

  const clearUpdateCharityData = () => {
    setUpdateModalState(false);
    setUpdateAmount([]);
    setUpdateMonthlyAmount([]);
    setUpdateName1({});
    setUpdateMonthlyName({});
    setUpdateAmount1("");
    setUpdateAmount2("");
    setUpdateAmount3("");
    setUpdateAmount4("");
    setUpdateAmount5("");
    setUpdateAmount6("");
    setUpdateAmount7("");
    setUpdateAmount8("");
    setUpdateSecond(false);
    setUpdateCharityType("Donations");
    setUpdateColor("");
    setUpdateFontColor("");
    setUpdateLotteryTemplateName("");
    setUpdateSendGridLotteryTemplateId("");
    setUpdateSendGridTemplateId("");
    setUpdateTemplateName("");
    setUpdateDonationEmailSendGridId("");
    setUpdateRulesRegUrl("");
    setUpdateLotteryEmailSendGridId("");
  };

  const getUpdatedAmount = (index) => {
    const upd = [...updateDonation_amount];
    const updated = upd.filter((data, id) => index != id);
    setUpdateDonation_amount(updated);
  };

  const updateHandleChange = (e) => {
    setAmountValue(e.target.value);
    // console.log(e.target.value, "e.target.value");
  };

  const updateDonationAmount = () => {
    let amnt1 = [];
    updateDonation_amount.map((val) => {
      val.map((a) => amnt1.push(a));
    });
    let chk = amnt1.length && amnt1.includes(AddAmountValue);
    if (chk) {
      setMsg(`You Can Not Add the Same Amount Again! `);
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } else {
      setUpdateDonation_amount((pre) => [...pre, [AddAmountValue]]);
    }
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanNum = phoneNumber?.toString().replace(/\D/g, "");
    if (phoneNumber) {
      const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        return +match[1] + "-" + (match[2] ? match[2] + "-" : "") + match[3];
      }
    }

    return cleanNum;
  }
  const checkValidationFormatBeforeSave = (imageUploadType) => {
    setError({});
    // let isError = false;
    let errorValue = {};
    //MAx size to upload an image
    const MAX_FILE_SIZE = 2000; // 2MB

    let errorText = "";
    let image = "";
    let colorValue = "";
    let fontColorValue = "";
    if (imageUploadType === "add") {
      image = charityLogo;
      if (color !== "" || color !== undefined || color !== null) {
        colorValue = color;
      }
      if (fontColor !== "" || fontColor !== undefined || fontColor !== null) {
        fontColorValue = fontColor;
      }
    } else if (imageUploadType === "upload") {
      if (abc) {
        image = abc;
      } else {
        image = updateCharityLogo;
      }
      if (
        updateColor !== "" ||
        updateColor !== undefined ||
        updateColor !== null
      ) {
        colorValue = updateColor;
      }
      if (
        updateFontColor !== "" ||
        updateFontColor !== undefined ||
        updateFontColor !== null
      ) {
        fontColorValue = updateFontColor;
      }
    }
    //Calculate image size in kilobytes

    const fileSizeKiloBytes = image.size / 1024;

    if (!image) {
      errorText = "Charity Logo is required.";

      errorValue = {
        ...errorValue,
        Logo: errorText,
      };
      setError(errorValue);
    } else if (image !== updateCharityLogo) {
      if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        errorText = "Please select valid image.";
        errorValue = {
          ...errorValue,
          Logo: errorText,
        };
        setError(errorValue);
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        errorText = "File size is greater than maximum limit.";
        errorValue = {
          ...errorValue,
          Logo: errorText,
        };
        setError(errorValue);
      }
    }
    if (
      colorValue !== "" &&
      colorValue !== null &&
      colorValue !== undefined &&
      fontColorValue !== null &&
      fontColorValue !== undefined &&
      fontColorValue !== "" &&
      fontColorValue === colorValue
    ) {
      errorText = "Color and font color could not be same.";
      errorValue = {
        ...errorValue,
        Color: errorText,
        FontColor: errorText,
      };
      setError(errorValue);
    }

    if (
      fontColorValue !== "" ||
      fontColorValue !== null ||
      fontColorValue !== undefined
    ) {
      if (
        fontColor === "#FFFFFF" ||
        fontColor === "#ffffff" ||
        fontColor === "#f7f7f7"
      ) {
        errorText = "Font color could not be white.";
        errorValue = {
          ...errorValue,
          FontColor: errorText,
        };
        // se
      }
      setError(errorValue);
    }

    if (
      Object.keys(errorValue).length === 0 &&
      errorValue.constructor === Object
    ) {
      return true;
    } else {
      console.log("Error");
      return false;
    }
  };

  const handleAmount = (e) => {
    const { name, value } = e.target;
    var hasVal = Object.values(name1).includes(value);
    if (value == "") {
      let amount = [];
      let data = { ...name1, [name]: value };
      Object.keys(data).map((key) =>
        data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
      );
      setamount(amount);
      setName1(data);
    } else if (hasVal) {
      e.target.value = "";
      setMsgAmount(`You Can Not Add the Same Amount Again! `);
      setTimeout(() => {
        setMsgAmount("");
      }, 3000);
    } else if (value == 0) {
      e.target.value = "";
      setMsgAmount(`You Can Not Add1 0 Amount! `);
      setTimeout(() => {
        setMsgAmount("");
      }, 4000);
    } else {
      let amount = [];
      let data = { ...name1, [name]: value };
      Object.keys(data).map((key) =>
        data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
      );
      setamount(amount);
      setName1(data);
      e.target.value = currencyFormat(value);
    }
  };
  const handleMonthlyAmount = (e) => {
    const { name, value } = e.target;
    var hasVal = Object.values(nameMonthly).includes(value);
    if (value == "") {
      let amount = [];
      let data = { ...nameMonthly, [name]: value };
      Object.keys(data).map((key) =>
        data[key] && data[key] !== 0 ? amount.push([Number(data[key])]) : ""
      );
      setMonthlyamount(amount);
      setNameMonthly(data);
    } else if (hasVal) {
      e.target.value = "";
      setMsgMonthlyAmount(`You Can Not Add the Same Amount Again! `);
      setTimeout(() => {
        setMsgMonthlyAmount("");
      }, 3000);
    } else if (value == 0) {
      e.target.value = "";
      setMsgMonthlyAmount(`You Can Not Add1 0 Amount! `);
      setTimeout(() => {
        setMsgMonthlyAmount("");
      }, 4000);
    } else {
      let amount = [];
      let data = { ...nameMonthly, [name]: value };
      Object.keys(data).map((key) =>
        data[key] && data[key] !== 0 ? amount.push([Number(data[key])]) : ""
      );
      setMonthlyamount(amount);
      setNameMonthly(data);
      e.target.value = currencyFormat(value);
    }
  };

  const handleUpdateAmount = (e) => {
    const { name, value } = e.target;

    var hasVal = Object.values(updateName1).includes(value);
    // if (hasVal) {
    //   e.target.value = "";
    //   setUpdateMsg(`You Can Not Add the Same Amount Again! `);
    //   setTimeout(() => {
    //     setUpdateMsg("");
    //   }, 3000);
    // } else {
    if (value == 0) {
      e.target.value = "";
      setUpdateMsg(`You Can Not Add 0 Amount! `);
      setTimeout(() => {
        setUpdateMsg("");
      }, 4000);
    }
    let amount = [];
    let data = { ...updateName1, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setUpdateAmount(amount);
    setUpdateName1(data);
    if (name === "name1") {
      setUpdateAmount1(currencyFormat(value));
    }
    if (name === "name2") {
      setUpdateAmount2(currencyFormat(value));
    }
    if (name === "name3") {
      setUpdateAmount3(currencyFormat(value));
    }
    if (name === "name4") {
      setUpdateAmount4(currencyFormat(value));
    }
    if (name === "name5") {
      setUpdateAmount5(currencyFormat(value));
    }
    if (name === "name6") {
      setUpdateAmount6(currencyFormat(value));
    }
    if (name === "name7") {
      setUpdateAmount7(currencyFormat(value));
    }
    // if (name === "name8") {
    //   setUpdateAmount8(currencyFormat(value));
    // }
    //}
  };

  const handleUpdateMonthlyAmount = (e) => {
    const { name, value } = e.target;

    var hasVal = Object.values(updateMonthlyName).includes(value);

    if (value == 0) {
      e.target.value = "";
      setUpdateMonthlyAmountMsg(`You Can Not Add 0 Amount! `);
      setTimeout(() => {
        setUpdateMonthlyAmountMsg("");
      }, 4000);
    }
    let amount = [];
    let data = { ...updateMonthlyName, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setUpdateMonthlyAmount(amount);
    setUpdateMonthlyName(data);
    if (name === "namemonthly1") {
      setUpdateMonthlyAmount1(currencyFormat(value));
    }
    if (name === "namemonthly2") {
      setUpdateMonthlyAmount2(currencyFormat(value));
    }
    if (name === "namemonthly3") {
      setUpdateMonthlyAmount3(currencyFormat(value));
    }
    if (name === "namemonthly4") {
      setUpdateMonthlyAmount4(currencyFormat(value));
    }
    if (name === "namemonthly5") {
      setUpdateMonthlyAmount5(currencyFormat(value));
    }
    if (name === "namemonthly6") {
      setUpdateMonthlyAmount6(currencyFormat(value));
    }
    if (name === "namemonthly7") {
      setUpdateMonthlyAmount7(currencyFormat(value));
    }
  };

  const columns = [
    {
      name: "Charity",
      selector: (row) => row.charityName ?? "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Address",
      selector: (row) =>
        `${row && row.address ? row.address : ""} ${
          row.unitSuite ? "Suite" : ""
        } ${row.unitSuite ? row.unitSuite : ""}, ${
          row.city ? row.city : ""
        },${" "}
      ${row.province ? row.province : ""}, ${
          row.country ? row.country : ""
        },${" "}
      ${row.postal_code ? row.postal_code : ""}` ?? "",
    },
    {
      name: "Contact Name",
      selector: (row) => row.contactName ?? "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: "Phone",
      selector: (row) =>
        `${formatPhoneNumber(row.phone) ?? ""} ${row.ext ? "Ext." : ""} ${
          row?.ext
        }`,
      sortable: true,
      filterable: true,
    },

    {
      name: "Actions",
      selector: (row) => row._id,
      cell: (row) => (
        <>
          <span
            className="edit"
            onClick={() => {
              selectCharity(row._id, row.charityName);
            }}
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </span>{" "}
          <span
            className="delete"
            onClick={
              () =>
                Swal.fire({
                  title: "Are you sure you want to delete this Charity?",
                  showConfirmButton: true,
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                  width:"470px",
                  height:"380px",
                  icon: "warning",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteCharity(row._id)
                    Swal.fire({ title: "Charity deleted!", showConfirmButton: true, icon: "success",  width:"470px",
                    height:"380px"});
                  } else {
                  }
                })
              // window.confirm("Are you sure you want to delete this Charity?") &&
              // deleteCharity(row._id)
            }
          >
            <i className="fa fa-trash-o" aria-hidden="true"></i>
          </span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  function currencyFormat(num) {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function currencyFormat2(num) {
    return typeof num === "undefined"
      ? ""
      : num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function postalCodeFormat(string) {
    let foo = string.split(" ").join(""); // remove hyphens
    if (foo.length > 0) {
      foo = foo.match(new RegExp(".{1,3}", "g")).join(" ");
    }
    return foo;
  }
  const normalizeInput = (value) => {
    const input = value.replace(/\D/g, "").substring(0, 10);

    // Divide numbers in 3 parts :"(123) 456-7890"
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      return `${first}-${middle}-${last}`;
    } else if (input.length > 3) {
      return `${first}-${middle}`;
    } else if (input.length >= 0) {
      return input;
    }
  };
  const openImportExcelModal = () => {
    setImportExcelModal(!importExcelModel);
    setFile("");
  };
  const handleImportExcelOnChange = (e) => {
    let file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setFile(e.target.files[0]);
      setFileNamemsg(file.name);
    } else {
      alert("Invalid File");
    }
  };
  const handleImportExcelOnSubmit = async () => {
    //e.preventDefault();

    if (file && file.type === "text/csv") {
      const formData = new FormData();
      formData.append("importCsv", file, file.name);
      importCsvCharityData(formData).then((res) => {
        if (res.data.status == true) {
          getCharity();
          setMsg(`Successfully CSV uploaded `);
          setFile("");
          setImportExcelModal(false);
          setFileNamemsg("");
          setTimeout(() => {
            setMsg("");
          }, 4000);
        } else {
          alert("File format incorrect, please correct");
        }
      });
    } else {
      //getRetailer();
      alert("CSV File Invalid");
    }
  };
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="charityContainer p-3 customMain_container">
              {setModal ? (
                <div className="ModalBackground">
                  <div className="modalContainer customModalContainer">
                    <div className="modalHeader mb-5">
                      <h3>Add Charity</h3>
                    </div>

                    <button className="closeBtn" onClick={clearCharityData}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <form
                      className="bodycstmscroll"
                      action="/charity"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="container-fluid">
                        {addSecond === false ? (
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Charity Name:</label>
                                <input
                                  type="text"
                                  name="charityName"
                                  maxLength={50}
                                  className={`form-control ${
                                    errors.charityName && "invalid"
                                  }`}
                                  {...register("CharityName", {
                                    required: "Charity Name is required",
                                  })}
                                  onChange={(e) =>
                                    setCharityName(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={charityName}
                                />
                                {errors.CharityName && (
                                  <small className="text-danger">
                                    {errors.CharityName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Contact Name:</label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  name="contactName"
                                  className={`form-control ${
                                    errors.ContctName && "invalid"
                                  }`}
                                  {...register("ContactName", {
                                    required: "Contact Name is required",
                                  })}
                                  onChange={(e) =>
                                    setContactName(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={contactName}
                                />
                                {errors.ContactName && (
                                  <small className="text-danger">
                                    {errors.ContactName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  type="text"
                                  name="email"
                                  className={`form-control ${
                                    errors.Email && "invalid"
                                  }`}
                                  {...register("Email", {
                                    required: "Email is required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  onChange={(e) =>
                                    setEmail(Validator.ltrim(e.target.value))
                                  }
                                  value={email}
                                />
                                {errors.Email && (
                                  <small className="text-danger">
                                    {" "}
                                    {errors.Email.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-10 col-8">
                              <div className="form-group">
                                <label>Phone Number:</label>
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  maxLength="13"
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[0-9]");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  {...register("PhoneNumber", {
                                    required: "Phone Number is required",

                                    pattern: {
                                      value:
                                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                      message: "Only numbers are allowed",
                                    },
                                  })}
                                  onChange={(e) =>
                                    setPhone(
                                      normalizeInput(
                                        Validator.ltrim(e.target.value)
                                      )
                                    )
                                  }
                                  value={phone}
                                />
                                {errors.PhoneNumber && (
                                  <small className="text-danger">
                                    {errors.PhoneNumber.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-2 col-4">
                              <label>Ext.</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="ext"
                                  maxLength="5"
                                  className="form-control"
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[0-9]");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  {...register("Ext", {
                                    // required: "Ext is required",
                                  })}
                                  onChange={(e) => setExt(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-10 col-8">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  maxLength={55}
                                  name="address"
                                  className="form-control"
                                  {...register("Address", {
                                    required: "Address is required",
                                  })}
                                  onChange={(e) =>
                                    setAddress(Validator.ltrim(e.target.value))
                                  }
                                  value={address}
                                />
                                {errors.Address && (
                                  <small className="text-danger">
                                    {errors.Address.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-2 col-4">
                              <label>Unit/Suite</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="uintSuite"
                                  className="form-control"
                                  maxLength="5"
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );

                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  {...register("UnitSuite", {
                                    pattern: {
                                      value: /^[a-zA-Z0-9]+$/,
                                      message:
                                        "Unit/Suite should be alphanumeric",
                                    },
                                  })}
                                  onChange={(e) =>
                                    setUnitSuite(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={unitSuite}
                                />
                                {errors.UnitSuite && (
                                  <small className="text-danger">
                                    {errors.UnitSuite.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>City:</label>
                                <input
                                  type="text"
                                  name="city"
                                  maxLength={50}
                                  className="form-control"
                                  {...register("City", {
                                    required: "City is required",
                                  })}
                                  onChange={(e) =>
                                    setCity(Validator.ltrim(e.target.value))
                                  }
                                  value={city}
                                />
                                {errors.City && (
                                  <small className="text-danger">
                                    {errors.City.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Province:</label>
                                <label
                                  htmlFor="provincefirst_n"
                                  className="arrowstickshowssh"
                                >
                                  <input
                                    id="provincefirst_n"
                                    type="text"
                                    name="province"
                                    className="form-control"
                                    list="browsers"
                                    {...register("Province", {
                                      required: "Province is required",
                                    })}
                                    onChange={(e) =>
                                      setProvince(
                                        Validator.ltrim(e.target.value)
                                      )
                                    }
                                    value={province}
                                  />
                                  <datalist id="browsers">
                                    {provinceList &&
                                      provinceList.map((list, id) =>
                                        list.province.map((res) => {
                                          return <option value={res} />;
                                        })
                                      )}
                                  </datalist>
                                </label>
                                {errors.Province && (
                                  <small className="text-danger">
                                    {errors.Province.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Country:</label>
                                <input
                                  type="text"
                                  name="country"
                                  className="form-control"
                                  {...register("Country", {
                                    required: "Country is required",
                                  })}
                                  value={country}
                                  onChange={(e) =>
                                    setCountry(Validator.ltrim(e.target.value))
                                  }
                                />
                                {errors.Country && (
                                  <small className="text-danger">
                                    {errors.Country.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Postal Code:</label>
                                <input
                                  type="text"
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  name="postal_code"
                                  className="form-control"
                                  maxLength="7"
                                  minLength="7"
                                  {...register("PostalCode", {
                                    required: "Postal Code is required",
                                    pattern: {
                                      //value: /^[a-zA-Z0-9]+$/,
                                      // message:
                                      //     "Postal Code should be alphanumeric",
                                    },
                                  })}
                                  onChange={(e) =>
                                    setPostal_Code(
                                      postalCodeFormat(
                                        Validator.ltrim(e.target.value)
                                      )
                                    )
                                  }
                                  value={postal_code}
                                />
                                {errors.PostalCode && (
                                  <small className="text-danger">
                                    {errors.PostalCode.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Account Name:</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="MID"
                                  maxLength="16"
                                  {...register("MID", {
                                    //required: "Account Name is required",
                                  })}
                                  onChange={handleMID}
                                  value={MID}
                                />
                                {errors.MID && (
                                  <small className="text-danger">
                                    {errors.MID.message}
                                  </small>
                                )}
                                <small style={{ color: "red" }}>{msg}</small>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <div class="custm-mb1">
                                <label>
                                  Upload Logo:
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="input-group mt-2 cstmchangeflexlist">
                                  <div className="custom-file overflow-hidden">
                                    <input
                                      type="file"
                                      className="custom-file-input "
                                      name="charityLogo"
                                      id="files"
                                      accept=".jpg,.jpeg,.png,"
                                      // {...register("Logo", {
                                      //   required: "Charity Logo is required",
                                      // })}
                                      onChange={handleFile}
                                    />
                                    <label
                                      className="custom-file-label text-size textNowWrap"
                                      for="files"
                                    >
                                      Upload Logo (Maximum Size is 2MB)
                                    </label>
                                  </div>
                                  <div className="Position-relative custmImgPos">
                                    {isAddUpload &&
                                      [...isAddUpload].map((file) => (
                                        <div className="cstmliste-sideup">
                                          <img
                                            src={URL.createObjectURL(file)}
                                            alt="logo"
                                          />
                                        </div>
                                      ))}
                                    {isAddUpload.length > 0 ? ( //////
                                      <a
                                        title="Delete Logo"
                                        class="text-dark crosPost"
                                        aria-hidden="true"
                                        onClick={() => deleteLogo()}
                                      >
                                        <i class="fa fa-times" />
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              {error.Logo && (
                                <small className="text-danger">
                                  {error.Logo}
                                </small>
                              )}
                            </div>

                            <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt-2">
                              <div className="form-group formgrpcustom">
                                <label>Language:</label>
                                <input
                                  type="radio"
                                  id="engselectlan"
                                  name={`fav_language1`}
                                  value={`English`}
                                  checked={language === "English"}
                                  onChange={(e) => setLanguage(e.target.value)}
                                  className="radioButton"
                                />

                                <label
                                  for="engselectlan"
                                  className="radioLable"
                                >
                                  English
                                </label>

                                <input
                                  type="radio"
                                  id="langfre"
                                  className="radioInput"
                                  name={`fav_language1`}
                                  value={`French`}
                                  checked={language === "French"}
                                  onChange={(e) => setLanguage(e.target.value)}
                                />

                                <label for="langfre" className="radioLable2">
                                  French
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group1 custm-mb1">
                                <label>Color:</label>
                                <input
                                  type="color"
                                  name="color"
                                  className="form-control"
                                  onChange={(e) =>
                                    setColor(Validator.ltrim(e.target.value))
                                  }
                                  value={color}
                                />
                              </div>
                              {error.Color && (
                                <small className="text-danger">
                                  {error.Color}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group1 custm-mb1">
                                <label>Font Color:</label>
                                <input
                                  type="color"
                                  name="fontColor"
                                  className="form-control"
                                  onChange={(e) =>
                                    setFontColor(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={fontColor}
                                />
                              </div>
                              {error.FontColor && (
                                <small className="text-danger">
                                  {error.FontColor}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <div className="form-group cstm_labelsdf">
                                <label>Donation Frequency:</label>
                                <div className="cstm_lblsdf">
                                  <label for="Donation" className="no-req-sign">
                                    <input
                                      type="radio"
                                      id="Donation"
                                      name={`fav_Frequency`}
                                      value={`OneTime`}
                                      checked={donationFrequency === "OneTime"}
                                      onChange={(e) => {
                                        setDonationFrequency(e.target.value);
                                        setisDonationMonthly(false);
                                      }}
                                    />
                                    <span>One-Time only</span>
                                  </label>

                                  <label
                                    for="Donationm"
                                    className="no-req-sign"
                                  >
                                    <input
                                      type="radio"
                                      id="Donationm"
                                      className=""
                                      name={`fav_Frequency`}
                                      value={`Monthly`}
                                      checked={donationFrequency === "Monthly"}
                                      onChange={(e) => {
                                        setDonationFrequency(e.target.value);
                                        setisDonationMonthly(true);
                                      }}
                                    />
                                    <span>One-Time and Monthly</span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                  <div className="form-group mt-3 mt-lg-0">
                                    <label>One-Time Donation Amount:</label>
                                    <div className="Flex-input flex-inutadd">
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name1"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name2"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name3"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name4"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name5"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name6"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="flexcstminput1">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onBlur={handleAmount}
                                          name="name7"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      {/* <div className="input-icon">
                                      <i>$</i>
                                    </div>
                                    <input
                                      onBlur={handleAmount}
                                      name="name8"
                                      className="form-control"
                                      type="text"
                                      maxLength="4"
                                      onKeyPress={function (event) {
                                        var regex = new RegExp("^[0-9]");
                                        var key = String.fromCharCode(
                                          !event.charCode
                                            ? event.which
                                            : event.charCode
                                        );
                                        if (!regex.test(key)) {
                                          event.preventDefault();
                                          return false;
                                        }
                                      }}
                                    /> */}
                                    </div>
                                    <small className="text-danger">
                                      {msgAmount}
                                    </small>
                                  </div>
                                </div>
                                {isDonationMonthly ? (
                                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="form-group1">
                                      <label>Monthly Donation Amount:</label>
                                      <div className="Flex-input flex-inutadd">
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly1"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly2"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly3"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly4"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly5"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly6"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flexcstminput1">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onBlur={handleMonthlyAmount}
                                            name="namemonthly7"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <small className="text-danger">
                                        {msgMonthlyAmount}
                                      </small>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card-radio">
                                <div className="col-md-12">
                                  <h5>Choose Option :</h5>
                                  <div className="col-sm-12">
                                    <div className="form-group1 formgrpcustom customForm_G">
                                      <input
                                        type="radio"
                                        id="chooseoptsome"
                                        name={`charityType`}
                                        value={"Donations"}
                                        onChange={(e) =>
                                          e.target.value === "Donations"
                                            ? [
                                                setCharityType(e.target.value),
                                                setLotteryTicket("No"),
                                                setFiftyTicket("No"),
                                                setCreateLotteryTicket("No"),
                                                setSendGridTemplateId(""),
                                                setDonationEmailSendGridId(""),
                                                setTemplateName(""),
                                                setStartingTicketNumber(""),
                                                setStringOne(""),
                                                setStringTwo(""),
                                                setSendGridLotteryTemplateId(
                                                  ""
                                                ),
                                                setlotteryTemplateName(""),
                                                setLotteryEmailSendGridId(""),
                                                setRulesRegUrl(""),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailEdit(false),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                        checked={charityType === "Donations"}
                                        className="radioButton"
                                      />
                                      <label
                                        for="chooseoptsome"
                                        className="radioLable"
                                      >
                                        Donations
                                      </label>
                                    </div>

                                    <div className="form-group1 formgrpcustom customForm_G">
                                      <input
                                        type="radio"
                                        id="donaradiolot"
                                        className="radioInputLottery"
                                        name={`charityType`}
                                        value={"Lottery"}
                                        onChange={(e) =>
                                          e.target.value === "Lottery"
                                            ? [
                                                setCharityType(e.target.value),
                                                setCreateTaxReceipt("0"),
                                                setTaxReceiptEligible("0"),
                                                setSendGridTemplateId(""),
                                                setTemplateName(""),
                                                setStartingTicketNumber(""),
                                                setSendGridLotteryTemplateId(
                                                  ""
                                                ),
                                                setlotteryTemplateName(""),
                                                setLotteryEmailSendGridId(""),
                                                setRulesRegUrl(""),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailSendGridId(""),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                        checked={charityType === "Lottery"}
                                      />
                                      <label
                                        for="donaradiolot"
                                        className="radioLableLottery"
                                      >
                                        Lottery
                                      </label>
                                    </div>

                                    <div className="form-group1 formgrpcustom customForm_G">
                                      <input
                                        type="radio"
                                        id="donaradiolotboth"
                                        className="radioInputBoth"
                                        name={`charityType`}
                                        value={"Both"}
                                        checked={charityType === "Both"}
                                        onChange={(e) =>
                                          e.target.value === "Both"
                                            ? [
                                                setCharityType(e.target.value),
                                                setCreateTaxReceipt("0"),
                                                setSendGridTemplateId(null),
                                                setTemplateName(null),
                                                setTaxReceiptEligible("0"),
                                                setLotteryTicket("No"),
                                                setFiftyTicket("No"),
                                                setCreateLotteryTicket("No"),
                                                setDonationEmailSendGridId(""),
                                                setSendGridTemplateId(""),
                                                setTemplateName(""),
                                                setStartingTicketNumber(""),
                                                setSendGridLotteryTemplateId(
                                                  ""
                                                ),
                                                setlotteryTemplateName(""),
                                                setLotteryEmailSendGridId(""),
                                                setRulesRegUrl(""),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailEdit(false),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                      />
                                      <label
                                        for="donaradiolotboth"
                                        className="radioLable3"
                                      >
                                        Both
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                charityType === "Donations" ||
                                charityType === "Both"
                                  ? "col-lg-4 col-md-12 cardDisplay"
                                  : "col-lg-4 col-md-12 cardNotDisplay"
                              }
                            >
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Donation Tax Receipt</h5>
                                </div>
                                <div className="col-md-12 row">
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Create Tax Receipt:</label>
                                      <input
                                        type="radio"
                                        id="createtax_teceiptr"
                                        name={`createTaxReceipt_1`}
                                        value={"1"}
                                        checked={createTaxReceipt === "1"}
                                        onChange={(e) => [
                                          setCreateTaxReceipt(e.target.value),
                                          setDonationEmailSendGridId(
                                            "noreply@bnasmartpayment.com"
                                          ),
                                        ]}
                                        className="radioButton"
                                      />

                                      <label
                                        for="createtax_teceiptr"
                                        className="radioLable"
                                      >
                                        Yes
                                      </label>

                                      <input
                                        type="radio"
                                        id="createtax_teceiptr1"
                                        className="radioInput"
                                        name={`createTaxReceipt_1`}
                                        value={"0"}
                                        checked={createTaxReceipt === "0"}
                                        onChange={(e) => [
                                          setCreateTaxReceipt(e.target.value),
                                          setSendGridTemplateId(""),
                                          setTemplateName(""),
                                          setDonationEmailSendGridId(""),
                                        ]}
                                      />

                                      <label
                                        for="createtax_teceiptr1"
                                        className="radioLable2"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  {createTaxReceipt === "1" ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="form-group ">
                                        <label className="text-nowrap">
                                          Donation Email SendGrid ID:
                                        </label>
                                        <input
                                          name="donationEmailSendGridId"
                                          type="text"
                                          className="form-control"
                                          readOnly={
                                            donationEmailEdit === true
                                              ? false
                                              : true
                                          }
                                          onChange={(e) => {
                                            setDonationEmailSendGridId(
                                              e.target.value
                                            );
                                          }}
                                          value={donationEmailSendGridId}
                                        />
                                        <button
                                          className="editMailBtn btn btn-primary"
                                          type="button"
                                          value="Alert the text input"
                                          onClick={() =>
                                            setDonationEmailEdit(true)
                                          }
                                        >
                                          Edit
                                        </button>

                                        {error.donationEmailSendGridIdVal && (
                                          <small className="text-danger">
                                            {error.donationEmailSendGridIdVal}
                                          </small>
                                        )}
                                      </div>

                                      <div className="form-group ">
                                        <label>
                                          Tax&nbsp;Receipt&nbsp;SendGrid&nbsp;Id:
                                        </label>
                                        <input
                                          name={`sendGridTemplateId`}
                                          className="form-control"
                                          type="text"
                                          value={sendGridTemplateId}
                                          onChange={(e) =>
                                            setSendGridTemplateId(
                                              e.target.value
                                            )
                                          }
                                        />
                                        {error.SendGridTemplateIdVal && (
                                          <small className="text-danger">
                                            {error.SendGridTemplateIdVal}
                                          </small>
                                        )}
                                      </div>
                                      {/* </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                      {/* <div className="form-group1 ">
                              <label>Tax&nbsp;Receipt&nbsp;Template&nbsp;Name:</label>
                              <input
                               name={`templateName`}
                                className="form-control"
                                type="text"
                                value={templateName}
                                onChange ={(e)=>setTemplateName(e.target.value)}
                              />
                                  {error.TemplateNameVal && (
                              <small className="text-danger">
                                {error.TemplateNameVal}
                              </small>
                                 )}
                         </div> */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Tax Receipt Eligible:</label>
                                      <input
                                        type="radio"
                                        id="textelirec"
                                        name={`taxReceiptEligible`}
                                        value={"1"}
                                        checked={taxReceiptEligible === "1"}
                                        onChange={(e) =>
                                          setTaxReceiptEligible(e.target.value)
                                        }
                                        className="radioButton"
                                      />

                                      <label
                                        for="textelirec"
                                        className="radioLable"
                                      >
                                        Yes
                                      </label>

                                      <input
                                        type="radio"
                                        id="textelirecno"
                                        className="radioInput"
                                        name={`taxReceiptEligible`}
                                        value={"0"}
                                        checked={taxReceiptEligible === "0"}
                                        onChange={(e) =>
                                          setTaxReceiptEligible(e.target.value)
                                        }
                                      />

                                      <label
                                        for="textelirecno"
                                        className="radioLable2"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                charityType === "Lottery" ||
                                charityType === "Both"
                                  ? " col-lg-4 col-md-12 cardDisplay"
                                  : "col-lg-4 col-md-12 cardNotDisplay"
                              }
                            >
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Lottery Ticket</h5>
                                </div>
                                <div className="row">
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="col-md-12">
                                      <div className="form-group1 formgrpcustom">
                                        <label>
                                          Create&nbsp;Lottery&nbsp;Ticket:
                                        </label>
                                        <input
                                          type="radio"
                                          id="createloticketyes"
                                          name={`createLotteryTicket`}
                                          value={"Yes"}
                                          checked={
                                            createLotteryTicket === "Yes"
                                          }
                                          onChange={(e) => [
                                            setCreateLotteryTicket(
                                              e.target.value
                                            ),
                                            setLotteryEmailSendGridId(
                                              "noreply@bnasmartpayment.com"
                                            ),
                                          ]}
                                          className="radioButton"
                                        />

                                        <label
                                          for="createloticketyes"
                                          className="radioLable"
                                        >
                                          Yes
                                        </label>

                                        <input
                                          type="radio"
                                          id="createloticketno"
                                          className="radioInput"
                                          name={`createLotteryTicket`}
                                          value={"No"}
                                          checked={createLotteryTicket === "No"}
                                          onChange={(e) => [
                                            setCreateLotteryTicket(
                                              e.target.value
                                            ),
                                            setStartingTicketNumber(""),
                                            setSendGridLotteryTemplateId(""),
                                            setlotteryTemplateName(""),
                                            setLotteryEmailSendGridId(""),
                                          ]}
                                        />

                                        <label
                                          for="createloticketno"
                                          className="radioLable2"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {createLotteryTicket === "Yes" ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group1 ">
                                          <label>
                                            Starting&nbsp;Ticket&nbsp;Number:
                                          </label>
                                          <div className="row input-align">
                                            <input
                                              name={`stringOne`}
                                              className="form-control col-3 col-md-2"
                                              type="text"
                                              value={stringOne}
                                              maxLength="3"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[a-zA-Z]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              onChange={(e) =>
                                                setStringOne(e.target.value)
                                              }
                                              // onChange ={(e)=>checkStartingTicketNumber(e.target.value)}
                                            />
                                            <input
                                              name={`stringTwo`}
                                              className="form-control col "
                                              type="text"
                                              value={stringTwo}
                                              maxLength="7"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              onChange={(e) =>
                                                setStringTwo(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group ">
                                          <label>
                                            Lottery Email SendGrid ID:
                                          </label>

                                          <input
                                            name="lotteryEmailSendGridId"
                                            type="text"
                                            className="form-control"
                                            readOnly={
                                              lotteryEmailEdit === true
                                                ? false
                                                : true
                                            }
                                            onChange={(e) => {
                                              setLotteryEmailSendGridId(
                                                e.target.value
                                              );
                                            }}
                                            value={lotteryEmailSendGridId}
                                          />
                                          <button
                                            className="editMailBtn btn btn-primary"
                                            type="button"
                                            value="Alert the text input"
                                            onClick={() =>
                                              setLotteryEmailEdit(true)
                                            }
                                          >
                                            Edit
                                          </button>

                                          {error.lotteryEmailSendGridIdVal && (
                                            <small className="text-danger">
                                              {error.lotteryEmailSendGridIdVal}
                                            </small>
                                          )}
                                        </div>

                                        <div className="form-group ">
                                          <label>
                                            SendGrid&nbsp;Template&nbsp;Id:
                                          </label>
                                          <input
                                            name={`sendGridLotteryTemplateId`}
                                            className="form-control"
                                            type="text"
                                            value={sendGridLotteryTemplateId}
                                            onChange={(e) =>
                                              setSendGridLotteryTemplateId(
                                                e.target.value
                                              )
                                            }
                                          />
                                          {error.SendGridLotteryTemplateIdVal && (
                                            <small className="text-danger">
                                              {
                                                error.SendGridLotteryTemplateIdVal
                                              }
                                            </small>
                                          )}
                                        </div>
                                        {/* </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12"> */}
                                        {/* <div className="form-group1 ">
                              <label>Template&nbsp;Name:</label>
                              <input
                               name={`lotteryTemplateName`}
                                className="form-control"
                                type="text"
                                value={lotteryTemplateName}
                                onChange ={(e)=>setlotteryTemplateName(e.target.value)}
                              />
                               {error.LotteryTemplateNameVal && (
                              <small className="text-danger">
                                {error.LotteryTemplateNameVal}
                              </small>
                            )}
                            </div> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="col-md-12">
                                      <div className="form-group1 formgrpcustom">
                                        <label>Lottery Tickets:</label>
                                        <input
                                          type="radio"
                                          id="lotticketyesd2"
                                          name={`lotteryTicket`}
                                          value={"Yes"}
                                          checked={lotteryTicket === "Yes"}
                                          onChange={(e) =>
                                            setLotteryTicket(e.target.value)
                                          }
                                          className="radioButton"
                                        />

                                        <label
                                          for="lotticketyesd2"
                                          className="radioLable"
                                        >
                                          Yes
                                        </label>

                                        <input
                                          type="radio"
                                          id="lotticketyesd1"
                                          className="radioInput"
                                          name={`lotteryTicket`}
                                          value={"No"}
                                          checked={lotteryTicket === "No"}
                                          onChange={(e) =>
                                            setLotteryTicket(e.target.value)
                                          }
                                        />

                                        <label
                                          for="lotticketyesd1"
                                          className="radioLable2"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="col-md-12">
                                      <div className="form-group1 formgrpcustom">
                                        <label>50/50 Tickets:</label>
                                        <input
                                          type="radio"
                                          id="fiftytwo2"
                                          name={`fiftyTicket`}
                                          value={"Yes"}
                                          checked={fiftyTicket === "Yes"}
                                          onChange={(e) =>
                                            setFiftyTicket(e.target.value)
                                          }
                                          className="radioButton"
                                        />

                                        <label
                                          for="fiftytwo2"
                                          className="radioLable"
                                        >
                                          Yes
                                        </label>

                                        <input
                                          type="radio"
                                          id="fiftytwo1"
                                          className="radioInput"
                                          name={`fiftyTicket`}
                                          value={"No"}
                                          checked={fiftyTicket === "No"}
                                          onChange={(e) =>
                                            setFiftyTicket(e.target.value)
                                          }
                                        />

                                        <label
                                          for="fiftytwo1"
                                          className="radioLable2"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="col-md-12">
                                      <div className="form-group1 ">
                                      <label>Rules and Regulations URL: {fiftyTicket === "Yes" || lotteryTicket === "Yes" ?  <sup className="color-cls">*</sup> : ""}</label>

                                        <input
                                          name="rulesRegUrl"
                                          type="text"
                                          className="form-control"
                                          onChange={(e) => {
                                            setRulesRegUrl(e.target.value);
                                          }}
                                          value={rulesRegUrl}
                                        />
                                      </div>
                                      { fiftyTicket === "Yes" || lotteryTicket === "Yes" ? 
                                      error.rulesRegUrlVal && (
                                        <small className="text-danger">
                                          {error.rulesRegUrlVal}
                                        </small>
                                      ) : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-4 col-md-12
                        "
                            >
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Processing Fee</h5>
                                </div>
                                <div className="col-md-12 row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Processing Fees Option:</label>
                                      <input
                                        type="radio"
                                        id="processfreeadd"
                                        name={`processFee`}
                                        value={"1"}
                                        checked={processFee === "1"}
                                        onChange={(e) =>
                                          setProcessFee(e.target.value)
                                        }
                                        className="radioButton"
                                      />

                                      <label
                                        for="processfreeadd"
                                        className="radioLable"
                                      >
                                        Yes
                                      </label>

                                      <input
                                        type="radio"
                                        id="processfreeadd1"
                                        className="radioInput"
                                        name={`processFee`}
                                        value={"0"}
                                        checked={processFee === "0"}
                                        onChange={(e) =>
                                          setProcessFee(e.target.value)
                                        }
                                      />

                                      <label
                                        for="processfreeadd1"
                                        className="radioLable2"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  {processFee === "1" ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="form-group cstm_posi_chng">
                                        <label>Processing Fees:</label>
                                        <div className="Flex-input">
                                          <input
                                            style={{ width: "100%" }}
                                            name="processFeeAmount"
                                            className="form-control"
                                            type="text"
                                            maxLength="6"
                                            {...register("processFeeAmount", {
                                              required: "Amount is required",
                                            })}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp(
                                                /^\d*\.?\d*$/
                                              );
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            onChange={(e) => {
                                              if (e.target.value <= 100) {
                                                setProcessFeeAmount(
                                                  e.target.value
                                                );
                                              } else {
                                                e.target.value = "";
                                              }
                                            }}
                                            value={processFeeAmount}
                                          />
                                          <i class="icon_pscstm addtwocst">%</i>
                                        </div>

                                        {errors.processFeeAmount && (
                                          <small className="text-danger">
                                            {errors.processFeeAmount.message}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                    {addSecond === false ? (
                      <div className="modalFooter text-right mt-5 pt-3">
                        <button
                          onClick={clearCharityData}
                          className="btn btn-primary"
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={handleSubmit(openAddSecondForm)}
                          disabled={
                            saveamount.length == 0 && saveMonthlyAmount == 0
                          }
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="modalFooter text-right mt-5 pt-3">
                        <button
                          onClick={closeAddSecondForm}
                          className="btn btn-primary nextButton-size"
                        >
                          {" "}
                          Back{" "}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit(addCharity)}
                          disabled={
                            saveamount.length == 0 && saveMonthlyAmount == 0
                          }
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {/* //update charity */}
              {setUpdateModal ? (
                <div className="ModalBackground">
                  <div className="modalContainer customModalContainer">
                    <div className="modalHeader mb-5">
                      <h3>Update Charity</h3>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={clearUpdateCharityData}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>

                    <form
                      action="/charity/:id"
                      method="PATCH"
                      encType="multipart/form-data"
                    >
                      <div className="container-fluid">
                        {updateSecond === false ? (
                          <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Charity Name:</label>
                                <input
                                  type="text"
                                  name="updateCharityName"
                                  maxLength={50}
                                  className="form-control"
                                  {...register("updateCharityName", {
                                    required: "Charity Name is required",
                                  })}
                                  // value={updateCharityName}
                                  onChange={(e) =>
                                    setUpdateCharityName(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={updateCharityName}
                                />
                                {errors.updateCharityName && (
                                  <small className="text-danger">
                                    {errors.updateCharityName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Contact Name:</label>
                                <input
                                  type="text"
                                  name="updateContactName"
                                  maxLength={50}
                                  className="form-control"
                                  {...register("updateContactName", {
                                    required: "Contact Name is required",
                                  })}
                                  // value={updateContactName}
                                  onChange={(e) =>
                                    setUpdateContactName(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={updateContactName}
                                />

                                {errors.updateContactName && (
                                  <small className="text-danger">
                                    {errors.updateContactName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  type="text"
                                  name="updateEmail"
                                  className="form-control"
                                  {...register("updateEmail", {
                                    required: "Email is required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  // value={updateEmail}
                                  onChange={(e) =>
                                    setUpdateEmail(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                                {errors.updateEmail && (
                                  <small className="text-danger">
                                    {" "}
                                    {errors.updateEmail.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-10 col-8">
                              <div className="form-group">
                                <label>Phone Number:</label>
                                <input
                                  type="text"
                                  name="updatePhone"
                                  className="form-control"
                                  maxLength="13"
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[0-9]");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  {...register("updatePhone", {
                                    required: "Phone Number is required",
                                    pattern: {
                                      value:
                                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                      message: "Only numbers are allowed",
                                    },
                                  })}
                                  value={updatePhone}
                                  onChange={(e) =>
                                    setUpdatePhone(
                                      normalizeInput(
                                        Validator.ltrim(e.target.value)
                                      )
                                    )
                                  }
                                />
                                {errors.updatePhone && (
                                  <small className="text-danger">
                                    {errors.updatePhone.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-2 col-4">
                              <label>Ext.</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="updateExt"
                                  maxLength="5"
                                  className="form-control"
                                  value={updateExt}
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[0-9]");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  {...register("updateExt", {})}
                                  onChange={(e) =>
                                    setUpdateExt(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-10 col-8">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="updateAddress"
                                  maxLength={55}
                                  className="form-control"
                                  //value={updateAddress}
                                  {...register("updateAddress", {
                                    required: "Address is required",
                                  })}
                                  onChange={(e) =>
                                    setUpdateAddress(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={updateAddress}
                                />
                                {errors.updateAddress && (
                                  <small className="text-danger">
                                    {errors.updateAddress.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-4 col-sm-2 col-4">
                              <label>Unit/Suite</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="updateUnitSuite"
                                  className="form-control"
                                  maxLength="5"
                                  value={updateUnitSuite}
                                  {...register("updateUnitSuite", {})}
                                  onChange={(e) =>
                                    setUpdateUnitSuite(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>City:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={50}
                                  name="updateCity"
                                  //value={updateCity}
                                  {...register("updateCity", {
                                    required: "City is required",
                                  })}
                                  onChange={(e) =>
                                    setUpdateCity(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                                {errors.updateCity && (
                                  <small className="text-danger">
                                    {errors.updateCity.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Province:</label>
                                <label
                                  htmlFor="provincefirst_ne"
                                  className="arrowstickshowssh"
                                >
                                  <input
                                    id="provincefirst_ne"
                                    type="text"
                                    name="updateProvince"
                                    className="form-control"
                                    list="browsers"
                                    //value={updateProvince}
                                    {...register("updateProvince", {
                                      required: "Province is required",
                                    })}
                                    onClick={(e) => (e.target.value = "")}
                                    onMouseLeave={(e) =>
                                      (e.target.value = updateProvince)
                                    }
                                    onChange={(e) =>
                                      setUpdateProvince(
                                        Validator.ltrim(e.target.value)
                                      )
                                    }
                                  />
                                  <datalist id="browsers">
                                    {provinceList &&
                                      provinceList.map((list, id) =>
                                        list.province.map((res) => {
                                          // console.log(res,"----aaaaaa")
                                          return <option value={res} />;
                                        })
                                      )}
                                  </datalist>
                                </label>
                                {errors.updateProvince && (
                                  <small className="text-danger">
                                    {errors.updateProvince.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Country:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="updateCountry"
                                  //value={updateCountry}
                                  {...register("updateCountry", {
                                    required: "Country is required",
                                  })}
                                  onChange={(e) =>
                                    setUpdateCountry(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                                {errors.updateCountry && (
                                  <small className="text-danger">
                                    {errors.updateCountry.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Postal Code:</label>
                                <input
                                  onKeyPress={function (event) {
                                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                                    var key = String.fromCharCode(
                                      !event.charCode
                                        ? event.which
                                        : event.charCode
                                    );
                                    if (!regex.test(key)) {
                                      event.preventDefault();
                                      return false;
                                    }
                                  }}
                                  name="updatePostalCode"
                                  type="text"
                                  className="form-control"
                                  maxLength="7"
                                  {...register("updatePostalCode", {
                                    required: "Postal Code is required",
                                    // pattern: {
                                    //   value: /^[A-Za-z_][A-Za-z\d_]*$/,
                                    //   message:
                                    //     "Postal Code should be alphanumeric",
                                    // },
                                  })}
                                  //value={updatePostal_Code}
                                  onChange={(e) =>
                                    setUpdatePostal_Code(
                                      postalCodeFormat(
                                        Validator.ltrim(e.target.value)
                                      )
                                    )
                                  }
                                  value={updatePostal_Code}
                                />
                                {errors.updatePostalCode && (
                                  <small className="text-danger">
                                    {errors.updatePostalCode.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="form-group">
                                <label>Account Name:</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="updateMID"
                                  maxLength="20"
                                  {...register("updateMID", {
                                    //required: "Account Name is required",
                                  })}
                                  //value={updateMID}
                                  onChange={(e) =>
                                    setUpdateMID(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                />
                                {errors.updateMID && (
                                  <small className="text-danger">
                                    {errors.updateMID.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label>Upload Logo</label>
                              <span style={{ color: "red" }}>*</span>
                              <div className="input-group mt-2 mb-3 cstmchangeflexlist">
                                <div className="custom-file overflow-hidden">
                                  <input
                                    className="uplaodController custom-file-input"
                                    type="file"
                                    name="UpdateLogo"
                                    id="files"
                                    accept=".jpg,.jpeg,.png"
                                    style={{}}
                                    onChange={updateHandleFile}
                                  />
                                  <label
                                    className="custom-file-label"
                                    for="files"
                                  >
                                    Upload Logo (Maximum Size is 2MB)
                                  </label>
                                </div>
                                <div className="cstmliste-sideup custmImgPos">
                                  {isUpload == "" ? (
                                    <img
                                      src={`/uploads/${updateCharityLogo}`}
                                      alt="logo"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {isUpload &&
                                    [...isUpload].map((file) => (
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt="logo"
                                      />
                                    ))}
                                  {(updateCharityLogo !== "" &&
                                    updateCharityLogo != undefined) ||
                                  isUpload?.length > 0 ? (
                                    <a
                                      title="Delete Logo"
                                      class="text-dark crosPost"
                                      aria-hidden="true"
                                      onClick={() => updateDeleteLogo()}
                                    >
                                      <i class="fa fa-times" />
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {error.Logo && (
                                <small className="text-danger">
                                  {error.Logo}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 col-12 mt-2">
                              <div className="form-group1 formgrpcustom">
                                <label>Language:</label>
                                <input
                                  type="radio"
                                  id="langu_radio1"
                                  name={`fav_language1`}
                                  value={`English`}
                                  checked={updateLanguage === "English"}
                                  onChange={(e) =>
                                    setUpdateLanguage(e.target.value)
                                  }
                                  className="radioButton"
                                />
                                <label
                                  for="langu_radio1"
                                  className="radioLable"
                                >
                                  English
                                </label>

                                <input
                                  type="radio"
                                  id="langu_radio2"
                                  className="radioInput"
                                  name={`fav_language1`}
                                  value={`French`}
                                  checked={updateLanguage === "French"}
                                  onChange={(e) =>
                                    setUpdateLanguage(e.target.value)
                                  }
                                />

                                <label
                                  for="langu_radio2"
                                  className="radioLable2"
                                >
                                  French
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group1 custm-mb1">
                                <label>Color:</label>
                                <input
                                  type="color"
                                  name="color"
                                  className="form-control"
                                  onChange={(e) =>
                                    setUpdateColor(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={updateColor}
                                />
                              </div>
                              {error.Color && (
                                <small className="text-danger">
                                  {error.Color}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div className="form-group custm-mb1">
                                <label>Font Color:</label>
                                <input
                                  type="color"
                                  name="fontColor"
                                  className="form-control"
                                  onChange={(e) =>
                                    setUpdateFontColor(
                                      Validator.ltrim(e.target.value)
                                    )
                                  }
                                  value={updateFontColor}
                                />
                              </div>
                              {error.FontColor && (
                                <small className="text-danger">
                                  {error.FontColor}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <div className="form-group1 cstm_labelsdf mb-3">
                                <label>Donation Frequency:</label>

                                <div className="cstm_lblsdf">
                                  <label
                                    for="donation_frequency1"
                                    className="no-req-sign"
                                  >
                                    <input
                                      type="radio"
                                      id="donation_frequency1"
                                      name={`fav_frequency`}
                                      value={`OneTime`}
                                      checked={
                                        updateDonationFrequency === "OneTime"
                                      }
                                      onChange={(e) => {
                                        setUpdateDonationFrequency(
                                          e.target.value
                                        );
                                        setupdateIsDonationMonthly(false);
                                      }}
                                    />
                                    <span>One-Time only</span>
                                  </label>

                                  <label
                                    for="donation_frequency2"
                                    className="no-req-sign"
                                  >
                                    <input
                                      type="radio"
                                      id="donation_frequency2"
                                      className=""
                                      name={`fav_frequency`}
                                      value={`Monthly`}
                                      checked={
                                        updateDonationFrequency === "Monthly"
                                      }
                                      onChange={(e) => {
                                        setUpdateDonationFrequency(
                                          e.target.value
                                        );
                                        setupdateIsDonationMonthly(true);
                                      }}
                                    />
                                    <span>One-Time and Monthly</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 resp-input-pdng">
                              <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                  <div className="form-group1 all-inputspdngs">
                                    <label>One-Time Donation Amount:</label>
                                    <div className="Flex-input">
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>
                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name1"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount1}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>
                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name2"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount2}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>

                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name3"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount3}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>

                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name4"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount4}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>

                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name5"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount5}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>

                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name6"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount6}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>

                                      <input
                                        onChange={handleUpdateAmount}
                                        name="name7"
                                        className="form-control"
                                        type="text"
                                        maxLength="4"
                                        value={updateAmount7}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp("^[0-9]");
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                      {/* <div className="input-icon">
                                      <i>$</i>
                                    </div> */}

                                      {/* <input
                                      onChange={handleUpdateAmount}
                                      name="name8"
                                      className="form-control"
                                      type="text"
                                      maxLength="4"
                                      value={updateAmount8}
                                      onKeyPress={function (event) {
                                        var regex = new RegExp("^[0-9]");
                                        var key = String.fromCharCode(
                                          !event.charCode
                                            ? event.which
                                            : event.charCode
                                        );
                                        if (!regex.test(key)) {
                                          event.preventDefault();
                                          return false;
                                        }
                                      }}
                                    /> */}
                                    </div>
                                    <small className="text-danger">
                                      {msg2}
                                    </small>
                                  </div>
                                </div>
                                {updateIsDonationMonthly ? (
                                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="form-group1">
                                      <label>Monthly Donation Amount:</label>
                                      <div className="Flex-input">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly1"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount1}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly2"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount2}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly3"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount3}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly4"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount4}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly5"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount5}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly6"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount6}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateMonthlyAmount}
                                          name="namemonthly7"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateMonthlyAmount7}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {msgUpdateMonthlyAmount}
                                      </small>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card-radio">
                                <div className="col-md-12">
                                  <h5>Choose Option :</h5>
                                  <div className="col-sm-12 form-group1">
                                    <div className="donationSection">
                                      <div className="form-group1 formgrpcustom customForm_G labelcheck min-0">
                                        <input
                                          type="radio"
                                          id="charitytype1"
                                          name={`charityType`}
                                          value={"Donations"}
                                          onChange={(e) =>
                                            e.target.value === "Donations"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateLotteryTicket("No"),
                                                  setUpdateFiftyTicket("No"),
                                                  setUpdateSendGridTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateTemplateName(""),
                                                  setUpdateCreateLotteryTicket(
                                                    "No"
                                                  ),
                                                  setUpdateStartingTicketNumber(
                                                    ""
                                                  ),
                                                  setUpdateStringOne(""),
                                                  setUpdateStringTwo(""),
                                                  setUpdateSendGridLotteryTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateLotteryTemplateName(
                                                    ""
                                                  ),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                          checked={
                                            updateCharityType === "Donations"
                                          }
                                          className="radioButton"
                                        />
                                        <label
                                          for="charitytype1"
                                          className="radioLable"
                                        >
                                          Donations
                                        </label>
                                      </div>
                                      <div className="form-group1 formgrpcustom customForm_G labelcheck min-0">
                                        <input
                                          type="radio"
                                          id="charitytype2"
                                          className="radioInputLottery"
                                          name={`charityType`}
                                          value={"Lottery"}
                                          onChange={(e) =>
                                            e.target.value === "Lottery"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateCreateTaxReceipt(
                                                    "0"
                                                  ),
                                                  setUpdateSendGridTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateTemplateName(""),
                                                  setUpdateTaxReceiptEligible(
                                                    "0"
                                                  ),
                                                  setUpdateStartingTicketNumber(
                                                    ""
                                                  ),
                                                  setUpdateStringOne(""),
                                                  setUpdateStringTwo(""),
                                                  setUpdateSendGridLotteryTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateLotteryTemplateName(
                                                    ""
                                                  ),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                          checked={
                                            updateCharityType === "Lottery"
                                          }
                                        />
                                        <label
                                          for="charitytype2"
                                          className="radioLableLottery"
                                        >
                                          Lottery
                                        </label>
                                      </div>
                                      <div className="form-group1 formgrpcustom customForm_G labelcheck min-0">
                                        <input
                                          type="radio"
                                          id="lottery_radio1"
                                          className="radioInputBoth"
                                          name={`charityType`}
                                          value={"Both"}
                                          checked={updateCharityType === "Both"}
                                          onChange={(e) =>
                                            e.target.value === "Both"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateCreateTaxReceipt(
                                                    "0"
                                                  ),
                                                  setUpdateSendGridTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateTemplateName(""),
                                                  setUpdateTaxReceiptEligible(
                                                    "0"
                                                  ),
                                                  setUpdateLotteryTicket("No"),
                                                  setUpdateFiftyTicket("No"),
                                                  setUpdateCreateLotteryTicket(
                                                    "No"
                                                  ),
                                                  setUpdateStartingTicketNumber(
                                                    ""
                                                  ),
                                                  setUpdateStringOne(""),
                                                  setUpdateStringTwo(""),
                                                  setUpdateSendGridLotteryTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateLotteryTemplateName(
                                                    ""
                                                  ),
                                                  setUpdateRulesRegUrl(""),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                        />
                                        <label
                                          for="lottery_radio1"
                                          className="radioLable3"
                                        >
                                          Both
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                updateCharityType === "Donations" ||
                                updateCharityType === "Both"
                                  ? "col-lg-4 col-md-12 cardDisplay"
                                  : "col-lg-4 col-md-12 cardNotDisplay"
                              }
                            >
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Donation Tax Receipt</h5>
                                </div>
                                <div className="col-md-12 row">
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Create Tax Receipt:</label>

                                      <div className="labelFlex">
                                        <div className="labelcheck">
                                          {" "}
                                          <input
                                            type="radio"
                                            id="tax_receiptradio1"
                                            name={`createTaxReceipt_1`}
                                            value={"1"}
                                            checked={
                                              updateCreateTaxReceipt === "1"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateTaxReceipt(
                                                e.target.value
                                              ),
                                              setUpdateDonationEmailSendGridId(
                                                "noreply@bnasmartpayment.com"
                                              ),
                                            ]}
                                            className="radioButton"
                                          />
                                          <label
                                            for="tax_receiptradio1"
                                            className="radioLable"
                                          >
                                            Yes
                                          </label>{" "}
                                        </div>

                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="tax_receiptradio2"
                                            className="radioInput"
                                            name={`createTaxReceipt_1`}
                                            value={"0"}
                                            checked={
                                              updateCreateTaxReceipt === "0"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateTaxReceipt(
                                                e.target.value
                                              ),
                                              setUpdateSendGridTemplateId(""),
                                              setUpdateTemplateName(""),
                                            ]}
                                          />

                                          <label
                                            for="tax_receiptradio2"
                                            className="radioLable2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {updateCreateTaxReceipt === "1" ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="col-lg-12 col-md-12 col-sm-112 col-12 p-0">
                                        <div className="form-group ">
                                          <label className="text-nowrap">
                                            Donation Email SendGrid ID:
                                          </label>
                                          <input
                                            name="updateDonationEmailSendGridId"
                                            type="text"
                                            className="form-control"
                                            readOnly={
                                              updateDonationEmailEdit === true
                                                ? false
                                                : true
                                            }
                                            onChange={(e) => {
                                              setUpdateDonationEmailSendGridId(
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              updateDonationEmailSendGridId
                                            }
                                          />
                                          <button
                                            className="editMailBtn btn btn-primary custmeditBtn"
                                            type="button"
                                            value="Alert the text input"
                                            onClick={() =>
                                              setUpdateDonationEmailEdit(true)
                                            }
                                          >
                                            Edit
                                          </button>

                                          {error.donationEmailSendGridIdVal && (
                                            <small className="text-danger">
                                              {error.donationEmailSendGridIdVal}
                                            </small>
                                          )}
                                        </div>

                                        <div className="form-group ">
                                          <label>
                                            Tax&nbsp;Receipt&nbsp;SendGrid&nbsp;Template&nbsp;Id:
                                          </label>
                                          <input
                                            name={`updateSendGridTemplateId`}
                                            className="form-control"
                                            type="text"
                                            value={updateSendGridTemplateId}
                                            onChange={(e) =>
                                              setUpdateSendGridTemplateId(
                                                e.target.value
                                              )
                                            }
                                          />
                                          {error.SendGridTemplateIdVal && (
                                            <small className="text-danger">
                                              {error.SendGridTemplateIdVal}
                                            </small>
                                          )}
                                        </div>
                                        {/* </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                        {/* <div className="form-group1 ">
                              <label>Tax&nbsp;Receipt&nbsp;Template&nbsp;Name:</label>
                              <input
                               name={`updateTemplateName`}
                                className="form-control"
                                type="text"
                                value={updateTemplateName}
                                onChange ={(e)=>setUpdateTemplateName(e.target.value)}
                              />
                                {error.TemplateNameVal && (
                              <small className="text-danger">
                                {error.TemplateNameVal}
                              </small>
                                 )}
                            </div> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Tax Receipt Eligible:</label>
                                      <div className="labelFlex">
                                        {" "}
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="receipteligible1"
                                            name={`taxReceiptEligible`}
                                            value={"1"}
                                            checked={
                                              updateTaxReceiptEligible === "1"
                                            }
                                            onChange={(e) =>
                                              setUpdateTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />
                                          <label
                                            for="receipteligible1"
                                            className="radioLable"
                                          >
                                            Yes
                                          </label>{" "}
                                        </div>
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="receipteligible2"
                                            className="radioInput"
                                            name={`taxReceiptEligible`}
                                            value={"0"}
                                            checked={
                                              updateTaxReceiptEligible === "0"
                                            }
                                            onChange={(e) =>
                                              setUpdateTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <label
                                            for="receipteligible2"
                                            className="radioLable2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                updateCharityType === "Lottery" ||
                                updateCharityType === "Both"
                                  ? "col-lg-4 col-md-12 cardDisplay"
                                  : "col-lg-4 col-md-12 cardNotDisplay"
                              }
                            >
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Lottery Ticket</h5>
                                </div>
                                <div className="col-md-12 row m-0 p-0">
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Create Lottery Ticket:</label>

                                      <div className="labelFlex">
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="lotteryticketc1"
                                            name={`updatecreateLotteryTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatecreateLotteryTicket ===
                                              "Yes"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateLotteryTicket(
                                                e.target.value
                                              ),
                                              setUpdateLotteryEmailSendGridId(
                                                "noreply@bnasmartpayment.com"
                                              ),
                                            ]}
                                            className="radioButton"
                                          />
                                          <label
                                            for="lotteryticketc1"
                                            className="radioLable"
                                          >
                                            Yes
                                          </label>{" "}
                                        </div>
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="lotteryticketc2"
                                            className="radioInput"
                                            name={`updatecreateLotteryTicket`}
                                            value={"No"}
                                            checked={
                                              updatecreateLotteryTicket === "No"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateLotteryTicket(
                                                e.target.value
                                              ),
                                              setUpdateStartingTicketNumber(""),
                                              setUpdateStringOne(""),
                                              setUpdateStringTwo(""),
                                              setUpdateSendGridLotteryTemplateId(
                                                ""
                                              ),
                                              setUpdateLotteryTemplateName(""),
                                            ]}
                                          />
                                          <label
                                            for="lotteryticketc2"
                                            className="radioLable2"
                                          >
                                            No
                                          </label>{" "}
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                  {updatecreateLotteryTicket === "Yes" ? (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group ">
                                          <label className="text-nowrap">
                                            Lottery Email SendGrid ID:
                                          </label>
                                          <input
                                            name="UpdateLotteryEmailSendGridId"
                                            type="text"
                                            className="form-control"
                                            readOnly={
                                              updateLotteryEmailEdit === true
                                                ? false
                                                : true
                                            }
                                            onChange={(e) => {
                                              setUpdateLotteryEmailSendGridId(
                                                e.target.value
                                              );
                                            }}
                                            value={updateLotteryEmailSendGridId}
                                          />
                                          <button
                                            className="editMailBtn btn btn-primary"
                                            type="button"
                                            value="Alert the text input"
                                            onClick={() =>
                                              setUpdateLotteryEmailEdit(true)
                                            }
                                          >
                                            Edit
                                          </button>

                                          {error.lotteryEmailSendGridIdVal && (
                                            <small className="text-danger">
                                              {error.lotteryEmailSendGridIdVal}
                                            </small>
                                          )}
                                        </div>

                                        <div className="form-group1 ">
                                          <label>
                                            Starting&nbsp;Ticket&nbsp;Number:
                                          </label>
                                          <div className="row input-align">
                                            <input
                                              name={`updateStringOne`}
                                              className="form-control col-3 col-md-2"
                                              type="text"
                                              value={updateStringOne}
                                              maxLength="3"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[a-zA-Z]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              onChange={(e) =>
                                                setUpdateStringOne(
                                                  e.target.value
                                                )
                                              }
                                              // onChange ={(e)=>checkStartingTicketNumber(e.target.value)}
                                            />
                                            <input
                                              name={`updateStringTwo`}
                                              className="form-control col"
                                              type="text"
                                              value={updateStringTwo}
                                              maxLength="7"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              onChange={(e) =>
                                                setUpdateStringTwo(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* </div>
                                       
                                       <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                        <div className="form-group ">
                                          <label>
                                            SendGrid&nbsp;Template&nbsp;Id:
                                          </label>
                                          <input
                                            name={`updateSendGridLotteryTemplateId`}
                                            className="form-control"
                                            type="text"
                                            value={
                                              updateSendGridLotteryTemplateId
                                            }
                                            onChange={(e) =>
                                              setUpdateSendGridLotteryTemplateId(
                                                e.target.value
                                              )
                                            }
                                          />
                                          {error.SendGridLotteryTemplateIdVal && (
                                            <small className="text-danger">
                                              {
                                                error.SendGridLotteryTemplateIdVal
                                              }
                                            </small>
                                          )}
                                        </div>
                                        {/* </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                        {/* <div className="form-group1 ">
                                          <label>Template&nbsp;Name:</label>
                                          <input
                                           name={`updateLotteryTemplateName`}
                                            className="form-control"
                                            type="text"
                                            value={updateLotteryTemplateName}
                                            onChange ={(e)=>setUpdateLotteryTemplateName(e.target.value)}
                                          />
                                           {error.LotteryTemplateNameVal && (
                                          <small className="text-danger">
                                            {error.LotteryTemplateNameVal}
                                          </small>
                                        )}
                                        </div> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-xl-4 col-lg-12 col-md-6">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Lottery&nbsp;Tickets:</label>
                                      <div className="labelFlex">
                                        <div className="labelcheck">
                                          {" "}
                                          <input
                                            type="radio"
                                            id="lotteryticketca1"
                                            name={`updatelotteryTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatelotteryTicket === "Yes"
                                            }
                                            onChange={(e) =>
                                              setUpdateLotteryTicket(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />
                                          <label
                                            for="lotteryticketca1"
                                            className="radioLable"
                                          >
                                            Yes
                                          </label>{" "}
                                        </div>
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="lotteryticketca2"
                                            className="radioInput  lottic"
                                            name={`updatelotteryTicket`}
                                            value={"No"}
                                            checked={
                                              updatelotteryTicket === "No"
                                            }
                                            onChange={(e) =>
                                              setUpdateLotteryTicket(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <label
                                            for="lotteryticketca2"
                                            className="radioLable2 lottic"
                                          >
                                            No
                                          </label>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>50/50 Tickets:</label>
                                      <div className="labelFlex">
                                        {" "}
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="fiftyticket1"
                                            name={`updatefiftyTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatefiftyTicket === "Yes"
                                            }
                                            onChange={(e) =>
                                              setUpdateFiftyTicket(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton fifty"
                                          />

                                          <label
                                            for="fiftyticket1"
                                            className="radioLable fifty"
                                          >
                                            Yes
                                          </label>
                                        </div>
                                        <div className="labelcheck">
                                          <input
                                            type="radio"
                                            id="fiftyticket2"
                                            className="radioInput"
                                            name={`updatefiftyTicket`}
                                            value={"No"}
                                            checked={updatefiftyTicket === "No"}
                                            onChange={(e) =>
                                              setUpdateFiftyTicket(
                                                e.target.value
                                              )
                                            }
                                          />

                                          <label
                                            for="fiftyticket2"
                                            className="radioLable2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="form-group1 ">
                                      <label>Rules and Regulations URL: {updatefiftyTicket === "Yes" || updatelotteryTicket === "Yes" ?  <sup className="color-cls">*</sup> : ""}</label>

                                      <input
                                        name="updateRulesRegUrl"
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                          setUpdateRulesRegUrl(e.target.value);
                                        }}
                                        value={updateRulesRegUrl}
                                      />
                                    </div>
                                  
                                   {updatefiftyTicket === "Yes" || updatelotteryTicket === "Yes" ?
                                    error.rulesRegUrlVal && (
                                    <small className="text-danger">
                                      {error.rulesRegUrlVal}
                                    </small>
                                  ) : ""}
                                </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                              <div className="card-radio Cstm-card">
                                <div className="col-md-12">
                                  <h5>Processing Fee</h5>
                                </div>
                                <div className="col-md-12 row">
                                  <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="form-group1 formgrpcustom">
                                      <label>Processing Fees Option:</label>
                                      <div className="labelFlex">
                                        <div className="labelcheck">
                                          {" "}
                                          <input
                                            type="radio"
                                            id="processingfees_yes"
                                            name={`processFee`}
                                            value={"1"}
                                            checked={updateProcessFee === "1"}
                                            onChange={(e) =>
                                              setUpdateProcessFee(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />
                                          <label
                                            for="processingfees_yes"
                                            className="radioLable"
                                          >
                                            Yes
                                          </label>{" "}
                                        </div>
                                        <div className="labelcheck">
                                          {" "}
                                          <input
                                            type="radio"
                                            id="processingfees_no"
                                            className="radioInput"
                                            name={`processFee`}
                                            value={"0"}
                                            checked={updateProcessFee === "0"}
                                            onChange={(e) =>
                                              setUpdateProcessFee(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <label
                                            for="processingfees_no"
                                            className="radioLable2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {updateProcessFee === "1" ? (
                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                      <div className="form-group cstm_posi_chng">
                                        <label>Processing Fees:</label>
                                        <div className="Flex-input">
                                          <input
                                            style={{ width: "100%" }}
                                            type="text"
                                            name="updateProcessFeeAmount"
                                            maxLength="6"
                                            className={`form-control`}
                                            {...register(
                                              "updateProcessFeeAmount",
                                              {
                                                required: "Amount is required",
                                              }
                                            )}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp(
                                                /^\d*\.?\d*$/
                                              );
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            onChange={(e) => {
                                              if (e.target.value <= 100) {
                                                setUpdateProcessFeeAmount(
                                                  e.target.value
                                                );
                                              } else {
                                                e.target.value = "";
                                              }
                                            }}
                                            value={updateProcessFeeAmount}
                                          />
                                          <i class="icon_pscstm addtwocst">%</i>
                                        </div>
                                        {errors.updateProcessFeeAmount && (
                                          <small className="text-danger">
                                            {
                                              errors.updateProcessFeeAmount
                                                .message
                                            }
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                    {updateSecond === false ? (
                      <div className="modalFooter text-right mt-5 pt-3">
                        <button
                          className="btn btn-primary"
                          onClick={clearUpdateCharityData}
                        >
                          {" "}
                          Cancel
                        </button>{" "}
                        &nbsp;
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={handleSubmit(openUpdateSecondForm)}
                          disabled={
                            saveUpdateAmount.length == 0 &&
                            saveUpdateMonthlyAmount.length == 0
                          }
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="modalFooter text-right mt-5 pt-3">
                        <button
                          id="updateCharityBackButton"
                          className="btn btn-primary nextButton-size"
                          onClick={closeUpdateSecondForm}
                        >
                          {" "}
                          Back
                        </button>{" "}
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          id="updateCharityButton"
                          onClick={handleSubmit(updateCharity)}
                          disabled={
                            saveUpdateAmount.length == 0 &&
                            saveUpdateMonthlyAmount.length == 0
                          }
                        >
                          Update Charity
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <h2 className="pageTitle mb-4">CHARITY INFORMATION</h2>
              <div className="topWrapper d-flex mb-5">
                <button className="btn btn-primary" onClick={openModal}>
                  Add Charity
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-warning float-right"
                  onClick={openImportExcelModal}
                >
                  Import Charity
                </button>
              </div>
              {importExcelModel ? (
                <div className="ModalBackground">
                  <div className="modalContainer modalImportContainer">
                    <div className="modalHeader mb-5">
                      <h3>Import Charities</h3>

                      <a href={`${APIBase_URL}/charityTemplate.csv`} download>
                        Download Template
                      </a>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => setImportExcelModal(false)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>

                    <form>
                      <div className="custom-file-import">
                        <input
                          className="custom-file-input"
                          type={"file"}
                          id={"csvFileInput"}
                          accept={".csv"}
                          {...register("CSVFile", {
                            required: "CSV File is required",
                          })}
                          onChange={handleImportExcelOnChange}
                        />
                        <label className="custom-file-label" for="files">
                          {fileNamemsg ? fileNamemsg : "Upload CSV"}
                        </label>
                        {errors.CSVFile && (
                          <small className="text-danger">
                            {errors.CSVFile.message}
                          </small>
                        )}
                      </div>
                    </form>
                    <div className="modalFooter text-right mt-5 pt-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit(handleImportExcelOnSubmit)}
                        // onClick={(e) => {
                        //   handleSubmit(handleImportExcelOnSubmit);
                        // }}
                      >
                        IMPORT CSV
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={() => setImportExcelModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              <h6 style={{ color: "green" }}>{msg}</h6>

              <div>
                <DataTable
                  columns={columns}
                  data={charityList}
                  striped
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                  progressPending={pending}
                  progressComponent={<TableLoader />}
                />
              </div>
            </div>
          </div>
          <br></br>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Charity;
