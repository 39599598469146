import axios from "axios";
import { APIBase_URL } from "../../../config";

export const getRetailerData = async () => {
  const result1 = await fetch(`${APIBase_URL}retailer`);
  const result = result1.json();
  return result;
};
export const addRetailerData = async (data) => {
  const result = await axios.post(`${APIBase_URL}retailer`, data);
  return result;
};
export const updateRetailerData = async (data, updateID) => {
  const result1 = await fetch(`${APIBase_URL}retailer/${updateID}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = result1.json();
  // console.log(result);
  return result;
};
export const deleteRetailerData = async (data, id) => {
  // console.log(data, id, "----");
  const result1 = await fetch(`${APIBase_URL}retailer/delete/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = result1.json();
  return result;
};

export const importCsvRetailerData = async (formData) => {
  const result = await axios.post(`${APIBase_URL}retailer/importCsv`,formData ,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return result
  
};

export const importCsvLocationData = async (formData, id) => {
  const result = await axios.post(`${APIBase_URL}location/importCsvlocation/${id}`,formData ,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  // console.log(result,"ffsdfasdssfasd")
  return result
  
};
