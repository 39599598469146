import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import moment from "moment";

export default function PrintComponent(props) {
  let componentRef = useRef();
  // console.log(props, "Props Data //////////////////");
  return (
    <>
      {/* <div id="print_component"> */}
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button style={{ marginTop: "10px", padding: "4px 20px" }}>Print</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint
            data={props.data}
            retailerName={props.retailerName}
            totalDonation ={props.totalDonation}
            printLocationData = {props.printLocationData}
            ref={(el) => (componentRef = el)}
          />
        </div>
      {/* </div> */}
    </>
  );
}

// component to be printed
class ComponentToPrint extends React.Component {
  render() {
    let DonationsData = this.props.data;
    let retailerName = this.props.retailerName;
    let PrintLocationData = this.props.printLocationData
    let totalDonation =this.props.totalDonation;
    return (
      <div style={{ background: "#fff"  }}>
        <h2 style={{ color: "green" }}>Donation Summary By Retailers/Events</h2>
        <div className="col-xl-3 col-lg-12 text-center ui-center-col">
          <h5>
            <b>TOTAL DONATIONS: ${parseFloat(totalDonation).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}</b>
          </h5>
        </div>
        <table className="table table-striped mt-3" >
          {/* <thead > */}
            <tr className="thead-light">
              <th className="main-cell">Retailer/Event</th>
              <th className="main-cell" style={{ textAlign: "right"  }}>Total Collected</th>
            </tr>
          {/* </thead> */}
          <tbody>
          {DonationsData?.filter(
        (vl) => vl.retailer === retailerName
      ).map((val) => val).length>0 &&
              DonationsData.map((val) => {
                let retailer = val.retailer
                if(retailer ===retailerName){
            return (
              <>
           <tr>
            <td className="sub-cell">{retailer}</td>
            <td className="sub-cell" style={{ textAlign: "right"  }}>{`$${parseFloat(val?.amount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`}</td>
           </tr>
           <tr>
           { PrintLocationData?.filter(
        (vl) => vl.retailer === retailer
      ).map((val) => val).length>0?
           
            <td colSpan={2} style={{ padding: "0 50px"  }}>
              <table className="table table-striped">
              <tr>
                <td className="subplus-cell w-75">Location </td>
                <td className="subplus-cell w-25">Amount</td>
              </tr>
              {PrintLocationData?.map((loc) => {
        if(loc.retailer ===retailer){
  
         return(
              <>
              <tr>
                   {loc?.location?.storeID ? (
                <td className="w-75"> 
                  {loc?.location?.address},{loc?.location?.city}
                  {loc?.location?.province}, {loc?.location?.postal_code},
                  {loc?.location?.country}, Store #{loc?.location?.storeID}
                </td>

              ) : (
                <td className="w-75">
                  {loc?.location?.address}, {loc?.location?.city},{" "}
                  {loc?.location?.province}, {loc?.location?.postal_code},{" "}
                  {loc?.location?.country}
                </td>
               
              )}
              <td className="w-25">{`$${parseFloat(loc?.amount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`}</td>
              </tr>
              {loc?.orderDetails?.length>0 ?
              <tr>
                <td colSpan={2}>
                  <table className="w-100">
                    <tr>
                      <td className="subplplus-cell">Donor Name</td>
                      <td className="subplplus-cell">Address</td>
                      <td className="subplplus-cell">Order ID</td>
                      <td className="subplplus-cell">Card Type</td>
                      <td className="subplplus-cell">Amount </td>
                      <td className="subplplus-cell" >Date</td>
                      <td className="subplplus-cell">Email</td> 
                    </tr>
                    {loc?.orderDetails?.map((donor) =>{
                      return(
                        <>
                          <tr>
                            <td>{donor?.donorName}</td>
                            <td>{donor?.address}</td>
                            <td>{donor?.orderID}</td>
                            <td>{donor?.cardType}</td>
                            <td>${parseFloat(donor?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} </td>
                            <td className="twrap">{donor?.date}</td>
                            <td>{donor?.email}</td> 
                          </tr>
                        </>
                      )
                    })
                  }
                  </table>
                </td>
              </tr>
              : ""}
              </>
                )} } )}
              
              </table>
            </td>
          
           : ""}
            </tr>
           </>
           );
                }
          })}
          
          </tbody>
        </table>
      </div>
      
    );
  }
}
