import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import moment from "moment";

export default function PrintMonthlyComponent(props) {
  let componentRef = useRef();
  return (
    <>
      <div id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentMonthlyToPrint
            data={props.data}
            ref={(el) => (componentRef = el)}
          />
        </div>
      </div>
    </>
  );
}

// component to be printed
class ComponentMonthlyToPrint extends React.Component {
  render() {
    let donatorList = this.props.data;
    return (
      <div>
        <h2 style={{ color: "green" }}>Monthly Recurring Donations</h2>
        <table className="table table-striped mt-3">
          <thead className="thead-light">
            <tr>
              <th>Donor Name</th>
              <th style={{ textAlign: "center" }}>Address</th>
              <th style={{ textAlign: "center" }}>Email</th>
              <th style={{ textAlign: "center" }}>Order ID</th>
              <th style={{ textAlign: "center" }}>Amount</th>
              <th style={{ textAlign: "center" }}>First Payment</th>
              <th style={{ textAlign: "center"}}>No. of Payments</th>
              <th style={{ textAlign: "center" }}>Final Payment</th>
            </tr>
          </thead>
          <tbody>
            {donatorList
              ? donatorList?.map((donator) => {
                  let text1 = donator.orderID;
                  let orderid = text1?.slice(0, 6);

                  let text = donator.created_at;
                  let val = text?.slice(0, 10);
                  let created_at = moment(val).format("MM-DD-YYYY");

                  return (
                    <tr>
                      <td>
                        {donator.donorName}
                      </td>
                      {donator.address ? (
                        <td style={{ textAlign: "center" }}>
                          {donator.address}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td style={{ textAlign: "center" }}>{donator.emailAddress}</td>
                      <td style={{ textAlign: "center" }}>{orderid}</td>
                      {donator.amount.includes(".") ? (
                        <td
                          style={{ textAlign: "right" }}
                        >{`$${donator.amount}`}</td>
                      ) : (
                        <td
                          style={{ textAlign: "right" }}
                        >{`$${donator.amount}.00`}</td>
                      )}
                      <td style={{ textAlign: "center" }}>{ moment(donator.startDate.slice(0, 10)).format("MM-DD-YYYY")}</td>
                      <td style={{ textAlign: "center" }}>{donator.recurringNo}</td>
                      <td style={{ textAlign: "center" }}>{donator.recurringNo!==1?moment(donator.startDate).add(donator.recurringNo - 1, 'M').format("MM-DD-YYYY"):''}</td>
                     

                    </tr>
                  );
                })
              : `${"No Record Found"}`}
          </tbody>
        </table>
      </div>
    );
  }
}
