import axios from "axios";
import { APIBase_URL } from "../../../config";
import jwt_decode from "jwt-decode";
import Export from "react-data-table-component"

export const getTaxInfoData = async () => {
  //const result1 = await fetch(`${APIBase_URL}taxinfo`);
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  // console.log(decodedData,"Current User Data.");
  const charityId = decodedData?.charityId;
  // const charityId = JSON.parse(charityIdDetail);
  const result1 = await fetch(`${APIBase_URL}taxinfo`, {
    method: "GET",
    headers: {
      ...(typeof(charityId) !=='undefined' && {Authorization: `${charityId}`}),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = result1.json();
  // console.log(result, "------123");
  return result;
};

export const getAllLotteryData = async () => {
  //const result1 = await fetch(`${APIBase_URL}taxinfo`);
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = decodedData?.charityId;
  const result1 = await fetch(`${APIBase_URL}taxinfo/getAllLottery`, {
    method: "GET",
    headers: {
      ...(typeof(charityId) !=='undefined' && {Authorization: `${charityId}`}),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = result1.json();
  // console.log(result, "------");
  return result;
};

export const getTaxInfoMonthlyData = async () => {
  //const result1 = await fetch(`${APIBase_URL}taxinfo/getAllMonthlyList`);
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = decodedData?.charityId;
  const result1 = await fetch(`${APIBase_URL}taxinfo/getAllMonthlyList`, {
    method: "GET",
    headers: {
      ...(typeof(charityId) !=='undefined' && {Authorization: `${charityId}`}),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = result1.json();
  // console.log(result, "------");
  return result;
};

export const getAllTransList = async () => {
  //const result1 = await fetch(`${APIBase_URL}taxinfo/getAllMonthlyList`);
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = decodedData?.charityId;
  const result1 = await fetch(`${APIBase_URL}taxinfo/getAllTransList`, {
    method: "GET",
    headers: {
      ...(typeof(charityId) !=='undefined' && {Authorization: `${charityId}`}),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = await result1.json();
  // console.log(result, "------");
  return result;
};

export const getAllTransListLottery = async () => {
  //const result1 = await fetch(`${APIBase_URL}taxinfo/getAllMonthlyList`);
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = decodedData?.charityId;
  const result1 = await fetch(`${APIBase_URL}taxinfo/getAllTransListLottery`, {
    method: "GET",
    headers: {
      ...(typeof(charityId) !=='undefined' && {Authorization: `${charityId}`}),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const result = await result1.json();
  // console.log(result, "------");
  return result;
};


export const addTaxInfoData = async (data) => {
  // console.log(data, "----");
  const result = await axios.post(`${APIBase_URL}taxinfo`, data);
  // console.log(result);
  return result;
};

export const updateTaxInfoData = async (data, taxInfoID) => {
  
  const result1 = await fetch(`${APIBase_URL}taxinfo/${taxInfoID}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = result1.json();
  // console.log(result);
  return result;
};

export const getTaxInfoDataByEmail = async (data) => {
  const result1 = await fetch(`${APIBase_URL}taxinfo/getTaxInfomation/${data}`);
  const result = result1?result1.json():{};
  return result;
};

