import React, { useEffect, useState } from "react";
import "./style.css";
import { useLocation, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import GooglePayButton from "@google-pay/button-react";
import ApplePayButton from "./ApplePay/ApplePayButton";
import { merchantIdentifier } from "./ApplePay/PaymentConf";
import { performPayment } from "./ApplePay/PaymentApi";
import "./donation.css";
import { useHistory } from "react-router";
import CryptoJS from "crypto-js";
import styles from "./donation.module.css";
import FooterWeb from "./Footer/FooterWeb";
import { useStateValue } from "../stateProvider";
import Validator from "validator";
import { getProvinceData } from "./API/ProvinceAPI/ProvinceAPI";
import { getTaxInfoDataByEmail } from "./API/TaxInfoAPI/TaxInfoAPI";
import Loader from "./Spinner/Loader";
import logo from "../logo/BNAedit.png";

import {
  APIBase_URL,
  GLOBLE_PAY_Merchant_Id,
  GPAY_Merchant_Id,
  GLOBLE_ENVIRONMENT,
} from "../config";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
} from "./constant";
import {
  stripeCardNumberValidation,
  stripeCardExpirValidation,
  textWithSpacesOnly,
  minLength,
  textAndSpacesOnly,
  texEmailOnly,
} from "./validations";

function Donation(props) {
  const [{privacy}, dispatch] = useStateValue();
  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [data, setData] = useState("");
  const [charitydata, setCharityData] = useState("");
  const [amount, setAmount] = useState([]);
  const [hidediv, sethidediv] = useState(false);
  const [currentAmount, setCurrentAmount] = useState("");
  const [clogo, setLogo] = useState("");
  const [mapAmount, setMapAmount] = useState("");
  const [mapMonthlyAmount, setMapMonthlyAmount] = useState("");
  const [fees, setFees] = useState("");
  const [isOhterAmountBox, setIsOhterAmountBox] = useState(true);
  const [isAmountBox, setIsAmountBox] = useState(true);
  const [isOtherform, setIsOtherform] = useState(false);
  const [otherAmount, setOtherAmount] = useState("");
  const [isprocessFee, setIsProcessFee] = useState(0);
  const [processFeeAmount, setProcessFeeAmount] = useState("");
  const [processFeeAmountPer, setProcessFeeAmountPer] = useState(0);
  //Language State
  const [selectDonationLan, setSelectDonationLan] = useState("");
  const [amountLan, setAmountLan] = useState("");
  const [otherLan, setOtherLan] = useState("");
  const [donationMesapplePayLan, setDonationMesapplePayLan] = useState("");
  const [donationMesgPayLan, setDonationMesgPayLan] = useState("");
  const [submitLan, setSubmitLan] = useState("");
  const [cancelLan, setCancelLan] = useState("");
  const [amountRequiredLan, setAmountRequiredLan] = useState("");
  const [backLan, setBackLan] = useState("");
  const [typeLan, setTypeLan] = useState("");
  const [englishAmountSign, setEnglishAmountSign] = useState("");
  const [frenchAmountSign, setFrenchAmountSign] = useState("");
  const [cardButtonLan, setCardButtonLan] = useState("");
  const [orLan, setOrLan] = useState("");
  const [cardPayButtonLan, setCardPayButtonLan] = useState("");
  const [cardNumberLan, setCardNumberLan] = useState("");
  const [cardHolderLan, setCardHolderLan] = useState("");
  const [cardExpiryMonthLan, setCardExpiryMonthLan] = useState("");
  const [cardExpiryYearLan, setCardExpiryYearLan] = useState("");
  const [cardCvnLan, setCardCvnLan] = useState("");
  const [requuireMessLan, setRequuireMessLan] = useState("");
  const [requuireMessNoOfPayments, setRequuireMessNoOfPayments] = useState("");
  const [requuireMessNoOfPaymentsFormat, setRequuireMessNoOfPaymentsFormat] =useState("");
  const [requuireMessStartDateLan, setRequuireMessStartDateLan] = useState("");
  const [requireMessStartDateFormat, setRequireMessStartDateFormat] = useState("");
  const [cardInvalidMesLan, setCardInvalidMesLan] = useState("");
  const [onlyAlphabetLan, setOnlyAlphabetLan] = useState("");
  const [invaildDataFormatLan, setInvaildDataFormatLan] = useState("");
  const [cvnErrorMessLan, setCvnErrorMessLan] = useState("");
  const [creditCardPageMessLan, setCreditCardPageMess] = useState("");
  const [cardDetailsMessLan, setCardDetailsMessLan] = useState("");
  const [firstNameLan, setfirstNameLan] = useState("");
  const [lastNameLan, setlastNameLan] = useState("");
  const [billingAddressLineLan, setbillingAddressLineLan] = useState("");
  const [billingAddressLineLan2, setbillingAddressLineLan2] = useState("");
  const [addressLan, setaddressLan] = useState("");
  const [cityLan, setcityLan] = useState("");
  const [postalCodeLan, setPostalCodeLan] = useState("");
  const [provinceLan, setprovinceLan] = useState("");
  const [emailLan, setemailLan] = useState("");
  const [phoneLan, setphoneLan] = useState("");
  const [billingAddressLan, setbillingAddressLan] = useState("");
  const [countryLan, setcountryLan] = useState("");
  const [canadaLan, setcanadaLan] = useState("");
  const [unitedSatesLan, setunitedSateLan] = useState("");
  const [emailInvalidLan, setemailInvalidLan] = useState("");
  const [monthlyLan, setmonthlyLan] = useState("");
  const [oneTimeLan, setoneTimeLan] = useState("");
  const [startDateLan, setstartDateLan] = useState("");
  const [noOfPaymentLan, setnoOfPaymentLan] = useState("");
  const [thankYouMesAgainLan, setthankYouMesAgainLan] = useState("");
  const [taxRecieptInfoLan, settaxRecieptInfoLan] = useState("");
  const [processFeeLan, setProcessFeeLan] = useState("");

  const [isOneTime, setIsOneTime] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [numTime, setNumTime] = useState("");

  const [cardSection, setCardSection] = useState(false);
  const [payButton, setPaybutton] = useState(true);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  //const [cardExpiryDate, setCardExpiryDate] = useState("");
  const [cardExpiryMonth, setCardExpiryMonth] = useState("");
  const [cardExpiryYear, setCardExpiryYear] = useState("");
  const [cardCvn, setCardCvn] = useState("");
  const [cardType, setCardType] = useState();
  const [error, setError] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [billingAddress, setBillingAddress] = useState("");
  // const [billingAddress2, setBillingAddress2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  //const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  //const [phone, setPhone] = useState("");
  const [provinceList, setProvinceList] = useState("");
  const [address, setAddress] = useState("");

  //const [donationFreq, setDonationFreq] = useState("");

  const [thankYouInfo, setthankYouInfo] = useState(false);
  const [donationPageInfo, setdonationPageInfo] = useState(true);
  const [formData, setFormData] = useState("");
  const [isLoder, setisLoder] = useState(false);
  const [color, setColor] = useState("#03989e");
  const [fontColor, setFontColor] = useState('#000000');
  const [btnFontColor, setBtnFontColor] = useState('#FFFFFF');
  const [spinner, setSpinner] = useState(true);
  
  var donationId1 = useLocation();
  
  var dataObj = donationId1.pathname;
  const str = dataObj;
  const data1 = str.substring(str.lastIndexOf("donation/") + 9, str.length);
 
  const bytes = CryptoJS.AES.decrypt(data1, "secret key 123");
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  const originalStr = originalText.split(",");

  const donationID = originalStr[0];
  const retailerID = originalStr[1];
  const locationID = originalStr[2];
  const taxInfo = originalStr[3];
 
  const qrCodeID =
    typeof originalStr[4] === "undefined"
      ? donationID === "6238a251af6c492314c85755"
        ? "6238a3bcaf6c492314c85776"
        : "62336d2bf091241e1ca13871"
      : originalStr[4];
  //const qrCodeID = originalStr[4]
  const qrActive = originalStr[5];

  const processingFee = (event) => {
    if (event.target.checked) {
      setFees(event.target.value);
    } else {
      setFees("0");
    }
  };

  const custom = (amount) => {
    var calculate = (amount * fees) / 100;
    let amount1 = Number(calculate) + Number(amount);
    return (processFeeAmount?amount1.toFixed(2):amount1).toString().replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      "$1,"
    );
  };

  const handleClick = (amount) => { 
    let processFee = processFeeAmount?((parseFloat(processFeeAmount)/ 100) * parseFloat(amount)).toFixed(2):0
    let totalAmount = processFeeAmount?parseFloat(amount) + parseFloat(processFee):amount

    setthankYouInfo(true);
    setProcessFeeAmountPer(processFee)
    setCurrentAmount(totalAmount);
    sethidediv(true);
    setIsOhterAmountBox(false);
    setdonationPageInfo(false);
  };
  const openOtherAmount = () => {
    setIsOhterAmountBox(false);
    setIsAmountBox(false);
    setIsOtherform(true);
  };

  const cancelOtherAmount = () => {
    //setCurrentAmount('');
    //setOtherAmount('')
    setIsOtherform(false);
    setIsAmountBox(true);
    setIsOhterAmountBox(true);
    setthankYouInfo(false);
    setdonationPageInfo(true);
  };
  const donationInfoPageOpem = () => {
    setdonationPageInfo(true);
    setthankYouInfo(false);
  };

  const cancelThankYou = () => {
    sethidediv(false);
    setCurrentAmount("");
    setIsOtherform(false);
    setIsAmountBox(true);
    setIsOhterAmountBox(true);
    setthankYouInfo(false);
    setdonationPageInfo(true);
    setProcessFeeAmountPer(0)
  };
  const otherAmountSelect = () => {
    let processFee = processFeeAmount?((parseFloat(processFeeAmount)/ 100) * parseFloat(otherAmount)).toFixed(2):0
    let totalAmount = processFeeAmount?parseFloat(otherAmount) + parseFloat(processFee):otherAmount
    setthankYouInfo(true);
    setdonationPageInfo(false);
    setProcessFeeAmountPer(processFee)
    setCurrentAmount(totalAmount);
    sethidediv(true);
    setIsOhterAmountBox(true);
    setIsOtherform(false);
  };

  const cardSectionOpen = () => {
    let errorCheckforMonthly = checkErrorBeforeSaveMonthly();
    if ((!errorCheckforMonthly && isOneTime === false) ) {
      setCardSection(true);
      setPaybutton(false);
    }else if(isOneTime === true){
      setCardSection(true);
      setPaybutton(false);
    }
  
  };
  const cancelCardPayment = () => {
    setCardSection(false);
    setPaybutton(true);
  };

  const onClick = (
    amount,
    charityName,
    clogo,
    dataObj,
    donationID,
    qrCodeID,
    locationID,
    taxinfo
  ) => {
    performPayment(
      amount,
      charityName,
      clogo,
      dataObj,
      donationID,
      qrCodeID,
      locationID,
      taxinfo
    )
      .then((Status) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // async function fetchQRStatus(){
  //   const res = await axios
  //       .get(`${APIBase_URL}charity/qrCode/status/${qrCodeID}`)
  //       .then((resp) => {
  //         const status = resp.data.active;
  //         console.log(status, "Print status data ....../////")
  //         res.send(status);
  //       })
  //       .catch((error) => {
  //         console.log(error, "error");
  //       });
  // }

  useEffect(() => {
    // const status = fetchQRStatus();
    // console.log(status, "Response Dataa//")
    async function fetchData() {

      const res = await axios
        .get(`${APIBase_URL}charity/donation/${donationID}/${locationID}`)
        .then((resp) => {
          const ab = resp.data;         
          //  console.log(resp.data, "Print donation based location data ////////");
          const oneTimeam = ab.donation_amount.map((val) => {
            return val.amount.map((val1) =>
              val1.map((val2) => setMapAmount((p) => [...p, `${val2}`]))
            );
          });

          //console.log(ab.monthlyDonationAmount,'ab.monthlyDonationAmount')
          const monthlyAm = ab.monthlyDonationAmount.map((val1) =>
            val1.map((val2) => setMapMonthlyAmount((p) => [...p, `${val2}`]))
          );

          dispatch({
            type: "LANGUAGE",
            data: `${ab.languageData.poweredBy}`,
          });
          dispatch({
            type: "PRIVACY",
            data: `${ab.languageData.privacyPolicy}`,
          });
          setCharityData(ab);
          if(ab.color !=='' || ab.color !==null || ab.color !==undefined){
            setColor(ab.color);
            localStorage.setItem("color", ab.color);
          }
          if(ab.fontColor !=='' || ab.fontColor !==null || ab.fontColor !==undefined){
            setFontColor(ab.fontColor);
            setBtnFontColor(ab.fontColor);
            localStorage.setItem("fontColor", ab.fontColor);
          }

          setSelectDonationLan(ab.languageData.selectDonation);
          setAmountLan(ab.languageData.amount);
          setOtherLan(ab.languageData.other);
          setDonationMesapplePayLan(ab.languageData.donationMesapplePay);
          setDonationMesgPayLan(ab.languageData.donationMesgPay);
          setSubmitLan(ab.languageData.submit);
          setCancelLan(ab.languageData.cancel);
          setAmountRequiredLan(ab.languageData.amountRequired);
          setBackLan(ab.languageData.back);
          setTypeLan(ab.languageData.type);
          setmonthlyLan(ab.languageData.monthly);
          setoneTimeLan(ab.languageData.oneTime);
          setCardButtonLan(ab.languageData.cardButton);
          setOrLan(ab.languageData.or);
          setCardPayButtonLan(ab.languageData.cardPayButton);
          setCardNumberLan(ab.languageData.cardNumber);
          setCardHolderLan(ab.languageData.cardHolder);
          setCardExpiryMonthLan(ab.languageData.cardExpiryMonth);
          setCardExpiryYearLan(ab.languageData.cardExpiryYear);
          setCardCvnLan(ab.languageData.cardCvn);
          setRequuireMessLan(ab.languageData.requuireMess);
          setRequuireMessNoOfPayments(ab.languageData.requuireMessNoOfPayments);
          setRequuireMessNoOfPaymentsFormat(ab.languageData.requuireMessNoOfPaymentsFormat);
          setRequuireMessStartDateLan(ab.languageData.requuireMessStartDate);
          setRequireMessStartDateFormat(ab.languageData.requireMessStartDateFormat);
          setCardInvalidMesLan(ab.languageData.cardInvalidMes);
          setOnlyAlphabetLan(ab.languageData.onlyAlphabet);
          setInvaildDataFormatLan(ab.languageData.invaildDataFormat);
          setCvnErrorMessLan(ab.languageData.cvnErrorMess);
          setCreditCardPageMess(ab.languageData.creditCardPageMess);
          setCardDetailsMessLan(ab.languageData.cardDetailsMess);

          setfirstNameLan(ab.languageData.firstName);
          setlastNameLan(ab.languageData.lastName);
          setbillingAddressLineLan(ab.languageData.billingAddressLine);
          setbillingAddressLineLan2(ab.languageData.billingAddressLine2);
          setaddressLan(ab.languageData.address);
          setcityLan(ab.languageData.city);
          setPostalCodeLan(ab.languageData.postalCode);
          setprovinceLan(ab.languageData.province);
          setemailLan(ab.languageData.email);
          setphoneLan(ab.languageData.phone);
          setbillingAddressLan(ab.languageData.billingAddress);
          setcountryLan(ab.languageData.country);
          setcanadaLan(ab.languageData.canada);
          setunitedSateLan(ab.languageData.unitedStates);
          setemailInvalidLan(ab.languageData.emailInvalid);
          setstartDateLan(ab.languageData.startDate);
          setnoOfPaymentLan(ab.languageData.noOfPayment);
          setthankYouMesAgainLan(ab.languageData.thankYouMesAgain);
          settaxRecieptInfoLan(ab.languageData.taxRecieptInfo);
          setProcessFeeLan(ab.languageData.processingFee)
          if (ab.languageData.type == "English") {
            setEnglishAmountSign("$");
          } else {
            setFrenchAmountSign("$");
          }
          setLogo(ab["charityLogo"]);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setSpinner(false);
        });
    }

    async function fetchQRStatus(){
       await axios
          .get(`${APIBase_URL}qrCode/status/${qrCodeID}`)
          .then((resp) => {
            const status = resp.data;
              
            // console.log(status, "Print status data ....../////")
            if(status === false){
              fetchData()
          } else if(status === true)
          {
            // localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataInvalid", 'Charity');
            history.push({ pathname: "/invalidQr" });

          }
          })
          .catch((error) => {
            console.log(error, "error");
            setSpinner(false);
          });
    }
   fetchQRStatus()
    //fetchData();
    getProvince();
    //getTransaction();
    // getOnPaymentAuthorized();
  }, []);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const getTransaction = async () => {
    const res = await fetch(`${APIBase_URL}transaction`);
    res.json().then((resp) => {
    });
  };

  const showDonationAmount = () => { 
    ///console.log("go to showDonationAmount");
    setthankYouInfo(false);
    setdonationPageInfo(true);
    setCardSection(false);
    setPaybutton(true);

    sethidediv(false);
    setCurrentAmount("");
    setIsOtherform(false);
    setIsAmountBox(true);
    setIsOhterAmountBox(true);
  };

  const goToCharity = () => {
    //console.log("go to charity");
    // history.back(-1);
  };

  let check = mapAmount.length;
  let check2 = mapMonthlyAmount.length;
  const dis = {
    display: "inline-block",
  };

  //let name = [...mapAmount];
  //let name2 = [...mapMonthlyAmount];
  let name = [...new Set(mapAmount)];
  let name2 = [...new Set(mapMonthlyAmount)];

  localStorage.setItem(
    "languageData",
    JSON.stringify(charitydata.languageData)
  );
  localStorage.setItem(
    "taxReceiptMes",
    charitydata.sendGridTemplateId && charitydata.templateName && charitydata.createTaxReceipt === 1 && (charitydata.charityType==="Donations" || charitydata.charityType==="Both" )?1:0
  );
   
  localStorage.setItem(
    "lotterySucessfulMsg",'Donations');
 

  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
        return card;
    }
    return "";
  }

  const handleInputData = (e) => {
    setCardNumber(e.target.value);
  };
  const handleInputData2 = (e) => {
    setCardHolderName(e.target.value);
  };

  const handleInputData4 = (e) => {
    setCardCvn(e.target.value);
  };

  const handleMonthData = (e) => {
    setCardExpiryMonth(e.target.value);
  };
  const handleYearData = (e) => {
    setCardExpiryYear(e.target.value);
  };

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case "card":
        setCardType(findDebitCardType(value));
        errorText = stripeCardNumberValidation(value, cardInvalidMesLan);
        setError({ ...error, cardError: errorText });
        break;
      case "cardHodler":
        errorText =
          value === ""
            ? requuireMessLan
            : textWithSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, cardHodlerError: errorText });
        break;
      case "expiry":
        errorText =
          value === ""
            ? requuireMessLan
            : stripeCardExpirValidation(value, invaildDataFormatLan);
        setError({ ...error, expiryError: errorText });
        break;
      case "securityCode":
        errorText =
          value === "" ? requuireMessLan : minLength(3)(value, cvnErrorMessLan);
        setError({ ...error, securityCodeError: errorText });
        break;
      case "expiryMonth":
        errorText =
          value === ""
            ? requuireMessLan
            : textAndSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, expiryMonthError: errorText });
        break;
      case "expiryYear":
        errorText =
          value === ""
            ? requuireMessLan
            : textAndSpacesOnly(value, onlyAlphabetLan);
        setError({ ...error, expiryYearError: errorText });
        break;
      default:
        break;
    }
  };
  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };
  const checkErrorEmailBeforeSave = (e) => {
    let errorText12 =
      e === "" ? requuireMessLan : texEmailOnly(e, emailInvalidLan);
    if (!errorText12) {
      getTaxInfoDataByEmail(e).then((resp) => {
        setFirstName(resp.firstName);
        setLastName(resp.lastName);
        setCity(resp.city);
        setProvince(resp.province);
        setPostalCode(resp.postalCode);
        setAddress(resp.address);
        let newData = {
          firstName: resp.firstName,
          lastName: resp.lastName,
          city: resp.city,
          province: resp.province,
          postalCode: postalCodeFormat(resp.postalCode),
          address: resp.address,
        };

        setFormData(newData);
      });
    }
  };
  const checkErrorBeforeSaveMonthly =() =>{
    let currentDate = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-");
    let errorValueMonthly ={};
    let isErrorMonthly = false;
    let errorTextCalRequired =startDate ==="" ? requuireMessStartDateLan: startDate <= currentDate? requireMessStartDateFormat: '';
    let errorTextNumTimeRquired = numTime === "" ? requuireMessNoOfPayments: numTime === '0'? requuireMessNoOfPaymentsFormat : '';
  
    if (errorTextCalRequired) {
      errorValueMonthly = {
        ...errorValueMonthly,
          startDateError: errorTextCalRequired ,
      };
      isErrorMonthly = true;
    }

    if (errorTextNumTimeRquired) {
      errorValueMonthly = {
        ...errorValueMonthly,
          numTimeError:  errorTextNumTimeRquired,
      };
      isErrorMonthly = true;
    }
  
    setError(errorValueMonthly);
    return isErrorMonthly;

  }
  const checkErrorBeforeSave = () => {
    let errorValue = {};
    let isError = false;
    let errorText1 = stripeCardNumberValidation(cardNumber, cardInvalidMesLan);
    let errorText2 =
      cardHolderName === ""
        ? requuireMessLan
        : textWithSpacesOnly(cardHolderName, onlyAlphabetLan);
    let errorText4 =
      cardCvn === "" ? requuireMessLan : minLength(3)(cardCvn, cvnErrorMessLan);
    let errorText5 =
      cardExpiryMonth === ""
        ? requuireMessLan
        : textAndSpacesOnly(cardExpiryMonth, onlyAlphabetLan);
    let errorText6 =
      cardExpiryYear === ""
        ? requuireMessLan
        : textAndSpacesOnly(cardExpiryYear, onlyAlphabetLan);
    if (errorText1) {
      errorValue = {
        ...errorValue,
        cardError: cardNumber ? errorText1 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText2) {
      errorValue = {
        ...errorValue,
        cardHodlerError: cardHolderName ? errorText2 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText4) {
      errorValue = {
        ...errorValue,
        securityCodeError: cardCvn ? errorText4 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText5) {
      errorValue = {
        ...errorValue,
        expiryMonthError: cardExpiryMonth ? errorText5 : requuireMessLan,
      };
      isError = true;
    }
    if (errorText6) {
      errorValue = {
        ...errorValue,
        expiryYearError: cardExpiryYear ? errorText5 : requuireMessLan,
      };
      isError = true;
    }

    setError(errorValue);
    return isError;
  };
  const handleSave = (e) => {
    let errorCheck = checkErrorBeforeSave();
    if (!errorCheck) {
      setisLoder(true)
      let cardNumberText = CryptoJS.AES.encrypt(
        cardNumber,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      let cardCvnText = CryptoJS.AES.encrypt(
        cardCvn,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      let cardTypeText = CryptoJS.AES.encrypt(
        cardType,
        GLOBLE_PAY_Merchant_Id
      ).toString();
      const data = {
        amount: parseFloat(currentAmount).toFixed(2),
        charityName: charitydata.charityName,
        charityID: donationID,
        retailerID: retailerID,
        locationID: locationID,
        qrCodeID: qrCodeID,
        cardNumber: cardNumberText,
        cardHolderName: cardHolderName,
        expMonth: cardExpiryMonth,
        expYear: cardExpiryYear.slice(-2),
        cvn: cardCvnText,
        cardType: cardTypeText,
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        email: email,
      };
      axios
        .post(`${APIBase_URL}creditCard`, data)
        .then((resp) => {
          setisLoder(false)
          let result1 = JSON.stringify(resp.data.result);
          let orderID = JSON.stringify(resp.data.orderid);

          let result = result1.toString();
          var arrayWithEventIds = result.replace(/["]+/g, "");
          let arrnew = arrayWithEventIds.replace(/\[|\]/g, "");

          let orderID1 = orderID.toString();
          var arrayWithEventIds1 = orderID1.replace(/["]+/g, "");
          let newOrderID = arrayWithEventIds1.replace(/\[|\]/g, "");
         
          let a = "00";
          if (arrnew === a) {
            //alert(qrCodeID)
            localStorage.setItem("am", currentAmount);
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            localStorage.setItem("orderID", newOrderID);
            localStorage.setItem(
              "paymentRequest",
              JSON.stringify(resp.data.tokenData)
            );
            localStorage.setItem("qrCodeID", qrCodeID);
            history.push({ pathname: "/thankyou" });
          } else {
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            history.push({ pathname: "/failed" });
          }
        })
        .catch((err) => {
         setisLoder(false)
         
        });
      //setCardType("");
    }
  };

  const handleSaveMonthly = (e) => {
    let errorCheck = checkErrorBeforeSave();
    if (!errorCheck) {
      setisLoder(true)
      const data = {
        amount: parseFloat(currentAmount).toFixed(2),
        charityName: charitydata.charityName,
        charityID: donationID,
        retailerID: retailerID,
        locationID: locationID,
        qrCodeID: qrCodeID,
        cardNumber: cardNumber,
        cardHolderName: cardHolderName,
        expMonth: cardExpiryMonth,
        expYear: cardExpiryYear.slice(-2),
        cvn: cardCvn,
        cardType: cardType,
        startDate: startDate,
        numTime: numTime,
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        email: email,
      };
      axios
        .post(`${APIBase_URL}createCustomerAndPay`, data)
        .then((resp) => {
          setisLoder(false)
          let result1 = JSON.stringify(resp.data.result);
          let orderID = JSON.stringify(resp.data.orderid);

          let result = result1.toString();
          var arrayWithEventIds = result.replace(/["]+/g, "");
          let arrnew = arrayWithEventIds.replace(/\[|\]/g, "");

          let orderID1 = orderID ? orderID.toString() : "";
          var arrayWithEventIds1 = orderID1.replace(/["]+/g, "");
          let newOrderID = arrayWithEventIds1.replace(/\[|\]/g, "");
         
          let a = "00";
          if (arrnew === a) {
            //alert(qrCodeID)
            localStorage.setItem("am", currentAmount);
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            localStorage.setItem("orderID", newOrderID);
            localStorage.setItem(
              "paymentRequest",
              JSON.stringify(resp.data.tokenData)
            );
            localStorage.setItem("qrCodeID", qrCodeID);
            history.push({ pathname: "/thankyou" });
          } else {
            localStorage.setItem("clogo", clogo);
            localStorage.setItem("dataObj", dataObj);
            history.push({ pathname: "/failed" });
          }
        })
        .catch((err) => {
          setisLoder(false)
          console.log(err, "error");
        });
      //setCardType("");
    }
  };
  const handleChangeNumTime = (e) => {
    const selectedNum = e.target.value;
    setNumTime(selectedNum);
  };
  const handleCalender = (e) => {
    const ab = e.target.value;
    setStartDate(ab);
  };
  const handlePayType = (e) => {
    setIsOneTime(true);
  };
  const handlePayType2 = (e) => {
    setIsOneTime(false);
  };
  const getProvince = async () => {
    getProvinceData().then((resp) => {
      setProvinceList(resp);
    });
  };

  const handleProcessingFee = (e) => {
    e.target.checked === true?setProcessFeeAmount(e.target.value):setProcessFeeAmount('')
  };

  const monthList = [];

  for (let i = 0; i <= 12; i++) {
    monthList.push(
      <option className="dropdown-item" value={i}>
        {i}
      </option>
    );
  }

  const monthListCArd = [];

  for (let i = 1; i <= 12; i++) {
    monthListCArd.push(
      <option className="dropdown-item" value={ ('0' + i).slice(-2) }>
        {('0' + i).slice(-2)}
      </option>
    );
  }

  const yearList = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i <= currentYear + 10; i++) {
    yearList.push(
      <option className="dropdown-item" value={i}>
        {i}
      </option>
    );
  }

  function postalCodeFormat(string) {
    if(string){
      let foo = string.split(" ").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,3}", "g")).join(" ");
      }
      return foo;
    }else{
      return ''
    }
    
  }
  // style= {{color: `#6a2936  !important`, fontWeight: "600 !important", backgroundColor:`${color} !important`}}
  return (
    <>
    {spinner === true? (
      <div className="container-fluid"><Loader /></div>):(
    <div className="container-fluid" style={{ color :"#000000", fontWeight: 600, backgroundColor: color?color:"#03989e", backgroundImage: color?color:"#03989e"}}>
      <div className="card-custom2" style={{ color :"#000000", fontWeight: 600, backgroundColor: color?color:"#03989e", backgroundImage: color?color:"#03989e" }}>
        {/* <div className="inner-card max-widthadded" style={{ color :fontColor, fontWeight: 600, backgroundColor: color?color:"#03989e" ,backgroundImage: color?color:"#03989e"}}> */}
        <div className="inner-card max-widthadded" style={{ color : "#000000", fontWeight: 600, backgroundColor: color?color:"#03989e" ,backgroundImage: color?color:"#03989e"}}>
          <div>
            <div className="logo donationLogo_Box">
              <span className="logo-circle charity_logo">
                <img src={`/uploads/${charitydata.charityLogo}`} alt="logo" />
              </span>
            </div>
          </div>
          <div className="custom-card custom-for-all">
            <div className="headingTop mt-1">
              <h2 hidden={hidediv}>{selectDonationLan}</h2>
              <h2 hidden={hidediv}>{amountLan}</h2>
              <p></p>
            </div>
            {charitydata.donationFrequency === "Monthly" ? (
              <div
                className="headingTop mt-1"
                hidden={hidediv}
                style={{ textAlign: "center" }}
              >
                <div className="form-group1">
                  <input
                    type="radio"
                    id="css"
                    name={`fav_language1`}
                    value={true}
                    checked={isOneTime == true}
                    onChange={handlePayType}
                  />
                  <label>&nbsp;{oneTimeLan}&nbsp;</label>
                  <input
                    type="radio"
                    id="html"
                    name={`fav_language1`}
                    value={false}
                    onChange={handlePayType2}
                  />
                  <label>&nbsp;{monthlyLan}</label>&nbsp;
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="custombtns">
              <div className="text-center d-justify-wrap">
                {isOtherform ? (
                  <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                    <div className="form-group">
                      <div
                        className={
                          typeLan == "English" ? "input-icon2" : "input-icon3"
                        }
                      >
                        <i>$</i>
                      </div>
                      <input
                      style={{padding:" 0.375rem 1.2rem"}}
                        className="form-control"
                        type="tel"
                        name="OtherAmount"
                        maxLength="5"
                        onKeyPress={function (event) {
                          var regex = new RegExp("^[0-9]");
                          var key = String.fromCharCode(
                            !event.charCode ? event.which : event.charCode
                          );
                        
                          if (!regex.test(key)) {
                            event.preventDefault();
                            return false;
                          }
                        }}
                        {...register("OtherAmount", {
                          required: `${amountRequiredLan}`,
                        })}
                        onChange={(e) => setOtherAmount(e.target.value)}
                        value={otherAmount}
                      />

                      {errors.OtherAmount && (
                        <small className="text-danger">
                          {errors.OtherAmount.message}
                        </small>
                      )}
                    </div>
                    <div className="customOther_MainBox">
                      <button
                        className="btn btn-primary btnOther customBTNOther"
                        onClick={handleSubmit(otherAmountSelect)}
                        style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                      >
                        {submitLan}
                      </button>
                      <button
                        className="btn btn-primary btnOther customBTNOther"
                        onClick={() => cancelOtherAmount()}
                        style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                      >
                        {cancelLan}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {cardSection ? (
                  <>
                    <div className="headingTop mt-1" style={{ color :"#000000", fontWeight: 600  }}>
                      <h2>{cardDetailsMessLan}</h2>
                      <p></p>
                    </div>
                    <div className={isLoder?"col-md-12 div-block":"col-md-12"} style={{ color :"#000000", fontWeight: 600 }}>
                      <div
                        className="form-group"
                        //style={{ position: "relative" }}
                      >
                        <input
                          className="form-control pr-5"
                          type="tel"
                          name="card"
                          required
                          value={cardNumber}
                          placeholder={`${cardNumberLan}*`}
                          maxLength="16"
                          onKeyPress={function (event) {
                            var regex = new RegExp("^[0-9]");
                            var key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );
                            if (!regex.test(key)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          onChange={handleInputData}
                          onBlur={handleBlur}
                        />
                        {(!error || !error.cardError) &&
                          CARDARR.includes(cardType) && (
                            <img
                              style={{
                                position: "absolute",
                                right: "13px",
                                top: "3px",
                              }}
                              src={CARDICON[cardType]}
                              alt="card"
                              width="50px"
                              height="33px"
                            />
                          )}
                        {error &&
                          error.cardError &&
                          error.cardError.length > 1 && (
                            <small className="text-danger">
                              {error.cardError}
                            </small>
                          )}
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="cardHodler"
                          required
                          placeholder={`${cardHolderLan}*`}
                          value={cardHolderName}
                          onChange={handleInputData2}
                          onBlur={handleBlur}
                        />
                        {error &&
                          error.cardHodlerError &&
                          error.cardHodlerError.length > 1 && (
                            <small className="text-danger">
                              {error.cardHodlerError}
                            </small>
                          )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <select
                              onChange={handleMonthData}
                              className="form-control"
                            >
                              <option className="dropdown-item">{`${cardExpiryMonthLan}*`}</option>
                              {monthListCArd}
                            </select>

                            {error &&
                              error.expiryMonthError &&
                              error.expiryMonthError.length > 1 && (
                                <small className="text-danger">
                                  {error.expiryMonthError}
                                </small>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <select
                              onChange={handleYearData}
                              className="form-control"
                            >
                              <option className="dropdown-item">{`${cardExpiryYearLan}*`}</option>
                              {yearList}
                            </select>

                            {error &&
                              error.expiryYearError &&
                              error.expiryYearError.length > 1 && (
                                <small className="text-danger">
                                  {error.expiryYearError}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          pattern="[0-9]*" 
                          inputmode="numeric"
                          name="securityCode"
                          required
                          placeholder={`${cardCvnLan}*`}
                          value={cardCvn}
                          onKeyPress={function (event) {
                            var regex = new RegExp("^[0-9]");
                            var key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );
                            if (!regex.test(key)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          onChange={handleInputData4}
                          onBlur={handleBlur}
                        />
                        {error &&
                          error.securityCodeError &&
                          error.securityCodeError.length > 1 && (
                            <small className="text-danger">
                              {error.securityCodeError}
                            </small>
                          )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="">
                        <button
                          className="btn btn-primary btnCard"
                          onClick={isOneTime ? handleSave : handleSaveMonthly}
                          style={{ color : btnFontColor, fontWeight: 600, background: color, backgroundImage: color}}
                        >
                          {`${cardPayButtonLan}`}
                        </button>
                        <button
                          className="btn btn-primary btnCard"
                          onClick={() => cancelCardPayment()}
                          style={{ color : btnFontColor, fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                        >
                          {cancelLan}
                        </button>
                      </div>
                    </div>
    
                    {isLoder?
                    <div className="div-image"> 
                      <svg
                        style={{
                          margin: "auto",
                          background: 'rgba(90, 90, 90, 0.5)',
                          display: "block",
                          backgroundColor:'transparent'
                        }}
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g transform="translate(80,50)">
                          <g transform="rotate(0)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="1"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.875s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.875s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(71.21320343559643,71.21320343559643)">
                          <g transform="rotate(45)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.875"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.75s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.75s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(50,80)">
                          <g transform="rotate(90)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.75"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.625s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.625s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(28.786796564403577,71.21320343559643)">
                          <g transform="rotate(135)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.625"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.5s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.5s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(20,50.00000000000001)">
                          <g transform="rotate(180)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.5"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.375s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(28.78679656440357,28.786796564403577)">
                          <g transform="rotate(225)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.375"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.25s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(49.99999999999999,20)">
                          <g transform="rotate(270)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.25"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="-0.125s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                        <g transform="translate(71.21320343559643,28.78679656440357)">
                          <g transform="rotate(315)">
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#03989e"
                              fill-opacity="0.125"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                values="1.5 1.5;1 1"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                              ></animateTransform>
                              <animate
                                attributeName="fill-opacity"
                                keyTimes="0;1"
                                dur="1s"
                                repeatCount="indefinite"
                                values="1;0"
                                begin="0s"
                              ></animate>
                            </circle>
                          </g>
                        </g>
                      </svg>
                    </div>
                    :''}
                  </>
                ) : (
                  ""
                )}
                {/* {"For onetime donation amount"} */}
                {isAmountBox &&
                charitydata.donation_amount &&
                payButton &&
                isOneTime === true &&
                donationPageInfo
                  ? name
                      .sort(function (a, b) {
                        return a - b;
                      })
                      .map((amount, i) => (
                        <div
                          className={`button-section-1`}
                          key={i}
                          style={{
                            display:
                              currentAmount === ""
                                ? ""
                                : (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer))
                                ? ""
                                : "none",
                            padding: "12px 8px",
                            margin: "0px",
                            width: check === 3 && "100%",
                          }}
                        >
                          <div
                            className={`boxElement_btn btnWidth ${
                              (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer)) && styles.wid
                            }`}
                            style={{ width: check === 3 && "100%" }}
                          >
                            <span
                              className="btn11"
                              style={{
                                width: " 100%",
                                fontSize: "25px",
                                fontWeight: " bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                color:btnFontColor,
                                backgroundColor:color,
                              }}
                              onClick={
                                currentAmount === ""
                                  ? () => handleClick(amount)
                                  : undefined
                              }
                            >
                              {`${englishAmountSign} ${
                                (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer))
                                  ? custom(currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer)?parseFloat(amount)+parseFloat(processFeeAmountPer):amount)
                                  : amount.replace(
                                      /(\d)(?=(\d\d\d)+(?!\d))/g,
                                      "$1,"
                                    )
                              }${frenchAmountSign}`}
                            </span>
                          </div>

                          {currentAmount === "" ? (
                            ""
                          ) : (
                            <div className="selectedCharityWithAmount">
                              <span>
                                {navigator.userAgent.match(
                                  /Mac OS| iPad |iPhone |iPod/i
                                )
                                  ? isOneTime
                                    ? donationMesapplePayLan
                                    : creditCardPageMessLan
                                  : isOneTime
                                  ? donationMesgPayLan
                                  : creditCardPageMessLan}
                              </span>
                            </div>
                          )}
                        </div>
                      ))
                  : null}
                {isOhterAmountBox &&
                charitydata.donation_amount &&
                payButton &&
                isOneTime === true &&
                donationPageInfo ? (
                  <div
                    className={`button-section-1`}
                    key="8"
                    style={{
                      padding: "12px 8px",
                      margin: "0px",
                      width: check === 3 && "100%",
                    }}
                  >
                    <div
                      className={`boxElement_btn btnWidth ${
                        currentAmount && styles.wid
                      }`}
                      style={{ width: check === 3 && "100%" }}
                    >
                      <span
                        className="btn11"
                        style={{
                          width: " 100%",
                          fontSize: "25px",
                          fontWeight: " bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color:btnFontColor,
                          backgroundColor:color,
                        }}
                        onClick={
                          currentAmount === ""
                            ? () => openOtherAmount()
                            : undefined
                        }
                      >
                        {currentAmount
                          ? `${englishAmountSign} ${processFeeAmount?parseFloat(currentAmount).toFixed(2):currentAmount}${frenchAmountSign}`
                          : `${otherLan}`}
                      </span>
                    </div>

                    {currentAmount === "" ? (
                      ""
                    ) : (
                      <div className="selectedCharityWithAmount">
                        <span>
                          {navigator.userAgent.match(
                            /Mac OS| iPad |iPhone |iPod/i
                          )
                            ? isOneTime
                              ? donationMesapplePayLan
                              : "Please Select Start Date and month for monthly payment."
                            : isOneTime
                            ? donationMesgPayLan
                            : "Please Select Start Date and month for monthly payment."}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* {"For Monthly donation amount"} */}
                {isAmountBox &&
                charitydata.monthlyDonationAmount &&
                payButton &&
                isOneTime === false &&
                donationPageInfo
                  ? name2
                      .sort(function (a, b) {
                        return a - b;
                      })
                      .map((amount, i) => (
                        <div
                          className={`button-section-1`}
                          key={i}
                          style={{
                            display:
                              currentAmount === ""
                                ? ""
                                : (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer))
                                ? ""
                                : "none",
                            padding: "12px 8px",
                            margin: "0px",
                            width: check2 === 3 && "100%",
                          }}
                        >
                          <div
                            className={`boxElement_btn btnWidth ${
                              (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer)) && styles.wid
                            }`}
                            style={{ width: check2 === 3 && "100%" }}
                          >
                            <span
                              className="btn11"
                              style={{
                                width: " 100%",
                                fontSize: "25px",
                                fontWeight: " bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                color:btnFontColor,
                                backgroundColor:color,
                              }}
                              onClick={
                                currentAmount === ""
                                  ? () => handleClick(amount)
                                  : undefined
                              }
                            >
                              {`${englishAmountSign} ${
                                (currentAmount === amount || currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer))
                                ? custom(currentAmount === parseFloat(amount)+parseFloat(processFeeAmountPer)?parseFloat(amount)+parseFloat(processFeeAmountPer):amount)
                                  : amount.replace(
                                      /(\d)(?=(\d\d\d)+(?!\d))/g,
                                      "$1,"
                                    )
                              }${frenchAmountSign}`}
                            </span>
                          </div>

                          {currentAmount === "" ? (
                            ""
                          ) : (
                            <div className="selectedCharityWithAmount">
                              <span>
                                {navigator.userAgent.match(
                                  /Mac OS| iPad |iPhone |iPod/i
                                )
                                  ? isOneTime
                                    ? donationMesapplePayLan
                                    : creditCardPageMessLan
                                  : isOneTime
                                  ? donationMesgPayLan
                                  : creditCardPageMessLan}
                              </span>
                            </div>
                          )}
                        </div>
                      ))
                  : null}
                {isOhterAmountBox &&
                charitydata.donation_amount &&
                payButton &&
                isOneTime === false &&
                donationPageInfo ? (
                  <div
                    className={`button-section-1`}
                    key="8"
                    style={{
                      padding: "12px 8px",
                      margin: "0px",
                      width: check2 === 3 && "100%",
                    }}
                  >
                    <div
                      className={`boxElement_btn btnWidth ${
                        currentAmount && styles.wid
                      }`}
                      style={{ width: check2 === 3 && "100%" }}
                    >
                      <span
                        className="btn11"
                        style={{
                          width: " 100%",
                          fontSize: "25px",
                          fontWeight: " bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          color: btnFontColor,
                          backgroundColor:color,
                        }}
                        onClick={
                          currentAmount === ""
                            ? () => openOtherAmount()
                            : undefined
                        }
                      >
                        {currentAmount
                          ? `${englishAmountSign} ${processFeeAmount?parseFloat(currentAmount).toFixed(2):currentAmount}${frenchAmountSign}`
                          : `${otherLan}`}
                      </span>
                    </div>

                    {currentAmount === "" ? (
                      ""
                    ) : (
                      <div className="selectedCharityWithAmount">
                        <span>
                          {navigator.userAgent.match(
                            /Mac OS| iPad |iPhone |iPod/i
                          )
                            ? isOneTime
                              ? donationMesapplePayLan
                              : "Please Select Start Date and month for monthly payment."
                            : isOneTime
                            ? donationMesgPayLan
                            : "Please Select Start Date and month for monthly payment."}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {/* {"For Thank you support page"} */}
                {thankYouInfo && donationPageInfo === false ? (
                  <>
                    <div
                      className="custom-modal-head"
                      style={{ marginBottom: "25px", color :"#000000", fontWeight: 600}}
                     
                    >
                      <h4>{`${thankYouMesAgainLan}`}</h4>
                      <h6>{`${taxRecieptInfoLan}`}</h6>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${emailLan}`}</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.email && "invalid"
                          }`}
                          //value={firstName}
                          name="email"
                          required
                          {...register("email", {
                            required: `${requuireMessLan}`,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          onChange={(e) => {
                            checkErrorEmailBeforeSave(e.target.value);
                            setEmail(e.target.value);
                          }}
                        />
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${firstNameLan}`}</label>
                        <input
                          type="text"
                          maxLength={50}
                          className={`form-control ${
                            errors.firstName && "invalid"
                          }`}
                          value={firstName}
                          name="firstName"
                          required
                          {...register("firstName", {
                            required: `${requuireMessLan}`,
                          })}
                          onChange={(e) =>
                            setFirstName(Validator.ltrim(e.target.value))
                          }
                        />
                        {errors.firstName && (
                          <small className="text-danger">
                            {errors.firstName.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${lastNameLan}`}</label>
                        <input
                          type="text"
                          maxLength={50}
                          className={`form-control ${
                            errors.lastName && "invalid"
                          }`}
                          name="lastName"
                          value={lastName}
                          required
                          {...register("lastName", {
                            required: `${requuireMessLan}`,
                          })}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        {errors.lastName && (
                          <small className="text-danger">
                            {errors.lastName.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${addressLan}`}</label>
                        <input
                          type="text"
                          maxLength={55}
                          className={`form-control ${
                            errors.address && "invalid"
                          }`}
                          name="address"
                          value={address}
                          required
                          {...register("address", {
                            required: `${requuireMessLan}`,
                          })}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        {errors.address && (
                          <small className="text-danger">
                            {errors.address.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${cityLan}`}</label>
                        <input
                          type="text"
                          maxLength={50}
                          className={`form-control ${errors.city && "invalid"}`}
                          name="city"
                          value={city}
                          required
                          {...register("city", {
                            required: `${requuireMessLan}`,
                          })}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        {errors.city && (
                          <small className="text-danger">
                            {errors.city.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${provinceLan}`}</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.province && "invalid"
                          }`}
                          name="province"
                          list="browsers"
                          value={province}
                          required
                          {...register("province", {
                            required: `${requuireMessLan}`,
                          })}
                          onClick={(e) => (e.target.value = "")}
                          onMouseLeave={(e) => (e.target.value = province?province:'')}
                          onChange={(e) => setProvince(e.target.value)}
                        />
                        <datalist id="browsers">
                          {provinceList &&
                            provinceList.map((list, id) =>
                              list.province.map((res) => {
                                return <option value={res} />;
                              })
                            )}
                        </datalist>
                        {errors.province && (
                          <small className="text-danger">
                            {errors.province.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="left-side">{`${postalCodeLan}`}</label>
                        <input
                          type="text"
                          onKeyPress={function (event) {
                            var regex = new RegExp("^[a-zA-Z0-9]+$");
                            var key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );
                            if (!regex.test(key)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          className={`form-control ${
                            errors.postalCode && "invalid"
                          }`}
                          maxLength="7"
                          minLength="7"
                          name="postalCode"
                          value={postalCode ? postalCodeFormat(postalCode) : ""}
                          required
                          {...register("postalCode", {
                            required: `${requuireMessLan}`,
                          })}
                          onChange={(e) =>
                            setPostalCode(postalCodeFormat(e.target.value))
                          }
                        />
                        {errors.postalCode && (
                          <small className="text-danger">
                            {errors.postalCode.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary btnOther"
                          onClick={handleSubmit(donationInfoPageOpem)}
                          style={{ color : btnFontColor?btnFontColor:'#FFFFFF', fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                        >
                          {`${submitLan}`}
                        </button>
                        <button
                          className="btn btn-primary btnOther"
                          onClick={() => cancelThankYou()}
                          style={{ color : btnFontColor?btnFontColor:'#FFFFFF', fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                        >
                          {cancelLan}
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12">
                          <p class="Spacing-custom"><a href="https://www.bnasmartpayment.com/privacy-policy" target="_blank" style={{color:'#000000'}}>{`${privacy?privacy:privacy.data?privacy.data:'Privacy Policy'}`}</a></p>
                     
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>       
              {charitydata &&
                donationPageInfo &&                
                (function(){
                    if (Object.values(charitydata).map(item => item === charitydata["contactName"])) {
                    if (
                      currentAmount !== "" &&
                      payButton == true &&
                      isOneTime == false
                    ) {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group1">
                                <label>{startDateLan}:</label>
                                <input
                                  className="form-control"
                                  type="date"
                                  min={new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate() + 1
                                  )
                                    .toJSON()
                                    .slice(0, 10)
                                    .replace(/-/g, "-")}
                                  name="celander"
                                  required
                                  onKeyPress={false}
                                  onChangeCapture={handleCalender}
                                  value={startDate}
                                ></input>
                                 {error &&
                          error.startDateError &&
                          error.startDateError.length > 1 && (
                            <small className="text-danger">
                              {error.startDateError}
                            </small>
                          )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group1">
                                <label>{noOfPaymentLan}:</label>
                                <select
                                  onChange={handleChangeNumTime}
                                  className="form-control"
                                >
                                  {monthList}
                                </select>
                                {error &&
                          error.numTimeError &&
                          error.numTimeError.length > 1 && (
                            <small className="text-danger">
                              {error.numTimeError}
                            </small>
                          )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ textAlign: "center" }}
                            className="App mt-btn-top"
                            //key={i}
                          >
                            <button
                              className="btn btn-primary btnOther maxwidth100"
                              onClick={cardSectionOpen}
                              style={{ color : btnFontColor, fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                            >
                              {cardButtonLan}
                            </button>
                          </div>
                        </>
                      );
                    }
                    if (
                      currentAmount !== "" &&
                      navigator.userAgent.match(
                        /Mac OS X| iPad |iPhone |iPod/i
                      ) &&
                      payButton == true &&
                      isOneTime == true
                    ) {
                      return (
                        <>
                          <div
                            style={{ textAlign: "center" }}
                            className="App mt-btn-top"
                            // key={i}
                          >
                            <ApplePayButton
                              merchantIdentifier={merchantIdentifier}
                              language={typeLan === "French" ? "fr" : ""}
                              onClick={()=>onClick(
                                currentAmount,
                                charitydata.charityName,
                                clogo,
                                dataObj,
                                donationID,
                                qrCodeID,
                                locationID,
                                {
                                  firstName: firstName,
                                  lastName: lastName,
                                  address: address,
                                  city: city,
                                  province: province,
                                  postalCode: postalCode,
                                  email: email,
                                }
                              )}
                              
                            />
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                            className="App end-btn-cstm"
                            // key={i}
                          >
                            <div className="pad-15">{orLan}</div>
                            <button
                              className="btn btn-primary btnCard"
                              onClick={cardSectionOpen}
                              style={{ color :"#000000", fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                            >
                              {cardButtonLan}
                            </button>
                          </div>
                        </>
                      );
                    } else {
                      if (isOneTime == true) {
                        return currentAmount === "" || payButton == false ? (
                          ""
                        ) : (
                          <>
                            <div
                              style={{ textAlign: "center" }}
                              className="App mt-btn-top"
                              // key={i}
                            >
                              <GooglePayButton
                                environment="PRODUCTION"
                                paymentRequest={{
                                  apiVersion: 2,
                                  apiVersionMinor: 0,
                                  allowedPaymentMethods: [
                                    {
                                      type: "CARD",
                                      // ['CARD', 'TOKENIZED_CARD'],
                                      parameters: {
                                        allowedAuthMethods: [
                                          "PAN_ONLY",
                                          "CRYPTOGRAM_3DS",
                                        ],
                                        allowedCardNetworks: [
                                          "MASTERCARD",
                                          "VISA",
                                        ],
                                      },
                                      tokenizationSpecification: {
                                        type: "PAYMENT_GATEWAY",
                                        parameters: {
                                          gateway: "globalpayments",
                                          gatewayMerchantId:
                                            GLOBLE_PAY_Merchant_Id,
                                        },
                                      },
                                    },
                                  ],
                                  merchantInfo: {
                                    merchantId: GPAY_Merchant_Id,
                                    merchantName: "",
                                  },
                                  transactionInfo: {
                                    totalPriceStatus: "FINAL",
                                    totalPriceLabel: "Total",
                                    totalPrice: String(currentAmount),
                                    currencyCode: "CAD",
                                    // countryCode: data.charity.country,
                                  },

                                  shippingAddressRequired: true,
                                  callbackIntents: [
                                    "SHIPPING_ADDRESS",
                                    "PAYMENT_AUTHORIZATION",
                                  ],
                                }}
                                onLoadPaymentData={(paymentRequest) => {
                                  try {
                                    const data = {
                                      amount: currentAmount,
                                      charityName: charitydata.charityName,
                                      charityID: donationID,
                                      retailerID: retailerID,
                                      locationID: locationID,
                                      paymentRequest: paymentRequest,
                                      qrCodeID: qrCodeID,
                                      firstName: firstName,
                                      lastName: lastName,
                                      address: address,
                                      city: city,
                                      province: province,
                                      postalCode: postalCode,
                                      email: email,
                                    };
                                    axios
                                      .post(`${APIBase_URL}gPayDebit`, data)
                                      .then((resp) => {
                                        let result1 = JSON.stringify(
                                          resp.data.result
                                        );
                                        let orderID = JSON.stringify(
                                          resp.data.orderid
                                        );

                                        let result = result1.toString();
                                        var arrayWithEventIds = result.replace(
                                          /["]+/g,
                                          ""
                                        );
                                        let arrnew = arrayWithEventIds.replace(
                                          /\[|\]/g,
                                          ""
                                        );

                                        let orderID1 = orderID.toString();
                                        var arrayWithEventIds1 =
                                          orderID1.replace(/["]+/g, "");
                                        let newOrderID =
                                          arrayWithEventIds1.replace(
                                            /\[|\]/g,
                                            ""
                                          );
                                      
                                        let a = "00";
                                        if (arrnew === a) {
                                          //alert(qrCodeID)
                                          localStorage.setItem(
                                            "am",
                                            currentAmount
                                          );
                                          localStorage.setItem("clogo", clogo);
                                          localStorage.setItem(
                                            "dataObj",
                                            dataObj
                                          );
                                          localStorage.setItem(
                                            "orderID",
                                            newOrderID
                                          );
                                          localStorage.setItem(
                                            "paymentRequest",
                                            JSON.stringify(resp.data.tokenData)
                                          );
                                          localStorage.setItem(
                                            "qrCodeID",
                                            qrCodeID
                                          );
                                          history.push({
                                            pathname: "/thankyou",
                                          });
                                        } else {
                                          localStorage.setItem("clogo", clogo);
                                          localStorage.setItem(
                                            "dataObj",
                                            dataObj
                                          );
                                          history.push({ pathname: "/failed" });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err, "error");
                                      });
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                onPaymentAuthorized={(paymentData) => {
                                  return {
                                    transactionState: "SUCCESS",
                                  };
                                }}
                                onPaymentDataChanged={(paymentData) => {
                                  return {};
                                }}
                                existingPaymentMethodRequired="false"
                                buttonColor="black"
                                buttonType="donate"
                                buttonLocale={typeLan === "French" ? "fr" : "en"}
                              />
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="App end-btn-cstm"
                              // key={i}
                            >
                              <div className="pad-15">{orLan}</div>
                              <button
                                className="btn btn-primary btnCard"
                                onClick={cardSectionOpen}
                                style={{ color : btnFontColor, fontWeight: 600, backgroundColor: color, backgroundImage: color}}
                              >
                                {cardButtonLan}
                              </button>
                            </div>
                          </>
                        );
                      }
                    }
                  }
                })()
              }
            </div>
            {charitydata.processFee === 1 ? (
              <div
                className="headingTop mt-1"
                hidden={hidediv}
                style={{ textAlign: "center",color : "#000000", fontWeight: 600 }}
              >
                <div className="form-group1" style={{ color : "#000000", fontWeight: 600 }}>
                <p style={{"textAlign": "justify",color : "#000000", fontWeight: 600}}><input
                  type="checkbox"
                  value={parseFloat(charitydata.processFeeAmount).toFixed(2)}
                  onClick={(e) =>
                    handleProcessingFee(e)
                  }
                />
                 
                  &nbsp;{`${typeLan==='English'?` Yes, I would like to cover the ${charitydata.processFeeAmount}% processing fee so that 100% of my donation goes to support  ${charitydata.charityName}.`:
                  charitydata.charityName == 'MADD Canada – Community Leaders' ? `Je souhaite assumer les frais de traitement de dons de ${charitydata.processFeeAmount}% afin que 100% de mon don soit utilisé pour soutenir MADD Canada - Leaders communautaires.` : 
                  charitydata.charityName =='MADD Canada – Chapters' ? `Je souhaite assumer les frais de traitement de dons de ${charitydata.processFeeAmount}% afin que 100% de mon don soit utilisé pour soutenir MADD Canada – Chapitres.`:
                  `Je souhaite assumer les frais de traitement de dons de ${charitydata.processFeeAmount}% afin que 100% de mon don soit utilisé pour soutenir ${charitydata.charityName}.`                                  
                } ` }&nbsp;</p>
                  
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* {console.log(props,"color",color)}  */}
         {color == "#ffffff" ? 
         <>
         <footer
           className="text-center text-lg-start text-dark footer-fixed-bottom"
          
         >
           <div
             className="text-center p-3"
             style={{
               color:"#000000",
               backgroundColor:"#ffffff",
             }}
   
           >
             Powered by&nbsp;
             <img src={logo} style={{width:'30px'}} alt="" />
           </div>
         </footer>
       </> : <FooterWeb />}

  
        
      </div>
      <section className="col-md-12">
        <span
          onClick={hidediv ? () => showDonationAmount() : () => goToCharity()}
          style={{ cursor: "pointer",color : "#000000", fontWeight: 600,backgroundColor:color }}
          className="link-next "
        >
          {hidediv && <span style={{ cursor: "pointer",color : "#000000"}}>{`${backLan}`}</span>}
        </span>
      </section>
    </div>
      )}
      </>
  );
}

export default withRouter(Donation);
