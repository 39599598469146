import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStateValue } from "../../stateProvider";

const Sidebar = () => {
  const [hide, setHide] = useState(false);
  const { userType, charityDetails } = JSON.parse(localStorage.getItem("data")).admin[0];
  // const [{ userProfile }, dispatch] = useStateValue();
  // const userType = userProfile?.userType;
  // const charityDetailsData = userProfile?.charityDetails;
  let charityColor = "#081D45";
  let fontColor = "#fff";

  if (userType === "CHARITYUSER") {
    ///const charityDetailsData = userProfile?.charityDetails;

    if (charityDetails?.length === 1) {
      charityDetails?.map((element) => {
        charityColor = element.color;
        fontColor = element.fontColor;
      });
    }
  }
  const details = JSON.parse(localStorage.getItem("data"))
  
  // useEffect(() => {
  //   if (userType === "CHARITYUSER") {
  //     setHide(true);
  //   }
  // }, [userType]);

  return (
    <>
      <div
        className="sidebar"
        id="addwidth"
        style={{
          backgroundColor: charityColor,
          color: fontColor,
          fontWeight: "600 !important",
        }}
      >
        <ul className="nav-links mt-5">
          {/* {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li>
              <NavLink activeClassName="active" exact to="/dashboard">
                <i class="fa fa-tachometer" aria-hidden="true"></i>
                <span className="links_name">Dashboard</span>
              </NavLink>
            </li>
          )} */}
          <li title="Dashboard">
            <NavLink
              activeClassName="active"
              exact
              to="/dashboard"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            >
              <i
                class="fa fa-tachometer"
                aria-hidden="true"
                title="Dashboard"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              ></i>
              <span
                className="links_name"
                title="Dashboard"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              >
                Dashboard
              </span>
            </NavLink>
          </li>
          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Charity">
              <NavLink activeClassName="active" exact to="/charity">
                <i class="fa fa-yelp" aria-hidden="true" title="Charity"></i>
                <span className="links_name" title="Charity">
                  Charity
                </span>
              </NavLink>
            </li>
          )}
          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Retailer/Event">
              <NavLink activeClassName="active" exact to="/Retailer">
                <i
                  class="fa fa-calendar"
                  aria-hidden="true"
                  title="Retailer/Event"
                ></i>
                {/* <i class="fa fa-money" aria-hidden="true" title="Retailer/Event"></i> */}
                <span className="links_name" title="Retailer/Event">
                  Retailer/Event
                </span>
              </NavLink>
            </li>
          )}
          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Generate QR Code">
              <NavLink to="/qrcode">
                <i
                  class="fa fa-qrcode"
                  aria-hidden="true"
                  title="Generate QR Code"
                ></i>
                <span className="links_name" title="Generate QR Code">
                  Generate QR Code
                </span>
              </NavLink>
            </li>
          )}

          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Charity Campaigns">
              <NavLink to="/charityCampaigns">
                <i
                  class="fa fa-gg-circle"
                  aria-hidden="true"
                  title="Charity Campaigns"
                ></i>
                <span className="links_name" title="Charity Campaigns">
                  Charity Campaigns
                </span>
              </NavLink>
            </li>
          )}
          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Lottery Campaigns">
              <NavLink to="/lotterCampaigns">
                <i
                  class="fa fa-money"
                  aria-hidden="true"
                  title="Lottery Campaigns"
                ></i>
                {/* <i class="fa fa-gg-circle" aria-hidden="true" title="Lottery Campaigns"></i> */}
                <span className="links_name" title="Lottery Campaigns">
                  Lottery Campaigns
                </span>
              </NavLink>
            </li>
          )}
          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Users">
              <NavLink to="/user">
                <i class="fa fa-users" aria-hidden="true" title="Users"></i>
                <span className="links_name" title="Users">
                  Users
                </span>
              </NavLink>
            </li>
          )}
          {/* {(userType === "ADMIN" || userType === "BNAUSER") && ( */}
          <li
            title="Transactions"
            style={{ color: fontColor, fontWeight: "600 !important" }}
          >
            <NavLink
              activeClassName="active"
              exact
              to="/transactionDetails"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            >
              <i
                class="fa fa-exchange"
                aria-hidden="true"
                title="Transactions"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              ></i>
              {/* <i class="fa fa-money" aria-hidden="true" title="Transactions" style= {{color: fontColor, fontWeight: "600 !important" }}></i> */}
              <span
                className="links_name"
                title="Transactions"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              >
                Transactions
              </span>
            </NavLink>
          </li>
          {/* )} */}

          {(userType === "ADMIN" || userType === "BNAUSER") || (details?.admin[0].charityDetails[0]?.donationFrequency === "Monthly" ) ? 
          <li
            title="Recurring Transactions"
            style={{ color: fontColor, fontWeight: "600 !important" }}
          >
            <NavLink
              activeClassName="active"
              exact
              to="/recurringTransactionDetails"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            >
              <i
                class="fa fa-repeat"
                aria-hidden="true"
                title="Recurring Transactions"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              ></i>
              {/* <i class="fa fa-money" aria-hidden="true" title="Recurring Transactions" style= {{color: fontColor, fontWeight: "600 !important" }}></i> */}
              <span
                className="links_name"
                title="Recurring Transactions"
                style={{ color: fontColor, fontWeight: "600 !important" }}
              >
                Recurring Transactions
              </span>
            </NavLink>
          </li> : ""}

          {(userType === "ADMIN" || userType === "BNAUSER") && (
            <li title="Lottery">
              <NavLink to="/lotteryList">
                <i class="fa fa-ticket" aria-hidden="true" title="Lottery"></i>
                <span className="links_name" title="Lottery">
                  Lottery Details
                </span>
              </NavLink>
            </li>
          )}
          <li
            className="m12 reportonly"
            id="reports-li"
            text="Reports"
            onClick={() => setHide(!hide)}
            style={{ color: fontColor, fontWeight: "600 !important" }}
          >
            <i
              class="fa fa-file-text"
              title="Reports"
              aria-hidden="true"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            ></i>
            <span
              className="links_name"
              title="Reports"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            >
              Reports
            </span>
            <i
              class="fa fa-caret-down"
              text="Reports"
              style={{ color: fontColor, fontWeight: "600 !important" }}
            ></i>
          </li>
          {hide && (
            <div className="subMenu">
              <ul>

              {(userType === "ADMIN" || userType === "BNAUSER") || (details?.admin[0]?.charityDetails[0]?.charityType === "Donations" ||
                 details?.admin[0]?.charityDetails[0]?.charityType === "Both" || details?.admin[0]?.charityDetails[0]?.charityType === "" || 
                 details?.admin[0]?.charityDetails[0]?.charityType === undefined ) ? 
                (<li text="Donation Details">
                  <NavLink
                    to="/donationDetail"
                    style={{ color: fontColor, fontWeight: "600 !important" }}
                  >
                    <i
                      class="fa fa-credit-card"
                      aria-hidden="true"
                      title="Donation Details"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    ></i>
                    <span
                      className="links_name"
                      title="Donation Details"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    >
                      Donation Details
                    </span>
                  </NavLink>
                </li>): ("")}

                {(userType === "ADMIN" || userType === "BNAUSER") || (details?.admin[0]?.charityDetails[0]?.charityType === "Lottery" ||
                 details?.admin[0]?.charityDetails[0]?.charityType === "Both") ? 
                (<li
                  title="Lottery Details"
                  style={{ color: fontColor, fontWeight: "600 !important" }}
                >
                  <NavLink
                    to="/lotteryDetail"
                    style={{ color: fontColor, fontWeight: "600 !important" }}
                  >
                    <i
                      class="fa fa-angellist"
                      aria-hidden="true"
                      title="Lottery Details"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    ></i>
                    <span
                      className="links_name"
                      title="Lottery Details"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    >
                      Lottery Details
                    </span>
                  </NavLink>
                </li> ): ("")}

          {(userType === "ADMIN" || userType === "BNAUSER") || (details.admin[0].charityDetails[0].donationFrequency === "Monthly" ) ? 
                <li
                  title="Recurring Donations"
                  style={{ color: fontColor, fontWeight: "600 !important" }}
                >
                  <NavLink
                    to="/donationMonthlyDetail"
                    style={{ color: fontColor, fontWeight: "600 !important" }}
                  >
                    <i
                      class="fa fa-refresh"
                      aria-hidden="true"
                      title="Recurring Donations"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    ></i>
                    <span
                      className="links_name"
                      title="Recurring Donations"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    >
                      Recurring Donations
                    </span>
                  </NavLink>
                </li>: ""}

                {(userType === "ADMIN" || userType === "BNAUSER") || (details?.admin[0]?.charityDetails[0]?.charityType === "Donations" ||
                 details?.admin[0]?.charityDetails[0]?.charityType === "Both" || details?.admin[0]?.charityDetails[0]?.charityType === "" || 
                 details?.admin[0]?.charityDetails[0]?.charityType === undefined) ? 
               (<li
                  title="Donation Summary"
                  style={{ color: fontColor, fontWeight: "600 !important" }}
                >
                  <NavLink
                    to="/donationSummary"
                    style={{ color: fontColor, fontWeight: "600 !important" }}
                  >
                    <i
                      class="fa fa-file-pdf-o"
                      aria-hidden="true"
                      title="Donation Summary"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    ></i>
                    <span
                      className="links_name"
                      title="Donation Summary"
                      style={{ color: fontColor, fontWeight: "600 !important" }}
                    >
                      Donation Summary
                    </span>
                  </NavLink>
                </li>): ("")}


              </ul>
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
