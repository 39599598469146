import axios from 'axios';
import { APIBase_URL } from "../../../config";

// this.fetch(`${API_URL}getCharity`, (response) => {

export const getUserData = async() => {
   const result1 = await fetch(`${APIBase_URL}user`)
   const result = result1.json()
    return result
}
export const addUserData = async(data) => {
   const result = await axios.post(`${APIBase_URL}user`,data)
    return result
}
export const sendVerifcationEmailData = async (data) => {
   try{ 
   const result = await axios.post(`${APIBase_URL}user/userEmail/Send`, data);
    return result.status;
   }catch(e){
   }
}
export const updateUserData = async(formData,updateID) => {
    const result1 = await axios.patch(`${APIBase_URL}user/${updateID}`,formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    // const result = result1.json()
    // console.log(result)
     return result1
//    const result1 = await fetch(`${APIBase_URL}user/${updateID}`,{
// 			method: 'PATCH',
// 			headers: {
// 				'Accept': 'application/json',
// 				'Content-Type':'application/json'
// 			},
// 			body:JSON.stringify(data) 
// 		})
//         const result = result1.json()
        // console.log(result)
        //  return result

}
export const updateUserToken = async(token, id) => {

    try{
    const result1 = await axios.post(`${APIBase_URL}user/${id}`, { token: token });
    return result1
 
    }catch(e){
        // console.log(e,"Result1 value=====================>>>>>>>>>>>>>>>")
    }
 
}
export const updateAdminData = async(formData,updateID) => {
   // console.log(formData.getAll("UpdateLogo"),"----aaaaa")

   const result1 = await axios.patch(`${APIBase_URL}admin/updateProfile/${updateID}`,formData,{
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
        // const result = result1.json()
        // console.log(result)
         return result1

}