import { React, useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import {
  getUserData,
  addUserData,
  updateUserData,
  sendVerifcationEmailData,
  updateUserToken,
} from "../API/UserAPI/UserAPI";
import { APIBase_URL } from "../../config";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2'

const User = () => {
  const [setModal, setModalState] = useState(false);
  const [setUpdateModal, setUpdateModalState] = useState(false);
  const [updateID, setUpdateID] = useState("");
  const [userList, setUserList] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [userType, setUserType] = useState("BNAUSER");
  const [charityId, setCharityID] = useState([]);
  const [abc, setAbc] = useState("");
  const [isUpload, setIsUpload] = useState("");
  const [charityList, setCharityList] = useState(false);
  const [multiCharityCss,setMultiCharityCss]=useState(0);
  const [allcheck, setAllcheck] = useState(false);
  const [charityOptions, setCharityOptions] = useState([]);
  const [updateCharityOptions, setUpdateCharityOptions] = useState([]);
  const [updateAllcheck, setUpdateAllcheck] = useState(false);
  const [charityIdError, setCharityIdError] = useState(null);
  const [updateCharityIdError, setUpdateCharityIdError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [msg, setMsg] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [errorMesEmail, setErrorMsgEmail] = useState("");

  const [updateFirstName, setUpdateFirstName] = useState("");
  const [updateLastName, setUpdateLastName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateProfileImg, setUpdateProfileImg] = useState("");
  const [updateUserType, setUpdateUserType] = useState("");
  const [updateCharityId, setUpdateCharityID] = useState([]);
  const [updateAbc, setUpdateAbc] = useState("");
  const [updateIsUpload, setUpdateIsUpload] = useState("");
    
  const [charityListData, setCharityListData]=useState([]);
 
  useEffect(() => {
    getUser();
    getCharity();
  }, []);

  const sendVerficatonEmail = async (email,token,firstName,lastName)=>{
    if (email) {
      let emailId = email;
      var ciphertext = CryptoJS.AES.encrypt(emailId, "secret key 123").toString();
      const passwordResetlink = APIBase_URL + "#/verifyemail/" + ciphertext + "/" + token;
      const data = {
        email:email,
        link:passwordResetlink,
        name:`${firstName} ${lastName}`
      }
      const resp = await sendVerifcationEmailData(data)
      return resp;
    }
  }
  
  const getUser = async () => {
    getUserData().then((resp) => {
      resp?.map((data) => (        
         data?.charityId.length>0
          && data?.charityId?.map((d) => data.charityNameList===undefined? data.charityNameList=d.charityName+', ' : data.charityNameList+=d.charityName+', ' )
      ))  
      // console.log("Users Responsed Data=============////////",resp)
      setUserList(resp)
      // console.log(resp, 'USerDataLIst============================>');
      // setUpdateFirstName(resp[0]?.firstName);
      // setUpdateLastName(resp[0]?.lastName);
      // setUpdateEmail(resp[0]?.email);
      // setUpdateID(resp[0]?._id);
    });
  };

let Obj = {}
  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
        });

        let filter1 = arr.map(({ _id, charityName }) => ( {_id, charityName }));
        setCharityList(arr);
        setCharityListData(filter1);
      })
      .catch((err) => {});
  };

  const openModal = () => {
    setModalState(!setModal);
    setUpdateFirstName("");
    setUpdateLastName("");
    setUpdateEmail("");
  };
  const addHandleFile = (event) => {
    let img = event.target.files[0];
    setAbc(img);
    setIsUpload(event.target.files);
  };
  const user = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("userType", userType);
    charityId.length > 0 && formData.append("charityId", JSON.stringify(charityId));
    if (abc) {
      formData.append("profileImg", abc, abc.name);
    }
    if(charityId.length === 0 && userType === "CHARITYUSER"){
      setCharityIdError(true)
      return;
    }
    if (firstName && lastName && email) {
      addUserData(formData) 
        .then((res) => {
          if(typeof res.data.Emailstatus === "undefined"){ 
            setMsg(`You Have Added A New User`);
            setTimeout(() => {
              setModalState(!setModal);
              getUser();
              setFirstName("");
              setLastName("");
              setEmail("");
              setUserType("BNAUSER")
              setProfileImg("")
              setCharityID([])
              setIsUpload("")
              reset()
            }, 10);
            setTimeout(() => {
              setMsg("");
            }, 4000);
            // console.log(res.data.passwordResetToken,'/////////////// Password Reset Token /////////////////');
            sendVerficatonEmail(res.data.email,res.data.passwordResetToken,res.data.firstName,res.data.lastName)
          }else if(res.data.Emailstatus === false){ 
            setEmailMsg(`Email Id already exist.`);
            setTimeout(() => {
              setEmailMsg("");
            }, 4000);
          }
          
        })
        .catch((err) => {
        })
        
    } else {
      alert("Please fill up all field.");
    }
  };

  

  const selectUser = async (id) => {
    setUpdateFirstName("");
    setUpdateLastName("");
    setUpdateEmail("");
    setUpdateModalState(!setUpdateModal);

    let item = await userList.filter((data) => data._id === id);
    setUpdateFirstName(item[0].firstName);
    setUpdateLastName(item[0].lastName);
    setUpdateEmail(item[0].email);
    setUpdateID(item[0]._id);
    if(item[0].userType==='CHARITYUSER'){
      setMultiCharityCss(1);
    }else{
      setMultiCharityCss(0);
    }
    setUpdateUserType(item[0].userType);
    setUpdateCharityID(item[0].charityId);
    setUpdateCharityOptions(item[0].charityId);
    setUpdateProfileImg(item[0].profileImg);
  };

  const updateHandleFile = (event) => {
    let img = event.target.files[0];
    setUpdateAbc(img);
    setUpdateIsUpload(event.target.files);
  };

  const updateUser = async () => {
    


    const formData = new FormData();
    formData.append("email", updateEmail);
    formData.append("firstName", updateFirstName);
    formData.append("lastName", updateLastName);
    formData.append("userType", updateUserType);

    if(typeof updateCharityId !== "undefined"){ 
      const filterUpdateCharityIdData= updateCharityId.map(({ _id}) => ( {_id}));
        formData.append("charityId", JSON.stringify(filterUpdateCharityIdData));
    }
    if (updateAbc) {
      formData.append("profileImg", updateAbc, updateAbc.name);
    }
    if(updateCharityId.length === 0 && updateUserType === "CHARITYUSER"){
      setUpdateCharityIdError(true)
      return;
    }

    if (updateFirstName && updateLastName && updateEmail) {
      updateUserData(formData, updateID).then((resp) => {
        setMsg(`User Successfully Updated`);
        setTimeout(() => {
          setMsg("");
        }, 4000);
        getUser();
        setUpdateModalState(false);
        setUpdateFirstName("");
        setUpdateLastName("");
        setUpdateEmail("");
        setUpdateID("");
        setUpdateUserType("");
        setUpdateCharityID([]);
        setUpdateProfileImg("");
        setUpdateIsUpload("")
      });
    } else {
      alert("Please fill up all field.");
    }
  };

  const deleteUser = async (id) => {
    // console.log(id, "<><><><><>");
    const res = await fetch(`${APIBase_URL}user/${id}`, {
      method: "DELETE",
    });
    res.json().then((res) => {
      // console.log(res);
      setMsg(`User Successfully Deleted`);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      getUser();
    });
    // console.log(id);
  };

  const handleEmail = (event) => {
    const email = validator.trim(event.target.value);
    let EmailData = userList.map((val) => val.email);
    let chk = EmailData.includes(email);
    if (chk) {
      event.target.value = "";
      setEmail("");
      setErrorMsgEmail(` Duplicate Email `);
      setTimeout(() => {
        setErrorMsgEmail("");
      }, 2000);
    } else {
      setEmail(email);
    }
  };
  const handleChange = (selectedList) => {
        if(selectedList.length > 0){
          setCharityIdError(false)
        }else{
          setCharityIdError(true)
        }

        setMultiCharityCss(1);
        let obj = selectedList.find(o => o._id === '*');
        if(typeof(obj) === "undefined"){
          const charityFilterOption = (charityListData.length === selectedList.length) ? [...selectedList, {_id: "*", charityName: "All Charities"}] : selectedList
          let filterCharityId = selectedList.map(({ _id }) => ( {_id }));
          setCharityOptions(charityFilterOption)
          setCharityID(filterCharityId)
          setAllcheck(false)
        }else{
          let filterCharityId = charityListData.map(({ _id }) => ( {_id }));
          setCharityOptions(charityListData)
          setCharityID(filterCharityId)
          setAllcheck(true)
        }
  };

  const handleRemove = (selectedList, removedItem) => {
      if(selectedList.length > 0){
        setCharityIdError(false)
      }else{
        setCharityIdError(true)
      }

     if(removedItem._id === "*"){
      setCharityOptions([])
      setCharityID([])
      setAllcheck(false)
     }else if(removedItem._id !== "*"){
      const filterCharity = selectedList.filter(vl => vl._id !== "*" && vl._id !== removedItem._id);
      const filterIds = filterCharity.map(({ _id }) => ( {_id }));
      setCharityOptions(filterCharity)
      setCharityID(filterIds)
      setAllcheck(false)
     }
};

  const handleUserTypeChange = (e) => {
    const value = e.target.value;
    setMultiCharityCss(0);
   // setAllcheck(false)
    setCharityOptions([])
    setUserType(value);
    if(value==='CHARITYUSER'){
      setMultiCharityCss(1);
    }else{
      setCharityOptions([])
      setCharityID([])
      setMultiCharityCss(0);
    }
  };
  const handleUpdateUserTypeChange = (e) => {
    setMultiCharityCss(0);
    const value = e.target.value;
    setUpdateUserType(value);
    if(value==='CHARITYUSER'){
      setMultiCharityCss(1);
    }else{
      setUpdateCharityOptions([])
      setUpdateCharityID([]);
      setMultiCharityCss(0);
    }
  };
  const handleUpdateCharityChange = (selectedList) => {
    if(selectedList.length > 0){
      setUpdateCharityIdError(false)
    }else{
      setUpdateCharityIdError(true)
    }
    setMultiCharityCss(1);
    let obj = selectedList.find(o => o._id === '*');
    if(typeof(obj) === "undefined"){
      const charityFilterOption = (charityListData.length === selectedList.length) ? [...selectedList, {_id: "*", charityName: "All Charities"}] : selectedList
      const filterUpdateCharityId= selectedList.map(({ _id,charityName}) => ( {_id,charityName }));
      setUpdateCharityOptions(charityFilterOption)
      setUpdateCharityID(filterUpdateCharityId);
      setUpdateAllcheck(false)
    }else{
      let filterUpdateCharityId= charityListData.map(({ _id }) => ( {_id }));
      setUpdateCharityOptions(charityListData)
      setUpdateCharityID(filterUpdateCharityId);
      setUpdateAllcheck(true)
    }

  };

  const handleUpdateCharityRemove = (selectedList, removedItem) => {
    if(selectedList.length > 0){
      setUpdateCharityIdError(false)
    }else{
      setUpdateCharityIdError(true)
    }
    if(removedItem._id === "*"){
     setUpdateCharityOptions([])
     setUpdateCharityID([])
     setUpdateAllcheck(false)
    }else if(removedItem._id !== "*"){
     const filterCharity = selectedList.filter(vl => vl._id !== "*" && vl._id !== removedItem._id);
     const filterIds = filterCharity.map(({ _id }) => ( {_id }));
     setUpdateCharityOptions(filterCharity)
     setUpdateCharityID(filterIds)
     setUpdateAllcheck(false)
    }
};


const resendVerificationMail = async (id) =>{
  let resendMailUserData = await userList.filter((data) => data._id === id);
  resendMailUserData.passwordResetToken = Math.floor(Math.random() * 100000000000001);
  if(resendMailUserData){
       await updateUserToken(resendMailUserData.passwordResetToken,id) 
        .then(async (res) => {
          if(res.data.Emailstatus !== false || typeof(res.data.Emailstatus) === undefined ){
            // const status =  sendVerficatonEmail(res.data.email, resendMailUserData.passwordResetToken, res.data.firstName, res.data.lastName)
            // console.log(status,"Check Mail Status")
            // if(status.checkStatus === true){
            //   setMsg(`Verification Mail Send Sucessfully.`);
            //   setTimeout(() => {
            //     setMsg("");
            //   }, 4000)
            // }else{
            //     console.log("Error .......//")
            // }
            // console.log(res.data, "res.datares.data", resendMailUserData.passwordResetToken)
            const checkStatus = await sendVerficatonEmail(res.data.email, res.data.passwordResetToken, res.data.firstName, res.data.lastName);
            if(checkStatus === 200){
              setMsg(`Verification Mail Send Sucessfully.`);
              setTimeout(() => {
                setMsg("");
              }, 4000)
            }else{
              setEmailMsg(`Mail already exist.`);
              setTimeout(() => {
                setEmailMsg("");
              }, 4000);
            }
           
          }else if(res.data.Emailstatus === false){ 
            setEmailMsg(`Email Id already exist.`);
            setTimeout(() => {
              setEmailMsg("");
            }, 4000);
          }
          
        })
        .catch((err) => {
          console.log(err);
        })
  }
}




// console.log(updateUserType,'setModal')
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="userContainer p-3">
              <h2 className="pageTitle mb-4">Users</h2>

              <div className="topWrapper d-flex mb-5">
                {setModal ? (
                  <div className="ModalBackground">
                    <div className="modalContainer modalcontainercstm" id={ multiCharityCss===1 && userType  === "CHARITYUSER"? "container-height":''}>
                      <div className="modalHeader mb-5">
                        <h3>Add User</h3>
                      </div>
                      <button
                        className="closeBtn"
                        onClick={() => setModalState(false)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <form className="multicharityslector">
                        <div className="container ">
                        <h6 style={{ color: "red" }}>{emailMsg}</h6>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>First Name:</label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  className={`form-control ${
                                    errors.firstName && "invalid"
                                  }`}
                                  {...register("firstName", {
                                    required: "First Name is required",
                                  })}
                                  onChange={(e) =>
                                    setFirstName(validator.trim(e.target.value))
                                  }
                                  value={firstName}
                                />
                                {errors.firstName && (
                                  <small className="text-danger">
                                    {(charityIdError === null && charityId.length === 0) && setCharityIdError(true)}
                                    {errors.firstName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  className={`form-control ${
                                    errors.lastName && "invalid"
                                  }`}
                                  {...register("lastName", {
                                    required: "Last Name is required",
                                  })}
                                  onChange={(e) =>
                                    setLastName(validator.trim(e.target.value))
                                  }
                                  value={lastName}
                                />
                                {errors.lastName && (
                                  <small className="text-danger">
                                    {(charityIdError === null && charityId.length === 0) && setCharityIdError(true)}
                                    {errors.lastName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  onChange={handleEmail}
                                  value={email}
                                  // onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                  <small className="text-danger">
                                    {(charityIdError === null && charityId.length === 0) && setCharityIdError(true)}
                                    {errors.email.message}
                                  </small>
                                )}
                                <small className="text-danger">
                                  {errorMesEmail}
                                </small>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>User Type:</label>
                                <select
                                  className="form-control"
                                  {...register("userType", {
                                    required: "User Type is required",
                                  })}
                                  onChange={handleUserTypeChange}
                                >
                                   <option className="dropdown-item" value={`BNAUSER`}>BNA Admin</option>
                                   <option className="dropdown-item" value={`CHARITYUSER`}>Charity User</option>
                                </select>
                                {errors.userType && (
                                  <small className="text-danger">
                                    {(charityIdError === null && charityId.length === 0) && setCharityIdError(true)}
                                    {errors.userType.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            {userType === 'CHARITYUSER'?
                               <div className="col-md-6">
                                    <div className="form-group">
                                  <label>Charities:</label>
                                  <Multiselect
                                    options={[{_id: "*", charityName: "All Charities"}, ...charityListData]}
                                    onSelect={handleChange}
                                    onRemove={handleRemove}
                                    selectedValues={allcheck ? [...charityListData, {_id: "*", charityName: "All Charities"}] : charityOptions}
                                    displayValue="charityName"
                                    showCheckbox={true}
                                    showArrow={true}
                                    avoidHighlightFirstOption={true}
                                    required={true}
                                    placeholder="Select Charity"
                                  />
                                  {charityIdError && (
                                  <small className="text-danger">
                                    Charity Id is required
                                  </small>
                                )}
                              </div>
                             </div>  
                            
                            :''}
                            <div className="col-md-6">
                              <label>Upload Picture</label>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    className="uplaodController custom-file-input"
                                    type="file"
                                    name="UpdateProfilePic"
                                    id="files"
                                    accept=".jpg,.jpeg,.png"
                                    style={{}}
                                    onChange={addHandleFile}
                                  />
                                  <label
                                    className="custom-file-label"
                                    for="files"
                                  >
                                    Upload Picture
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: "118px" }}>
                                {isUpload &&
                                  [...isUpload].map((file) => (
                                    <img
                                      style={{ width: "100%" }}
                                      src={URL.createObjectURL(file)}
                                      alt="logo"
                                    />
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="modalFooter modalfootercstm text-right mt-5 pt-3">
                        <button
                          className="btn btn-primary actioncstm"
                          onClick={handleSubmit(user)}
                        >
                          {" "}
                          Add User
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary actioncstm"
                          onClick={() => setModalState(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {setUpdateModal ? (
                  <div className="ModalBackground">
                    <div className="modalContainer" id={ multiCharityCss===1 && updateUserType === "CHARITYUSER"? "container-height":''}>
                      <div className="modalHeader mb-5">
                        <h3>Update User</h3>
                      </div>
                      <button
                        className="closeBtn"
                        onClick={() => setUpdateModalState(false)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <form className="multicharityslector">
                        <div className="container ">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>First Name:</label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  name="updateFirstName"
                                  onChange={(e) =>
                                    setUpdateFirstName(
                                      validator.trim(e.target.value)
                                    )
                                  }
                                  value={updateFirstName}
                                />
                                {errors.firstName && (
                                  <small className="text-danger">
                                    {(updateCharityIdError === null && updateCharityId.length === 0) && setUpdateCharityIdError(true)}
                                    {errors.firstName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  name="updateLastName"
                                  value={updateLastName}
                                  onChange={(e) =>
                                    setUpdateLastName(
                                      validator.trim(e.target.value)
                                    )
                                  }
                                />
                                {errors.lastName && (
                                  <small className="text-danger">
                                     {(updateCharityIdError === null && updateCharityId.length === 0) && setUpdateCharityIdError(true)}
                                    {errors.lastName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email:</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="updateEmail"
                                  {...register("updateEmail", {
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                  onChange={(e) =>
                                    setUpdateEmail(e.target.value)
                                  }
                                  value={updateEmail}
                                />
                                {errors.updateEmail && (
                                  <small className="text-danger">
                                     {(updateCharityIdError === null && updateCharityId.length === 0) && setUpdateCharityIdError(true)}
                                    {errors.updateEmail.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>User Type:</label>
                                <select
                                  className="form-control"
                                  {...register("updateuserType", {
                                    required: "User Type is required",
                                  })}
                                  onChange={handleUpdateUserTypeChange}
                                >
                                   <option className="dropdown-item" value={`BNAUSER`} selected={updateUserType === "BNAUSER"}>BNA User</option>
                                   <option className="dropdown-item" value={`CHARITYUSER`} selected={updateUserType === "CHARITYUSER"}>Charity User</option>
                                </select>
                                {errors.updateuserType && (
                                  <small className="text-danger">
                                    {errors.updateuserType.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            {updateUserType === 'CHARITYUSER'?
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Charities:</label>
                                  <Multiselect
                                  options={[{_id: "*", charityName: "All Charities"}, ...charityListData]}
                                  // selectedValues={updateCharityId}
                                  selectedValues={updateAllcheck ? [...charityListData, {_id: "*", charityName: "All Charities"}] : updateCharityOptions}
                                  onSelect ={handleUpdateCharityChange}
                                  onRemove={handleUpdateCharityRemove}
                                  displayValue="charityName"
                                  showArrow={true}
                                  showCheckbox={true}
                                  avoidHighlightFirstOption={true}
                                  placeholder="Select Charity"
                                 
                                />
                                  {updateCharityIdError && (
                                  <small className="text-danger">
                                    Charity Id is required
                                  </small>
                                )}

                                </div>
                              </div> 
                            :''}
                            <div className="col-md-6">
                              <label>Upload Picture</label>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    className="uplaodController custom-file-input"
                                    type="file"
                                    name="UpdateProfilePic"
                                    id="files"
                                    accept=".jpg,.jpeg,.png"
                                    style={{}}
                                    onChange={updateHandleFile}
                                  />
                                  <label
                                    className="custom-file-label"
                                    for="files"
                                  >
                                    Upload Picture
                                  </label>
                                </div>
                              </div>
                              <div style={{ width: "118px" }}>
                                {updateIsUpload === "" && updateProfileImg ? (
                                  <img
                                    style={{ width: "100%" }}
                                    src={`/profile/${updateProfileImg}`}
                                    alt="Picture"
                                  />
                                ) : (
                                  ""
                                )}
                                {updateIsUpload &&
                                  [...updateIsUpload].map((file) => (
                                    <img
                                      style={{ width: "100%" }}
                                      src={URL.createObjectURL(file)}
                                      alt="logo"
                                    />
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="modalFooter modalfootercstm text-right mt-5 pt-3 d-flex justify-content-end">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit(updateUser)}
                        >
                          {" "}
                          Update User
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          onClick={() => setUpdateModalState(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                <button className="btn btn-primary" onClick={openModal}>
                  {" "}
                  Add User
                </button>
              </div>
              <h6 style={{ color: "green" }}>{msg}</h6>
              <div className="table-responsive custom-height">
                <table className="table table-striped mt-3">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: "0" }}
                  >
                    <tr>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Charity</th>
                      <th>User Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(userList,"Usr")} */}
                    {userList.length
                      ? userList.map((data) => (
                          <tr key={data._id}>
                            <td>
                              {data.firstName} {data.lastName}
                            </td>
                            <td>{data.email}</td>
                            <td>{data?.passwordResetToken===""? "Verified": "Pending"}</td>   
                            <td width="30%">{ data.userType === 'CHARITYUSER'? data?.charityId ? (data?.charityId?.length > 1 ? data.charityNameList.substring(0, data.charityNameList.lastIndexOf(",")) : data?.charityId[0]?.charityName) : '' : '' }</td>
                            <td>{data.userType==='BNAUSER'?'BNA':'Charity'}</td>
                            <td>
                          
                              <span
                                className="edit"
                                onClick={() => selectUser(data._id)}
                                title="Edit"
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  title="Edit"
                                ></i>
                              </span>
                              <span
                                className="delete"
                                onClick={() =>
                                  Swal.fire({
                                    title: "Are you sure you want to delete this User?",
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    icon: "warning",
                                    width:"470px",
                                    height:"380px"
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteUser(data._id)
                                      Swal.fire({ title: "User deleted!", showConfirmButton: true, icon: "success", width:"470px",
                                      height:"380px" });
                                    } else {
                                    }
                                  })
                                  // window.confirm(
                                  //   "Are you sure you want to delete this User?"
                                  // ) && deleteUser(data._id)
                                }
                                title="Delete"
                              >
                                <i className="fa fa-trash-o" aria-hidden="true" title="Delete"></i>
                              </span>
                              {data?.passwordResetToken!==""? 
                              <span
                                  className="resend-verification"
                                  onClick={() =>
                                    Swal.fire({
                                      title: "Are you sure you want to resend verification mail to this User?",
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                      confirmButtonText: "Yes",
                                      cancelButtonText: "Cancel",
                                      icon: "warning",
                                      width:"470px",
                                      height:"380px",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        resendVerificationMail(data._id)
                                        Swal.fire({ title: "Mail sent!", showConfirmButton: true, icon: "success", width:"470px",
                                        height:"380px", });
                                      } else {
                                      }
                                    })
                                    // window.confirm(
                                    //   "Are you sure you want to resend verification mail to this User?"
                                    // ) && resendVerificationMail(data._id)
                                  }
                                  title="Re-send Verification"
                                >
                                  <i className="fa fa-envelope-o" aria-hidden="true" title="Re-send Verification"></i>
                                  {/* <i className='fa fa-paper-plane' aria-hidden="true"></i> */}
                                </span>
                              : ""}
                             
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default User;
