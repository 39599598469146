export const initialState = {
    basket: "test",
    userProfile: {},
    language: "",
    privacy: "",
  };
  
  // Selector
  
  const reducer = (state, action) => { 
    //console.log(action.data,'action.data')
    switch (action.type) {
      case "ADD_TO_USER":
        return {
          ...state,
          userProfile: {...state.userProfile, ...action.data},
        };
        case "EDIT_USER":
        return {
          ...state,
          userProfile: {...state.userProfile, ...action.data},
        };
        case "LANGUAGE":
        return {
          ...state,
          language: action.data,
        };
        case "PRIVACY":
        return {
          ...state,
          privacy: action.data,
        };
  
      default:
        return state;
    }
  };
  
  export default reducer;