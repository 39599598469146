import React, { useEffect, useState } from "react";
import clsx from "clsx";
import _, { sum } from "lodash";
import {
  CssBaseline,
  Typography,
  Grid,
  Container,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

import {
  BarChart,
  PieChart,
  Pie,
  Sector,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  Brush,
  Label,
  ScrollContainer,
} from "recharts";
import { APIBase_URL } from "../../config";
import { getTaxInfoData } from "../API/TaxInfoAPI/TaxInfoAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = () => {
  const currentUserData = localStorage?localStorage.getItem("token"):'';
  var decodedData = currentUserData?jwt_decode(currentUserData):'';
  const charityId = decodedData?.charityId;
  const [charityLogo, setCharityLogo] = useState("");
  const theme = useTheme();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [charityList, setCharityList] = useState("");
  const [retailerList, setRetailerList] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filterData2, setFilterData2] = useState([]);
  const [startDate, setStartDate] = useState(); //moment().toDate()
  const [endDate, setEndDate] = useState(new Date());
  const [disableDates, setDisableDates] = useState(new Date());

  useEffect(() => {
    setTimeout(() => {
      window.history.forward();
    }, -1);
    window.onunload = () => {
      return null;
    };
    getCharity();
    getDonator();
    getRetailer();
  }, []);

  let currentUser = localStorage.getItem("token");
  if (!currentUser && currentUser.length < 10) {
    <Redirect to="/" />;
  }

  const getCharity = async () => {
    const res = await fetch(`${APIBase_URL}charity`);
    res.json().then((resp) => {
      let arr = [];
      let filter = resp.map((data, i) => {
        if (data.isActive === false) {
          arr.push(data);
          if(charityId === data._id){
            setCharityLogo(data.charityLogo)
          }
        }
      });

      setCharityList(arr);
      // console.log(arr, "charitydata");
    });
  };

  const getRetailer = async () => {
    const res = await fetch(`${APIBase_URL}retailer`);
    res.json().then((resp) => {
      let arr = [];
      const resp1 = resp.retailer;
      let filter = resp1.map((data) => {
        // console.log(data, "resp1resp1");
        if (data.isActive === false) {
          arr.push(data);
        }
      });

      setRetailerList(arr);
      // console.log(resp.retailer, "retailerdata");
    });
  };

  const getDonator = async () => {
    getTaxInfoData().then((resp) => {
      setFilterData2(resp);
      setFilterData(resp);
    }).catch((err) => {
    });
    // const res = await fetch(`${APIBase_URL}taxinfo`);
    // res.json().then((resp) => {
    //   console.log(resp, "resppppppp");
    //   setFilterData2(resp);
    //   setFilterData(resp);
    // });
  };

  let customArray = [];
  const arr2 =
    charityList &&
    charityList.map((c, index) => {
      filterData &&
        filterData.map((d) => {
          d.charityID.map((ac) => {
            if (ac._id == c._id) {
              customArray.push({
                name: c.charityName,
                donation: Number(d.amount),
                id: c._id,
                value: Number(d.amount),
              });
            }
          });
        });
    });

  // console.log(customArray, "customArray");

  var DonationsData = customArray.reduce((acc, obj) => {
    var existItem = acc.find((item) => item.id === obj.id);
    // console.log(existItem, "existItem");
    if (existItem) {
      existItem.donation = Number(existItem.donation) + Number(obj.donation);
      existItem.value = existItem.donation + 10;
      return acc;
    }
    acc.push(obj);
    return acc;
  }, []);

  // console.log(DonationsData, "DonationsData");
  const donationBarColors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
  ];

  // transaction data are define here ===========================

  // const handleFirstCalender = (e) => {
  //   const ab = e.target.value;
  //   if (ab > endDate) {
  //     setEndDate("");
  //   }
  //   setStartDate(ab);
  //   setDisableDates(ab);

  //   if (endDate) {
  //     setFilterData([]);
  //     let filter = filterData2.map((data, i) => {
  //       let text = data.created_at;
  //       let created_at = text.slice(0, 10);
  //       if (created_at >= ab && created_at <= endDate) {
  //         console.log("matched");
  //         setFilterData((p) => [...p, data]);
  //       } else {
  //         console.log("not matched 250");
  //       }
  //     });
  //   }
  // };

  const handleFirstCalender = (e) => {
    const ab = e.target.value;

    if (ab > endDate) {
      setEndDate("");
    }
    setStartDate(ab);
    setDisableDates(ab);
  };

  const handleSecondCalender = (e) => {
    const dateObj2 = e.target.value;
    setEndDate(dateObj2);
    
    if (startDate > dateObj2 ||  startDate === "" || startDate === undefined) {
      return;
    }
    setFilterData([]);
    let filter = filterData2.map((data, i) => {
      let text = data.created_at;
      let created_at = text.slice(0, 10);
      if (created_at >= startDate && created_at <= dateObj2) {
        // console.log("matched");
        setFilterData((p) => [...p, data]);
        setEndDate(dateObj2);
      } else {
        // console.log("not matched");
        // alert("No Data Found")
      }
    });
  };

  let customArray1 = [];
  const arr1 =
    charityList &&
    charityList.map((c, index) => {
      filterData &&
        filterData.map((d) => {
          d.charityID.map((ac) => {
            if (ac._id == c._id) {
              customArray1.push({
                name: c.charityName,
                donation: Number(d.amount),
                id: c._id,
                transactions: 1,
              });
            }
          });
        });
    });
  // console.log(customArray1, "customArray1");
  var transactionsData = customArray1.reduce((acc, obj) => {
    var existItem2 = acc.find((item) => item.id === obj.id);
    // console.log(existItem2, "existItem");
    if (existItem2) {
      existItem2.transactions =
        Number(existItem2.transactions) + Number(obj.transactions);

      return acc;
    }
    acc.push(obj);
    return acc;
  }, []);

  //console.log(transactionsData, "transactionsData");

  const transactionsBarColors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
  ];

  let customArrayDataValue = [];
  filterData.map((d) => {
    customArrayDataValue.push({
      name: d.retailerID[0].RetailerName,
      value: d.amount,
      id: d.retailerID[0]._id,
    });
  });

  var retailerData = customArrayDataValue.reduce((acc, obj) => {
    var existItem4 = acc.find((item) => item.id === obj.id);
    if (existItem4) {
      existItem4.value = Number(existItem4.value) + Number(obj.value);
      return acc;
    }
    obj.value = Number(obj.value);
    acc.push(obj);
    return acc;
  }, []);
  // console.log("retailerDataxrr", retailerData);

  const RETAILERCOLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderCustomizedLabel2 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    index,
  }) => {
    // console.log(value, "valuevalue");
    // console.log(midAngle, "midAngle");
    // console.log(percent, "percent");
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`$${parseFloat(value).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`}
      </text>
    );
  };

  let consumerData1 = [];
  filterData.map((d) => {
    consumerData1.push({
      name: d.charityID[0].charityName,
      value: d.amount,
      id: d.charityID[0]._id,
      total: 1,
    });
  });
  // console.log(consumerData1, "customArray11");

  var consumerData10 = consumerData1.reduce((acc, obj) => {
    var existItem5 = acc.find((item) => item.id === obj.id);
    if (existItem5) {
      // existItem.value = obj.value
      existItem5.total = Number(existItem5.total) + 1;
      existItem5.value = Number(existItem5.value) + Number(obj.value);

      return acc;
    }
    obj.value = Number(obj.value);
    acc.push(obj);
    return acc;
  }, []);
  var consumerData = consumerData10.map((data) => ({
    name: data.name,
    value: Math.round(Number(data.value / data.total) * 100) / 100,
    total: data.total,
  }));
  //console.log(result)
  // console.log(consumerData, "consumerData");
  const CONSUMERCOLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#1f77b4",
    "#ff7f0e",
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : $${parseFloat(payload[0].value).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`}</p>
        </div>
      );
    }
    return null;
  };
  const CustomTooltip1 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} :${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipRetailerData = ({ payload }) => {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${
          payload?.[0]?.payload?.name
        } :$${parseFloat(payload?.[0]?.payload?.value).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`}</p>
      </div>
    );
  };
  const CustomTooltipConsumerData = ({ payload }) => {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${
          payload?.[0]?.payload?.name
        } :$${parseFloat(payload?.[0]?.payload?.value).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`}</p>
      </div>
    );
  };
  const formatYAxis = (value) => {
    return `$${parseFloat(value).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}`;
  };

  let colorIndex = 0;
  const generateRandomColor = (value, ColorsArray) => {
    let color = "";
    if (value > ColorsArray.length) {
      color = ColorsArray[colorIndex];
      if (colorIndex > 8) {
        colorIndex = 0;
      } else {
        colorIndex = colorIndex + 1;
      }
    } else {
      color = ColorsArray[value];
    }
    return color;
  };

  const renderLegend = (props) => {
    return (
      <div
        className="recharts-legend-wrapper"
        style={{
          width: "750px",
          height: "auto",
          left: "0px",
          bottom: "5px",
        }}
      >
        <ul
          className="recharts-default-legend"
          style={{ padding: "0px", margin: "0px", textAlign: "center" }}
        >
          {DonationsData.map((entry, index) => (
            <li
              className={`recharts-legend-item legend-item-${index}`}
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                id="pie-chart-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "4px",
                }}
              >
                <path
                  stroke={index}
                  fill={generateRandomColor(index, donationBarColors)}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                ></path>
              </svg>
              <span className="recharts-legend-item-text">{entry.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderLegend2 = (props) => {
    return (
      <div
        className="recharts-legend-wrapper"
        style={{
          width: "750px",
          height: "auto",
          left: "0px",
          bottom: "5px",
        }}
      >
        <ul
          className="recharts-default-legend"
          style={{ padding: "0px", margin: "0px", textAlign: "center" }}
        >
          {transactionsData.map((entry, index) => (
            <li
              className="recharts-legend-item legend-item-0"
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "4px",
                }}
              >
                <path
                  stroke="none"
                  fill={generateRandomColor(index, transactionsBarColors)}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                ></path>
              </svg>
              <span className="recharts-legend-item-text">{entry.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <CssBaseline />
        <section className="home-section">
          {/* //donation Bar chart */}

          <div className="home-content dashboad-bar">
            <div className="Dashboardcontainer">
              <div className="row">
              <div className="col-md-1">
                  {charityLogo !== "" ? 
                  <img
                  style={{ width: "100%",height:"35px" }}
                  src={`/uploads/${charityLogo}`}
                  alt="logo"
                />
                    :""}
                   </div> 
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-6 col-custom1024 frst-col"  style={{paddingTop:"35px"}}>
                  <Typography
                    className="text-center"
                    component="h2"
                    variant="h6"
                    gutterBottom
                  >
                    {typeof(charityId) ==='undefined' || charityId ===''?
                    <div>Number Of Charities : {DonationsData.length}</div>
                    :''}
                    <div className="bar_graph_name">Donations</div>
                  </Typography>
                  <BarChart
                    width={750}
                    height={320}
                    barSize={100}
                    margin={{ top: 5, right: 0, bottom: 5, left: 50 }}
                    // barGap={1}
                    barCategoryGap={"5%"}
                    data={DonationsData}
                  >
                    <XAxis dataKey="name" tick={false}>
                      <Label />
                      stroke={theme.palette.text.primary}
                    </XAxis>
                    <YAxis
                      dataKey="value"
                      type="number"
                      domain={[0, "dataMax + 0"]}
                      allowDataOverflow={true}
                      stroke={theme.palette.text.primary}
                      tickFormatter={formatYAxis}
                    />

                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={true}
                      viewBox={{ x: 10, y: 140, width: 400, height: 400 }}
                    />

                    <Bar
                      dataKey="donation"
                      stroke={theme.palette.text.primary}
                      fill="#black"
                      width={1000}
                    >
                      {DonationsData.map((entry, index) => (
                        <Cell
                          key={`cell- ${index}`}
                          fill={generateRandomColor(index, donationBarColors)}
                        />
                      ))}
                    </Bar>
                    <Legend content={renderLegend} />
                  </BarChart>
                </div>

                <div className="col-md-12 col-lg-6 col-custom1024">
                  <form className="form-main">
                    <div className="MainDates">
                      <label className="col-form-label">
                        <b>From</b>
                      </label>
                      <div className="form-group custmmrg10">
                        <input
                          type="date"
                          name="celander"
                          required
                          onChangeCapture={handleFirstCalender}
                          className="form-control"
                          value={startDate}
                          // onChangeCapture={handleFirstCalender}
                        ></input>
                      </div>
                      &nbsp;
                      <label className="col-form-label">
                        <b>To</b>
                      </label>
                      <div className="form-group custmmrg10">
                        <input
                          type="date"
                          name="celander"
                          required
                          onChangeCapture={handleSecondCalender}
                          className="form-control"
                          // onChangeCapture={handleSecondCalender}
                          value={endDate}
                           min={disableDates}
                        ></input>
                      </div>
                    </div>
                  </form>
                  <Typography
                    className="text-center"
                    component="h2"
                    variant="h6"
                    gutterBottom
                  >
                    <div className="bar_graph_name" style={{paddingTop: "5px" }}>Transactions</div>
                  </Typography>
                  <BarChart
                    width={750}
                    height={320}
                    barSize={100}
                    align="center"
                    option={{ title: "donation chart of 5 member" }}
                    data={transactionsData}
                  >
                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                    <XAxis
                      dataKey="name"
                      stroke={theme.palette.text.primary}
                      tick={false}
                    />
                    <YAxis stroke={theme.palette.text.primary} />
                    <Tooltip content={<CustomTooltip1 />} />
                    {/* <Legend /> */}
                    <Bar dataKey="transactions" fill="black">
                      {transactionsData &&
                        transactionsData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            // fill={transactionsBarColors[index % 20]}
                            fill={generateRandomColor(
                              index,
                              transactionsBarColors
                            )}
                          />
                        ))}
                    </Bar>
                    <Legend content={renderLegend2} />
                  </BarChart>
                </div>
              </div>
            </div>

            <div className="row custmmb-5">
              <div className="col-md-12 col-lg-6 chat_cstmfnt">
                <Typography
                  className="text-center"
                  component="h2"
                  variant="h6"
                  gutterBottom
                >
                  {" "}
                  <div className="bar_graph_name">{typeof(charityId) !=='undefined' && charityId !==''? "Donations by Retailer/Event":"Donations by Retailer"}</div>
                </Typography>
                <PieChart width={800} height={380}>
                  <Legend />
                  <Pie
                    data={retailerData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {retailerData &&
                      retailerData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={generateRandomColor(
                            index,
                            transactionsBarColors
                          )}
                        />
                      ))}
                  </Pie>
                  <Tooltip
                    content={<CustomTooltipRetailerData />}
                    cursor={false}
                    position={{ y: 30 }}
                  />
                </PieChart>
              </div>

              <div className="col-md-12 col-lg-6 chat_cstmfnt">
                <Typography
                  className="text-center"
                  component="h2"
                  variant="h6"
                  gutterBottom
                >
                  <div className="bar_graph_name">
                  {typeof(charityId) !=='undefined' && charityId !==''? "Average Donation":"Average Donation by Charity"}
                  </div>
                </Typography>
                <PieChart width={800} height={380}>
                  <Tooltip
                    content={<CustomTooltipConsumerData />}
                    cursor={false}
                    position={{ y: 10 }}
                    //offset={-60}
                  />
                  <Legend />
                  <Pie
                    data={consumerData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel2}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    filterNull={true}
                  >
                    {consumerData &&
                      consumerData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={generateRandomColor(
                            index,
                            transactionsBarColors
                          )}
                        />
                      ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>

          <Footer />
        </section>
      </div>
    </>
  );
};

export default Dashboard;
