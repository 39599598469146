import { React, useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import PasswordForm from "./PasswordForm";

const ChangePassword = () => {
 

  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
           <PasswordForm />
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default ChangePassword;
