import { React, useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import {
  getAddData,
  addLotterData,
  updateLotterData,
} from "../API/LotteryAPI/LotteryAPI";
import { APIBase_URL } from "../../config";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import Swal from 'sweetalert2'

const Lottery = () => {
  const [setModal, setModalState] = useState(false);
  const [setUpdateModal, setUpdateModalState] = useState(false);
  const [updateID, setUpdateID] = useState("");
  const [lotteryList, setLotteryList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [lotteryName, setLotteryName] = useState("");
  const [lotteryType, setLotteryType] = useState(1);
  const [amount, setAmount] = useState("");

  const [charityId, setCharityID] = useState("");

  const [charityList, setCharityList] = useState(false);
  const [filtercharityId, setFiltercharityId] = useState("");
  const [filterType, setFilterType] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [msg, setMsg] = useState("");

  const [updateLotteryName, setUpdateLotteryName] = useState("");
  const [updateLotteryType, setUpdateLotteryType] = useState("");
  const [updateAmount, setUpdateAmount] = useState("");
  const [updateCharityId, setUpdateCharityID] = useState("");
  const [formData, setFormData] = useState("");
  const [formValues, setFormValues] = useState([
    { lotteryName: "", Amount: "" },
  ]);

  useEffect(() => {
    getLotteryList();
    getCharity();
  }, []);

  const getLotteryList = async () => {
    getAddData().then((resp) => {
      setLotteryList(resp);
      setFilterData(resp);
    });
  };

  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
        });

        setCharityList(arr);
      })
      .catch((err) => {});
  };

  const openModal = () => {
    setModalState(!setModal);
    setLotteryName("");
    setAmount("");
  };
  const AddLottery = async () => {
    const newArray = formValues.map((element, i) => {
      return {
        lotteryName: element?.[`lottery.${i}.name`],
        amount: element?.[`price.${i}.amount`],
      };
    });
    const data = {
      lotteryName: lotteryName,
      lotteryType: lotteryType,
      formValues: newArray,
      charityId: charityId,
    };
    if (formValues && lotteryType && charityId) {
      addLotterData(data)
        .then((res) => {
          if (res.data.status === true) {
            setMsg(`You Have Added A New Lottery`);
            setTimeout(() => {
              setModalState(!setModal);
              setLotteryName("");
              setLotteryType(1);
              setFormValues([{ lotteryName: "", Amount: "" }]);
              setCharityID("");
              getLotteryList();
              reset();
            }, 10);
            setTimeout(() => {
              setMsg("");
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please fill up all field.");
    }
  };
  useEffect(() => {
    reset(formData);
  }, [formData]);
  const selectLottery = async (id) => {
    setUpdateLotteryName("");
    setUpdateLotteryType("");
    setUpdateAmount("");
    setUpdateModalState(!setUpdateModal);

    let item = await lotteryList.filter((data) => data.lottery._id === id);
    let newData = {
      updateLotteryName: item[0].lottery.lotteryName,
      updateLotteryType: item[0].lottery.lotteryType,
      updateAmount: item[0].lottery.amount,
      updateCharityId: item[0].charityId,
    };
    setFormData(newData);
    //console.log(item);
    setUpdateLotteryName(item[0].lottery.lotteryName);
    setUpdateLotteryType(item[0].lottery.lotteryType);
    setUpdateAmount(item[0].lottery.amount);
    setUpdateCharityID(item[0].charityId);
    setUpdateID(item[0].lottery._id);
    setFilterType("");
    setFiltercharityId("");
  };

  const updateLottery = async () => {
    const formData = {
      lotteryName: updateLotteryName,
      //lotteryType: updateLotteryType,
      amount: updateAmount,
    };
    if (updateLotteryName && updateAmount) {
      updateLotterData(formData, updateID).then((resp) => {
       // console.log(resp);
        setMsg(`Lottery Successfully Updated`);
        setFilterType("");
        setFiltercharityId("");
        setTimeout(() => {
          setMsg("");
        }, 4000);
        getLotteryList();
        setUpdateModalState(false);
        setUpdateID("");
        setUpdateLotteryName("");
        setUpdateLotteryType("");
        setUpdateAmount("");
        setUpdateCharityID("");
        window.location.reload(false)
      });
    } else {
      alert("Please fill up all field.");
    }
  };

  const deleteLottery = async (id) => {
    const res = await fetch(`${APIBase_URL}lottery/${id}`, {
      method: "DELETE",
    });
    res.json().then((res) => {
      // console.log(res);
      setMsg(`Lottery Successfully Deleted`);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      getLotteryList();
    });
    // console.log(id);
  };

  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setCharityID(selectedCharity);
  };
  const handleUpdateChange = (e) => {
    const selectedCharity = e.target.value;
    setUpdateCharityID(selectedCharity);
  };
  const handleLotteryTypeChange = (e) => {
    const value = e.target.value;
    setLotteryType(value);
  };
  const handleUpdateLotteryTypeChange = (e) => {
    const value = e.target.value;
    setUpdateLotteryType(value);
  };
  const handleChangeCharity = (e) => {
    const selectedCharity = e.target.value;
    setLotteryList([]);
    setFilterType("");
    setFiltercharityId(
      selectedCharity === "Select Charity" ? "" : selectedCharity
    );
    if (selectedCharity === "Select Charity") {
      setFiltercharityId("");
      if (filterType) {
        let filter = filterData.map((data, i) => {
          if (data.lotteryType === filterType) {
            setLotteryList((p) => [...p, data]);
          }
        });
      } else {
        setLotteryList(filterData);
      }
    } else {
      let filter = filterData.map((data, i) => {
        if (selectedCharity && filterType) {
          if (
            data.charityId === selectedCharity &&
            data.lotteryType == filterType
          ) {
            setLotteryList((p) => [...p, data]);
          }
        } else if (data.charityId === selectedCharity) {
          setLotteryList((p) => [...p, data]);
        }
      });
    }
  };

  const handleChangeType = (e) => {
    const selectedType = e.target.value;
    setLotteryList([]);
    setFilterType(selectedType === "All Lottery Types" ? "" : selectedType);
    if (selectedType === "All Lottery Types") {
      setFilterType("");
      if (filtercharityId) {
        let filter = filterData.map((data, i) => {
          if (data.charityId === filtercharityId) {
            setLotteryList((p) => [...p, data]);
          }
        });
      } else {
        setLotteryList(filterData);
      }
    } else {
      let filter = filterData.map((data, i) => {
        if (selectedType && filtercharityId) {
          if (
            data.lotteryType == selectedType &&
            data.charityId === filtercharityId
          ) {
            setLotteryList((p) => [...p, data]);
          }
        } else if (data.lotteryType == selectedType) {
          setLotteryList((p) => [...p, data]);
        }
      });
    }
  };

  let handleAddChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { lotteryName: "", Amount: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleAddSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="userContainer p-3">
              <h2 className="pageTitle mb-4">Lottery Details</h2>

              <div className="topWrapper d-flex mb-5">
                {setModal ? (
                  <div className="ModalBackground">
                    <div className="lotterymodalContainer">
                      <div className="modalHeader mb-5">
                        <h3>Add Lottery</h3>
                      </div>
                      <button
                        className="closeBtn"
                        onClick={() => {
                          setModalState(false);
                          setFormValues([{ lotteryName: "", Amount: "" }]);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <form>
                        <div className="container">
                          <div className="row ">
                            {formValues.map((element, index) => (
                              <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Package Details</label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      name="lotteryName"
                                      {...register(`lottery.${index}.name`, {
                                        required: "Package details is required",
                                      })}
                                      //value={element.lotteryName || ""}
                                      onChange={(e) =>
                                        handleAddChange(index, e)
                                      }
                                    />
                                    {errors.lottery?.[index]?.name && (
                                      <small className="text-danger">
                                        {errors.lottery?.[index]?.name?.message}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    <label>Price</label>
                                    <div className="Flex-input">
                                      <div className="input-icon">
                                        <i>$</i>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        maxLength={6}
                                        //value={element.amount || ""}
                                        {...register(`price.${index}.amount`, {
                                          required: "Price is required",
                                        })}
                                        onKeyPress={function (event) {
                                          var regex = new RegExp(/^\d*\.?\d*$/);
                                          var key = String.fromCharCode(
                                            !event.charCode
                                              ? event.which
                                              : event.charCode
                                          );
                                          if (!regex.test(key)) {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                        onChange={(e) => {
                                          if (e.target.value <= 1000) {
                                            handleAddChange(index, e);
                                          } else {
                                            e.target.value = "";
                                          }
                                        }}
                                      />
                                    </div>
                                    {errors.price?.[index]?.amount && (
                                      <small className="text-danger">
                                        {errors.price?.[index]?.amount?.message}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                {index ? (
                                  <div className="col-md-1">
                                    <div className="form-group">
                                      <i
                                        style={{ marginTop: "48px" }}
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                        onClick={() => removeFormFields(index)}
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Lottery Type:</label>
                                <select
                                  className="form-control"
                                  {...register("lotteryType", {
                                    required: "Lottery Type is required",
                                  })}
                                  onChange={handleLotteryTypeChange}
                                >
                                  <option className="dropdown-item" value={1}>
                                    Lottery
                                  </option>
                                  <option className="dropdown-item" value={2}>
                                    50/50 Lottery
                                  </option>
                                </select>
                                {errors.lotteryType && (
                                  <small className="text-danger">
                                    {errors.lotteryType.message}
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Charity:</label>
                                <select
                                  className="form-control"
                                  {...register("charityID", {
                                    required: "Charity is required",
                                  })}
                                  onChange={handleChange}
                                >
                                  <option className="dropdown-item" value="">
                                    Select Charity
                                  </option>
                                  {charityList
                                    ? charityList.map((data) => (
                                        <option
                                          className="dropdown-item"
                                          value={data._id}
                                        >
                                          {data.charityName}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                {errors.charityID && (
                                  <small className="text-danger">
                                    {errors.charityID.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modalFooter modalfootercstm text-right mt-5 pt-3 d-flex justify-content-end cstmBtn">
                          <button
                            className="btn btn-primary add cstmBtn"
                            type="button"
                            onClick={() => addFormFields()}
                          >
                            Add Row
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-primary cstmBtn"
                            onClick={handleSubmit(AddLottery)}
                          >
                            {" "}
                            Save Lottery
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : null}

                {setUpdateModal ? (
                  <div className="ModalBackground">
                    <div className="lotterymodalContainer">
                      <div className="modalHeader mb-5">
                        <h3>Update Lottery</h3>
                      </div>
                      <button
                        className="closeBtn"
                        onClick={() => setUpdateModalState(false)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <form>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Package Details:</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="updateLotteryName"
                                  {...register("updateLotteryName", {
                                    required: "Package Details is required",
                                  })}
                                  onChange={(e) =>
                                    setUpdateLotteryName(
                                      validator.trim(e.target.value)
                                    )
                                  }
                                  value={updateLotteryName}
                                />
                                {errors.updateLotteryName && (
                                  <small className="text-danger">
                                    {errors.updateLotteryName.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Price:</label>
                                <div className="Flex-input">
                                  <div className="input-icon">
                                    <i>$</i>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="updateAmount"
                                    maxLength={6}
                                    value={updateAmount}
                                    onKeyPress={function (event) {
                                      var regex = new RegExp(/^\d*\.?\d*$/);
                                      var key = String.fromCharCode(
                                        !event.charCode
                                          ? event.which
                                          : event.charCode
                                      );
                                      if (!regex.test(key)) {
                                        event.preventDefault();
                                        return false;
                                      }
                                    }}
                                    {...register("updateAmount", {
                                      required: "Lottery Amount is required",
                                    })}
                                    onChange={(e) => {
                                      if (e.target.value <= 1000) {
                                        setUpdateAmount(e.target.value);
                                      } else {
                                        e.target.value = "";
                                      }
                                    }}
                                  />
                                </div>
                                {errors.updateAmount && (
                                  <small className="text-danger">
                                    {errors.updateAmount.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </form>
                      <div className="modalFooter modalfootercstm text-right mt-5 pt-3 d-flex justify-content-end">
                        <button
                          className="btn btn-primary cstmBtn"
                          onClick={handleSubmit(updateLottery)}
                        >
                          {" "}
                          Update Lottery
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary cstmBtn"
                          onClick={() => setUpdateModalState(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                <button className="btn btn-primary" onClick={openModal}>
                  {" "}
                  Add Lottery
                </button>
              </div>
              <div >
                <div>
                  <select
                    onChange={handleChangeCharity}
                    className="selectBox ui-selectBox lotterySelect"
                  >
                    <option className="dropdown-item">Select Charity</option>
                    {charityList
                      ? charityList.map((data) => (
                          <option className="dropdown-item" value={data._id}>
                            {data.charityName}
                          </option>
                        ))
                      : null}
                  </select>
                  <select
                    onChange={handleChangeType}
                    className="selectBox ui-selectBox"
                  >
                    <option className="dropdown-item">All Lottery Types</option>
                    <option className="dropdown-item" value={1}>
                      Lottery
                    </option>
                    <option className="dropdown-item" value={2}>
                      50/50 Lottery
                    </option>
                  </select>
                </div>
              </div>
              <h6 style={{ color: "green" }}>{msg}</h6>
              <div className="table-responsive custom-height">
                <table className="table table-striped mt-3">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: "0" }}
                  >
                    <tr>
                      <th>Charity Name</th>
                      <th>Package Details</th>
                      <th>Price</th>
                      <th>Lottery Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lotteryList.length
                      ? lotteryList.map((data) => (
                          <tr key={data.lottery._id}>
                            <td>{data.charity[0].charityName}</td>
                            <td>{data.lottery.lotteryName}</td>
                            <td>{`$${parseFloat(
                              data.lottery.amount
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}</td>
                            <td>
                              {data.lotteryType === 1
                                ? "Lottery"
                                : "50/50 Lottery"}
                            </td>
                            <td>
                              <span
                                className="edit"
                                onClick={() => selectLottery(data.lottery._id)}
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className="delete"
                                onClick={() =>
                                  Swal.fire({
                                    title: "Are you sure you want to delete this Lottery?",
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    icon: "warning",
                                    width:"470px",
                                    height:"380px"
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteLottery(data.lottery._id)
                                      Swal.fire({ title: "Lottery deleted!", showConfirmButton: true, icon: "success",  width:"470px",
                                      height:"380px"});
                                    } else {
                                    }
                                  })
                                  // window.confirm(
                                  //   "Are you sure you want to delete this Lottery?"
                                  // ) && deleteLottery(data.lottery._id)
                                }
                              >
                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Lottery;
