import { React, useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { APIBase_URL } from "../../config";
import { useFormik } from "formik";

const ForgotPassword = () => {
  const [passwordModal, setPasswordModal] = useState(true);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const validate = async (values) => {
    const errors = {};
    if (!values.forgotEmail) {
      errors.forgotEmail = "Email is required";
    } else if (
      !values.forgotEmail.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    ) {
      errors.forgotEmail = "Please enter valid email";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      forgotEmail: "",
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      sendEmail(values);
      resetForm();
    },
  });

  const sendEmail = async (values) => {
    let emailId = values.forgotEmail;
    var token = Math.floor(Math.random() * 100000000000001);
    const emailData = {
      email: emailId,
    };
    await axios
      .post(`${APIBase_URL}admin/adminUsingEmail`, emailData)
      .then((res) => {
        // console.log("response", res.data);
        if (res.data == true) {
          // alert("true");
          sendResetPasswordLink(values, token);
          saveResetPasswordTime(values.forgotEmail, token);
        } else if (res.data == false) {
          setErrMsg("This email is not associated with any account");
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const sendResetPasswordLink = async (values, token) => {
    let emailId = values.forgotEmail;

    //  console.log(isValidEmail,"valid email");

    var ciphertext = CryptoJS.AES.encrypt(emailId, "secret key 123").toString();

    // console.log(ciphertext, "ciphertext");

    const passwordResetlink =
      APIBase_URL + "#/forgotpassword/" + ciphertext + "/" + token;

    // console.log("password Reseet link ",passwordResetlink);

    const mailText =
      "<p>Hi,</br>You requested to reset your password.</br> Please, click the link below to reset your passowrd.</p></br>";
    const UrlLink =
      mailText + `<a href=${passwordResetlink}>${passwordResetlink}</a>`;

    const data = {
      from: "bnasmartpay21@gmail.com",
      to: emailId,
      subject: "BNA Smart Payment",
      html: UrlLink,
      link:passwordResetlink
    };

    await axios
      .post(`${APIBase_URL}mail`, data)
      .then((result) => {
        setErrMsg("");
        setMsg(`Change Password Link has been sent to your email`);
        setTimeout(() => {}, 2000);

        document.getElementById("forgotPassworSubmitBtn").disabled = true;
        document.getElementById("forgotEmail").disabled = true;
      })
      .catch((err) => {});
  };

  const saveResetPasswordTime = async (emailId, token) => {
    const emailData = {
      email: emailId,
      token: token,
    };
    await axios
      .post(`${APIBase_URL}admin/lastForgotPassword`, emailData)
      .then((res) => {
        // console.log("response", res.data);
      })
      .catch((err) => {});
  };
  return (
    <>
      {passwordModal ? (
        <div className="ModalBackground">
          <div className="modalContainer rounded p-3" style={{ textAlign: "center" }}>
          <div className="modalBg">
            <div className="modalHeader border-0 mb-3">
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setPasswordModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="text-left"> Forgot Password </h3>
            </div>
            <div>
              <h6 style={{ color: "green" }}>{msg}</h6>
              <h6 style={{ color: "red" }}>{errMsg}</h6>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div
                  className="form-group">
                  <label className="form-inline font-weight-bold mb-0">Email:</label>
                  <input
                    type="text"
                    name="forgotEmail"
                    className={`form-control mt-1`}
                    onChange={formik.handleChange}
                    value={formik.values.forgotEmail}
                    id="forgotEmail"
                  />
                </div>

                {formik.touched.forgotEmail && formik.errors.forgotEmail ? (
                  <small className="text-danger">
                    {" "}
                    {formik.errors.forgotEmail}
                  </small>
                ) : null}
              

              <div className="modalFooter text-right border-0">
                <button
                  className="btn btn-primary m-0"
                  style={{ width: "15%", padding: "2%", float: "right" }}
                  onClick={() => setPasswordModal(false)}
                >
                  {" "}
                  Cancel
                </button>
                &nbsp;
                <button
                  className="btn btn-primary my-0"
                  style={{height: "40px", float: "right" }}
                  type="submit"
                  id="forgotPassworSubmitBtn"
                >
                  {" "}
                  Send Email
                </button>
                &nbsp;
              </div>
            </form>
          </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ForgotPassword;
