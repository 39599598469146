import { React, useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import QRCODE from "react-qr-code";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import {
  getLotterQRCodeData,
  deleteQRCodeData,
} from "../API/QRCodeAPI/QRCodeAPI";
import { APIBase_URL } from "../../config";
import { getCharityData } from "../API/CharityAPI/CharityAPI";
import phoneLogo from "../../logo/phone-qr.png";
import CryptoJS from "crypto-js";
import Spinner from "../Spinner/Spinner";
import Swal from 'sweetalert2'

const LotteryCampaigns = () => {
  const [QRList, setQRList] = useState(false);
  const [QRModal, setQRModalState] = useState(false);
  const [QRAllModal, setQRAllModalState] = useState(false);
  const [QRAllModalAlert, setQRAllModalAlertState] = useState(false);
  const [qr, setQr] = useState("");
  const [qrName, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [charityList, setCharityList] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [allQrCode, setAllQrCode] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckDisable, setIsCheckDisable] =useState([]);
  const [charityLogo, setCharityLogo] = useState("");
  const refInput = useRef(null); //for download multiple QR we need to use useRef
  const refInputClose = useRef(null); //for close hidden modal popup we need to use useRef
  const [qrId, setQRId]=useState([]);

  useEffect(() => {
    getQR();
    getCharity();
  }, []);

  const getQR = async () => {
    getLotterQRCodeData().then((res) => {
      let arr = [];
      let resp = res.data;
      let filter = resp.map((data1, i) => {
        return data1.charityID.map((d) => {
          if (data1.active === false) {
            // if(data1.active || d.isActive === false){
            arr.push(data1);
          }
        });
      });

      setQRList(arr);
      setFilterData(arr);
      // setQRList(res.data)
    });
  };

  const deleteCharityCampaign = async (id) => {
    const data = {
      active: true,
    };
    deleteQRCodeData(data, id).then((res) => {
      getQR();
      setMsg(`Lottery Campaign Successfully Deleted `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
    });
  };

  const openQR = (qr, name, rName, languageType = "", logo = "") => {
    setQr(qr);
    setName(`${name} - ${rName}`);
    setLocationName(
      languageType === "English" ? `Donate to ${rName}` : `Donnez ${rName}`
    );
    setCharityLogo(logo);
    // setRName(rName);
    setQRModalState(!false);
  };

  const getAllOpenQR = (qr, name, rName, languageType = "", id, logo) => {
    let allData = [];
    const found = allQrCode.some((el) => el.id === id);
    let length = QRList.length;
    if (!found) {
      allData = [
        ...allQrCode,
        {
          qr,
          name,
          rName,
          languageType,
          id,
          logo,
        },
      ];
    } else {
      allData = allQrCode.filter((e) => e.id !== id);
    }
    if (length !== allData.length) {
      setIsCheckAll(false);
    } else if (length === allData.length) {
      setIsCheckAll(true);
    }
    setIsCheck([]);
    allData.map((val) => {
      setIsCheck((pre) => [...pre, val.id]);
    });

    setAllQrCode(allData);
  };

  const email = (id) => {
    window.open(`mailto : ? subject= & body=`);
  };

  const downloadQRCode = (id) => {
    domtoimage.toPng(document.getElementById(id)).then(function (dataUrl) {
      window.saveAs(dataUrl, `${qrName}.png`);
    });
  };

  const downloadQRAll = () => {
    setQRId([]);
    if (allQrCode.length > 5) {
      setQRAllModalAlertState(true);
      setQRAllModalState(false);
    
    }else if( allQrCode.length > 0 && allQrCode.length <= 5 ){
      setQRAllModalAlertState(false);

      setQRAllModalState(!false);
      setTimeout(function() {
        if(isCheckDisable.length>0){
          setIsCheckDisable([...isCheckDisable,...isCheck]);
        }else{
          setIsCheckDisable(isCheck);
        }
        for (let value of allQrCode) {
          qrPng(value.id, value.name);
        }
        
    }, 1000);
  
   
    }
     else {
      Swal.fire({ title: "Please select QR Code", showConfirmButton: true, icon: "warning", width:"470px",
      height:"380px", });
      // alert("Please select checkbox");
    }
  };
  // const downloadQRCodeAll = (id) => {

  //   domtoimage.toPng(document.getElementById(id)).then(function (dataUrl) {
  //     /* do something */

  //     window.saveAs(dataUrl, `${qrName}.png`);
  //   });
  // };

  const downloadQRCodeAll = (id) => {
    if(isCheckDisable.length>0){
      setIsCheckDisable([...isCheckDisable,...isCheck]);
    }else{
      setIsCheckDisable(isCheck);
    }
    for (let value of allQrCode) {
      qrPng(value.id, value.name);
    }
    // setIsCheck([]);
    // setQRAllModalState(false);
    // setAllQrCode([]);
  };
  const qrPng = (id, name) => {
    // domtoimage.toPng(document.getElementById(id)).then(function (dataUrl) {
    //   window.saveAs(dataUrl, `${name}.png`);
    // });
    domtoimage.toPng(document.getElementById(id)).then(function (dataUrl) {
        setQRId(qrId.push(id));
        if (window.saveAs(dataUrl, `${name}.png`)) {
        } else {
        }
        if(qrId.length===allQrCode.length){
          refInputClose.current.click();  //to click button for download selected QR codes
        }
        //window.saveAs(dataUrl, `${name}.png`);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckDisable([]);
      setAllQrCode([]);
    } else {
      QRList.map((data) => {
        setIsCheck((pre) => [...pre, data._id]);
        let ab = `${data.charityID[0]?._id},${data.retailerID[0]._id},${data.locationID[0]._id},${data.taxInfo},${data._id}`;
        var ciphertext = CryptoJS.AES.encrypt(ab, "secret key 123").toString();
        let qr = ciphertext;
        let name = data.charityID[0].charityName;
        let rName = data.retailerID[0].RetailerName;
        let languageType =
          typeof data.locationID[0].language != "undefined" &&
          data.locationID[0].language !== ""
            ? data.locationID[0].language
            : data.charityID[0].language;
        let id = data._id;
        // let logo = data.charityID[0].charityLogo;
        let logo =  data?.locationID[0]?.charityLogo ? data?.locationID[0]?.charityLogo : data.charityID[0].charityLogo;
        setAllQrCode((pre) => [
          ...pre,
          {
            qr,
            name,
            rName,
            languageType,
            id,
            logo,
          },
        ]);
      });
    }
  };

  let arr111 = [];
  const getCharity = async () => {
    getCharityData()
      .then((resp) => {
        let arr = [];
        let filter = resp.map((data, i) => {
          if (data.isActive === false) {
            arr.push(data);
          }
        });

        setCharityList(arr);
      })
      .catch((err) => {});
  };
  const handleChange = (e) => {
    const selectedCharity = e.target.value;
    setIsCheckAll(false);
    setIsCheck([]);
    setIsCheckDisable([]);
    setAllQrCode([]);
    setQRList([]);
    if (selectedCharity === "All Charities") return setQRList(filterData);
    let filter = filterData.map((data, i) => {
      return data.charityID.map((d) => {
        if (d.charityName === selectedCharity) {
          setQRList((p) => [...p, data]);
        } else {
          <div>"no Record Found"</div>;
        }
      });
    });
  };
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="retailerContainer p-3">
              <h2 className="pageTitle mb-4">LOTTERY CAMPAIGNS</h2>
              <div className="topWrapper">
                <div className="charityMain">
                  <h5>
                    <b>CHARITY</b>
                  </h5>
                  <div>
                    <select onChange={handleChange} className="selectBox">
                      <option className="dropdown-item">All Charities</option>
                      {charityList
                        ? charityList.map((data) => (
                            <option
                              className="dropdown-item"
                              value={data.charityName}
                            >
                              {data.charityName}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>{" "}
                </div>
                <div className="SearchWrap position-relative mr-4">
                  <button
                    style={{ marginTop: "-42px", float: "right" }}
                    className="btn btn-primary"
                    onClick={() => downloadQRAll(`qr${qr}`)}
                    disabled = {QRList.length > 0? false : true }
                  >
                    Download QR Code
                  </button>
                </div>
              </div>
              <h6 style={{ color: "green" }}>{msg}</h6>
              <div className="table-responsive custom-height">
                <table className="table table-striped mt-3 lottery-table">
                  <thead className="thead-light" style={{ zIndex: "2" }}>
                    <tr>
                      <th>Charity</th>
                      <th>Retailer/Event</th>
                      <th>Location</th>
                      {/* <th style={{ textAlign: "center" }}>Tax Receipt</th> */}
                      {/* <th>Amounts</th> */}
                      {/* <th>QR Code</th> */}
                      <th>Actions</th>
                      <th>
                        <br></br>
                        {/* <input
                          type="checkbox"
                          checked={isCheckAll}
                          onChange={handleSelectAll}
                          isChecked={isCheckAll}
                          disabled = {QRList.length > 0? false : true }
                        />{" "} */}
                        Select QR
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {QRList
                      ? QRList.map((data) => {
                          let ab = `${data.charityID[0]?._id},${data.retailerID[0]?._id},${data.locationID[0]?._id},${data.taxInfo},${data._id}`;
                          var ciphertext = CryptoJS.AES.encrypt(
                            ab,
                            "secret key 123"
                          ).toString();

                          return (
                            <tr key={data._id}>
                              <td>{data.charityID[0]?.charityName}</td>
                              <td>{data.retailerID[0].RetailerName}</td>
                              <td style={{ whiteSpace: "initial" }}>
                                {data.locationID[0].address},{" "}
                                {data.locationID[0].city},{" "}
                                {data.locationID[0].province},{" "}
                                {data.locationID[0].country},{" "}
                                {data.locationID[0].postal_code}
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {data.taxInfo}
                              </td> */}
                              <td id="qr">
                                <button
                                  style={{ marginLeft: "auto" }}
                                  type="button"
                                  className="btn btn-primary view-qr"
                                  onClick={() =>
                                    openQR(
                                      ciphertext,
                                      data.charityID[0].charityName,
                                      data.retailerID[0].RetailerName,
                                      typeof data.locationID[0].language !=
                                        "undefined" &&
                                        data.locationID[0].language !== ""
                                        ? data.locationID[0].language
                                        : data.charityID[0].language,
                                      data?.locationID[0]?.charityLogo ? data?.locationID[0]?.charityLogo : data.charityID[0].charityLogo
                                      // data.charityID[0].charityLogo
                                    )
                                  }
                                >
                                  View QR
                                </button>{" "}
                                &nbsp;
                                <span
                                  className="delete"
                                  onClick={() =>
                                    Swal.fire({
                                      title: "Are you sure you want to delete this Lottery Campaign?",
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                      confirmButtonText: "Yes",
                                      cancelButtonText: "Cancel",
                                      width:"470px",
                                      height:"380px",
                                      icon: "warning",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        deleteCharityCampaign(data._id)
                                        Swal.fire({ title: "Lottery Campaign deleted!", showConfirmButton: true, icon: "success", width:"470px",
                                        height:"380px" });
                                      } else {
                                      }
                                    })
                                    // window.confirm(
                                    //   "Are you sure you want to delete this Charity Campaign?"
                                    // ) && deleteCharityCampaign(data._id)
                                  }
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <input
                                  onClick={() =>
                                    getAllOpenQR(
                                      ciphertext,
                                      data.charityID[0].charityName,
                                      data.retailerID[0].RetailerName,
                                      typeof data.locationID[0].language !=
                                        "undefined" &&
                                        data.locationID[0].language !== ""
                                        ? data.locationID[0].language
                                        : data.charityID[0].language,
                                      data._id,
                                      data?.locationID[0]?.charityLogo ? data?.locationID[0]?.charityLogo : data.charityID[0].charityLogo
                                      // data.charityID[0].charityLogo
                                    )
                                  }
                                  type="checkbox"
                                  checked={isCheck.includes(data._id)}
                                  disabled ={isCheckDisable.includes(
                                    data._id
                                  )}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : "Loading..."}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </section>

        {QRModal ? (
          <div
            className="ModalBackground customQRCode"
            style={{
              flexDirection: "column",
            }}
          >
            <div
              id={`qr${qr}`}
              className="modalContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "30%",
              }}
            >
              <QRCODE
                className="qrcodecstm"
                value={`${APIBase_URL}#/lottery/${qr}`}
                //onClick={openQR}
                size="400"
                //size="120"
              />

              <div className="main-btn-wrapper qrcodedetails mt-4">
                <div className="img-portion-inside qrLogoBox">
                  <span className="img-portion-logo-circle charity_imgbox">
                    <img
                      src={charityLogo ? `/uploads/${charityLogo}` : phoneLogo}
                      alt="BNA logo"
                    />
                  </span>
                </div>
                <div className="text-heading"> Lottery Ticket</div>
              </div>
            </div>
            <div
              style={{
                background: "white",
                width: "506px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                paddingRight:"20px"
              }}
            >
              {/* <button
                style={{ marginTop: "12px" }}
                className="btn btn-primary"
                onClick={() => downloadQRCode(`qr${qr}`)}
              >
                Download
              </button>
              &nbsp; */}
              <button
                style={{ marginTop: "12px" }}
                className="btn btn-primary"
                onClick={() => setQRModalState(false)}
              >
                Close
              </button>
            </div>
          </div>
        ) : null}
        
        {QRAllModalAlert ? (
          <div
            className="ModalBackground"
            style={{
              flexDirection: "column",
            }}
            id ="maxQRAlert"
          >
           <div style={{ width: "100%" ,maxWidth:"506px"}}>
              <div
                className="modalContainer"
                style={{
                  background: "white",
                  width: "100%",
                  maxWidth:"506px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }} >
                Maximum 5 QR Code can be downloaded at one time.
              </div>
            </div>
            <div>
              <div
                style={{
                  background: "white",
                  width: "506px",
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginTop: "12px" }}
                  className="btn btn-primary"
                  onClick={() => {
                    setQRAllModalAlertState(false);
                    //setAllQrCode([])
                  }}
                >
                  OK
                </button>
                &nbsp;
                <button
                  style={{ marginTop: "12px" }}
                  className="btn btn-primary"
                  onClick={() => [
                    setQRAllModalAlertState(false),
                    setAllQrCode([])]
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : null}       
        {QRAllModal ? (

            <>
            <div
            className="ModalBackground customQRCode"
            style={{
              flexDirection: "column",
            }}
            >
            <Spinner />
            <div className="downloadSpinText">Downloading is in Progress ...</div>
            </div>

          <div
            id="modalpopup"
            className="ModalBackground customQRCode"
            style={{
              flexDirection: "column",
              //backgroundColor:"#000000d4",
            }}
          >
            <div className="max-height-scroll" style={{ width: "100%", maxWidth:"506px" }}>
              {allQrCode.length &&
                allQrCode.map((data) => (
                  <div
                  id={`${data.id}`}
                    className="modalContainer"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "30%",
                     
                    }}
                  >
                    <QRCODE
                    className="qrcodecstm"
                      value={`${APIBase_URL}#/lottery/${data.qr}`}
                      //onClick={openQR}
                      size="400"
                      //size="120"
                    />

                    <div className="main-btn-wrapper qrcodedetails mt-4">
                      {/* <div className="img-portion-inside">
                        {" "}
                        <img
                          src={data.logo?`/uploads/${data.logo}`:phoneLogo}
                          alt="BNA logo"
                          className="model-inside-img"
                        />
                      </div> */}
                      <div className="img-portion-inside qrLogoBox">
                        <span className="img-portion-logo-circle charity_imgbox">
                          <img
                            src={
                              data.logo ? `/uploads/${data.logo}` : phoneLogo
                            }
                            alt="BNA logo"
                          />
                        </span>
                      </div>
                      <div className="text-heading">
                        {" "}
                        {data.languageType === "English"
                          ? `Lottery Ticket`
                          : `Lottery Ticket`}
                      </div>
                    </div>
                    <br></br>
                  </div>
                ))}
            </div>
            <div style={{width: "100%" , visibility:"hidden"}}>
              <div
                   style={{
                    background: "white",
                    width: "100%",
                    maxWidth:"506px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    margin:"auto"
                  }}
              >
                <button
                  style={{ marginTop: "12px" }}
                  className="btn btn-primary"
                  ref={refInput}
                  onClick={() => downloadQRCodeAll(`qrAll`)}
                >
                  Download
                </button>{" "}
                &nbsp;
                <button
                  style={{ marginTop: "12px" }}
                  className="btn btn-primary"
                  ref={refInputClose}
                  onClick={() => {
                    setQRAllModalState(false);
                    setAllQrCode([])
                    setIsCheck([])
                    setQRId([]);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default LotteryCampaigns;
