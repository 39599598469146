import { React, useEffect, useState, createContext } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import coverLogo from "../../logo/img_avatar.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { APIBase_URL } from "../../config";
import validator from "validator";
import { updateAdminData } from "../API/UserAPI/UserAPI";
import { useStateValue } from "../../stateProvider";

const Profile = () => {
  const [loginUser, setLoginUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [MSG, setMsg] = useState("");
  const [abc, setAbc] = useState("");
  const [isUpload, setIsUpload] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const currentUser = localStorage.getItem("token");
  var token = currentUser;
  var decoded = jwt_decode(token);
  const userID = decoded.adminid;
  // console.log(decoded, userID, "======id");

  useEffect(() => {
    getUser();
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const getUser = async () => {
    const res = await fetch(`${APIBase_URL}admin/${userID}`);
    res.json().then((res) => {
      dispatch({
        type: "ADD_TO_USER",
        data: res,
      });
        setLoginUser(res);
        setEmail(res.email);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setProfileImg(res.profileImg);
      
    });
  };

  const handleEditProfile = () => {
    setOpenModal(true);
  };
  const updateHandleFile = (event) => {
    let img = event.target.files[0];
    setAbc(img);
    setIsUpload(event.target.files);
  };
  const editProfile = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    if (abc) {
      formData.append("profileImg", abc, abc.name);
    }

    updateAdminData(formData, userID)
      .then((res1) => {
        setProfileImg(res1.data.profileImg);
        setMsg(`Profile Updated Successfully`);
        setTimeout(() => {
          setMsg("");
        }, 2000);

        setOpenModal(false);

        getUser();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      {/* <ProfileImg.Provider value={profileImg}>
           <Header />
      </ProfileImg.Provider> */}
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="retailerContainer p-3">
              <h2 className="pageTitle mb-4">PROFILE</h2>
            <div className="cstmRetailer">
              <div className="imgcard Img">
                <div>
                <img
                  src={profileImg ? `/profile/${profileImg}` : coverLogo}
                  alt="Avatar"
                  class="avatar avatarCstm"
                  width="60"
                  height="60"
                  style={{ display: isLoading ? "none" : "block" }}
                />
                </div>
              </div>
              <div>
              <div className="topWrapper new-display-class d-flex mb-2">
                <h3>
                  <table>
                  <tr>
                    <th>Name : </th>
                    <td>
                      {" "}
                     {loginUser.firstName} {loginUser.lastName}
                    </td>
                  </tr>
                  <tr>
                    <th>Email : </th>
                    <td>{loginUser.email}</td>
                  </tr>
                  </table>
                </h3>
              </div>
              <button
                type="button"
                class="btn btn-primary float-right"
                onClick={handleEditProfile}
              >
                Edit Profile
              </button>
              <div className="text-success">
                <p>{MSG}</p>
              </div>
              </div>
             
            </div>
            </div>
          </div>
          <div className="retailerContainer p-3">
            {openModal ? (
              <div className="ModalBackground">
                <div className="modalContainer ediPrf m-4 rounded">
                  <div className="modalHeader mb-5">
                    <h3>Edit Profile</h3>
                  </div>
                  <button
                    className="closeBtn"
                    onClick={() => setOpenModal(false)}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                  <form>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name:</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              name="firstName"
                              {...register("firstName", {
                                required: "First Name is required",
                              })}
                              // style={{marginTop: "14px"}}
                              onChange={(e) =>
                                setFirstName(validator.ltrim(e.target.value))
                              }
                              value={firstName}
                            />
                            {errors.firstName && (
                              <small className="text-danger">
                                {errors.firstName.message}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name:</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              name="lastName"
                              {...register("lastName", {
                                required: "Last Name is required",
                              })}
                              // style={{marginTop: "14px"}}

                              onChange={(e) =>
                                setLastName(validator.ltrim(e.target.value))
                              }
                              value={lastName}
                            />
                            {errors.lastName && (
                              <small className="text-danger">
                                {errors.lastName.message}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email:</label>
                            <input
                              required
                              type="Email"
                              className="form-control"
                              name="email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                            {errors.email && (
                              <small className="text-danger">
                                {errors.email.message}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>Upload Picture</label>
                          <div className="input-group mb-3">
                            <div className="custom-file">
                              <input
                                className="uplaodController custom-file-input"
                                type="file"
                                name="UpdateProfilePic"
                                id="files"
                                accept=".jpg,.jpeg,.png"
                                style={{}}
                                onChange={updateHandleFile}
                              />
                              <label className="custom-file-label" for="files">
                                Upload Picture
                              </label>
                            </div>
                          </div>
                          <div style={{ width: "118px" }}>
                            {isUpload == "" ? (
                              <img
                                style={{ width: "100%" }}
                                src={`/profile/${profileImg}`}
                                alt="Picture"
                              />
                            ) : (
                              ""
                            )}
                            {isUpload &&
                              [...isUpload].map((file) => (
                                <img
                                  style={{ width: "100%" }}
                                  src={URL.createObjectURL(file)}
                                  alt="logo"
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="modalFooter text-right mt-5 pt-3">
                    <button
                      className="btn btn-primary "
                      onClick={handleSubmit(editProfile)}
                    >
                      Update Profile
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-primary "
                      onClick={() => setOpenModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Profile;
