import { React, useState, useEffect, useContext  } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo2 from "../../logo/logo-header-final-12.png";
import coverLogo from "../../logo/img_avatar.png";
import { useStateValue } from "../../stateProvider";

const Header = (prop) => {
  // const pImg = useContext(ProfileImg);
  // console.log(pImg, 'pImgpImgpImgpImgpImgpImg')
  // const ProfileImg = pImg?pImg:''
  const [loginStatus, setLoginStatus] = useState(false);
  const [state, setState] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("data"));
  // const currentUse1r = JSON.parse(localStorage.getItem('token'))

  const history = useHistory();

  const [{ userProfile }, dispatch] = useStateValue();

  function logOut() {
    localStorage.clear();
    setLoginStatus(false);
    history.push("/");
  }

  useEffect(()=>{
// console.log('22');
  },[userProfile])

  const setstyle = () => {
    let a = document.getElementById("addwidth");

    if (state && a) {
      setState(false);
      // a.style.width = "0px";
      a.className = "sidebar";
    } else {
      setState(true);
      // a.style.width = "235px";
      a.className = "sidebar expendMenu";
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-light bg-dark ">
        <Link exact to="/dashboard">
          <div className="header-logo">
            <img src={logo2} alt="BNA logo" />
          </div>
        </Link>
        <div className="userAccount_option">
          <div className="responsive-toggle">
            <i onClick={setstyle} className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <div className="profile">
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle text-white d-flex align-items-center"
                data-toggle="dropdown"
              >
                {/* <i
                  className="fa fa-user-circle-o text-white"
                  aria-hidden="true"
                >
                </i> */}
                {userProfile?.profileImg?
                <img
                key={userProfile?.profileImg}
                  src={`/profile/${userProfile?.profileImg}` || ""}
                  alt="Avatar"
                  className="header-profilepic"
                />:
                <i
                  className="fa fa-user-circle-o text-white"
                  aria-hidden="true"
                >
                </i>
                }
                
                 <label className="username mb-0 ml-3">
                 {userProfile?.firstName?userProfile.firstName
                 :currentUser?.admin && currentUser?.admin[0].firstName
                 }
                </label>
                
              </button>
              <div className="dropdown-menu drop-menu-1">
                <Link className="dropdown-item text-center" exact to="/profile">
                  Profile
                </Link>
                <Link className="dropdown-item" exact to="/changePassword">
                  Change Password
                </Link>
                <a
                  className="dropdown-item text-center"
                  style={{ cursor: "pointer" }}
                  onClick={logOut}
                >
                  Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <style>
        {`.dropdown-item:focus, .dropdown-item:hover {
    color: #f5f5f5;
    text-decoration: none;
    background-color: #0a2558;
}`}
      </style>
    </>
  );
};

export default Header;
