import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import reducer, { initialState } from "./reducer";
import { StateProvider } from "./stateProvider";

ReactDOM.render(
  <BrowserRouter>
  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
    </StateProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
