import axios from "axios";
import { APIBase_URL } from "../../../config";

export const getLocationData = async () => {
  const result1 = await fetch(`${APIBase_URL}location`);
  const result = result1.json();
  return result;
};

export const addLocationData = async (data, retailerID) => {
  const result = await axios.post(`${APIBase_URL}location/${retailerID}`, data);
  return result;
};

export const updateLocationData = async (data, updateID) => {
  const result1 = await fetch(`${APIBase_URL}retailer/${updateID}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = result1.json();
  return result;
};

export const updateLocationDataById = async (data, updateID) => {
  const result1 = await fetch(`${APIBase_URL}location/updateLocationById/${updateID}`, {
    method: "PATCH",
    body: data,
  });
  const result = await result1.json();
  return result;
};

export const deleteLocationLogo = async (updateID) => { //////
  const result1 = await fetch(`${APIBase_URL}location/deletelogo/${updateID}`, {
    method: "PATCH",
  });
  const result = await result1.json();
  return result;
};

export const deleteLocationData = async (data, id) => {
  const result1 = await fetch(`${APIBase_URL}location/delete/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = result1.json();
  return result;
};
export const getLocationStartingTicketNumberData = async(startingTicketNumber) => {
  const result1 = await fetch(`${APIBase_URL}location/lotteryNumber/${startingTicketNumber}`)
  const result = await result1.json()
   return result

}
