import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  getRetailerData,
  addRetailerData,
  updateRetailerData,
  deleteRetailerData,
  importCsvRetailerData,
  importCsvLocationData,
} from "../API/RetailerAPI/RetailerAPI";
import {
  getLocationData,
  addLocationData,
  deleteLocationData,
  updateLocationDataById,
  deleteLocationLogo, /////
  getLocationStartingTicketNumberData,
} from "../API/RetailerAPI/LocationAPI";
import { getProvinceData } from "../API/ProvinceAPI/ProvinceAPI";
import { APIBase_URL } from "../../config";
import Validator from "validator";
import Swal from 'sweetalert2'

const Retailer = () => {
  const [setModal, setModalState] = useState(false);
  const [setUpdateModal, setUpdateModalState] = useState(false);
  const [setlocationModal, setLocationModalState] = useState(false);
  const [updateLocationModal, setUpdateLocationModal] = useState(false);
  const [updateColor, setUpdateColor] = useState("");
  const [updateFontColor, setUpdateFontColor] = useState("");
  const [viewLocation, setViewLocationModal] = useState(false);
  const [locationData, setLocationData] = useState(false);
  const [retailerID, setRetailerID] = useState("");
  const [rName, setRName] = useState("");
  const [updateID, setUpdateID] = useState(false);
  const [retailerList, setRetailerList] = useState("");
  const [provinceList, setProvinceList] = useState("");
  const [msg, setMsg] = useState("");
  const [fileNamemsg, setFileNamemsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isActive, setActive] = useState(false);
  const [isUpload, setIsUpload] = useState(""); //////
  const [charityLogo, setCharityLogo] = useState(""); //////
  const [isAddUpload, setIsAddUpload] = useState(""); //////
  const [RetailerName, setRetailerName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ext, setExt] = useState("");
  const [updateCharityLogo, setUpdateCharityLogo] = useState(""); //////
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [abc, setAbc] = useState(""); //////
  const [country, setCountry] = useState("Canada");
  const [postal_code, setPostal_Code] = useState("");
  const [storeID, setStoreID] = useState("");
  const [locationContact, setLocationContact] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [locationPhone, setLocationPhone] = useState("");
  const [locationExt, setLocationExt] = useState("");
  const [MID, setMID] = useState("");
  const [language, setLanguage] = useState("English");
  const [donationFrequency, setDonationFrequency] = useState("OneTime");

  const [createTaxReceipt, setCreateTaxReceipt] = useState("0");
  // const [taxReceiptEligible, setTaxReceiptEligible] = useState("0");
  // const [processFee, setProcessFee] = useState("0");

  const [taxReceiptEligible, setTaxReceiptEligible] = useState("0");
  const [processFee, setProcessFee] = useState("0");
  const [processFeeAmount, setProcessFeeAmount] = useState("");
  const [lotteryTicket, setLotteryTicket] = useState(null);
  const [fiftyTicket, setFiftyTicket] = useState(null);
  const [createLotteryTicket, setCreateLotteryTicket] = useState(null);
  const [charityType, setCharityType] = useState(null);
  const [addSecond, setAddSecond] = useState(false);
  // const [processFeeAmount, setProcessFeeAmount] = useState('');

  const [updateRetailerName, setUpdateRetailerName] = useState("");
  const [updateContactName, setUpdateContactName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [updateExt, setUpdateExt] = useState("");
  const [color, setColor] = useState("");
  const [fontColor, setFontColor] = useState("");

  const [updateAddress, setUpdateAddress] = useState("");
  const [updateProvince, setUpdateProvince] = useState("");
  const [updateCity, setUpdateCity] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updatePostal_Code, setUpdatePostal_Code] = useState("");
  const [updateStoreID, setUpdateStoreID] = useState("");
  const [updateLocationContact, setUpdateLocationContact] = useState("");
  const [updateLocationEmail, setUpdateLocationEmail] = useState("");
  const [updateLocationPhone, setUpdateLocationPhone] = useState("");
  const [updateLocationExt, setUpdateLocationExt] = useState("");
  const [updateMID, setUpdateMID] = useState("");
  const [updateLanguage, setUpdateLanguage] = useState();
  const [updateDonationFrequency, setUpdateDonationFrequency] = useState("");

  const [updateCreateTaxReceipt, setUpdateCreateTaxReceipt] = useState("0");
  const [updateTaxReceiptEligible, setUpdateTaxReceiptEligible] = useState("0");
  const [updateProcessFee, setUpdateProcessFee] = useState("0");
  const [updateSecond, setUpdateSecond] = useState(false);
  const [updateProcessFeeAmount, setUpdateProcessFeeAmount] = useState();
  const [updatelotteryTicket, setUpdateLotteryTicket] = useState(null);
  const [updatefiftyTicket, setUpdateFiftyTicket] = useState(null);
  const [updatecreateLotteryTicket, setUpdateCreateLotteryTicket] =
    useState(null);
  const [updateCharityType, setUpdateCharityType] = useState(null);

  const [updateAmount1, setUpdateAmount1] = useState("");
  const [updateAmount2, setUpdateAmount2] = useState("");
  const [updateAmount3, setUpdateAmount3] = useState("");
  const [updateAmount4, setUpdateAmount4] = useState("");
  const [updateAmount5, setUpdateAmount5] = useState("");
  const [updateAmount6, setUpdateAmount6] = useState("");
  const [updateAmount7, setUpdateAmount7] = useState("");
  const [updateAmount8, setUpdateAmount8] = useState("");

  const [updateMonthlyAmount1, setUpdateMonthlyAmount1] = useState("");
  const [updateMonthlyAmount2, setUpdateMonthlyAmount2] = useState("");
  const [updateMonthlyAmount3, setUpdateMonthlyAmount3] = useState("");
  const [updateMonthlyAmount4, setUpdateMonthlyAmount4] = useState("");
  const [updateMonthlyAmount5, setUpdateMonthlyAmount5] = useState("");
  const [updateMonthlyAmount6, setUpdateMonthlyAmount6] = useState("");
  const [updateMonthlyAmount7, setUpdateMonthlyAmount7] = useState("");

  //===================================================================//
  const [addDonationAmount, setAddDonationAmount] = useState([]);
  const [getDonationAmount, setGetDonationAmount] = useState([]);
  const [donationAmount, setDonationAmount] = useState("");

  const [cheack, setCheack] = useState("");
  const [importExcelModel, setImportExcelModal] = useState(false);
  const [file, setFile] = useState();
  const [importCsvLocationModel, setImportCsvLocationModal] = useState(false);
  const [retailerIDLocation, setRetailerIDLocation] = useState("");

  const [msgCsvUpload, setMsgCsvUpload] = useState("");
  const [retailerIDGetLocation, setRetailerIDGetLocation] = useState("");
  const [getLocationId, setGetLocationId] = useState("");
  const [formData, setFormData] = useState("");

  const [msgAmount, setMsgAmount] = useState("");
  const [msgMonthlyAmount, setMsgMonthlyAmount] = useState("");
  const [msg2, setUpdateMsg] = useState("");
  const [saveamount, setamount] = useState([]);
  const [saveMonthlyAmount, setMonthlyamount] = useState([]);
  const [saveUpdateMonthlyAmount, setUpdateMonthlyAmount] = useState([]);
  const [saveUpdateAmount, setUpdateAmount] = useState([]);
  const [name1, setName1] = useState({});
  const [nameMonthly, setNameMonthly] = useState({});
  const [updateName1, setUpdateName1] = useState({});
  const [updateMonthlyName, setUpdateMonthlyName] = useState({});
  const [msgLocation, setMeslocation] = useState("");
  const [msgUpdateMonthlyAmount, setUpdateMonthlyAmountMsg] = useState("");

  const [isDonationMonthly, setisDonationMonthly] = useState(false);
  const [updateIsDonationMonthly, setupdateIsDonationMonthly] = useState(false);

  const [addAmount1, setAddAmount1] = useState("");
  const [addAmount2, setAddAmount2] = useState("");
  const [addAmount3, setAddAmount3] = useState("");
  const [addAmount4, setAddAmount4] = useState("");
  const [addAmount5, setAddAmount5] = useState("");
  const [addAmount6, setAddAmount6] = useState("");
  const [addAmount7, setAddAmount7] = useState("");

  const [addMonthlyAmount1, setAddMonthlyAmount1] = useState("");
  const [addMonthlyAmount2, setAddMonthlyAmount2] = useState("");
  const [addMonthlyAmount3, setAddMonthlyAmount3] = useState("");
  const [addMonthlyAmount4, setAddMonthlyAmount4] = useState("");
  const [addMonthlyAmount5, setAddMonthlyAmount5] = useState("");
  const [addMonthlyAmount6, setAddMonthlyAmount6] = useState("");
  const [addMonthlyAmount7, setAddMonthlyAmount7] = useState("");
  const [startingTicketNumber, setStartingTicketNumber] = useState(null);
  const [updateStartingTicketNumber, setUpdateStartingTicketNumber] =
    useState(null);
  const [sendGridTemplateId, setSendGridTemplateId] = useState(null);
  const [sendGridLotteryTemplateId, setSendGridLotteryTemplateId] =
    useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [lotteryTemplateName, setlotteryTemplateName] = useState(null);
  const [updateSendGridTemplateId, setUpdateSendGridTemplateId] =
    useState(null);
  const [updateSendGridLotteryTemplateId, setUpdateSendGridLotteryTemplateId] =
    useState(null);
  const [updateTemplateName, setUpdateTemplateName] = useState(null);
  const [updateLotteryTemplateName, setUpdateLotteryTemplateName] =
    useState(null);

  const [updateLotteryEmailSendGridId, setUpdateLotteryEmailSendGridId] =
    useState("");
  const [updateDonationEmailSendGridId, setUpdateDonationEmailSendGridId] =
    useState("");
  const [lotteryEmailSendGridId, setLotteryEmailSendGridId] = useState("");
  const [donationEmailSendGridId, setDonationEmailSendGridId] = useState("");
  const [error, setError] = useState({});
  const [stringOne, setStringOne] = useState("");
  const [stringTwo, setStringTwo] = useState("");
  const [updateStringOne, setUpdateStringOne] = useState("");
  const [updateStringTwo, setUpdateStringTwo] = useState("");
  const [lotteryEmailEdit, setLotteryEmailEdit] = useState(false);
  const [donationEmailEdit, setDonationEmailEdit] = useState(false);
  const [updateLotteryEmailEdit, setUpdateLotteryEmailEdit] = useState(false);
  const [updateDonationEmailEdit, setUpdateDonationEmailEdit] = useState(false);
  const [rulesRegUrl, setRulesRegUrl] = useState("");
  const [updateRulesRegUrl, setUpdateRulesRegUrl] = useState("");

  useEffect(() => {
    getRetailer();
    getProvince();
  }, []);

  const getRetailer = async () => {
    getRetailerData().then((resp) => {
      let arr = [];
      const resp1 = resp.retailer;
      let filter = resp1.map((data) => {
        if (data.isActive === false) {
          arr.push(data);
        }
      });
      setRetailerList(arr);
      setUpdateRetailerName(resp?.retailer[0]?.RetailerName);
      setUpdateContactName(resp?.retailer[0]?.contactName);
      setUpdateEmail(resp?.retailer[0]?.email);
      setUpdateID(resp?.retailer[0]?._id);
    });
  };
  const getProvince = async () => {
    getProvinceData().then((resp) => {
      setProvinceList(resp);
    });
  };

  const openModal = () => {
    setModalState(!setModal);
    setUpdateRetailerName("");
    setUpdateContactName("");
    setUpdateEmail("");
    setUpdatePhone("");
    setUpdateExt("");
  };

  const addretailer = () => {
    const data = {
      RetailerName: RetailerName,
      contactName: contactName,
      email: email,
      phone: phone,
      ext: ext,
    };
    addRetailerData(data)
      .then((res) => {
        localStorage.setItem("retailer", JSON.stringify(res.data));
        setMsg(`Retailer Successfully Added `);
        setTimeout(() => {
          setMsg("");
        }, 4000);
        getRetailer();
      })
      .catch((err) => {
        // console.log(err);
      });
    setModalState(false);
    setRetailerName("");
    setContactName("");
    setEmail("");
    setPhone("");
    setExt("");

    reset();
  };

  const selectRetailer = async (id, name) => {
    setRName(name);
    setUpdateRetailerName("");
    setUpdateContactName("");
    setUpdateEmail("");
    setUpdatePhone("");
    setUpdateExt("");
    setUpdateModalState(!setUpdateModal);
    let filter = await retailerList.filter((data) => data._id === id);
    let newData = {
      updateRetailerName: filter[0]?.RetailerName,
      updateContactName: filter[0]?.contactName,
      updateEmail: filter[0]?.email,
      updatePhone: filter[0]?.phone,
    };

    setFormData(newData);
    setUpdateRetailerName(filter[0]?.RetailerName);
    setUpdateContactName(filter[0]?.contactName);
    setUpdateEmail(filter[0]?.email);
    setUpdatePhone(normalizeInput(filter[0]?.phone));
    setUpdateExt(filter[0]?.ext);
    setUpdateID(filter[0]?._id);
  };

  const handleFile = (event) => {
    //////f
    let img = event.target.files[0];
    setCharityLogo(img);
    setIsAddUpload(event.target.files);
  };

  const updateHandleFile = (event) => {
    //////f
    let img = event.target.files[0];
    setAbc(img);
    setIsUpload(event.target.files);
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const selectLocation = async (id) => {
    setUpdateLocationModal(!updateLocationModal);
    let filter = await retailerList.filter(
      (data) => data._id === retailerIDGetLocation
    );
    let filter2 = await filter[0].locations.filter((data) => data._id === id);
    let newData = {
      UpdateAddress: filter2[0].address,
      UpdateProvince: filter2[0].province,
      UpdateCity: filter2[0].city,
      UpdateCountry: filter2[0].country,
      UpdatePostal_Code: postalCodeFormat(filter2[0].postal_code),
      UpdateStoreID: filter2[0].storeID,
      UpdateLocationContact: filter2[0].locationContact,
      UpdateLocationEmail: filter2[0].locationEmail,
      UpdateLocationPhone: normalizeInput(filter2[0].locationPhone),
      UpdateLocationExt: filter2[0].locationExt,
      UpdateMID: filter2[0].MID,
      charityType: filter2[0].charityType,
      lotteryTicket: filter2[0].lotteryTicket,
      fiftyTicket: filter2[0].fiftyTicket,
      createLotteryTicket: filter2[0].createLotteryTicket,
      startingTicketNumber: filter[0].startingTicketNumber,
      lotteryTemplateName: filter[0]?.lotteryTemplateName,
      sendGridLotteryTemplateId: filter[0]?.sendGridLotteryTemplateId,
      sendGridTemplateId: filter[0]?.sendGridTemplateId,
      templateName: filter[0]?.templateName,
      color: filter[0]?.color,
      fontColor: filter[0]?.fontColor,
      // emailFrom:filter[0]?.emailFrom,
      donationEmailSendGridId: filter[0]?.donationEmailSendGridId,
      lotteryEmailSendGridId: filter[0]?.lotteryEmailSendGridId,
    };
    setFormData(newData);
    setGetLocationId(filter2[0]._id);
    setUpdateCharityLogo(filter2[0]?.charityLogo); //////
    setUpdateAddress(filter2[0].address);
    setUpdateProvince(filter2[0].province);
    setUpdateCity(filter2[0].city);
    setUpdateCountry(filter2[0].country);
    setUpdatePostal_Code(postalCodeFormat(filter2[0].postal_code));
    setUpdateStoreID(filter2[0].storeID);
    setUpdateLocationContact(filter2[0].locationContact);
    setUpdateLocationEmail(filter2[0].locationEmail);
    setUpdateLocationPhone(normalizeInput(filter2[0].locationPhone));
    setUpdateLocationExt(filter2[0].locationExt);
    setUpdateMID(filter2[0].MID);
    setUpdateLanguage(
      typeof filter2[0].language != "undefined"
        ? filter2[0].language
        : "English"
    );
    setUpdateDonationFrequency(
      typeof filter2[0].donationFrequency != "undefined"
        ? filter2[0].donationFrequency
        : "OneTime"
    );
    if (filter2[0].donationFrequency == "Monthly") {
      setupdateIsDonationMonthly(true);
    }
    setUpdateAmount1(
      filter2[0].donationAmount[0] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[0])
        : ""
    );
    setUpdateAmount2(
      filter2[0].donationAmount[1] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[1])
        : ""
    );
    setUpdateAmount3(
      filter2[0].donationAmount[2] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[2])
        : ""
    );
    setUpdateAmount4(
      filter2[0].donationAmount[3] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[3])
        : ""
    );
    setUpdateAmount5(
      filter2[0].donationAmount[4] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[4])
        : ""
    );
    setUpdateAmount6(
      filter2[0].donationAmount[5] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[5])
        : ""
    );
    setUpdateAmount7(
      filter2[0].donationAmount[6] !== "undefined"
        ? currencyFormat2(filter2[0].donationAmount[6])
        : ""
    );
    // setUpdateAmount8(
    //   filter2[0].donationAmount[7] !== "undefined"
    //     ? currencyFormat2(filter2[0].donationAmount[7])
    //     : ""
    // );
    let amount = filter2[0].donationAmount;

    var amountData = {};
    amount.forEach(function (e, i) {
      amountData[`name${i + 1}`] = e[0];
    });
    setUpdateAmount(amount);
    setUpdateName1(amountData);

    setUpdateMonthlyAmount1(
      filter2[0].monthlyDonationAmount[0] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[0])
        : ""
    );
    setUpdateMonthlyAmount2(
      filter2[0].monthlyDonationAmount[1] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[1])
        : ""
    );
    setUpdateMonthlyAmount3(
      filter2[0].monthlyDonationAmount[2] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[2])
        : ""
    );
    setUpdateMonthlyAmount4(
      filter2[0].monthlyDonationAmount[3] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[3])
        : ""
    );
    setUpdateMonthlyAmount5(
      filter2[0].monthlyDonationAmount[4] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[4])
        : ""
    );
    setUpdateMonthlyAmount6(
      filter2[0].monthlyDonationAmount[5] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[5])
        : ""
    );
    setUpdateMonthlyAmount7(
      filter2[0].monthlyDonationAmount[6] !== "undefined"
        ? currencyFormat2(filter2[0].monthlyDonationAmount[6])
        : ""
    );

    let amountMonthly = filter2[0].monthlyDonationAmount;
    var amountMonthlyData = {};
    amountMonthly.forEach(function (e, i) {
      amountMonthlyData[`namemonthly${i + 1}`] = e[0];
    });
    setUpdateMonthlyAmount(amountMonthly);
    setUpdateMonthlyName(amountMonthlyData);
    setUpdateCharityType(filter2[0].charityType);
    setUpdateCreateTaxReceipt(`${filter2[0].createTaxReceipt}`);
    setUpdateTaxReceiptEligible(`${filter2[0].taxReceiptEligible}`);
    setUpdateProcessFee(`${filter2[0].processFee}`);
    setUpdateLotteryTicket(`${filter2[0].lotteryTicket}`);
    setUpdateFiftyTicket(`${filter2[0].fiftyTicket}`);
    setUpdateCreateLotteryTicket(`${filter2[0].createLotteryTicket}`);
    setUpdateColor(`${filter2[0].color}`);
    setUpdateFontColor(`${filter2[0].fontColor}`);
    setUpdateProcessFeeAmount(
      filter2[0].processFeeAmount !== "undefined"
        ? filter2[0].processFeeAmount
        : ""
    );
    if (filter2[0]?.startingTicketNumber) {
      let whole_string = filter2[0]?.startingTicketNumber;
      let split_string = whole_string.split(/(\d+)/);
      setUpdateStringOne(split_string[0]);
      setUpdateStringTwo(split_string[1]);
      setUpdateStartingTicketNumber(filter2[0]?.startingTicketNumber);
    }

    setUpdateLotteryTemplateName(filter2[0]?.lotteryTemplateName);
    setUpdateSendGridLotteryTemplateId(filter2[0]?.sendGridLotteryTemplateId);
    setUpdateSendGridTemplateId(filter2[0]?.sendGridTemplateId);
    setUpdateTemplateName(filter2[0]?.templateName);
    setUpdateDonationEmailSendGridId(filter2[0]?.donationEmailSendGridId);
    setUpdateRulesRegUrl(filter2[0]?.rulesRegUrl);
    setUpdateLotteryEmailSendGridId(filter2[0]?.lotteryEmailSendGridId);
  };

  const updateRetailer = async () => {
    const data = {
      RetailerName: updateRetailerName,
      contactName: updateContactName,
      email: updateEmail,
      phone: updatePhone,
      ext: updateExt,
    };
    updateRetailerData(data, updateID).then((res) => {
      // console.log(res);
      setMsg(`Retailer ${rName} Successfully Updated `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      getRetailer();
    });
    setFormData("");
    setUpdateModalState(false);
    setUpdateRetailerName("");
    setUpdateContactName("");
    setUpdateEmail("");
    setUpdatePhone("");
    setUpdateExt("");
  };

  const deleteRetailer = async (id, location) => {
    let data = {
      isActive: true,
    };
    deleteRetailerData(data, id).then((res) => {
      getRetailer();
      setMsg(`Retailer Successfully Deleted `);
      setTimeout(() => {
        setMsg("");
      }, 4000);
    });
  };

  const getRetailerID = (id, name) => {
    setRName(name);
    setAddress("");
    setCity("");
    setProvince("");
    //setCountry("");
    setPostal_Code("");
    setStoreID("");
    setLocationModalState(!setlocationModal);
    setRetailerID(id);
  };

  const handlePostalCode = (event) => {
    let postalCode = event.target.value;
    setPostal_Code(postalCodeFormat(postalCode));
    // console.log(postalCode, "postalCodepostalCode");
    // let val = retailerList.map((a) => a.locations.map((b) => b.postal_code));
    // var merged = [].concat.apply([], val);
    // var chk = merged.includes(postalCode);
    // setCheack(chk);
    // if (chk) {
    //   setMsg(` Duplicate Postal Code `);
    //   setTimeout(() => {
    //     setMsg("");
    //   }, 2000);
    // } else {
    //   setPostal_Code(postalCodeFormat(postalCode));
    // }
  };

  const handleUpdatePostalCode = (event) => {
    let postalCode = event.target.value;
    setUpdatePostal_Code(postalCodeFormat(postalCode));
    // let val = retailerList.map((a) => a.locations.map((b) => b.postal_code));
    // var merged = [].concat.apply([], val);
    // var chk = merged.includes(postalCode);
    // setCheack(chk);
    // if (chk) {
    //   setMsg(` Duplicate Postal Code `);
    //   setTimeout(() => {
    //     setMsg("");
    //   }, 2000);
    // } else {
    //   setUpdatePostal_Code(postalCodeFormat(postalCode));
    // }
  };

  const openAddSecondForm = async () => {
    let errorCheckforImage = checkValidationFormatBeforeSave("add");
    if (errorCheckforImage === true) {
      setAddSecond(true);
    } else {
      setAddSecond(false);
    }
  };
  const closeAddSecondForm = async () => {
    setAddSecond(false);
  };
  const checkValidationFormatBeforeSave = (dataType) => {
    //////f
    setError({});
    // let isError = false;
    let errorValue = {};
    //MAx size to upload an image
    const MAX_FILE_SIZE = 2000; // 2MB

    let errorText = "";
    let image = "";
    let colorValue = "";
    let fontColorValue = "";
    if (dataType === "add") {
      image = charityLogo;
      if (color !== "" || color !== undefined || color !== null) {
        colorValue = color;
      }
      if (fontColor !== "" || fontColor !== undefined || fontColor !== null) {
        fontColorValue = fontColor;
      }
    } else if (dataType === "upload") {
      if (abc) {
        image = abc;
      } else {
        image = updateCharityLogo;
      }
      if (
        updateColor !== "" ||
        updateColor !== undefined ||
        updateColor !== null
      ) {
        colorValue = updateColor;
      }
      if (
        updateFontColor !== "" ||
        updateFontColor !== undefined ||
        updateFontColor !== null
      ) {
        fontColorValue = updateFontColor;
      }
    }
    //Calculate image size in kilobytes

    const fileSizeKiloBytes = image?.size / 1024;

    // if (!image) {
    //   errorText = "Location Logo is required.";

    //   errorValue = {
    //     ...errorValue,
    //     Logo: errorText,
    //   };
    //   setError(errorValue);
    // } else
    if (image !== updateCharityLogo) {
      //////
      if (image && !image?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
        errorText = "Please select valid image.";
        errorValue = {
          ...errorValue,
          Logo: errorText,
        };
        setError(errorValue);
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        errorText = "File size is greater than maximum limit.";
        errorValue = {
          ...errorValue,
          Logo: errorText,
        };
        setError(errorValue);
      }
    }

    if (
      colorValue !== "" &&
      colorValue !== null &&
      colorValue !== undefined &&
      fontColorValue !== null &&
      fontColorValue !== undefined &&
      fontColorValue !== "" &&
      fontColorValue === colorValue
    ) {
      errorText = "Color and font color could not be same.";
      errorValue = {
        ...errorValue,
        Color: errorText,
        FontColor: errorText,
      };
      setError(errorValue);
    }

    if (
      fontColorValue !== "" ||
      fontColorValue !== null ||
      fontColorValue !== undefined
    ) {
      if (
        fontColor === "#FFFFFF" ||
        fontColor === "#ffffff" ||
        fontColor === "#f7f7f7"
      ) {
        errorText = "Font color could not be white.";
        errorValue = {
          ...errorValue,
          FontColor: errorText,
        };
        // se
      }
      setError(errorValue);
    }

    if (
      Object.keys(errorValue).length === 0 &&
      errorValue.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  };

  ///Validate Data Fields before addition or updation of charity
  const checkValidDataFields = async (formType) => {
    setError({});
    let errorValueData = {};
    let errorText = "";
    let startTicketNo = "";
    let taxReceipt = "";
    //let donationTempName= "";
    let donationSendGridTempId = "";
    let lotteryTempName = "";
    let lotterySendGridTempId = "";
    let createLotteryTicketVal = "";
    let donationEmailSendGridIdValue = "";
    let lotteryEmailSendGridIdValue = "";
    let rulesRegUrlValue = "";
    let charityTypeValue = "";
    if (formType === "add") {
      //  startTicketNo=startingTicketNumber;
      taxReceipt = createTaxReceipt;
      //donationTempName= templateName;
      donationSendGridTempId = sendGridTemplateId;
      //  lotteryTempName= lotteryTemplateName;
      lotterySendGridTempId = sendGridLotteryTemplateId;
      createLotteryTicketVal = createLotteryTicket;
      lotteryEmailSendGridIdValue = lotteryEmailSendGridId;
      donationEmailSendGridIdValue = donationEmailSendGridId;
      rulesRegUrlValue = rulesRegUrl;
      charityTypeValue = charityType;
    } else if (formType === "update") {
      // startTicketNo=updateStartingTicketNumber;
      taxReceipt = updateCreateTaxReceipt;
      // donationTempName= updateTemplateName;
      donationSendGridTempId = updateSendGridTemplateId;
      // lotteryTempName= updateLotteryTemplateName;
      lotterySendGridTempId = updateSendGridLotteryTemplateId;
      createLotteryTicketVal = updatecreateLotteryTicket;
      lotteryEmailSendGridIdValue = updateLotteryEmailSendGridId;
      donationEmailSendGridIdValue = updateDonationEmailSendGridId;
      rulesRegUrlValue = updateRulesRegUrl;
      charityTypeValue = updateCharityType;
    }

    if (taxReceipt === "1") {
      if (
        donationSendGridTempId === "" ||
        donationSendGridTempId === null ||
        donationSendGridTempId === undefined
      ) {
        errorText = "Donation SendGrid Template Id is required.";
        errorValueData = {
          ...errorValueData,
          SendGridTemplateIdVal: errorText,
        };
        setError(errorValueData);
      }
      if (
        donationEmailSendGridIdValue === "" ||
        donationEmailSendGridIdValue === null ||
        donationEmailSendGridIdValue === undefined
      ) {
        errorText = "Donation Email SendGrid Id is required.";
        errorValueData = {
          ...errorValueData,
          donationEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          donationEmailSendGridIdValue
        )
      ) {
        errorText = "Invalid email address.";
        errorValueData = {
          ...errorValueData,
          donationEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      }
      // if(donationTempName==='' || donationTempName===null || donationTempName===undefined){
      //   errorText = "Donation Template Name is required.";
      //   errorValueData = {
      //     ...errorValueData,
      //     TemplateNameVal: errorText ,
      //   }
      //   setError(errorValueData);
      // }
    }
    if (charityTypeValue === "Lottery" || charityTypeValue === "Both") {
      if (
        rulesRegUrlValue === "" ||
        rulesRegUrlValue === undefined ||
        rulesRegUrlValue === null
      ) {
        errorText = "Rules and Regulations URL is required.";
        errorValueData = {
          ...errorValueData,
          rulesRegUrlVal: errorText,
        };
        setError(errorValueData);
      }
    }
    if (createLotteryTicketVal === "Yes") {
      if (
        lotterySendGridTempId === "" ||
        lotterySendGridTempId === null ||
        lotterySendGridTempId === undefined
      ) {
        errorText = "Lottery SendGrid Template Id is required.";
        errorValueData = {
          ...errorValueData,
          SendGridLotteryTemplateIdVal: errorText,
        };
        setError(errorValueData);
      }
      // if(lotteryTempName==='' || lotteryTempName===null || lotteryTempName===undefined){
      //   errorText = "Lottery Template Name is required.";
      //   errorValueData = {
      //     ...errorValueData,
      //     LotteryTemplateNameVal: errorText ,
      //   }
      //   setError(errorValueData);
      // }
      if (
        lotteryEmailSendGridIdValue === "" ||
        lotteryEmailSendGridIdValue === null ||
        lotteryEmailSendGridIdValue === undefined
      ) {
        errorText = "Lottery Email SendGrid Id is required.";
        errorValueData = {
          ...errorValueData,
          lotteryEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          lotteryEmailSendGridIdValue
        )
      ) {
        errorText = "Invalid email address.";
        errorValueData = {
          ...errorValueData,
          lotteryEmailSendGridIdVal: errorText,
        };
        setError(errorValueData);
      }
    }
    if (
      Object.keys(errorValueData).length === 0 &&
      errorValueData.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  };
  const addLocation = async () => {
    let startingNumber = "";
    if (
      stringOne !== "" &&
      stringOne !== null &&
      typeof stringOne !== undefined
    ) {
      startingNumber = stringOne + stringTwo;
    } else if (
      stringOne === "" ||
      stringOne === null ||
      typeof stringOne === undefined
    ) {
      startingNumber = stringTwo;
    } else if (
      stringTwo === "" ||
      stringTwo === null ||
      typeof stringTwo === undefined
    ) {
      startingNumber = stringOne;
    }
    setStartingTicketNumber(startingNumber);
    let errorCheckFoTicketNumber = await checkValidDataFields("add");
    if (errorCheckFoTicketNumber) {
      const data = {
        address: address,
        province: province,
        city: city,
        country: country,
        postal_code: postal_code,
        storeID: storeID,
        locationContact: locationContact,
        locationPhone: locationPhone,
        locationEmail: locationEmail,
        locationExt: locationExt,
        MID: MID,
        donationAmount: saveamount,
        language: language,
        monthlyDonationAmount:
          donationFrequency == "Monthly" ? saveMonthlyAmount : [],
        donationFrequency: donationFrequency,
        createTaxReceipt: createTaxReceipt,
        taxReceiptEligible: taxReceiptEligible,
        charityType: charityType,
        lotteryTicket: lotteryTicket,
        fiftyTicket: fiftyTicket,
        createLotteryTicket: createLotteryTicket,
        processFee: processFee,
        processFeeAmount:
          processFee === "1"
            ? parseFloat(processFeeAmount.replace(/,/g, ""))
            : "",
        startingTicketNumber: startingNumber,
        lotteryTemplateName: "lotteryTicket",
        sendGridLotteryTemplateId: sendGridLotteryTemplateId,
        sendGridTemplateId: sendGridTemplateId,
        templateName: "taxReciept",
        donationEmailSendGridId: donationEmailSendGridId,
        lotteryEmailSendGridId: lotteryEmailSendGridId,
        color: color,
        fontColor: fontColor,
        rulesRegUrl: rulesRegUrl,
        // charityLogo: charityLogo.name
      };
      const formData = new FormData(); //////
      formData.append("data", JSON.stringify(data)); //////
      formData.append("charityLogo", charityLogo); //////
      addLocationData(formData, retailerID) //////
        .then((res) => {
          getRetailer();
          setMsg(`You Have Added a New Location for ${rName}`);
          setTimeout(() => {
            setMsg("");
          }, 4000);
        })
        .catch((err) => {});
      setamount([]);
      setName1({});
      setLocationModalState(false);
      setisDonationMonthly(false);
      setLocationContact("");
      setLocationEmail("");
      setLocationPhone("");
      setAddress("");
      setProvince("");
      setCity("");
      setCountry("");
      setMID("");
      setPostal_Code("");
      setLocationExt("");
      setCharityLogo(""); //////
      setLanguage("English");
      setDonationFrequency("OneTime");
      setStoreID("");
      setAddSecond(false);
      setCreateTaxReceipt("0");
      setTaxReceiptEligible("0");
      setCharityType(null);
      setLotteryTicket(null);
      setFiftyTicket(null);
      setCreateLotteryTicket(null);
      setProcessFee("0");
      setProcessFeeAmount("");
      setAddAmount1("");
      setAddAmount2("");
      setAddAmount3("");
      setAddAmount4("");
      setAddAmount5("");
      setAddAmount6("");
      setAddAmount7("");
      setAddMonthlyAmount1("");
      setAddMonthlyAmount2("");
      setAddMonthlyAmount3("");
      setAddMonthlyAmount4("");
      setAddMonthlyAmount5("");
      setAddMonthlyAmount6("");
      setAddMonthlyAmount7("");
      setStartingTicketNumber("");
      setStringOne("");
      setStringTwo("");
      setStringOne("");
      setStringTwo("");
      setlotteryTemplateName("");
      setSendGridLotteryTemplateId("");
      setSendGridTemplateId("");
      setTemplateName("");
      setLotteryEmailSendGridId("");
      setRulesRegUrl("");
      setDonationEmailSendGridId("");
      setColor("");
      setFontColor("");
      setIsAddUpload(""); //////
      reset();
    }
  };

  const showLocation = (id) => {
    setViewLocationModal(!viewLocation);

    let filter = retailerList.filter((data) => data._id === id);

    setLocationData(filter[0].locations);
    setRetailerIDGetLocation(id);
  };

  const deleteLocation = async (id) => {
    const data1 = {
      isActive: true,
    };
    const res = await fetch(`${APIBase_URL}location/delete/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data1),
    });
    let data = [...locationData];
    let result = data.filter((data) => data._id !== id);
    let data2 = retailerList;

    var result2 = [];
    data2.forEach((value, index) => {
      result2[index] = value;
      result2[index].locations = value.locations.filter(
        (item) => item._id !== id
      );
    });

    res.json().then((res) => {
      setLocationData(result);
      setRetailerList(result2);
    });
  };

  const openUpdateSecondForm = async () => {
    let errorCheckforImage = checkValidationFormatBeforeSave("upload"); //////
    if (errorCheckforImage === true) {
      setUpdateSecond(true);
    } else {
      setUpdateSecond(false);
    }
  };
  const closeUpdateSecondForm = async () => {
    setUpdateSecond(false);
  };

  const deleteLogo = () => {
    //////
    setIsAddUpload("");
    setCharityLogo("");
  };

  const updateDeleteLogo = () => {
    //////
    setUpdateCharityLogo("");
    setIsUpload("");
    setAbc("");
  };

  const updateLocation = async () => {
    let updateStartingNumber = "";
    if (
      updateStringOne !== "" &&
      updateStringOne !== null &&
      typeof updateStringOne !== undefined
    ) {
      updateStartingNumber = updateStringOne + updateStringTwo;
    } else if (
      updateStringOne === "" ||
      updateStringOne === null ||
      typeof updateStringOne === undefined
    ) {
      updateStartingNumber = updateStringTwo;
    } else if (
      updateStringTwo === "" ||
      updateStringTwo === null ||
      typeof updateStringTwo === undefined
    ) {
      updateStartingNumber = updateStringOne;
    }

    setUpdateStartingTicketNumber(updateStartingNumber);
    let errorCheckFoTicketNumber = await checkValidDataFields("add");
    if (errorCheckFoTicketNumber) {
      const data = {
        address: updateAddress,
        province: updateProvince,
        city: updateCity,
        country: updateCountry,
        postal_code: updatePostal_Code,
        storeID: updateStoreID,
        locationContact: updateLocationContact,
        locationPhone: updateLocationPhone,
        locationEmail: updateLocationEmail,
        locationExt: updateLocationExt,
        MID: updateMID,
        donationAmount: saveUpdateAmount,
        monthlyDonationAmount:
          updateDonationFrequency == "Monthly" ? saveUpdateMonthlyAmount : [],
        language: updateLanguage,
        donationFrequency: updateDonationFrequency,
        createTaxReceipt: updateCreateTaxReceipt,
        taxReceiptEligible: updateTaxReceiptEligible,
        charityType: updateCharityType,
        lotteryTicket: updatelotteryTicket,
        fiftyTicket: updatefiftyTicket,
        createLotteryTicket: updatecreateLotteryTicket,
        processFee: updateProcessFee,
        processFeeAmount:
          updateProcessFee === "1"
            ? parseFloat(updateProcessFeeAmount.replace(/,/g, ""))
            : "",
        startingTicketNumber: updateStartingNumber,
        lotteryTemplateName: "lotteryTicket",
        sendGridLotteryTemplateId: updateSendGridLotteryTemplateId,
        sendGridTemplateId: updateSendGridTemplateId,
        templateName: "taxReciept",
        donationEmailSendGridId: updateDonationEmailSendGridId,
        lotteryEmailSendGridId: updateLotteryEmailSendGridId,
        color: updateColor,
        fontColor: updateFontColor,
        rulesRegUrl: updateRulesRegUrl,
        // charityLogo: abc.name
      };
      const formDataUpdate = new FormData(); //////
      formDataUpdate.append("info", JSON.stringify(data)); //////

      if (updateCharityLogo == "" && isUpload == "" && abc == "") {
        //////
        deleteLocationLogo(getLocationId);
      } else {
        formDataUpdate.append("charityLogo", abc);
      } //////
      updateLocationDataById(formDataUpdate, getLocationId) //////
        .then(async (res) => {
          let datalocation = [...locationData];
          let currrentLocation = {
            _id: getLocationId,
            address: updateAddress,
            province: updateProvince,
            city: updateCity,
            country: updateCountry,
            postal_code: updatePostal_Code,
            storeID: updateStoreID,
            locationContact: updateLocationContact,
            locationPhone: updateLocationPhone,
            locationEmail: updateLocationEmail,
            locationExt: updateLocationExt,
            MID: updateMID,
            donationAmount: saveUpdateAmount,
            monthlyDonationAmount: saveUpdateMonthlyAmount,
            language: updateLanguage,
            donationFrequency: updateDonationFrequency,
            createTaxReceipt: updateCreateTaxReceipt,
            taxReceiptEligible: updateTaxReceiptEligible,
            charityType: updateCharityType,
            lotteryTicket: updatelotteryTicket,
            fiftyTicket: updatefiftyTicket,
            createLotteryTicket: updatecreateLotteryTicket,
            processFee: updateProcessFee,
            processFeeAmount:
              updateProcessFee === "1" ? updateProcessFeeAmount : "",
            startingTicketNumber: updateStartingTicketNumber,
            lotteryTemplateName: "lotteryTicket",
            sendGridLotteryTemplateId: updateSendGridLotteryTemplateId,
            sendGridTemplateId: updateSendGridTemplateId,
            templateName: "taxReciept",
            donationEmailSendGridId: updateDonationEmailSendGridId,
            lotteryEmailSendGridId: updateLotteryEmailSendGridId,
            color: updateColor,
            fontColor: updateFontColor,
            rulesRegUrl: updateRulesRegUrl,
            charityLogo: abc.name, //////
          };
          datalocation.forEach(function (x, index) {
            if (datalocation[index]._id === getLocationId) {
              datalocation[index] = currrentLocation;
            }
          });

          let data2 = retailerList;

          var result2 = [];
          data2.forEach((value, index) => {
            result2[index] = value;
            const arr = value.locations;
            arr.forEach((element, index2) => {
              if (element._id === getLocationId) {
                result2[index].locations[index2] = currrentLocation;
              }
            });
          });
          setRetailerList(result2);
          setLocationData(false);
          setLocationData(datalocation);
          setViewLocationModal(false);
          setUpdateLocationModal(false);
          setGetLocationId("");
          setAbc(""); //////
          setUpdateAddress("");
          setUpdateProvince("");
          setUpdateCity("");
          setUpdateCountry("");
          setUpdatePostal_Code("");
          setUpdateStoreID("");
          setUpdateLocationContact("");
          setUpdateLocationEmail("");
          setUpdateLocationPhone("");
          setUpdateLocationExt("");
          setUpdateLanguage("");
          setFormData("");
          setCharityLogo(""); //////
          showLocation2(retailerIDGetLocation);
          setUpdateAmount([]);
          setUpdateMonthlyAmount([]);
          setUpdateName1({});
          setUpdateMonthlyName({});
          setUpdateColor("");
          setUpdateFontColor("");
          setUpdateMonthlyAmount1("");
          setUpdateMonthlyAmount2("");
          setUpdateMonthlyAmount3("");
          setUpdateMonthlyAmount4("");
          setUpdateMonthlyAmount5("");
          setUpdateMonthlyAmount6("");
          setUpdateMonthlyAmount7("");
          setUpdateSecond(false);
          setUpdateCreateTaxReceipt("0");
          setUpdateTaxReceiptEligible("0");
          setUpdateProcessFee("0");
          setUpdateProcessFeeAmount("");
          setUpdateLotteryTicket(null);
          setUpdateFiftyTicket(null);
          setUpdateCreateLotteryTicket(null);
          setUpdateStartingTicketNumber("");
          setUpdateStringOne("");
          setUpdateStringTwo("");
          setUpdateLotteryTemplateName("");
          setUpdateSendGridLotteryTemplateId("");
          setUpdateSendGridTemplateId();
          setUpdateTemplateName();
          setUpdateLotteryTemplateName("");
          setUpdateSendGridLotteryTemplateId("");
          setUpdateSendGridTemplateId("");
          setUpdateTemplateName("");
          setUpdateDonationEmailSendGridId("");
          setUpdateRulesRegUrl("");
          setUpdateLotteryEmailSendGridId("");
          setMeslocation(`Successfully Updated`);
          setTimeout(() => {
            setMeslocation("");
          }, 4000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };
  const showLocation2 = (id) => {
    setViewLocationModal(true);
  };
  const closeupdatelocatonPopup = () => {
    setUpdateLocationModal(false);
    setGetLocationId("");
    setUpdateAddress("");
    setUpdateProvince("");
    setUpdateCity("");
    setUpdateCountry("");
    setUpdatePostal_Code("");
    setUpdateStoreID("");
    setUpdateLocationContact("");
    setUpdateLocationEmail("");
    setUpdateLocationPhone("");
    setUpdateLocationExt("");
    setUpdateAmount([]);
    setUpdateName1({});
    setUpdateMonthlyName({});
    setUpdateAmount1("");
    setUpdateAmount2("");
    setUpdateAmount3("");
    setUpdateAmount4("");
    setUpdateAmount5("");
    setUpdateAmount6("");
    setUpdateAmount7("");
    setUpdateAmount8("");
    setUpdateMonthlyAmount([]);
    setUpdateMonthlyAmount1("");
    setUpdateMonthlyAmount2("");
    setUpdateMonthlyAmount3("");
    setUpdateMonthlyAmount4("");
    setUpdateMonthlyAmount5("");
    setUpdateMonthlyAmount6("");
    setUpdateMonthlyAmount7("");
    setUpdateSecond(false);
    setUpdateCharityType(null);
    setUpdateLotteryTicket(null);
    setUpdateFiftyTicket(null);
    setUpdateCreateLotteryTicket(null);
    setUpdateStartingTicketNumber("");
    setUpdateStringOne("");
    setUpdateStringTwo("");
    setUpdateTemplateName();
    setUpdateLotteryTemplateName("");
    setUpdateSendGridLotteryTemplateId("");
    setUpdateSendGridTemplateId("");
    setUpdateTemplateName("");
    setUpdateDonationEmailSendGridId("");
    setUpdateRulesRegUrl("");
    setUpdateLotteryEmailSendGridId("");
    setUpdateColor("");
    setUpdateFontColor("");
  };

  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const cleanNum = phoneNumber.toString().replace(/\D/g, "");
      const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        return +match[1] + "-" + (match[2] ? match[2] + "-" : "") + match[3];
      }
      return cleanNum;
    }
    return phoneNumber;
  }

  const openImportExcelModal = () => {
    setImportExcelModal(!importExcelModel);
    setFile("");
  };
  const handleImportExcelOnChange = (e) => {
    let file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setFile(e.target.files[0]);
      setFileNamemsg(file.name);
    } else {
      alert("Invalid File");
    }
  };

  const handleImportExcelOnSubmit = async () => {
    //e.preventDefault();

    if (file && file.type === "text/csv") {
      const formData = new FormData();
      formData.append("importCsv", file, file.name);
      importCsvRetailerData(formData).then((res) => {
        if (res.data.status == true) {
          getRetailer();
          setMsg(`Successfully CSV uploaded `);
          setFile("");
          setImportExcelModal(false);
          setFileNamemsg("");
          setTimeout(() => {
            setMsg("");
          }, 4000);
        } else {
          alert("File Format Incorrect");
        }
      });
    } else {
      getRetailer();
      alert("CSV File Invalid");
    }
  };
  const OpenImportLocation = (id) => {
    setImportCsvLocationModal(true);
    setRetailerIDLocation(id);
  };
  const handleImportCSVLocationOnSubmit = (e) => {
    e.preventDefault();

    if (file && file.type === "text/csv") {
      const formData = new FormData();
      formData.append("importCsv", file, file.name);
      importCsvLocationData(formData, retailerIDLocation).then((res) => {
        if (res.data.status == true) {
          getRetailer();
          setMsg(`Successfully CSV uploaded for location`);
          setFile("");
          setImportCsvLocationModal(false);
          setRetailerIDLocation("");
          setTimeout(() => {
            setMsg("");
          }, 4000);
        } else {
          alert("Something went wrong!");
        }
      });
    } else {
      alert("Invalid File");
    }
  };
  const normalizeInput = (value) => {
    if (typeof value === "undefined") {
      return "";
    } else {
      const input = value.replace(/\D/g, "").substring(0, 10);
      const first = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);
      if (input.length > 6) {
        return `${first}-${middle}-${last}`;
      } else if (input.length > 3) {
        return `${first}-${middle}`;
      } else if (input.length >= 0) {
        return input;
      }
    }
  };
  function postalCodeFormat(string) {
    let foo = string.split(" ").join(""); // remove hyphens
    if (foo.length > 0) {
      foo = foo.match(new RegExp(".{1,3}", "g")).join(" ");
    }
    return foo;
  }
  const handleMID = (event) => {
    const mid = Validator.ltrim(event.target.value);
    let midData = locationData.map((val) => val.MID);
    let chk = midData.includes(mid);
    if (chk) {
      // <div>"Duplicate MID"</div>
      setMsg(` Duplicate MID `);
      setTimeout(() => {
        setMsg("");
      }, 2000);
    } else {
      setMID(mid);
    }
  };
  const clearAddlocationData = () => {
    setamount([]);
    setName1({});
    setLocationModalState(false);
    setAddSecond(false);
    setAddAmount1("");
    setAddAmount2("");
    setAddAmount3("");
    setAddAmount4("");
    setAddAmount5("");
    setAddAmount6("");
    setAddAmount7("");
    setAddMonthlyAmount1("");
    setAddMonthlyAmount2("");
    setAddMonthlyAmount3("");
    setAddMonthlyAmount4("");
    setAddMonthlyAmount5("");
    setAddMonthlyAmount6("");
    setAddMonthlyAmount7("");
  };

  const handleAmount = (e) => {
    const { name, value } = e.target;
    var hasVal = Object.values(name1).includes(value);
    // if (value == "") {
    //   let amount = [];
    //   let data = { ...name1, [name]: value };
    //   Object.keys(data).map((key) =>
    //     data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    //   );
    //   setamount(amount);
    //   setName1(data);
    // }
    // } else if (hasVal) {
    //   e.target.value = "";
    //   setMsgAmount(`You Can Not Add the Same Amount Again! `);
    //   setTimeout(() => {
    //     setMsgAmount("");
    //   }, 3000);
    // } else
    if (value == 0) {
      e.target.value = "";
      setMsgAmount(`You Can Not Add1 0 Amount! `);
      setTimeout(() => {
        setMsgAmount("");
      }, 4000);
    }
    //} else {
    let amount = [];
    let data = { ...name1, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setamount(amount);
    setName1(data);
    //e.target.value = currencyFormat(value);
    if (name === "name1") {
      setAddAmount1(currencyFormat(value));
    }
    if (name === "name2") {
      setAddAmount2(currencyFormat(value));
    }
    if (name === "name3") {
      setAddAmount3(currencyFormat(value));
    }
    if (name === "name4") {
      setAddAmount4(currencyFormat(value));
    }
    if (name === "name5") {
      setAddAmount5(currencyFormat(value));
    }
    if (name === "name6") {
      setAddAmount6(currencyFormat(value));
    }
    if (name === "name7") {
      setAddAmount7(currencyFormat(value));
    }
    //}
  };
  const handleMonthlyAmount = (e) => {
    const { name, value } = e.target;
    var hasVal = Object.values(nameMonthly).includes(value);
    // if (value == "") {
    //   let amount = [];
    //   let data = { ...nameMonthly, [name]: value };
    //   Object.keys(data).map((key) =>
    //     data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    //   );
    //   setMonthlyamount(amount);
    //   setNameMonthly(data);
    // } else if (hasVal) {
    //   e.target.value = "";
    //   setMsgMonthlyAmount(`You Can Not Add the Same Amount Again! `);
    //   setTimeout(() => {
    //     setMsgMonthlyAmount("");
    //   }, 3000);
    // } else
    if (value == 0) {
      e.target.value = "";
      setMsgMonthlyAmount(`You Can Not Add1 0 Amount! `);
      setTimeout(() => {
        setMsgMonthlyAmount("");
      }, 4000);
    }
    //} else {
    let amount = [];
    let data = { ...nameMonthly, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setMonthlyamount(amount);
    setNameMonthly(data);
    //e.target.value = currencyFormat(value);
    if (name === "namemonthly1") {
      setAddMonthlyAmount1(currencyFormat(value));
    }
    if (name === "namemonthly2") {
      setAddMonthlyAmount2(currencyFormat(value));
    }
    if (name === "namemonthly3") {
      setAddMonthlyAmount3(currencyFormat(value));
    }
    if (name === "namemonthly4") {
      setAddMonthlyAmount4(currencyFormat(value));
    }
    if (name === "namemonthly5") {
      setAddMonthlyAmount5(currencyFormat(value));
    }
    if (name === "namemonthly6") {
      setAddMonthlyAmount6(currencyFormat(value));
    }
    if (name === "namemonthly7") {
      setAddMonthlyAmount7(currencyFormat(value));
    }
    //}
  };
  const handleUpdateAmount = (e) => {
    const { name, value } = e.target;

    if (value == 0) {
      e.target.value = "";
      setUpdateMsg(`You Can Not Add 0 Amount! `);
      setTimeout(() => {
        setUpdateMsg("");
      }, 4000);
    }
    let amount = [];
    let data = { ...updateName1, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setUpdateAmount(amount);
    setUpdateName1(data);
    if (name === "name1") {
      setUpdateAmount1(currencyFormat(value));
    }
    if (name === "name2") {
      setUpdateAmount2(currencyFormat(value));
    }
    if (name === "name3") {
      setUpdateAmount3(currencyFormat(value));
    }
    if (name === "name4") {
      setUpdateAmount4(currencyFormat(value));
    }
    if (name === "name5") {
      setUpdateAmount5(currencyFormat(value));
    }
    if (name === "name6") {
      setUpdateAmount6(currencyFormat(value));
    }
    if (name === "name7") {
      setUpdateAmount7(currencyFormat(value));
    }
    // if (name === "name8") {
    //   setUpdateAmount8(currencyFormat(value));
    // }
  };
  const handleUpdateMonthlyAmount = (e) => {
    const { name, value } = e.target;
    var hasVal = Object.values(updateMonthlyName).includes(value);

    if (value == 0) {
      e.target.value = "";
      setUpdateMonthlyAmountMsg(`You Can Not Add 0 Amount! `);
      setTimeout(() => {
        setUpdateMonthlyAmountMsg("");
      }, 4000);
    }
    let amount = [];
    let data = { ...updateMonthlyName, [name]: value };
    Object.keys(data).map((key) =>
      data[key] && data[key] != 0 ? amount.push([Number(data[key])]) : ""
    );
    setUpdateMonthlyAmount(amount);
    setUpdateMonthlyName(data);
    if (name === "namemonthly1") {
      setUpdateMonthlyAmount1(currencyFormat(value));
    }
    if (name === "namemonthly2") {
      setUpdateMonthlyAmount2(currencyFormat(value));
    }
    if (name === "namemonthly3") {
      setUpdateMonthlyAmount3(currencyFormat(value));
    }
    if (name === "namemonthly4") {
      setUpdateMonthlyAmount4(currencyFormat(value));
    }
    if (name === "namemonthly5") {
      setUpdateMonthlyAmount5(currencyFormat(value));
    }
    if (name === "namemonthly6") {
      setUpdateMonthlyAmount6(currencyFormat(value));
    }
    if (name === "namemonthly7") {
      setUpdateMonthlyAmount7(currencyFormat(value));
    }
  };
  function currencyFormat(num) {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function currencyFormat2(num) {
    return typeof num === "undefined"
      ? ""
      : num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return (
    <>
      <Header />
      <div className="Dashboard d-flex">
        <Sidebar />
        <section className="home-section">
          <div className="home-content">
            <div className="retailerContainer p-3 cstmheight_rmv">
              {setModal ? (
                <div className="ModalBackground">
                  <div className="modalContainer RetailerPopupContainer">
                    <div className="modalHeader mb-5">
                      <h3>Add Retailer/Event</h3>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => setModalState(false)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <form>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Retailer/Event:</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.RetailerName && "invalid"
                                }`}
                                {...register("RetailerName", {
                                  required: "Retailer Name is required",
                                })}
                                onChange={(e) =>
                                  setRetailerName(
                                    Validator.ltrim(e.target.value)
                                  )
                                }
                                value={RetailerName}
                              />
                              {errors.RetailerName && (
                                <small className="text-danger">
                                  {errors.RetailerName.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Contact Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("RetailerContactName", {
                                  required: "Retailer Contact Name is required",
                                })}
                                onChange={(e) =>
                                  setContactName(
                                    Validator.ltrim(e.target.value)
                                  )
                                }
                                value={contactName}
                              />
                              {errors.RetailerContactName && (
                                <small className="text-danger">
                                  {errors.RetailerContactName.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                {...register("email", {
                                  required:
                                    "Retailer Contact Email is required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                  },
                                })}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  {errors.email.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-8 col-sm-10">
                            <div className="form-group">
                              <label>Phone Number:</label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="13"
                                {...register("PhoneNumber", {
                                  required: "Phone Number is required",
                                  pattern: {
                                    value:
                                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                    message: "Only numbers are allowed",
                                  },
                                })}
                                onChange={(e) =>
                                  setPhone(normalizeInput(e.target.value))
                                }
                                value={phone}
                              />
                              {errors.PhoneNumber && (
                                <small className="text-danger">
                                  {errors.PhoneNumber.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4 col-sm-2">
                            <label>Ext.</label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="Ext"
                                maxLength="5"
                                className="form-control"
                                onKeyPress={function (event) {
                                  var regex = new RegExp("^[0-9]");
                                  var key = String.fromCharCode(
                                    !event.charCode
                                      ? event.which
                                      : event.charCode
                                  );
                                  if (!regex.test(key)) {
                                    event.preventDefault();
                                    return false;
                                  }
                                }}
                                {...register("Ext", {
                                  // required: "Ext is required",
                                })}
                                onChange={(e) => setExt(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="modalFooter text-right mt-5 pt-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit(addretailer)}
                      >
                        Submit
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={() => setModalState(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* update retailer modal */}
              {setUpdateModal ? (
                <div className="ModalBackground">
                  <div className="modalContainer RetailerPopupContainer">
                    <div className="modalHeader mb-5">
                      <h3>Update Retailer/Event</h3>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => setUpdateModalState(false)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <form>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Retailer/Event:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="updateRetailerName"
                                {...register("updateRetailerName", {
                                  required: "Retailer Name is required",
                                  pattern: {
                                    value: /([^\s]+)/g,
                                    message:
                                      "Please remove space from first word.",
                                  },
                                })}
                                // value={updateRetailerName}
                                onChange={(e) =>
                                  setUpdateRetailerName(
                                    Validator.ltrim(e.target.value)
                                  )
                                }
                              />
                              {errors.updateRetailerName && (
                                <small className="text-danger">
                                  {errors.updateRetailerName.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Contact Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="updateContactName"
                                {...register("updateContactName", {
                                  required: "Contact Name is required",
                                  pattern: {
                                    value: /([^\s]+)/g,
                                    message:
                                      "Please remove space from first word.",
                                  },
                                })}
                                onChange={(e) =>
                                  setUpdateContactName(
                                    Validator.ltrim(e.target.value)
                                  )
                                }
                                // value={updateContactName}
                              />

                              {errors.updateContactName && (
                                <small className="text-danger">
                                  {errors.updateContactName.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="updateEmail"
                                {...register("updateEmail", {
                                  required:
                                    "Retailer Contact Email is required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                  },
                                })}
                                onChange={(e) => setUpdateEmail(e.target.value)}
                                // value={updateEmail}
                              />
                              {errors.updateEmail && (
                                <small className="text-danger">
                                  {errors.updateEmail.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-8 col-sm-12">
                            <div className="form-group">
                              <label>Phone Number:</label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="13"
                                name="updatePhone"
                                {...register("updatePhone", {
                                  required: "Phone is required",
                                  pattern: {
                                    value:
                                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                    message: "Only numbers are allowed",
                                  },
                                })}
                                onChange={(e) =>
                                  setUpdatePhone(normalizeInput(e.target.value))
                                }
                                value={updatePhone}
                              />
                              {errors.updatePhone && (
                                <small className="text-danger">
                                  {errors.updatePhone.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4 col-sm-12">
                            <label>Ext.</label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="updateExt"
                                maxLength="5"
                                className="form-control"
                                value={updateExt}
                                onKeyPress={function (event) {
                                  var regex = new RegExp("^[0-9]");
                                  var key = String.fromCharCode(
                                    !event.charCode
                                      ? event.which
                                      : event.charCode
                                  );
                                  if (!regex.test(key)) {
                                    event.preventDefault();
                                    return false;
                                  }
                                }}
                                {...register("updateExt", {})}
                                onChange={(e) =>
                                  setUpdateExt(Validator.ltrim(e.target.value))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="modalFooter text-right mt-5 pt-3">
                      <button
                        className="btn btn-primary updateRetailerButton"
                        onClick={handleSubmit(updateRetailer)}
                        id="updateRetailerBtn"
                      >
                        Update Retailer
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-primary updateRetailerButton"
                        onClick={() => setUpdateModalState(false)}
                        id="updateRetailerCancelBtn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* update retailer modal */}
              {importExcelModel ? (
                <div className="ModalBackground" id="exportModalBackground">
                  <div className="modalContainer">
                    <div className="modalHeader mb-5">
                      <h3>Import Retailer/Event</h3>

                      <a href={`${APIBase_URL}/retailerTemplate.csv`} download>
                        Download Template
                      </a>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => setImportExcelModal(false)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>

                    <form>
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          type={"file"}
                          id={"csvFileInput"}
                          accept={".csv"}
                          {...register("CSVFile", {
                            required: "CSV file required",
                          })}
                          onChange={handleImportExcelOnChange}
                        />
                        <label className="custom-file-label" for="files">
                          {fileNamemsg ? fileNamemsg : "Upload CSV"}
                        </label>
                        {errors.CSVFile && (
                          <small className="text-danger">
                            {errors.CSVFile.message}
                          </small>
                        )}
                      </div>
                    </form>
                    <div className="modalFooter text-right mt-5 pt-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit(handleImportExcelOnSubmit)}
                      >
                        IMPORT CSV
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={() => setImportExcelModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              <h2 className="pageTitle mb-4">Retailer/Event INFORMATION</h2>
              <div className="topWrapper  d-flex mb-5">
                <button
                  className="btn btn-primary text-nowrap"
                  onClick={openModal}
                >
                  Add Retailer/Event
                </button>{" "}
                &nbsp;&nbsp;
                <button
                  className="btn btn-warning float-right text-nowrap"
                  onClick={openImportExcelModal}
                >
                  Import Retailer/Event
                </button>
              </div>
              <h6 style={{ color: "green" }}>{msg}</h6>
              <div className="table-responsive custom-height">
                <table className="table table-striped mt-3 customize-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Retailer/Event</th>
                      <th>Contact Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody style={{ height: "300px" }}>
                    {retailerList.length
                      ? retailerList.map((retailer) => {
                          let abc = retailer.locations.map((a) => a._id);

                          return (
                            <tr id={retailer._id} key={retailer._id}>
                              <td>{retailer.RetailerName}</td>
                              <td>{retailer.contactName}</td>
                              <td>{retailer.email}</td>

                              <td>
                                {formatPhoneNumber(retailer.phone) ?? ""}{" "}
                                {retailer.ext ? `Ext. ${retailer.ext}` : ""}
                              </td>
                              <td>
                                <span>
                                  <button
                                    className="btn btn-primary text-nowrap"
                                    onClick={() => showLocation(retailer._id)}
                                  >
                                    {" "}
                                    View Locations{" "}
                                  </button>
                                </span>
                              </td>
                              <td>
                                <span
                                  className="edit"
                                  onClick={() => {
                                    selectRetailer(
                                      retailer._id,
                                      retailer.RetailerName
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span
                                  className="delete"
                                  onClick={() =>
                                    Swal.fire({
                                      title: "Are you sure you want to delete this Retailer/Event?",
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                      confirmButtonText: "OK",
                                      cancelButtonText: "Cancel",
                                        width:"470px",
                                        height:"380px",
                                      icon: "warning",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        deleteRetailer(retailer._id, abc)
                                        Swal.fire({ title: "Retailer/Event deleted!", showConfirmButton: true, icon: "success", width:"470px",
                                        height:"380px"});
                                      } else {
                                      }
                                    })
                                    // window.confirm(
                                    //   "Are you sure you want to delete this Retailer?"
                                    // ) && deleteRetailer(retailer._id, abc)
                                  }
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                &nbsp;
                                <span>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      getRetailerID(
                                        retailer._id,
                                        retailer.RetailerName
                                      )
                                    }
                                  >
                                    Add Locations
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
              {/* view retailer location */}
              {viewLocation ? (
                <div className="ModalBackground">
                  <div className="modalContainer modelLocationContainer">
                    <div className="modalHeader mb-5">
                      <h3>Locations</h3>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => setViewLocationModal(false)}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <h6 style={{ color: "green" }}>{msgLocation}</h6>
                    <div className="table-responsive location-custom-height">
                      <table className="table table-striped mt-3">
                        <thead className="thead-light">
                          <tr>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>StoreID</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {locationData.length
                            ? locationData.map((location) => (
                                <tr
                                  id={locationData._id}
                                  key={locationData._id}
                                >
                                  <td>{location.locationContact}</td>
                                  <td>{location.locationEmail}</td>
                                  <td>
                                    {normalizeInput(location.locationPhone)}{" "}
                                    {location.locationExt
                                      ? `Ext ${location.locationExt}`
                                      : ""}
                                  </td>
                                  <td>
                                    {location.address}, {location.city},{" "}
                                    {location.province}, {location.country},{" "}
                                    {location.postal_code}
                                  </td>
                                  <td>{location.storeID}</td>
                                  <td>
                                    <div className="cstmbtn_res">
                                      <span
                                        className="edit"
                                        onClick={() => {
                                          selectLocation(location._id);
                                        }}
                                      >
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span
                                        className="delete"
                                        onClick={() =>
                                          window.confirm(
                                            "Are you sure you want to delete this Location?"
                                          ) && deleteLocation(location._id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : `No Location found`}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="modalFooter text-right"
                      style={{ marginTop: "18px", padding: "0" }}
                    >
                      <button
                        className="btn btn-primary"
                        style={{ float: "right", marginTop: "10px" }}
                        onClick={() => setViewLocationModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* location modal */}

              {setlocationModal ? (
                <div className="ModalBackground">
                  <div className="modelAddLocationContainer w80 addLocation wid100">
                    <div className="modalHeader mb-5">
                      <h3>Add Location</h3>
                    </div>
                    <button className="closeBtn" onClick={clearAddlocationData}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <form className="bodycstmscroll adlocation_cstmbox cstmAddLocationModal">
                      <div className="container-fluid">
                        {
                          addSecond === false ? (
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                  <label>Contact:</label>
                                  <input
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    {...register("LocationContact", {
                                      required: "Location contact is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setLocationContact(e.target.value)
                                    }
                                    value={locationContact}
                                  />
                                  {errors.LocationContact && (
                                    <small className="text-danger">
                                      {errors.LocationContact.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                  <label>Email:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("LocationEmail", {
                                      required: "Location Email is required",
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setLocationEmail(e.target.value)
                                    }
                                    value={locationEmail}
                                  />
                                  {errors.LocationEmail && (
                                    <small className="text-danger">
                                      {errors.LocationEmail.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-8 col-sm-10 col-8">
                                <div className="form-group">
                                  <label>Phone:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    maxLength="13"
                                    {...register("LocationPhone", {
                                      required: "Location phone is required",
                                      pattern: {
                                        value:
                                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                        message: "Only numbers are allowed",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setLocationPhone(
                                        normalizeInput(e.target.value)
                                      )
                                    }
                                    value={locationPhone}
                                  />
                                  {errors.LocationPhone && (
                                    <small className="text-danger">
                                      {errors.LocationPhone.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 col-sm-2 col-4">
                                <label>Ext.</label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="LocationExt"
                                    maxLength="5"
                                    className="form-control"
                                    onKeyPress={function (event) {
                                      var regex = new RegExp("^[0-9]");
                                      var key = String.fromCharCode(
                                        !event.charCode
                                          ? event.which
                                          : event.charCode
                                      );
                                      if (!regex.test(key)) {
                                        event.preventDefault();
                                        return false;
                                      }
                                    }}
                                    {...register("LocationExt", {
                                      // required: "Ext is required",
                                    })}
                                    onChange={(e) =>
                                      setLocationExt(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Address:</label>
                                  <input
                                    type="text"
                                    maxLength={55}
                                    className="form-control"
                                    {...register("Address", {
                                      required: "Address is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                  {errors.Address && (
                                    <small className="text-danger">
                                      {errors.Address.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>City:</label>
                                  <input
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    {...register("City", {
                                      required: "City is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) => setCity(e.target.value)}
                                  />
                                  {errors.City && (
                                    <small className="text-danger">
                                      {errors.City.message}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Province:</label>
                                  <label
                                    htmlFor="provincefirst_new1"
                                    className="arrowstickshowssh"
                                  >
                                    <input
                                      id="provincefirst_new1"
                                      type="text"
                                      className="form-control"
                                      list="browsers"
                                      {...register("Province", {
                                        required: "Province is required",
                                        pattern: {
                                          value: /([^\s]+)/g,
                                          message:
                                            "Please remove space from first word.",
                                        },
                                      })}
                                      onChange={(e) =>
                                        setProvince(e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.Province && (
                                    <small className="text-danger">
                                      {errors.Province.message}
                                    </small>
                                  )}
                                </div>
                                <datalist id="browsers">
                                  {provinceList &&
                                    provinceList.map((list, id) =>
                                      list.province.map((res) => {
                                        return <option value={res} />;
                                      })
                                    )}
                                </datalist>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Country:</label>
                                  <input
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    {...register("Country", {
                                      required: "Country is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    // value="Canada"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                  />
                                  {errors.Country && (
                                    <small className="text-danger">
                                      {errors.Country.message}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Postal Code:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    maxLength="7"
                                    {...register("PostalCode", {
                                      required: "Postal Code is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                      // pattern: {
                                      // 	value:/^([a-zA-Z][0-9][a-zA-Z])\s*([0-9][a-zA-Z][0-9])$/,
                                      // message: "Postal Code should be alphanumeric"
                                      // },
                                    })}
                                    onChange={handlePostalCode}
                                    value={postal_code}
                                  />
                                  {errors.PostalCode && (
                                    <small className="text-danger">
                                      {errors.PostalCode.message}
                                    </small>
                                  )}
                                  <small style={{ color: "red" }}>{msg}</small>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group1 custm-mb1">
                                  <label>StoreID:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setStoreID(e.target.value)}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-12 col-lg-4"> */}
                              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="form-group1 custm-mb1">
                                  <label>Account Name:</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="MID"
                                    maxLength="16"
                                    {...register("MID", {
                                      //required: "Account Name is required",
                                    })}
                                    onChange={(e) => setMID(e.target.value)}
                                    //onChange={handleMID}
                                    //value={MID}
                                  />
                                  {errors.MID && (
                                    <small className="text-danger">
                                      {errors.MID.message}
                                    </small>
                                  )}
                                  <small style={{ color: "red" }}>{msg}</small>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="custm-mb1">
                                  <label>
                                    Upload Logo:
                                    {/* <span style={{ color: "red" }}>*</span> ////// */}
                                  </label>
                                  <div className="input-group mt-2 cstmchangeflexlist">
                                    <div className="custom-file overflow-hidden">
                                      <input
                                        type="file"
                                        className="custom-file-input "
                                        name="charityLogo"
                                        id="files"
                                        accept=".jpg,.jpeg,.png,"
                                        // {...register("Logo", {
                                        //   required: "Location Logo is required",
                                        // })}
                                        onChange={handleFile}
                                      />
                                      <label
                                        className="custom-file-label text-size textNowWrap"
                                        for="files"
                                      >
                                        Upload Logo (Maximum Size is 2MB)
                                      </label>
                                    </div>
                                    <div className="Position-relative custmImgPos">
                                      {isAddUpload &&
                                        [...isAddUpload].map((file) => (
                                          <div className="cstmliste-sideup">
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt="logo"
                                            />
                                          </div>
                                        ))}
                                      {isAddUpload.length > 0 ? (
                                        <a
                                          title="Delete Logo"
                                          class="text-dark crosPost"
                                          aria-hidden="true"
                                          onClick={() => deleteLogo()}
                                        >
                                          <i class="fa fa-times" />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {error.Logo && (
                                  <small className="text-danger">
                                    {error.Logo}
                                  </small>
                                )}
                              </div>

                              <div className="col-md-12 col-lg-2">
                                <div className="form-group1 formgrpcustom1 formgroupCustom mb-4 custmHeight">
                                  <label>Language:</label>

                                  <input
                                    type="radio"
                                    id="html"
                                    name={`fav_language1`}
                                    value={`English`}
                                    checked={language === "English"}
                                    onChange={(e) =>
                                      setLanguage(e.target.value)
                                    }
                                    className="radioLanButton addradioRetl"
                                  />

                                  <label
                                    for="html"
                                    className="radioLanLable addradioRetlTxt"
                                  >
                                    English
                                  </label>

                                  <input
                                    type="radio"
                                    id="css"
                                    className="radioLanInput addradioRetl"
                                    name={`fav_language1`}
                                    value={`French`}
                                    checked={language === "French"}
                                    onChange={(e) =>
                                      setLanguage(e.target.value)
                                    }
                                  />

                                  <label
                                    for="css"
                                    className="radioLanLable2 addradioRetlTxt"
                                  >
                                    French
                                  </label>
                                </div>
                              </div>
                              {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3"> */}
                              <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                <div className="form-group1 custm-mb1 custmMt-1">
                                  <label>Color:</label>
                                  <input
                                    type="color"
                                    name="color"
                                    className="form-control"
                                    onChange={(e) =>
                                      setColor(Validator.ltrim(e.target.value))
                                    }
                                    value={color}
                                  />
                                </div>
                                {error.Color && (
                                  <small className="text-danger">
                                    {error.Color}
                                  </small>
                                )}
                              </div>
                              {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3"> */}
                              <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                <div className="form-group1 custm-mb1">
                                  <label>Font Color:</label>
                                  <input
                                    type="color"
                                    name="fontColor"
                                    className="form-control"
                                    onChange={(e) =>
                                      setFontColor(
                                        Validator.ltrim(e.target.value)
                                      )
                                    }
                                    value={fontColor}
                                  />
                                </div>
                                {error.FontColor && (
                                  <small className="text-danger">
                                    {error.FontColor}
                                  </small>
                                )}
                              </div>

                              <div className="col-md-8 col-lg col-xl-3 col-sm-12 custom-md">
                                <div className="form-group1 formgrpcustom1 cstmresp_mng cstmheighds">
                                  <br></br>
                                  <label>Donation Frequency:</label>

                                  <input
                                    type="radio"
                                    id="donation"
                                    name={`fav_Frequency`}
                                    value={`OneTime`}
                                    checked={donationFrequency === "OneTime"}
                                    onChange={(e) => {
                                      setDonationFrequency(e.target.value);
                                      setisDonationMonthly(false);
                                    }}
                                    className="radioLanButton"
                                  />

                                  <label
                                    for="donation"
                                    className="radioLanLable"
                                  >
                                    One-Time only
                                  </label>

                                  <input
                                    type="radio"
                                    id="donationm"
                                    className="radioLanInputAdd"
                                    name={`fav_Frequency`}
                                    value={`Monthly`}
                                    checked={donationFrequency === "Monthly"}
                                    onChange={(e) => {
                                      setDonationFrequency(e.target.value);
                                      setisDonationMonthly(true);
                                    }}
                                  />

                                  <label
                                    for="donationm"
                                    className="radioLanLableAdd2"
                                  >
                                    One-Time and Monthly
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                    <div className="form-group1">
                                      <label>One-Time Donation Amount:</label>
                                      <div className="Flex-input flex-inutadd">
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name1"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount1}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name2"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount2}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name3"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount3}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name4"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount4}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name5"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount5}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name6"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount6}
                                          />
                                        </div>
                                        <div className="flexcstminput1 m0_Input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleAmount}
                                            name="name7"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                            value={addAmount7}
                                          />
                                        </div>
                                        {/* <div className="input-icon">
                                      <i>$</i>
                                    </div>
                                    <input
                                      onBlur={handleAmount}
                                      name="name8"
                                      className="form-control"
                                      type="text"
                                      maxLength="4"
                                      onKeyPress={function (event) {
                                        var regex = new RegExp("^[0-9]");
                                        var key = String.fromCharCode(
                                          !event.charCode
                                            ? event.which
                                            : event.charCode
                                        );
                                        if (!regex.test(key)) {
                                          event.preventDefault();
                                          return false;
                                        }
                                      }}
                                    /> */}
                                      </div>
                                      <small className="text-danger">
                                        {msgAmount}
                                      </small>
                                    </div>
                                  </div>
                                  {isDonationMonthly ? (
                                    <div className="col-md-12 col-lg-6">
                                      <div className="form-group1">
                                        <label>Monthly Donation Amount:</label>
                                        <div className="Flex-input flex-inutadd cstmmrtp10">
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly1"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount1}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly2"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount2}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly3"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount3}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly4"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount4}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly5"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount5}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly6"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount6}
                                            />
                                          </div>
                                          <div className="flexcstminput1">
                                            <div className="input-icon">
                                              <i>$</i>
                                            </div>
                                            <input
                                              onChange={handleMonthlyAmount}
                                              name="namemonthly7"
                                              className="form-control"
                                              type="text"
                                              maxLength="4"
                                              onKeyPress={function (event) {
                                                var regex = new RegExp(
                                                  "^[0-9]"
                                                );
                                                var key = String.fromCharCode(
                                                  !event.charCode
                                                    ? event.which
                                                    : event.charCode
                                                );
                                                if (!regex.test(key)) {
                                                  event.preventDefault();
                                                  return false;
                                                }
                                              }}
                                              value={addMonthlyAmount7}
                                            />
                                          </div>
                                        </div>
                                        <small className="text-danger">
                                          {msgMonthlyAmount}
                                        </small>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row row-gap">
                              <div className="col-md-12">
                                <div className="card-radio">
                                  <div className="col-md-12">
                                    <h5>Choose Option :</h5>
                                    <div className="col-sm-12 form-group1 formgrpcustom mb-0">
                                      <input
                                        type="radio"
                                        id="html"
                                        name={`charityType`}
                                        value={"Donations"}
                                        onChange={(e) =>
                                          e.target.value === "Donations"
                                            ? [
                                                setCharityType(e.target.value),
                                                setLotteryTicket(null),
                                                setCreateTaxReceipt("0"),
                                                setFiftyTicket(null),
                                                setCreateLotteryTicket(null),
                                                setSendGridTemplateId(""),
                                                setTemplateName(""),
                                                setStartingTicketNumber(""),
                                                setStringOne(""),
                                                setStringTwo(""),
                                                setStringOne(""),
                                                setStringTwo(""),
                                                setRulesRegUrl(""),
                                                setSendGridLotteryTemplateId(
                                                  ""
                                                ),
                                                setlotteryTemplateName(""),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailEdit(false),
                                                setLotteryEmailSendGridId(""),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                        checked={charityType === "Donations"}
                                        className="radioButton"
                                      />
                                      <label
                                        for="html"
                                        className="radioLableLocation"
                                      >
                                        Donations
                                      </label>

                                      <input
                                        type="radio"
                                        id="html"
                                        className="radioInputLottery"
                                        name={`charityType`}
                                        value={"Lottery"}
                                        onChange={(e) =>
                                          e.target.value === "Lottery"
                                            ? [
                                                setCharityType(e.target.value),
                                                setCreateTaxReceipt("0"),
                                                setTaxReceiptEligible("0"),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailEdit(false),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                        checked={charityType === "Lottery"}
                                      />
                                      <label
                                        for="css"
                                        className="radioLableLotteryLocation"
                                      >
                                        Lottery
                                      </label>
                                      <input
                                        type="radio"
                                        id="html"
                                        className="radioInputBoth"
                                        name={`charityType`}
                                        value={"Both"}
                                        checked={charityType === "Both"}
                                        onChange={(e) =>
                                          e.target.value === "Both"
                                            ? [
                                                setCharityType(e.target.value),
                                                setCreateTaxReceipt("0"),
                                                setTaxReceiptEligible("0"),
                                                setLotteryTicket(null),
                                                setFiftyTicket(null),
                                                setCreateLotteryTicket("No"),
                                                setSendGridTemplateId(""),
                                                setTemplateName(""),
                                                setStartingTicketNumber(""),
                                                setStringOne(""),
                                                setStringTwo(""),
                                                setRulesRegUrl(""),
                                                setSendGridLotteryTemplateId(
                                                  ""
                                                ),
                                                setlotteryTemplateName(""),
                                                setLotteryEmailEdit(false),
                                                setDonationEmailEdit(false),
                                              ]
                                            : setCharityType(e.target.value)
                                        }
                                      />
                                      <label
                                        for="css"
                                        className="radioLable3Location"
                                      >
                                        Both
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  charityType === "Donations" ||
                                  charityType === "Both"
                                    ? "col-lg-6 col-xl-4 cardDisplay"
                                    : "col-lg-6 col-xl-4 cardNotDisplay"
                                }
                              >
                                <div className="card-radio Cstm-card">
                                  <div className="col-md-12">
                                    <h5>Donation Tax Receipt</h5>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Create Tax Receipt:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`createTaxReceipt_1`}
                                            value={"1"}
                                            checked={createTaxReceipt === "1"}
                                            onChange={(e) => [
                                              setCreateTaxReceipt(
                                                e.target.value
                                              ),
                                              setDonationEmailSendGridId(
                                                "noreply@bnasmartpayment.com"
                                              ),
                                            ]}
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`createTaxReceipt_1`}
                                            value={"0"}
                                            checked={createTaxReceipt === "0"}
                                            onChange={(e) => [
                                              setCreateTaxReceipt(
                                                e.target.value
                                              ),
                                              setSendGridTemplateId(""),
                                              setTemplateName(""),
                                              setDonationEmailSendGridId(""),
                                            ]}
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {createTaxReceipt === "1" ? (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                          <div className="form-group position-relative">
                                            <label>
                                              Donation Email SendGrid ID:
                                            </label>
                                            <input
                                              name="donationEmailSendGridId"
                                              type="text"
                                              className="form-control"
                                              readOnly={
                                                donationEmailEdit === true
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e) => {
                                                setDonationEmailSendGridId(
                                                  e.target.value
                                                );
                                              }}
                                              value={donationEmailSendGridId}
                                            />
                                            <button
                                              className="editMailBtnLoc btn btn-primary change_posibtnsc custmPos custmWidthEdit"
                                              type="button"
                                              value="Alert the text input"
                                              onClick={() =>
                                                setDonationEmailEdit(true)
                                              }
                                            >
                                              Edit
                                            </button>

                                            {error.donationEmailSendGridIdVal && (
                                              <small className="text-danger">
                                                {
                                                  error.donationEmailSendGridIdVal
                                                }
                                              </small>
                                            )}
                                          </div>
                                          <div className="form-group ">
                                            <label>
                                              Tax&nbsp;Receipt&nbsp;SendGrid&nbsp;Id:
                                            </label>
                                            <input
                                              name={`sendGridTemplateId`}
                                              className="form-control"
                                              type="text"
                                              value={sendGridTemplateId}
                                              onChange={(e) =>
                                                setSendGridTemplateId(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            {error.SendGridTemplateIdVal && (
                                              <small className="text-danger">
                                                {error.SendGridTemplateIdVal}
                                              </small>
                                            )}
                                          </div>
                                          {/* </div>
                       <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                          {/* <div className="form-group1 ">
                             <label>Tax&nbsp;Receipt&nbsp;Template&nbsp;Name:</label>
                             <input
                              name={`templateName`}
                               className="form-control"
                               type="text"
                               value={templateName}
                               onChange ={(e)=>setTemplateName(e.target.value)}
                             />
                                 {error.TemplateNameVal && (
                             <small className="text-danger">
                               {error.TemplateNameVal}
                             </small>
                                )}
                        </div> */}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Tax Receipt Eligible:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`taxReceiptEligible`}
                                            value={"1"}
                                            checked={taxReceiptEligible === "1"}
                                            onChange={(e) =>
                                              setTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`taxReceiptEligible`}
                                            value={"0"}
                                            checked={taxReceiptEligible === "0"}
                                            onChange={(e) =>
                                              setTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  charityType === "Lottery" ||
                                  charityType === "Both"
                                    ? "col-lg-6 col-xl-4 cardDisplay"
                                    : "col-lg-6 col-xl-4 cardNotDisplay"
                                }
                              >
                                <div className="card-radio">
                                  <div className="col-md-12">
                                    <h5>Lottery Ticket</h5>
                                  </div>
                                  <div className="col-md-12 row m-0">
                                    {/* <div className="col-md-12 row"> */}
                                    <div className="row">
                                      <div className="col-lg-6 col-md-5 col-sm-12 col-12 mb-3">
                                        <label>
                                          Create&nbsp;Lottery&nbsp;Ticket:
                                        </label>
                                        <input
                                          type="radio"
                                          id="html"
                                          name={`createLotteryTicket`}
                                          value={"Yes"}
                                          checked={
                                            createLotteryTicket === "Yes"
                                          }
                                          onChange={(e) => [
                                            setCreateLotteryTicket(
                                              e.target.value
                                            ),
                                            setLotteryEmailSendGridId(
                                              "noreply@bnasmartpayment.com"
                                            ),
                                          ]}
                                          className="radioButton"
                                        />

                                        <label
                                          for="html"
                                          className="radioLableLocation"
                                        >
                                          Yes
                                        </label>

                                        <input
                                          type="radio"
                                          id="css"
                                          className="radioInput"
                                          name={`createLotteryTicket`}
                                          value={"No"}
                                          checked={createLotteryTicket === "No"}
                                          onChange={(e) => [
                                            setCreateLotteryTicket(
                                              e.target.value
                                            ),
                                            setStartingTicketNumber(""),
                                            setStringOne(""),
                                            setStringTwo(""),
                                            setSendGridLotteryTemplateId(""),
                                            setlotteryTemplateName(""),
                                          ]}
                                        />

                                        <label
                                          for="css"
                                          className="radioLable2Location"
                                        >
                                          No
                                        </label>
                                      </div>
                                      {createLotteryTicket === "Yes" ? (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                          <div className="custom-form-wrapper mt-4">
                                            <div className="form-group1 ">
                                              <label>
                                                Starting&nbsp;Ticket&nbsp;Number:
                                              </label>
                                              <div className="row input-align">
                                                <input
                                                  name={`stringOne`}
                                                  className="form-control form-control col-md-2 p-0 custom-sm"
                                                  type="text"
                                                  value={stringOne}
                                                  maxLength="3"
                                                  onKeyPress={function (event) {
                                                    var regex = new RegExp(
                                                      "^[a-zA-Z]"
                                                    );
                                                    var key =
                                                      String.fromCharCode(
                                                        !event.charCode
                                                          ? event.which
                                                          : event.charCode
                                                      );
                                                    if (!regex.test(key)) {
                                                      event.preventDefault();
                                                      return false;
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    setStringOne(e.target.value)
                                                  }
                                                  // onChange ={(e)=>checkStartingTicketNumber(e.target.value)}
                                                />
                                                <input
                                                  name={`stringTwo`}
                                                  className="form-control col-md custom-sm2"
                                                  type="text"
                                                  value={stringTwo}
                                                  maxLength="7"
                                                  onKeyPress={function (event) {
                                                    var regex = new RegExp(
                                                      "^[0-9]"
                                                    );
                                                    var key =
                                                      String.fromCharCode(
                                                        !event.charCode
                                                          ? event.which
                                                          : event.charCode
                                                      );
                                                    if (!regex.test(key)) {
                                                      event.preventDefault();
                                                      return false;
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    setStringTwo(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group position-relative">
                                              <label>
                                                Lottery Email SendGrid ID:
                                              </label>

                                              <input
                                                name="lotteryEmailSendGridId"
                                                type="text"
                                                className="form-control"
                                                readOnly={
                                                  lotteryEmailEdit === true
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setLotteryEmailSendGridId(
                                                    e.target.value
                                                  );
                                                }}
                                                value={lotteryEmailSendGridId}
                                              />
                                              <button
                                                className="editMailBtnLoc btn btn-primary change_posibtnsc-2 custmWidthEdit custmPos"
                                                type="button"
                                                value="Alert the text input"
                                                onClick={() =>
                                                  setLotteryEmailEdit(true)
                                                }
                                              >
                                                Edit
                                              </button>

                                              {error.lotteryEmailSendGridIdVal && (
                                                <small className="text-danger">
                                                  {
                                                    error.lotteryEmailSendGridIdVal
                                                  }
                                                </small>
                                              )}
                                            </div>
                                          </div>
                                          <div className="custom-form-wrapper">
                                            <div className="form-group ">
                                              <label>
                                                SendGrid&nbsp;Template&nbsp;Id:
                                              </label>
                                              <input
                                                name={`sendGridLotteryTemplateId`}
                                                className="form-control"
                                                type="text"
                                                value={
                                                  sendGridLotteryTemplateId
                                                }
                                                onChange={(e) =>
                                                  setSendGridLotteryTemplateId(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {error.SendGridLotteryTemplateIdVal && (
                                                <small className="text-danger">
                                                  {
                                                    error.SendGridLotteryTemplateIdVal
                                                  }
                                                </small>
                                              )}
                                            </div>
                                            {/* </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12"> */}
                                            {/* <div className="form-group1 ">
                              <label>Template&nbsp;Name:</label>
                              <input
                               name={`lotteryTemplateName`}
                                className="form-control"
                                type="text"
                                value={lotteryTemplateName}
                                onChange ={(e)=>setlotteryTemplateName(e.target.value)}
                              />
                               {error.LotteryTemplateNameVal && (
                              <small className="text-danger">
                                {error.LotteryTemplateNameVal}
                              </small>
                            )}
                            </div> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {/* </div> */}
                                      <div className="col-lg-6 col-md-3 col-sm-12 col-12 lotteryTics">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Lottery&nbsp;Tickets:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`lotteryTicket`}
                                            value={"Yes"}
                                            checked={lotteryTicket === "Yes"}
                                            onChange={(e) => [
                                              setLotteryTicket(e.target.value),
                                            ]}
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`lotteryTicket`}
                                            value={"No"}
                                            checked={lotteryTicket === "No"}
                                            onChange={(e) =>
                                              setLotteryTicket(e.target.value)
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 lotteryFifty lotteryFifty-custom mt-0">
                                        <div className="form-group1 formgrpcustom">
                                          <label>50/50 Tickets:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`fiftyTicket`}
                                            value={"Yes"}
                                            checked={fiftyTicket === "Yes"}
                                            onChange={(e) =>
                                              setFiftyTicket(e.target.value)
                                            }
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`fiftyTicket`}
                                            value={"No"}
                                            checked={fiftyTicket === "No"}
                                            onChange={(e) =>
                                              setFiftyTicket(e.target.value)
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="form-group ">
                                          <label>
                                            Rules and Regulations URL:
                                          </label>

                                          <input
                                            name="rulesRegUrl"
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => {
                                              setRulesRegUrl(e.target.value);
                                            }}
                                            value={rulesRegUrl}
                                          />
                                        </div>
                                        {error.rulesRegUrlVal && (
                                          <small className="text-danger">
                                            {error.rulesRegUrlVal}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-4">
                                <div className="card-radio Cstm-card">
                                  <div className="col-md-12">
                                    <h5>Processing Fee</h5>
                                  </div>
                                  <div className="col-md-12 ">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Processing Fees Option:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`processFee`}
                                            value={"1"}
                                            checked={processFee === "1"}
                                            onChange={(e) =>
                                              setProcessFee(e.target.value)
                                            }
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`processFee`}
                                            value={"0"}
                                            checked={processFee === "0"}
                                            onChange={(e) =>
                                              setProcessFee(e.target.value)
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {processFee === "1" ? (
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                          <div className="form-group cstm_posi_chng">
                                            <label>Processing Fees:</label>
                                            <div className="Flex-input">
                                              <input
                                                name="processFeeAmount"
                                                className="form-control"
                                                id="processFeeInput"
                                                type="text"
                                                maxLength="6"
                                                {...register(
                                                  "processFeeAmount",
                                                  {
                                                    required:
                                                      "Amount is required",
                                                  }
                                                )}
                                                onKeyPress={function (event) {
                                                  var regex = new RegExp(
                                                    /^\d*\.?\d*$/
                                                  );
                                                  var key = String.fromCharCode(
                                                    !event.charCode
                                                      ? event.which
                                                      : event.charCode
                                                  );
                                                  if (!regex.test(key)) {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  if (e.target.value <= 100) {
                                                    setProcessFeeAmount(
                                                      e.target.value
                                                    );
                                                  } else {
                                                    e.target.value = "";
                                                  }
                                                }}
                                                value={processFeeAmount}
                                              />
                                              {/* <div className="processing-input-icon">
                                              <i id="locationPercentIcon">%</i>
                                            </div> */}
                                              <i className="icon_pscstm">%</i>
                                            </div>

                                            {errors.processFeeAmount && (
                                              <small className="text-danger">
                                                {
                                                  errors.processFeeAmount
                                                    .message
                                                }
                                              </small>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          // <div className="row">
                          //   <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Create Tax Receipt:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`createTaxReceipt_1`}
                          //           value={"1"}
                          //           checked={createTaxReceipt == "1"}
                          //           onChange={(e) => setCreateTaxReceipt(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`createTaxReceipt_1`}
                          //           value={"0"}
                          //           checked={createTaxReceipt === "0"}
                          //           onChange={(e) => setCreateTaxReceipt(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div>
                          //   <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Processing Fees Option:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`processFee`}
                          //           value={"1"}
                          //           checked={processFee === "1"}
                          //           onChange={(e) => setProcessFee(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`processFee`}
                          //           value={"0"}
                          //           checked={processFee === "0"}
                          //           onChange={(e) => setProcessFee(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div>
                          //   {processFee === "1"?
                          //   <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                          //   <div className="form-group">
                          //     <label>Processing Fees:</label>
                          //     <div className="Flex-input">
                          //         <input
                          //           style={{"width": "123px"}}
                          //           name="processFeeAmount"
                          //           className="form-control"
                          //           type="text"
                          //           maxLength="6"
                          //           {...register("processFeeAmount", {
                          //             required: "Amount is required",
                          //           })}
                          //           onKeyPress={function (event) {
                          //             var regex = new RegExp(/^\d*\.?\d*$/);
                          //             var key = String.fromCharCode(
                          //               !event.charCode
                          //                 ? event.which
                          //                 : event.charCode
                          //             );
                          //             if (!regex.test(key)) {
                          //               event.preventDefault();
                          //               return false;
                          //             }
                          //           }}
                          //           onChange={(e) =>{
                          //               if(e.target.value <= 100){
                          //                 setProcessFeeAmount(e.target.value)
                          //               }else{
                          //                 e.target.value = ''
                          //               }
                          //             }
                          //           }
                          //           value={processFeeAmount}
                          //         />
                          //         <div className="processing-input-icon">
                          //           <i>%</i>
                          //         </div>

                          //     </div>

                          //     {errors.processFeeAmount && (
                          //       <small className="text-danger">
                          //         {errors.processFeeAmount.message}
                          //       </small>
                          //     )}
                          //   </div>
                          //   </div>
                          //   :''
                          //   }
                          //   {/* <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Tax Receipt Eligible:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`taxReceiptEligible`}
                          //           value={"1"}
                          //           checked={taxReceiptEligible === "1"}
                          //           onChange={(e) => setTaxReceiptEligible(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`taxReceiptEligible`}
                          //           value={"0"}
                          //           checked={taxReceiptEligible === "0"}
                          //           onChange={(e) => setTaxReceiptEligible(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div> */}
                          // </div>
                        }
                      </div>
                    </form>
                    {addSecond === false ? (
                      <div className="modalFooter text-right mt-5 pt-3 d-flex justify-content-end">
                        <button
                          className="btn btn-primary"
                          onClick={clearAddlocationData}
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={handleSubmit(openAddSecondForm)}
                          disabled={cheack ? true : false}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="modalFooter d-flex justify-content-end text-right mt-5 pt-3">
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={closeAddSecondForm}
                        >
                          Back
                        </button>

                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit(addLocation)}
                          disabled={cheack ? true : false}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {/* updateLocation Modal	 */}
              {updateLocationModal ? (
                <div className="ModalBackground">
                  <div className="modalContainer modelAddLocationContainer cstm_editLoaction_Modal">
                    <div className="modalHeader mb-5">
                      <h3>Update Location</h3>
                    </div>
                    <button
                      className="closeBtn"
                      onClick={() => closeupdatelocatonPopup()}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <form>
                      <div className="container">
                        {
                          updateSecond === false ? (
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Contact:</label>
                                  <input
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    // value={updateLocationContact}
                                    {...register("UpdateLocationContact", {
                                      required: "Location contact is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateLocationContact(e.target.value)
                                    }
                                    value={updateLocationContact}
                                  />
                                  {errors.UpdateLocationContact && (
                                    <small className="text-danger">
                                      {errors.UpdateLocationContact.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Email:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // value={updateLocationEmail}
                                    {...register("UpdateLocationEmail", {
                                      required: "Location Email is required",
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateLocationEmail(e.target.value)
                                    }
                                    value={updateLocationEmail}
                                  />
                                  {errors.UpdateLocationEmail && (
                                    <small className="text-danger">
                                      {errors.UpdateLocationEmail.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-8 col-sm-6">
                                <div className="form-group">
                                  <label> Phone:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    maxLength="13"
                                    // value={updateLocationPhone}
                                    {...register("UpdateLocationPhone", {
                                      required: "Location phone is required",
                                      pattern: {
                                        value:
                                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                        message: "Only numbers are allowed",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateLocationPhone(
                                        normalizeInput(e.target.value)
                                      )
                                    }
                                    value={updateLocationPhone}
                                  />
                                  {errors.UpdateLocationPhone && (
                                    <small className="text-danger">
                                      {errors.UpdateLocationPhone.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 col-sm-6">
                                <label>Ext.</label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="updateExt"
                                    maxLength="5"
                                    className="form-control"
                                    value={updateLocationExt}
                                    onKeyPress={function (event) {
                                      var regex = new RegExp("^[0-9]");
                                      var key = String.fromCharCode(
                                        !event.charCode
                                          ? event.which
                                          : event.charCode
                                      );
                                      if (!regex.test(key)) {
                                        event.preventDefault();
                                        return false;
                                      }
                                    }}
                                    {...register("updateLocationExt", {})}
                                    onChange={(e) =>
                                      setUpdateLocationExt(
                                        Validator.ltrim(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Address:</label>
                                  <input
                                    type="text"
                                    maxLength={55}
                                    className="form-control"
                                    // value={updateAddress}
                                    {...register("UpdateAddress", {
                                      required: "Address is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateAddress(e.target.value)
                                    }
                                  />
                                  {errors.UpdateAddress && (
                                    <small className="text-danger">
                                      {errors.UpdateAddress.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>City:</label>
                                  <input
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    // value={updateCity}
                                    {...register("UpdateCity", {
                                      required: "City is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from first word.",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateCity(e.target.value)
                                    }
                                  />
                                  {errors.UpdateCity && (
                                    <small className="text-danger">
                                      {errors.UpdateCity.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Province:</label>
                                  <label
                                    htmlFor="provincefirst_new2"
                                    className="arrowstickshowssh"
                                  >
                                    <input
                                      id="provincefirst_new2"
                                      type="text"
                                      name="updateProvince"
                                      className="form-control"
                                      list="browsers"
                                      // value={updateProvince}
                                      {...register("UpdateProvince", {
                                        required: "Province is required",
                                        pattern: {
                                          value: /([^\s]+)/g,
                                          message:
                                            "Please remove space from first word.",
                                        },
                                      })}
                                      onClick={(e) => (e.target.value = "")}
                                      onMouseLeave={(e) =>
                                        (e.target.value = updateProvince)
                                      }
                                      onChange={(e) =>
                                        setUpdateProvince(e.target.value)
                                      }
                                    />
                                    <datalist id="browsers">
                                      {provinceList &&
                                        provinceList.map((list, id) =>
                                          list.province.map((res) => {
                                            return (
                                              <option
                                                selected="selected"
                                                value={res}
                                              />
                                            );
                                          })
                                        )}
                                    </datalist>
                                  </label>
                                  {errors.UpdateProvince && (
                                    <small className="text-danger">
                                      {errors.UpdateProvince.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Country:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // value={updateCountry}
                                    {...register("UpdateCountry", {
                                      required: "Country is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from start word.",
                                      },
                                    })}
                                    onChange={(e) =>
                                      setUpdateCountry(e.target.value)
                                    }
                                  />
                                  {errors.UpdateCountry && (
                                    <small className="text-danger">
                                      {errors.UpdateCountry.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Postal Code:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    maxLength="7"
                                    // value={updatePostal_Code}
                                    {...register("UpdatePostal_Code", {
                                      required: "Postal Code is required",
                                      pattern: {
                                        value: /([^\s]+)/g,
                                        message:
                                          "Please remove space from start word.",
                                      },
                                    })}
                                    onChange={handleUpdatePostalCode}
                                    value={updatePostal_Code}
                                  />
                                  {errors.UpdatePostal_Code && (
                                    <small className="text-danger">
                                      {errors.UpdatePostal_Code.message}
                                    </small>
                                  )}
                                  <small style={{ color: "red" }}>{msg}</small>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group1 custm-mb1">
                                  <label>StoreID:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={updateStoreID}
                                    onChange={(e) =>
                                      setUpdateStoreID(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-5 col-lg-5 col-sm-12"> */}
                              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="form-group1 custm-mb1">
                                  <label>Account Name:</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="updateMID"
                                    maxLength="20"
                                    {...register("UpdateMID", {
                                      //required: "Account Name is required",
                                    })}
                                    //value={updateMID}
                                    onChange={(e) =>
                                      setUpdateMID(
                                        Validator.ltrim(e.target.value)
                                      )
                                    }
                                  />
                                  {errors.UpdateMID && (
                                    <small className="text-danger">
                                      {errors.UpdateMID.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              {/* ////// */}
                              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="custm-mb1 custmMob-1">
                                  <label>Upload Logo</label>
                                  <div className="input-group mt-2 mb-3 cstmchangeflexlist">
                                    <div className="custom-file overflow-hidden">
                                      <input
                                        className="uplaodController custom-file-input"
                                        type="file"
                                        name="UpdateLogo"
                                        id="files"
                                        accept=".jpg,.jpeg,.png"
                                        style={{}}
                                        onChange={updateHandleFile}
                                      />
                                      <label
                                        className="custom-file-label"
                                        for="files"
                                      >
                                        Upload Logo (Maximum Size is 2MB)
                                      </label>
                                    </div>
                                    <div className="cstmliste-sideup postion-relative custmImgPos">
                                      {isUpload == "" ? (
                                        <img
                                          src={`/uploads/${updateCharityLogo}`}
                                          alt="logo"
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {isUpload &&
                                        [...isUpload].map((file) => (
                                          <img
                                            src={URL.createObjectURL(file)}
                                            alt="logo"
                                          />
                                        ))}
                                      {(updateCharityLogo !== "" &&
                                        updateCharityLogo != undefined) ||
                                      isUpload?.length > 0 ? (
                                        <a
                                          title="Delete Logo"
                                          class="text-dark crosPost"
                                          aria-hidden="true"
                                          onClick={() => updateDeleteLogo()}
                                        >
                                          <i class="fa fa-times" />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {error.Logo && (
                                  <small className="text-danger">
                                    {error.Logo}
                                  </small>
                                )}
                              </div>
                              <div className="col-md-3 col-lg-2 col-xl-2 col-sm-12 mt-lg-2">
                                <div className="form-group1 formgrpcustom1 formgroupCustom custmHeight custm-mb1">
                                  <label>Language:</label>
                                  <input
                                    type="radio"
                                    id="htmll"
                                    name={`fav_language1`}
                                    value={`English`}
                                    checked={updateLanguage === "English"}
                                    onChange={(e) =>
                                      setUpdateLanguage(e.target.value)
                                    }
                                    className="radioLanButton updtRadioBtn"
                                  />

                                  <label
                                    for="html"
                                    className="radioLanLable updtRadioBtnTxt"
                                  >
                                    English
                                  </label>

                                  <input
                                    type="radio"
                                    id="csss"
                                    className="radioLanInput updtRadioBtn"
                                    name={`fav_language1`}
                                    value={`French`}
                                    checked={updateLanguage === "French"}
                                    onChange={(e) =>
                                      setUpdateLanguage(e.target.value)
                                    }
                                  />

                                  <label
                                    for="css"
                                    className="radioLanLable2 updtRadioBtnTxt"
                                  >
                                    French
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-9">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group1 custm-mb1">
                                      <label>Color:</label>
                                      <input
                                        type="color"
                                        name="color"
                                        className="form-control"
                                        onChange={(e) =>
                                          setUpdateColor(
                                            Validator.ltrim(e.target.value)
                                          )
                                        }
                                        value={updateColor}
                                      />
                                    </div>
                                    {error.Color && (
                                      <small className="text-danger">
                                        {error.Color}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group1 custm-mb1">
                                      <label>Font Color:</label>
                                      <input
                                        type="color"
                                        name="fontColor"
                                        className="form-control"
                                        onChange={(e) =>
                                          setUpdateFontColor(
                                            Validator.ltrim(e.target.value)
                                          )
                                        }
                                        value={updateFontColor}
                                      />
                                    </div>
                                    {error.FontColor && (
                                      <small className="text-danger">
                                        {error.FontColor}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8 col-lg-8 col-xl-3 col-sm-12">
                                <div className="form-group1 formgrpcustom2 cstmheighds custm-mb1">
                                  <br></br>
                                  <label>Donation Frequency:</label>

                                  <input
                                    type="radio"
                                    id="DonationFrequencyadded"
                                    name={`fav_frequency`}
                                    value={`OneTime`}
                                    checked={
                                      updateDonationFrequency === "OneTime"
                                    }
                                    onChange={(e) => {
                                      setUpdateDonationFrequency(
                                        e.target.value
                                      );
                                      setupdateIsDonationMonthly(false);
                                    }}
                                    className="radioLanButton"
                                  />

                                  <label
                                    for="DonationFrequencyadded"
                                    className="radioLanLable"
                                  >
                                    One-Time only
                                  </label>

                                  <input
                                    type="radio"
                                    id="DonationFrequencyaddedt"
                                    className="radioLanInputAdd"
                                    name={`fav_frequency`}
                                    value={`Monthly`}
                                    checked={
                                      updateDonationFrequency === "Monthly"
                                    }
                                    onChange={(e) => {
                                      setUpdateDonationFrequency(
                                        e.target.value
                                      );
                                      setupdateIsDonationMonthly(true);
                                    }}
                                  />

                                  <label
                                    for="DonationFrequencyaddedt"
                                    className="radioLanLableAdd2"
                                  >
                                    One-Time and Monthly
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-12 col-lg-6 col-sm-12">
                                    <div className="form-group1">
                                      <label>One-Time Donation Amount:</label>
                                      <div className="Flex-input">
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name1"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount1}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>
                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name2"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount2}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name3"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount3}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name4"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount4}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name5"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount5}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name6"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount6}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        <div className="input-icon">
                                          <i>$</i>
                                        </div>

                                        <input
                                          onChange={handleUpdateAmount}
                                          name="name7"
                                          className="form-control"
                                          type="text"
                                          maxLength="4"
                                          value={updateAmount7}
                                          onKeyPress={function (event) {
                                            var regex = new RegExp("^[0-9]");
                                            var key = String.fromCharCode(
                                              !event.charCode
                                                ? event.which
                                                : event.charCode
                                            );
                                            if (!regex.test(key)) {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                        {/* <div className="input-icon">
                                      <i>$</i>
                                    </div>

                                    <input
                                      onChange={handleUpdateAmount}
                                      name="name8"
                                      className="form-control"
                                      type="text"
                                      maxLength="4"
                                      value={updateAmount8}
                                      onKeyPress={function (event) {
                                        var regex = new RegExp("^[0-9]");
                                        var key = String.fromCharCode(
                                          !event.charCode
                                            ? event.which
                                            : event.charCode
                                        );
                                        if (!regex.test(key)) {
                                          event.preventDefault();
                                          return false;
                                        }
                                      }}
                                    /> */}
                                      </div>
                                      <small className="text-danger">
                                        {msg2}
                                      </small>
                                    </div>
                                  </div>
                                  {updateIsDonationMonthly ? (
                                    <div className="col-md-12 col-xl-6 col-sm-12">
                                      <div className="form-group1">
                                        <label>Monthly Donation Amount:</label>
                                        <div className="Flex-input">
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly1"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount1}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>
                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly2"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount2}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>

                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly3"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount3}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>

                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly4"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount4}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>

                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly5"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount5}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>

                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly6"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount6}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                          <div className="input-icon">
                                            <i>$</i>
                                          </div>

                                          <input
                                            onChange={handleUpdateMonthlyAmount}
                                            name="namemonthly7"
                                            className="form-control"
                                            type="text"
                                            maxLength="4"
                                            value={updateMonthlyAmount7}
                                            onKeyPress={function (event) {
                                              var regex = new RegExp("^[0-9]");
                                              var key = String.fromCharCode(
                                                !event.charCode
                                                  ? event.which
                                                  : event.charCode
                                              );
                                              if (!regex.test(key)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        </div>
                                        <small className="text-danger">
                                          {msgUpdateMonthlyAmount}
                                        </small>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card-radio">
                                  <div className="col-md-12">
                                    <h5>Choose Option :</h5>
                                    <div className="col-sm-12 form-group1 formgrpcustom">
                                      <div>
                                        <input
                                          type="radio"
                                          id="html"
                                          name={`charityType`}
                                          value={"Donations"}
                                          onChange={(e) =>
                                            e.target.value === "Donations"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateLotteryTicket(null),
                                                  setUpdateFiftyTicket(null),
                                                  setUpdateCreateLotteryTicket(
                                                    null
                                                  ),
                                                  setUpdateStartingTicketNumber(
                                                    ""
                                                  ),
                                                  setUpdateStringOne(""),
                                                  setUpdateStringTwo(""),
                                                  setUpdateSendGridLotteryTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateLotteryTemplateName(
                                                    ""
                                                  ),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                          checked={
                                            updateCharityType === "Donations"
                                          }
                                          className="radioButton"
                                        />
                                        <label
                                          for="html"
                                          className="radioLableLocation"
                                        >
                                          Donations
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          id="html"
                                          className="radioInputLottery"
                                          name={`charityType`}
                                          value={"Lottery"}
                                          onChange={(e) =>
                                            e.target.value === "Lottery"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateCreateTaxReceipt(
                                                    "0"
                                                  ),
                                                  setUpdateTaxReceiptEligible(
                                                    "0"
                                                  ),
                                                  setUpdateStartingTicketNumber(
                                                    ""
                                                  ),
                                                  setUpdateStringOne(""),
                                                  setUpdateStringTwo(""),
                                                  setUpdateSendGridLotteryTemplateId(
                                                    ""
                                                  ),
                                                  setUpdateLotteryTemplateName(
                                                    ""
                                                  ),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                          checked={
                                            updateCharityType === "Lottery"
                                          }
                                        />
                                        <label
                                          for="css"
                                          className="radioLableLotteryLocation"
                                        >
                                          Lottery
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          id="html"
                                          className="radioInputBoth"
                                          name={`charityType`}
                                          value={"Both"}
                                          checked={updateCharityType === "Both"}
                                          onChange={(e) =>
                                            e.target.value === "Both"
                                              ? [
                                                  setUpdateCharityType(
                                                    e.target.value
                                                  ),
                                                  setUpdateCreateTaxReceipt(
                                                    "0"
                                                  ),
                                                  setUpdateTaxReceiptEligible(
                                                    "0"
                                                  ),
                                                  setUpdateLotteryTicket(null),
                                                  setUpdateFiftyTicket(null),
                                                  setUpdateCreateLotteryTicket(
                                                    null
                                                  ),
                                                ]
                                              : setUpdateCharityType(
                                                  e.target.value
                                                )
                                          }
                                        />
                                        <label
                                          for="css"
                                          className="radioLable3Location"
                                        >
                                          Both
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  updateCharityType === "Donations" ||
                                  updateCharityType === "Both"
                                    ? "col-md-12 col-lg-6 col-xl-4 cardDisplay"
                                    : "col-md-12 col-xl-4 cardNotDisplay"
                                }
                              >
                                <div className="card-radio Cstm-card custmHeigh95">
                                  <div className="col-md-12">
                                    <h5>Donation Tax Receipt</h5>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Create Tax Receipt:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`createTaxReceipt_1`}
                                            value={"1"}
                                            checked={
                                              updateCreateTaxReceipt === "1"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateTaxReceipt(
                                                e.target.value
                                              ),
                                              setUpdateDonationEmailSendGridId(
                                                "noreply@bnasmartpayment.com"
                                              ),
                                            ]}
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`createTaxReceipt_1`}
                                            value={"0"}
                                            checked={
                                              updateCreateTaxReceipt === "0"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateTaxReceipt(
                                                e.target.value,
                                                setUpdateSendGridTemplateId(""),
                                                setUpdateTemplateName("")
                                              ),
                                            ]}
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {updateCreateTaxReceipt === "1" ? (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                          <div className="col-lg-12 col-md-12 col-sm-112 col-12 p-0">
                                            <div className="form-group1 ">
                                              <label>
                                                Donation Email SendGrid ID:
                                              </label>
                                              <input
                                                name="updateDonationEmailSendGridId"
                                                type="text"
                                                className="form-control"
                                                readOnly={
                                                  updateDonationEmailEdit ===
                                                  true
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setUpdateDonationEmailSendGridId(
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  updateDonationEmailSendGridId
                                                }
                                              />
                                              <button
                                                // className="editMailBtnLoc btn btn-primary change_posibtnsc-3"
                                                className="editMailBtnLoc btn btn-primary change_posibtnsc-4"
                                                type="button"
                                                value="Alert the text input"
                                                onClick={() =>
                                                  setUpdateDonationEmailEdit(
                                                    true
                                                  )
                                                }
                                              >
                                                Edit
                                              </button>

                                              {error.donationEmailSendGridIdVal && (
                                                <small className="text-danger">
                                                  {
                                                    error.donationEmailSendGridIdVal
                                                  }
                                                </small>
                                              )}
                                            </div>

                                            <div className="form-group ">
                                              <label>
                                                Tax&nbsp;Receipt&nbsp;SendGrid&nbsp;Template&nbsp;Id:
                                              </label>
                                              <input
                                                name={`updateSendGridTemplateId`}
                                                className="form-control"
                                                type="text"
                                                value={updateSendGridTemplateId}
                                                onChange={(e) =>
                                                  setUpdateSendGridTemplateId(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {error.SendGridTemplateIdVal && (
                                                <small className="text-danger">
                                                  {error.SendGridTemplateIdVal}
                                                </small>
                                              )}
                                            </div>
                                            {/* <div className="form-group1 ">
                            <label>Tax&nbsp;Receipt&nbsp;Template&nbsp;Name:</label>
                              <input
                               name={`updateTemplateName`}
                                className="form-control"
                                type="text"
                                value={updateTemplateName}
                                onChange ={(e)=>setUpdateTemplateName(e.target.value)}
                              />
                                {error.TemplateNameVal && (
                              <small className="text-danger">
                                {error.TemplateNameVal}
                              </small>
                                 )}
                            </div> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Tax Receipt Eligible:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`taxReceiptEligible`}
                                            value={"1"}
                                            checked={
                                              updateTaxReceiptEligible === "1"
                                            }
                                            onChange={(e) =>
                                              setUpdateTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`taxReceiptEligible`}
                                            value={"0"}
                                            checked={
                                              updateTaxReceiptEligible === "0"
                                            }
                                            onChange={(e) =>
                                              setUpdateTaxReceiptEligible(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  updateCharityType === "Lottery" ||
                                  updateCharityType === "Both"
                                    ? "col-md-12 col-lg-6 col-xl-4 cardDisplay"
                                    : "col-md-12 col-xl-4 cardNotDisplay"
                                }
                              >
                                <div className="card-radio Cstm-card custmHeigh95">
                                  <div className="col-md-12">
                                    <h5>Lottery Ticket</h5>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>
                                            Create&nbsp;Lottery&nbsp;Ticket:
                                          </label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`updatecreateLotteryTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatecreateLotteryTicket ===
                                              "Yes"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateLotteryTicket(
                                                e.target.value
                                              ),
                                              setUpdateLotteryEmailSendGridId(
                                                "noreply@bnasmartpayment.com"
                                              ),
                                            ]}
                                            className="radioButton"
                                          />
                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>
                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`updatecreateLotteryTicket`}
                                            value={"No"}
                                            checked={
                                              updatecreateLotteryTicket === "No"
                                            }
                                            onChange={(e) => [
                                              setUpdateCreateLotteryTicket(
                                                e.target.value
                                              ),
                                              setUpdateStartingTicketNumber(""),
                                              setUpdateStringOne(""),
                                              setUpdateStringTwo(""),
                                              setUpdateSendGridLotteryTemplateId(
                                                ""
                                              ),
                                              setUpdateLotteryTemplateName(""),
                                            ]}
                                          />
                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {updatecreateLotteryTicket === "Yes" ? (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                            <div className="form-group position-relative inpcsbtdf">
                                              <label>
                                                Lottery Email SendGrid ID:
                                              </label>
                                              <input
                                                name="UpdateLotteryEmailSendGridId"
                                                type="text"
                                                className="form-control"
                                                readOnly={
                                                  updateLotteryEmailEdit ===
                                                  true
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setUpdateLotteryEmailSendGridId(
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  updateLotteryEmailSendGridId
                                                }
                                              />
                                              <button
                                                className="editMailBtnLoc btn btn-primary change_posibtnsc-4"
                                                type="button"
                                                value="Alert the text input"
                                                onClick={() =>
                                                  setUpdateLotteryEmailEdit(
                                                    true
                                                  )
                                                }
                                              >
                                                Edit
                                              </button>

                                              {error.lotteryEmailSendGridIdVal && (
                                                <small className="text-danger">
                                                  {
                                                    error.lotteryEmailSendGridIdVal
                                                  }
                                                </small>
                                              )}
                                            </div>
                                            <div className="form-group1 ">
                                              <label>
                                                Starting&nbsp;Ticket&nbsp;Number:
                                              </label>
                                              <div className="row input-align">
                                                <input
                                                  name={`updateStringOne`}
                                                  className="form-control col-3 col-md-2"
                                                  type="text"
                                                  value={updateStringOne}
                                                  maxLength="3"
                                                  onKeyPress={function (event) {
                                                    var regex = new RegExp(
                                                      "^[a-zA-Z]"
                                                    );
                                                    var key =
                                                      String.fromCharCode(
                                                        !event.charCode
                                                          ? event.which
                                                          : event.charCode
                                                      );
                                                    if (!regex.test(key)) {
                                                      event.preventDefault();
                                                      return false;
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    setUpdateStringOne(
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange ={(e)=>checkStartingTicketNumber(e.target.value)}
                                                />
                                                <input
                                                  name={`updateStringTwo`}
                                                  className="form-control col"
                                                  type="text"
                                                  value={updateStringTwo}
                                                  maxLength="7"
                                                  onKeyPress={function (event) {
                                                    var regex = new RegExp(
                                                      "^[0-9]"
                                                    );
                                                    var key =
                                                      String.fromCharCode(
                                                        !event.charCode
                                                          ? event.which
                                                          : event.charCode
                                                      );
                                                    if (!regex.test(key)) {
                                                      event.preventDefault();
                                                      return false;
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    setUpdateStringTwo(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* </div>
                                       
                                       <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}

                                            <div className="form-group ">
                                              <label>
                                                SendGrid&nbsp;Template&nbsp;Id:
                                              </label>
                                              <input
                                                name={`updateSendGridLotteryTemplateId`}
                                                className="form-control"
                                                type="text"
                                                value={
                                                  updateSendGridLotteryTemplateId
                                                }
                                                onChange={(e) =>
                                                  setUpdateSendGridLotteryTemplateId(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {error.SendGridLotteryTemplateIdVal && (
                                                <small className="text-danger">
                                                  {
                                                    error.SendGridLotteryTemplateIdVal
                                                  }
                                                </small>
                                              )}
                                            </div>
                                            {/* </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6"> */}
                                            {/* <div className="form-group1 ">
                                          <label>Template&nbsp;Name:</label>
                                          <input
                                           name={`updateLotteryTemplateName`}
                                            className="form-control"
                                            type="text"
                                            value={updateLotteryTemplateName}
                                            onChange ={(e)=>setUpdateLotteryTemplateName(e.target.value)}
                                          />
                                            {error.LotteryTemplateNameVal && (
                                          <small className="text-danger">
                                            {error.LotteryTemplateNameVal}
                                          </small>
                                        )}
                                        </div> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Lottery&nbsp;Tickets:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`updatelotteryTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatelotteryTicket === "Yes"
                                            }
                                            onChange={(e) =>
                                              setUpdateLotteryTicket(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />
                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>
                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput  lottic"
                                            name={`updatelotteryTicket`}
                                            value={"No"}
                                            checked={
                                              updatelotteryTicket === "No"
                                            }
                                            onChange={(e) =>
                                              setUpdateLotteryTicket(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <label
                                            for="css"
                                            className="radioLable2Location lottic"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>50/50 Tickets:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`updatefiftyTicket`}
                                            value={"Yes"}
                                            checked={
                                              updatefiftyTicket === "Yes"
                                            }
                                            onChange={(e) =>
                                              setUpdateFiftyTicket(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton fifty"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation fifty"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`updatefiftyTicket`}
                                            value={"No"}
                                            checked={updatefiftyTicket === "No"}
                                            onChange={(e) =>
                                              setUpdateFiftyTicket(
                                                e.target.value
                                              )
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="form-group">
                                          <label>
                                            Rules and Regulations URL:
                                          </label>

                                          <input
                                            name="updateRulesRegUrl"
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => {
                                              setUpdateRulesRegUrl(
                                                e.target.value
                                              );
                                            }}
                                            value={updateRulesRegUrl}
                                          />
                                        </div>
                                        {error.rulesRegUrlVal && (
                                          <small className="text-danger">
                                            {error.rulesRegUrlVal}
                                          </small>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6 col-xl-4">
                                <div className="card-radio Cstm-card custmHeigh95">
                                  <div className="col-md-12">
                                    <h5>Processing Fee</h5>
                                  </div>
                                  <div class="col-md-12">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="form-group1 formgrpcustom">
                                          <label>Processing Fees Option:</label>
                                          <input
                                            type="radio"
                                            id="html"
                                            name={`processFee`}
                                            value={"1"}
                                            checked={updateProcessFee === "1"}
                                            onChange={(e) =>
                                              setUpdateProcessFee(
                                                e.target.value
                                              )
                                            }
                                            className="radioButton"
                                          />

                                          <label
                                            for="html"
                                            className="radioLableLocation"
                                          >
                                            Yes
                                          </label>

                                          <input
                                            type="radio"
                                            id="css"
                                            className="radioInput"
                                            name={`processFee`}
                                            value={"0"}
                                            checked={updateProcessFee === "0"}
                                            onChange={(e) =>
                                              setUpdateProcessFee(
                                                e.target.value
                                              )
                                            }
                                          />

                                          <label
                                            for="css"
                                            className="radioLable2Location"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      {updateProcessFee === "1" ? (
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                          <div className="form-group cstm_posi_chng">
                                            <label>Processing Fees:</label>
                                            <div className="Flex-input">
                                              <input
                                                type="text"
                                                name="updateProcessFeeAmount"
                                                id="processFeeInput"
                                                maxLength="6"
                                                className={`form-control cstm_mrgn_zero`}
                                                {...register(
                                                  "updateProcessFeeAmount",
                                                  {
                                                    required:
                                                      "Amount is required",
                                                  }
                                                )}
                                                onKeyPress={function (event) {
                                                  var regex = new RegExp(
                                                    /^\d*\.?\d*$/
                                                  );
                                                  var key = String.fromCharCode(
                                                    !event.charCode
                                                      ? event.which
                                                      : event.charCode
                                                  );
                                                  if (!regex.test(key)) {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  if (e.target.value <= 100) {
                                                    setUpdateProcessFeeAmount(
                                                      e.target.value
                                                    );
                                                  } else {
                                                    e.target.value = "";
                                                  }
                                                }}
                                                value={updateProcessFeeAmount}
                                              />
                                              {/* <div className="processing-input-icon">
                                                <i id="locationPercentIcon">%</i>
                                              </div> */}
                                              <i className="icon_pscstm">%</i>
                                            </div>
                                            {errors.updateProcessFeeAmount && (
                                              <small className="text-danger">
                                                {
                                                  errors.updateProcessFeeAmount
                                                    .message
                                                }
                                              </small>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          // <div className="row">
                          //   <div className="col-md-4">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Create Tax Receipt:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`createTaxReceipt_1`}
                          //           value={"1"}
                          //           checked={updateCreateTaxReceipt === "1"}
                          //           onChange={(e) => setUpdateCreateTaxReceipt(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`createTaxReceipt_1`}
                          //           value={"0"}
                          //           checked={updateCreateTaxReceipt === "0"}
                          //           onChange={(e) => setUpdateCreateTaxReceipt(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div>
                          //   <div className="col-md-4">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Processing Fees Option:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`processFee`}
                          //           value={"1"}
                          //           checked={updateProcessFee === "1"}
                          //           onChange={(e) => setUpdateProcessFee(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`processFee`}
                          //           value={"0"}
                          //           checked={updateProcessFee === "0"}
                          //           onChange={(e) => setUpdateProcessFee(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div>
                          //   {updateProcessFee === "1"?
                          //     <div className="col-md-4">
                          //     <div className="form-group">
                          //       <label>Processing Fees:</label>
                          //         <div className="Flex-input">
                          //           <input
                          //             style={{"width": "123px"}}
                          //             type="text"
                          //             maxLength="6"
                          //             name="updateProcessFeeAmount"
                          //             className={`form-control`}
                          //             {...register("updateProcessFeeAmount", {
                          //               required: "Amount is required",
                          //             })}
                          //             onKeyPress={function (event) {
                          //               var regex = new RegExp(/^\d*\.?\d*$/);
                          //               var key = String.fromCharCode(
                          //                 !event.charCode
                          //                   ? event.which
                          //                   : event.charCode
                          //               );
                          //               if (!regex.test(key)) {
                          //                 event.preventDefault();
                          //                 return false;
                          //               }
                          //               }}
                          //               onChange={(e) =>{
                          //                 if(e.target.value <= 100){
                          //                   setUpdateProcessFeeAmount(e.target.value)
                          //                 }else{
                          //                   e.target.value = ''
                          //                 }
                          //               }

                          //               }
                          //               value={updateProcessFeeAmount}
                          //           />
                          //           <div className="processing-input-icon">
                          //             <i>%</i>
                          //           </div>

                          //        </div>

                          //         {errors.updateProcessFeeAmount && (
                          //           <small className="text-danger">
                          //             {errors.updateProcessFeeAmount.message}
                          //           </small>
                          //         )}
                          //       </div>
                          //     </div>
                          //   :''
                          //   }
                          //   {/* <div className="col-md-3">
                          //       <div className="form-group1 formgrpcustom2">
                          //         <label>Tax Receipt Eligible:</label>
                          //         <input
                          //           type="radio"
                          //           id="html"
                          //           name={`taxReceiptEligible`}
                          //           value={"1"}
                          //           checked={updateTaxReceiptEligible === "1"}
                          //           onChange={(e) => setUpdateTaxReceiptEligible(e.target.value)}
                          //           className="radioLanButton"
                          //         />

                          //         <label for="html" className="radioLanLable">
                          //           Yes
                          //         </label>

                          //         <input
                          //           type="radio"
                          //           id="css"
                          //           className="radioLanInput"
                          //           name={`taxReceiptEligible`}
                          //           value={"0"}
                          //           checked={updateTaxReceiptEligible === "0"}
                          //           onChange={(e) => setUpdateTaxReceiptEligible(e.target.value)}
                          //         />

                          //         <label for="css" className="radioLanLable2">
                          //           No
                          //         </label>
                          //       </div>
                          //   </div> */}
                          // </div>
                        }
                      </div>
                    </form>
                    {updateSecond === false ? (
                      <div className="modalFooter d-flex justify-content-end text-right mt-5 pt-3">
                        <button
                          className="btn btn-primary"
                          onClick={() => setUpdateLocationModal(false)}
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={handleSubmit(openUpdateSecondForm)}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="modalFooter text-right mt-5 pt-3">
                        <button
                          className="btn btn-primary nextButton-size"
                          onClick={closeUpdateSecondForm}
                          id="updateLocationBackButton"
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          id="updateLocationButton"
                          className="btn btn-primary"
                          onClick={handleSubmit(updateLocation)}
                        >
                          Update Location
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Retailer;
